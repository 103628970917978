import { Tabs } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner } from "flowbite-react";
import { Modal } from "flowbite-react";
import Select from "react-select";

const SponsorDetails = () => {
  const { id } = useParams();
  const [sponsor, setSponsor] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const client = useClient();
  // eslint-disable-next-line
  const navigate = useNavigate();

  const deleteSponsor = () => {
    client(`/api/v1/module/company-management/${id}`, {
      method: "DELETE",
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Sponsor Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const editSponsor = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const data = new FormData();
    data.append("name", form.name.value);
    data.append("type", form.type.value);
    data.append("email", form.email.value);

    client("/api/v1/sponsors", {
      method: "PUT",
      json: false,
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  useEffect(() => {
    client(`/api/v1/sponsors/${id}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        setSponsor(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoading(false);
      });
  }, [id, client]);

  return (
    <PageBuilder
      name={`Sponsor Details - ${sponsor?.name ?? "loading..."}`}
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
            onClick={() => deleteSponsor()}
          >
            Delete Sponsor
          </button>
          <button
            type="button"
            className="mx-2 my-2 btn btn-secondary"
            onClick={() => setModalShow(true)}
          >
            Edit Sponsor
          </button>

          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
                // window.location.reload();
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white"
                  id="editSponsorForm"
                  onSubmit={editSponsor}
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      Edit Sponsor
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Edit Sponsor</span>
                      </button>
                    </div>
                  </div>
                  {/* <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div> */}
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="name" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Sponsor Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Sponsor Name"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          defaultValue={sponsor?.name}
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="type" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Sponsor Type
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Sponsor Type"
                          classNamePrefix="select"
                          options={["private", "government"].map((type) => ({
                            value: type.uuid,
                            label: type.name,
                          }))}
                          isSearchable={false}
                          id="type"
                          required
                          name="type"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-full mr-16">
                        <label htmlFor="email" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>}
                          Email
                        </label>
                        <textarea
                          id="email"
                          name="email"
                          placeholder="Email"
                          className="flex w-full placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          defaultValue={sponsor?.email}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : sponsor ? (
          // eslint-disable-next-line
          <Tabs.Group style="underline">
            <Tabs.Item active={true} name="Info">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      Info
                    </h6>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        UUID
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {id}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {sponsor?.name}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Email
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {sponsor?.email}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Type
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {sponsor?.type}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">
              Unable to fetch records for sponsor '{id}'
            </h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default SponsorDetails;
