import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NumberToNaira from "../utilities/numberToNaira";
import PageTitle from "./Layouts/PageTitle";
import TraineeCheckAccountStatus from "./registration-components/CheckAccountStatus";


const TraineePaymentDetails = () =>{

    let myCourses = [
        {course: "Animal Breeding", due_date: "Jul 2, 2022", amount: 100000, paid: 50000, balance: 100000, status: 0, parentId: 1, id: 2},
        {course: "Animal Breeding", due_date: "Jan 2, 2022", amount: 100000, paid: 50000, balance: 100000, status: 0, parentId: 1, id: 1},
        {course: "Ogbonge", due_date: "Feb 2, 2022", amount: 2457666, paid: 0, balance: 457666, status: 0, parentId: 2, id: 3},
        {course: "Ogbonge", due_date: "Jan 2, 2022", amount: 2457666, paid: 2000000, balance: 457666, status: 1, parentId: 2, id: 4},
        {course: "Ogbonge", due_date: "Dec 2, 2021", amount: 2457666, paid: 1000000, balance: 1457666, status: 1, parentId: 2, id: 5},
        {course: "Fashion", due_date: "Dec 2, 2021", amount: 56657, paid: 56657, balance: 0, status: 2, parentId: 3, id: 6},
    ]
	
    const {id} = useParams()
    const [editProfile, setEditProfile] = useState(false);
    const [payments, setPayments] = useState([]);
    const token = localStorage.getItem("token") 

				useEffect(()=>{
       setPayments(myCourses.filter(x => x.parentId == id))
    }, [] )

    
    return ( <div class="content container-fluid">
        <PageTitle title="Payment Details"/>  

        <TraineeCheckAccountStatus _token={token}/>

		<div class="card">
		<div class="card-body">

        <div style={{display: !editProfile ? `block` : `none`}}>
                <div className="row">

                    <div className="table-responsive">
<table className="table">
<thead>
    <tr>
    <th>Course</th>
    <th>Due Date</th>
    <th>Amount </th>
    <th>To Pay </th>
    <th>Balance </th>
    <th>Status </th>
    </tr>
</thead>
<tbody>
  {payments.map( x => 
    <tr>
    <td>{x.course}</td>
    <td>{x.due_date}</td>
    <td>{NumberToNaira(x.amount)}</td>
    <td>{NumberToNaira(x.paid)}</td>
    <td>{NumberToNaira(x.balance)}</td>
    <td>
    {x.status == 0? <span className="text-danger"><button className="btn btn-sm btn-primary">Pay</button></span> : ''}
    {x.status == 1? <span className="text-success">Paid</span> : ''}
        </td>
    </tr>
    )}  
</tbody>
</table>
                    </div>                    
                </div>
            </div>					

				</div>
				</div>

        </div>  

    )
}

export default TraineePaymentDetails;