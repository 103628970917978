import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";


const TrainerApplications = () =>{

    let myCourses = [
        {course: "Animal Breeding", applicationId: "ABC", id: 1, status: 0},
        {course: "Comedy Kits", applicationId: "ABC", id: 2, status: 1},
    ]
	
    const [editProfile, setEditProfile] = useState(false);
    
				useEffect(()=>{
       // alert("Hello world")
    }, [] )

    
    return ( <div class="content container-fluid">
        <PageTitle title="Applications"/>        
		
		<div class="card">
		<div class="card-body">

        <div style={{display: !editProfile ? `block` : `none`}}>
                <div className="row">

                    <div className="table-responsive">
<table className="table">
<thead>
    <tr>
    <th>Course Title</th>
    <th>Application ID</th>
    <th>status </th>
    </tr>
</thead>
<tbody>
  {myCourses.map( x => 
    <tr>
    <td>{x.course}</td>
    <td>{x.applicationId}</td>
    <td>
    {x.status == 0? <span className="text-danger">Pending</span> : ''}
    {x.status == 1? <span className="text-success">Approved</span> : ''}
    {x.status == 2? <span className="text-secondary">Declined</span> : ''}
        </td>
    </tr>
    )}  
</tbody>
</table>
                    </div>                    
                </div>
            </div>

	
					

				</div>
				</div>

                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                        <Link to={"/trainee/available_courses"}>Click here</Link> to apply for a course
                    </div>

        </div>  

    )
}

export default TrainerApplications;