import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { Plus, WarningCircle } from "phosphor-react";
import { Modal } from "flowbite-react";
import Select from "react-select";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";

const AdminUsers = () => {
  const {
    data: { adminUsers, permissions },
    loaders: { getAdminUsers, getPermissions },
    pagination: {
      adminUsers: { updatePage },
    },
  } = useData();

  const [modalShow, setModalShow] = useState(false);
  const [userStatus, setUserStatus] = useState({
    value: "active",
    label: "Active",
  });
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const client = useClient();

  const [userPermissions, setUserPermissions] = useState([]);

  const createUser = (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    const userInfo = {
      email: form.email.value,
      phoneNumber: form.phoneNumber.value,
      firstName: form.firstName.value,
      surname: form.surname.value,
      userType: "admin",
      permissions: userPermissions.map((perm) => perm.value),
    };

    client("/api/v1/user/management/create/admin", {
      method: "POST",
      data: userInfo,
      credentials: true,
    })
      .then((response) => {
        const res = response;
        if (res.success === true) {
          setModalShow(false);
          setLoaded(false);
          flashMessage({ message: res.message, type: "success" });
        } else {
          setModalShow(false);

          flashMessage({ message: res.message, type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response;
          setModalShow(false);

          flashMessage({ message: data.message, type: "error" });
        } else {
          setModalShow(false);

          flashMessage({
            message:
              "Something went wrong creating the user account, please try again later.",
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (!loaded) {
      getAdminUsers({
        getActive: userStatus.value === "active",
        getInActive: userStatus.value === "inactive",
        getAll: userStatus.value === "all",
      });
      getPermissions();
      setLoaded(true);
    }
  }, [loaded, getAdminUsers, getPermissions, userStatus]);

  return (
    <PageBuilder
      name="Admin Users"
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="flex items-center mx-0 btn btn-secondary"
            onClick={() => setModalShow(true)}
          >
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Create New Admin User</span>
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white"
                  onSubmit={createUser}
                  id="createUserForm"
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      New Admin User
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Create Admin User</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="firstName" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} First Name
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="surname" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Last Name
                        </label>
                        <input
                          type="text"
                          id="surname"
                          name="surname"
                          placeholder="Last Name"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="email" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>}
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="permissions" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} User
                          Permissions
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="User Permissions"
                          classNamePrefix="select"
                          options={permissions.map((permission) => ({
                            value: permission,
                            label: permission,
                          }))}
                          isSearchable={true}
                          isMulti={true}
                          id="permissions"
                          onChange={(newValue) => setUserPermissions(newValue)}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="phoneNumber" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Phone Number
                        </label>
                        <input
                          type="tel"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col">
        <div className="flex justify-between w-full px-2 py-2">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Filter Admin Users
            </span>
            <div className="flex w-full mt-4">
              <div className="flex flex-col">
                <span className="text-black">Filter by Status</span>
                <Select
                  options={[
                    { value: "all", label: "All" },
                    { value: "active", label: "Active" },
                    { value: "inactive", label: "Inactive" },
                  ]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  onChange={setUserStatus}
                  defaultValue={userStatus}
                />
              </div>
              <div className="flex flex-col">
                <button
                  type="button"
                  className="mx-2 my-4 btn btn-primary"
                  onClick={() => setLoaded(false)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <PaginatedDataTable
          title="List of Admin Users"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            { Header: "Registration Number", accessor: "registrationNumber" },
            { Header: "Full Name", accessor: "name" },
            {
              Header: "Status",
              accessor: (row) => {
                return row.active ? "active" : "inactive";
              },
            },
            {
              Header: "Role(s)",
              accessor: (row) => {
                return row.role?.join(", ") ?? "N/A";
              },
            },
            { Header: "Email Address", accessor: "email" },
            { Header: "Mobile Number", accessor: "phoneNumber" },
          ]}
          data={adminUsers[userStatus.value]?.data ?? []}
          count={adminUsers[userStatus.value]?.count ?? 0}
          currentPage={adminUsers[userStatus.value]?.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page, category: userStatus.value });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(`/admin/manage/users/details/${row.uuid}`);
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default AdminUsers;
