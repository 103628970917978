import { FileText } from "phosphor-react";
import React from "react";
import PageBuilder from "../../components/layout/PageBuilder";

const TrainingDataReports = () => {
  return (
    <PageBuilder name="Reports">
      <div className="flex flex-col w-full p-4">
        <div className="flex flex-col mb-4">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-4">
            Training Data Reports
          </span>
          <div className="flex flex-wrap">
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">General Training Applications</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">
                General Training Applications (2)
              </span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Trainee Master List</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Trainee Kit-Size List</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Trainee Kit-Size Summary</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Choice of Trainee Center</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Training Advert History</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Trainee Posting List</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Training Posting Summary</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Training Suggestion List</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Training Suggestion Summary</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Admission Letter Template</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-14">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-4">
            Payment Data Reports
          </span>
          <div className="flex flex-wrap">
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Full Payment List</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Part Payment List</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Payment List per Payer Type</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">
                Payment List per Payment Switch
              </span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Sponsor Payment Receipt</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">General Payment Summary</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">
                Payment Transaction Master List
              </span>
            </div>
          </div>
        </div>
      </div>
    </PageBuilder>
  );
};

export default TrainingDataReports;
