// import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TraineeMainLayout from "./components/trainee-dashboard/Layouts/TraineeMainLayout";
import PageNotFound from "./components/trainee-dashboard/Layouts/PageNotFound";
import TraineeDashbaord from "./components/trainee-dashboard/Dashboard";
import TraineeRegistration from "./components/trainee-dashboard/registration-components/Registration";
import TraineeProfile from "./components/trainee-dashboard/profile-components/Profile";
import TraineeCheckResult from "./components/trainee-dashboard/CheckResult";
import TraineePersonalInformation from "./components/trainee-dashboard/profile-components/PersonalProfile";
import TraineeUpdatePassword from "./components/trainee-dashboard/profile-components/UpdatePassword";
import TraineePersonalLocation from "./components/trainee-dashboard/profile-components/Location";
import TraineeNOK from "./components/trainee-dashboard/profile-components/NOK";

import TraineeKitInformation from "./components/trainee-dashboard/profile-components/Kit";
import TraineeEducationalQualifications from "./components/trainee-dashboard/profile-components/EducationalQualifications";
import TraineeProfessionalCertificates from "./components/trainee-dashboard/profile-components/ProfessionalCertificates";
import TraineeAvailableCourses from "./components/trainee-dashboard/courses-components/AvailableCourses";
import TraineeMyCourses from "./components/trainee-dashboard/courses-components/MyCourses";
import TraineeApplications from "./components/trainee-dashboard/courses-components/Applications";
import TraineeAssignments from "./components/trainee-dashboard/courses-components/Assignments";
import TraineeQuizzes from "./components/trainee-dashboard/courses-components/Quizzes";
import TraineeAnnouncements from "./components/trainee-dashboard/institution-components/Announcement";
import TraineeViewAnnouncement from "./components/trainee-dashboard/institution-components/ViewAnnouncement";
import TraineeTickets from "./components/trainee-dashboard/institution-components/Tickets";
import TraineeViewTicket from "./components/trainee-dashboard/institution-components/ViewTicket";
import TraineeCalendar from "./components/trainee-dashboard/institution-components/Calendar";
import TraineeFeedback from "./components/trainee-dashboard/Feedback";
import TraineePosting from "./components/trainee-dashboard/Posting";
import TraineePayments from "./components/trainee-dashboard/Payments";
import TraineePaymentDetails from "./components/trainee-dashboard/PaymentDetails";

import TrainerPageNotFound from "./components/trainer-dashboard/Layouts/PageNotFound";
import TrainerMainLayout from "./components/trainer-dashboard/Layouts/TraineeMainLayout";
import TrainerDashbaord from "./components/trainer-dashboard/Dashboard";
//import HostClass from "./components/trainer-dashboard/hostClass";
import TrainerRegistration from "./components/trainer-dashboard/registration-components/Registration";
import TrainerProfile from "./components/trainer-dashboard/profile-components/Profile";
import TrainerPersonalInformation from "./components/trainer-dashboard/profile-components/PersonalProfile";
import TrainerUpdatePassword from "./components/trainer-dashboard/profile-components/UpdatePassword";
import TrainerNOK from "./components/trainer-dashboard/profile-components/NOK";
import TrainerKitInformation from "./components/trainer-dashboard/profile-components/Kit";
import TrainerEducationalQualifications from "./components/trainer-dashboard/profile-components/EducationalQualifications";
import TrainerProfessionalCertificates from "./components/trainer-dashboard/profile-components/ProfessionalCertificates";
import TrainerAvailableCourses from "./components/trainer-dashboard/courses-components/AvailableCourses";
import TrainerMyCourses from "./components/trainer-dashboard/courses-components/MyCourses";
import MyStudents from "./components/trainer-dashboard/courses-components/my_students";
import TrainerApplications from "./components/trainer-dashboard/courses-components/Applications";
import TrainerAssignments from "./components/trainer-dashboard/courses-components/Assignments";
import TrainerQuizzes from "./components/trainer-dashboard/courses-components/Quizzes";
import ViewStudentsForCourse from "./components/trainer-dashboard/courses-components/viewStudents";
import TrainerAnnouncements from "./components/trainer-dashboard/institution-components/Announcement";
import TrainerViewAnnouncement from "./components/trainer-dashboard/institution-components/ViewAnnouncement";
import TrainerTickets from "./components/trainer-dashboard/institution-components/Tickets";
import TrainerViewTicket from "./components/trainer-dashboard/institution-components/ViewTicket";
import TrainerCalendar from "./components/trainer-dashboard/institution-components/Calendar";
import TrainerAddAssignments from "./components/trainer-dashboard/courses-components/AddAssignment";
import TrainerAddQuizzes from "./components/trainer-dashboard/courses-components/AddQuiz";
import TrainerAddCourses from "./components/trainer-dashboard/courses-components/AddCourses";
import TrainerVerifyEmail from "./components/trainer-dashboard/registration-components/VerifyEmail";
import TraineeVerifyEmail from "./components/trainee-dashboard/registration-components/VerifyEmail";
import TraineeViewCourse from "./components/trainee-dashboard/courses-components/ViewCourse";
import TrainerViewCourse from "./components/trainer-dashboard/courses-components/ViewCourse";
import ViewAssessment from "./components/trainer-dashboard/courses-components/viewAssessment";

import SponsorMainLayout from "./components/sponsor-dashboard/Layouts/TraineeMainLayout";
// import SponsorPageNotFound from "./components/sponsor-dashboard/Layouts/PageNotFound";
import SponsorDashbaord from "./components/sponsor-dashboard/Dashboard";
import SponsorRegistration from "./components/sponsor-dashboard/registration-components/Registration";
// import SponsorVerifyEmail from "./components/sponsor-dashboard/registration-components/VerifyEmail";
import SponsorPersonalInformation from "./components/sponsor-dashboard/profile-components/PersonalProfile";
import SponsorAvailableCourses from "./components/sponsor-dashboard/courses-components/AvailableCourses";
import SponsorViewCourse from "./components/sponsor-dashboard/courses-components/ViewCourse";
import SponsorApplications from "./components/sponsor-dashboard/courses-components/Applications";
import SponsorPayments from "./components/sponsor-dashboard/Payments";
import SponsorPaymentDetails from "./components/sponsor-dashboard/PaymentDetails";
import Benefactors from "./components/sponsor-dashboard/benefactors";

import PageWrapper from "./components/admin-dashboard/components/layout/PageWrapper";
import Step from "./components/admin-dashboard/components/layout/Step";
import ManageSponsors from "./components/admin-dashboard/pages/sponsor/ManageSponsors";
import ManageReports from "./components/admin-dashboard/pages/sponsor/ManageReports";
import Dashboard from "./components/admin-dashboard/pages/support/Dashboard";
import FAQs from "./components/admin-dashboard/pages/support/FAQs";
import TraineeFeedbackSurvey from "./components/trainee-dashboard/feedbackSurvey";
import PaymentSupport from "./components/admin-dashboard/pages/support/PaymentSupport";
import TraineeManagementDashboard from "./components/admin-dashboard/pages/trainee/TraineeManagementDashboard";
import ProspectiveTrainees from "./components/admin-dashboard/pages/trainee/ProspectiveTrainees";
import ConfirmedTrainees from "./components/admin-dashboard/pages/trainee/ConfirmedTrainees";
import AlumniRecords from "./components/admin-dashboard/pages/trainee/AlumniRecords";
import TraineeDataReports from "./components/admin-dashboard/pages/trainee/TraineeDataReports";
import TrainingAdverts from "./components/admin-dashboard/pages/training/TrainingAdverts";
import ExamConfiguration from "./components/admin-dashboard/pages/training/ExamConfiguration";
import AdmissionLetterTemplates from "./components/admin-dashboard/pages/training/AdmissionLetterTemplates";
import AdminDashboard from "./components/admin-dashboard/pages/AdminDashboard";
import ShortlistedTrainees from "./components/admin-dashboard/pages/trainee/ShortlistedTrainees";
import ApplicantDetails from "./components/admin-dashboard/pages/trainee/ApplicantDetails";
import TrainingSuggestions from "./components/admin-dashboard/pages/training/TrainingSuggestions";
import TrainingDataReports from "./components/admin-dashboard/pages/training/TrainingDataReports";
import ProspectiveSupervisors from "./components/admin-dashboard/pages/trainer/ProspectiveSupervisors";
import ConfirmedSupervisors from "./components/admin-dashboard/pages/trainer/ConfirmedSupervisors";
import TraineePostings from "./components/admin-dashboard/pages/rtc/TraineePosting";
import AssessmentAndGrading from "./components/admin-dashboard/pages/rtc/AssessmentsAndGrading";
import NotFound from "./components/admin-dashboard/pages/utility/NotFound";
import Login from "./components/admin-dashboard/pages/utility/Login";
import EntryPoint from "./components/admin-dashboard/EntryPoint";
import TrainingCenters from "./components/admin-dashboard/pages/rtc/TrainingCenters";
import TrainingCenterDetails from "./components/admin-dashboard/pages/rtc/TrainingCenterDetails";
import TrainerManagementDashboard from "./components/admin-dashboard/pages/trainer/TrainerManagementDashboard";
import TrainerDataReports from "./components/admin-dashboard/pages/trainer/TrainerDataReports";
import UserDetails from "./components/admin-dashboard/pages/users/UserDetails";
import AdminUsers from "./components/admin-dashboard/pages/users/AdminUsers";
import UserAnalytics from "./components/admin-dashboard/pages/users/UserAnalytics";
import TicketManagement from "./components/admin-dashboard/pages/support/TicketManagement";
import AnnouncementDetails from "./components/admin-dashboard/pages/institution/AnnouncementDetails";
import Announcements from "./components/admin-dashboard/pages/institution/Announcements";
import Testimonials from "./components/admin-dashboard/pages/support/Testimonials";
import FieldLocations from "./components/admin-dashboard/pages/rtc/FieldLocations";
import TrainingCenterDataReports from "./components/admin-dashboard/pages/rtc/TrainingCenterDataReports";
// import TrainingAdvertsDetails from "./components/admin-dashboard/pages/training/TrainingAdvertsDetails";
import EmptyPage from "./components/admin-dashboard/pages/EmptyPage";
// import TrainingCenterUsers from "./components/admin-dashboard/pages/rtc/TrainingCenterUsers";
import GroupTrainingApplications from "./components/admin-dashboard/pages/sponsor/GroupTrainingApplications";
import PaymentRecords from "./components/admin-dashboard/pages/finance/PaymentRecords";
import ManageCourses from "./components/admin-dashboard/pages/training/ManageCourses";
import CourseDetails from "./components/admin-dashboard/pages/training/CourseDetails";
import TrainerDetails from "./components/admin-dashboard/pages/trainer/TrainerDetails";
import TrainerSingleCourse from "./components/trainer-dashboard/courses-components/TrainerSingleCourse";
import SponsorDetails from "./components/admin-dashboard/pages/sponsor/SponsorDetails";
import Exam from "./components/trainer-dashboard/courses-components/Exam";
import TrainerAddExam from "./components/trainer-dashboard/courses-components/TrainerAddExam";
import TraineeExam from "./components/trainee-dashboard/courses-components/TraineeExam";
import Attendance from "./components/trainer-dashboard/courses-components/Attendance";
import TrainingCalendar from "./components/admin-dashboard/pages/institution/TrainingCalendar";
import FAQDetails from "./components/admin-dashboard/pages/support/FAQDetails";
import TicketDetails from "./components/admin-dashboard/pages/support/TicketDetails";
import Scores from "./components/trainer-dashboard/courses-components/Scores";
import TrainerSingleAss from "./components/trainer-dashboard/courses-components/TrainerSingleAss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TrainerSIngleExam from "./components/trainer-dashboard/courses-components/TrainerSIngleExam";
import TakeExam from "./components/trainee-dashboard/courses-components/TakeExam";
import TrainerSingleQuiz from "./components/trainer-dashboard/courses-components/TrainerSingleQuiz";
import TakeQuiz from "./components/trainee-dashboard/courses-components/TakeQuiz";
import TakeAss from "./components/trainee-dashboard/courses-components/TakeAss";
import AssignmentCourse from "./components/trainer-dashboard/courses-components/AssignmentCourse";
import TakeAss1 from "./components/trainee-dashboard/courses-components/TakeAss1";
import Forgot from "./components/admin-dashboard/pages/utility/Forgot";
import EmailConsole from "./components/admin-dashboard/pages/messaging/EmailConsole";
import SMSConsole from "./components/admin-dashboard/pages/messaging/SMSConsole";
import ResetPassword from "./components/admin-dashboard/pages/utility/ResetPassword";
import SystemUsers from "./components/admin-dashboard/pages/users/SystemUsers";
import TraineePrograms from "./components/trainee-dashboard/courses-components/TraineePrograms";
import ManagePrograms from "./components/admin-dashboard/pages/training/ManagePrograms";
import ProgramDetails from "./components/admin-dashboard/pages/training/ProgramDetails";
import ViewProgramCourses from "./components/trainee-dashboard/courses-components/ViewProgramCourses";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* Trainee Page */}
        <Route path="/trainee" element={<TraineeMainLayout />}>
          <Route index element={<TraineeDashbaord />} />
          <Route path="/trainee/dashboard" element={<TraineeDashbaord />} />
          <Route
            path="/trainee/verify_email"
            element={<TraineeVerifyEmail />}
          />
          <Route path="/trainee/register" element={<TraineeRegistration />} />
          <Route path="/trainee/profile" element={<TraineeProfile />} />
          <Route
            path="/trainee/personal_profile"
            element={<TraineePersonalInformation />}
          />
          <Route
            path="/trainee/update_password"
            element={<TraineeUpdatePassword />}
          />
          <Route
            path="/trainee/location"
            element={<TraineePersonalLocation />}
          />
          <Route path="/trainee/nok" element={<TraineeNOK />} />
          <Route
            path="/trainee/kit_information"
            element={<TraineeKitInformation />}
          />
          <Route
            path="/trainee/educational"
            element={<TraineeEducationalQualifications />}
          />
          <Route
            path="/trainee/certificates"
            element={<TraineeProfessionalCertificates />}
          />
          <Route
            path="/trainee/available_courses"
            element={<TraineeAvailableCourses />}
          />
          <Route
            path="/trainee/:id/view_course"
            element={<TraineeViewCourse />}
          />
          <Route path="/trainee/my_courses" element={<TraineeMyCourses />} />
          <Route
            path="/trainee/application"
            element={<TraineeApplications />}
          />
          <Route
            path="/trainee/program-courses/:id"
            element={<ViewProgramCourses />}
          />
          <Route path="/trainee/programs" element={<TraineePrograms />} />

          <Route path="/trainee/assignment" element={<TraineeAssignments />} />
          <Route path="/trainee/takeassignment/:id" element={<TakeAss />} />
          <Route path="/trainee/takeassignment1/:id" element={<TakeAss1 />} />
          <Route path="/trainee/quizzes" element={<TraineeQuizzes />} />
          <Route path="/trainee/takequizzes/:id" element={<TakeQuiz />} />
          <Route path="/trainee/exam" element={<TraineeExam />} />
          <Route path="/trainee/exam1/:id" element={<TakeExam />} />
          <Route
            path="/trainee/announcement"
            element={<TraineeAnnouncements />}
          />
          <Route
            path="/trainee/:id/view_announcement"
            element={<TraineeViewAnnouncement />}
          />
          <Route path="/trainee/tickets" element={<TraineeTickets />} />
          <Route
            path="/trainee/:id/view_ticket"
            element={<TraineeViewTicket />}
          />
          <Route path="/trainee/calendar" element={<TraineeCalendar />} />
          <Route path="/trainee/feedback" element={<TraineeFeedback />} />
          <Route
            path="/trainee/feedbackSurvey"
            element={<TraineeFeedbackSurvey />}
          />
          <Route path="/trainee/posting" element={<TraineePosting />} />
          <Route path="/trainee/payments" element={<TraineePayments />} />
          <Route
            path="/trainee/:id/payment_details"
            element={<TraineePaymentDetails />}
          />
          <Route
            path="/trainee/check_result"
            element={<TraineeCheckResult />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        {/* Trainer Page */}
        <Route path="/trainer" element={<TrainerMainLayout />}>
          <Route index element={<TrainerDashbaord />} />
          <Route path="/trainer/dashboard" element={<TrainerDashbaord />} />
          <Route
            path="/trainer/verify_email"
            element={<TrainerVerifyEmail />}
          />
          <Route path="/trainer/students" element={<MyStudents />} />

          <Route path="/trainer/register" element={<TrainerRegistration />} />
          <Route path="/trainer/profile" element={<TrainerProfile />} />
          <Route
            path="/trainer/personal_profile"
            element={<TrainerPersonalInformation />}
          />
          <Route
            path="/trainer/update_password"
            element={<TrainerUpdatePassword />}
          />
          <Route
            path="/trainer/location"
            element={<TraineePersonalLocation />}
          />
          <Route path="/trainer/nok" element={<TrainerNOK />} />
          <Route
            path="/trainer/kit_information"
            element={<TrainerKitInformation />}
          />
          <Route
            path="/trainer/educational"
            element={<TrainerEducationalQualifications />}
          />
          <Route
            path="/trainer/:courseUuid/:traineeUuid"
            element={<ViewAssessment />}
          />
          <Route
            path="/trainer/certificates"
            element={<TrainerProfessionalCertificates />}
          />
          <Route
            path="/trainer/available_courses"
            element={<TrainerAvailableCourses />}
          />
          <Route path="/trainer/my_courses" element={<TrainerMyCourses />} />
          <Route
            path="/trainer/single_course/:type/:id"
            element={<TrainerSingleCourse />}
          />
          <Route
            path="/trainer/:id/view_course"
            element={<TrainerViewCourse />}
          />
          <Route path="/trainer/add_course" element={<TrainerAddCourses />} />
          <Route
            path="/trainer/application"
            element={<TrainerApplications />}
          />
          <Route path="/trainer/assignment" element={<AssignmentCourse />} />
          <Route
            path="/trainer/assignment/:id"
            element={<TrainerAssignments />}
          />
          <Route
            path="/trainer/assignment1/:id"
            element={<TrainerSingleAss />}
          />
          <Route
            path="/trainer/add_assignment"
            element={<TrainerAddAssignments />}
          />
          <Route path="/trainer/attendance/:id" element={<Attendance />} />
          <Route
            path="/trainer/viewStudents/:id"
            element={<ViewStudentsForCourse />}
          />
          <Route path="/trainer/scores" element={<Scores />} />
          <Route path="/trainer/quizzes" element={<TrainerQuizzes />} />
          <Route path="/trainer/quizzes1/:id" element={<TrainerSingleQuiz />} />
          <Route path="/trainer/add_quiz" element={<TrainerAddQuizzes />} />
          <Route path="/trainer/exams" element={<Exam />} />
          <Route path="/trainer/exams1/:id" element={<TrainerSIngleExam />} />
          <Route path="/trainer/add_exam" element={<TrainerAddExam />} />
          <Route
            path="/trainer/announcement"
            element={<TrainerAnnouncements />}
          />
          <Route
            path="/trainer/:id/view_announcement"
            element={<TrainerViewAnnouncement />}
          />
          <Route path="/trainer/tickets" element={<TrainerTickets />} />
          <Route
            path="/trainer/:id/view_ticket"
            element={<TrainerViewTicket />}
          />
          <Route path="/trainer/calendar" element={<TrainerCalendar />} />
          {/* <Route path="/trainer/feedback" element={<TrainerFeedback />}/>    */}
          <Route path="*" element={<TrainerPageNotFound />} />
        </Route>

        {/* Company/Sponsor Page */}
        <Route path="/sponsor" element={<SponsorMainLayout />}>
          <Route index element={<SponsorDashbaord />} />
          <Route path="/sponsor/dashboard" element={<SponsorDashbaord />} />
          {/* <Route
            path="/sponsor/verify_email"
            element={<SponsorVerifyEmail />}
          /> */}
          <Route path="/sponsor/register" element={<SponsorRegistration />} />
          <Route
            path="/sponsor/personal_profile"
            element={<SponsorPersonalInformation />}
          />
          <Route
            path="/sponsor/available_courses"
            element={<SponsorAvailableCourses />}
          />
          <Route
            path="/sponsor/application"
            element={<SponsorApplications />}
          />
          <Route
            path="/sponsor/:id/view_course"
            element={<SponsorViewCourse />}
          />
          <Route path="/sponsor/benefactor" element={<Benefactors />} />
          <Route path="/sponsor/payments" element={<SponsorPayments />} />
          <Route
            path="/sponsor/:id/payment_details"
            element={<SponsorPaymentDetails />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        {/* Admin Page */}
        <Route index element={<Navigate to={"/admin/login"} />} />
        <Route path="/admin" element={<EntryPoint />}>
          <Route index element={<Navigate to={"/admin/login"} />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="dashboard" element={<PageWrapper />}>
            <Route index element={<AdminDashboard />} />
          </Route>
          <Route path="manage" element={<PageWrapper />}>
            <Route path="account" element={<Step />}>
              <Route path="password" element={<ResetPassword />} />
            </Route>
            <Route path="sponsor" element={<Step />}>
              <Route path="companies" element={<ManageSponsors />} />
              <Route path="details/:id" element={<SponsorDetails />} />
              <Route
                path="group-trainings"
                element={<GroupTrainingApplications />}
              />
              <Route path="reports" element={<ManageReports />} />
            </Route>
            <Route path="support" element={<Step />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="tickets" element={<Step />}>
                <Route index element={<TicketManagement />} />
                <Route path="details/:id" element={<TicketDetails />} />
              </Route>
              <Route path="faqs" element={<Step />}>
                <Route index element={<FAQs />} />
                <Route path="details/:id" element={<FAQDetails />} />
              </Route>
              <Route path="payment" element={<PaymentSupport />} />
              <Route path="testimonials" element={<Testimonials />} />
            </Route>
            <Route path="trainee" element={<Step />}>
              <Route
                path="dashboard"
                element={<TraineeManagementDashboard />}
              />
              <Route path="prospective" element={<ProspectiveTrainees />} />
              <Route path="confirmed" element={<ConfirmedTrainees />} />
              <Route path="shortlisted" element={<ShortlistedTrainees />} />
              <Route
                path="applicants/details/:id"
                element={<ApplicantDetails />}
              />
              <Route path="alumni" element={<AlumniRecords />} />
              <Route path="reports" element={<TraineeDataReports />} />
            </Route>
            <Route path="training" element={<Step />}>
              <Route path="adverts" element={<TrainingAdverts />} />
              <Route path="configure/exams" element={<ExamConfiguration />} />
              <Route
                path="admissions/templates"
                element={<AdmissionLetterTemplates />}
              />
              <Route path="courses" element={<Step />}>
                <Route index element={<ManageCourses />} />
                <Route path="details/:id" element={<CourseDetails />} />
              </Route>
              <Route path="programs" element={<Step />}>
                <Route index element={<ManagePrograms />} />
                <Route path="details/:id" element={<ProgramDetails />} />
              </Route>
              <Route path="suggestions" element={<TrainingSuggestions />} />
              <Route path="reports" element={<TrainingDataReports />} />
            </Route>
            <Route path="trainer" element={<Step />}>
              <Route
                path="dashboard"
                element={<TrainerManagementDashboard />}
              />
              <Route path="supervisors" element={<Step />}>
                <Route
                  path="prospectives"
                  element={<ProspectiveSupervisors />}
                />
                <Route path="confirmed" element={<ConfirmedSupervisors />} />
                <Route path="details/:id" element={<TrainerDetails />} />
                <Route path="reports" element={<TrainerDataReports />} />
              </Route>
            </Route>
            <Route path="rtc" element={<Step />}>
              <Route path="postings" element={<TraineePostings />} />
              <Route path="assessments" element={<AssessmentAndGrading />} />
              <Route path="locations" element={<FieldLocations />} />
              <Route path="all" element={<TrainingCenters />} />
              <Route path=":id" element={<TrainingCenterDetails />} />
              <Route path="reports" element={<TrainingCenterDataReports />} />
              {/* <Route path="users" element={<TrainingCenterUsers />} /> */}
            </Route>
            <Route path="institution" element={<Step />}>
              <Route path="announcements" element={<Announcements />} />
              <Route path="calendar" element={<TrainingCalendar />} />
              <Route path="forums" element={<EmptyPage />} />
              <Route
                path="announcement/:id"
                element={<AnnouncementDetails />}
              />
            </Route>
            <Route path="payment" element={<Step />}>
              <Route path="types" element={<EmptyPage />} />
              <Route path="records" element={<PaymentRecords />} />
            </Route>
            <Route path="users" element={<Step />}>
              <Route path="admins" element={<AdminUsers />} />
              <Route path="system" element={<SystemUsers />} />
              <Route path="details/:id" element={<UserDetails />} />
              <Route path="stats" element={<UserAnalytics />} />
            </Route>
            <Route path="messaging" element={<Step />}>
              <Route path="emails" element={<EmailConsole />} />
              <Route path="smses" element={<SMSConsole />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
