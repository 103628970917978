import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavMobile from "../Layouts/NavMobile";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";
import urls from "../../utilities/urls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TrainerAssignments = () => {
  const { id } = useParams();
  const [ass, setAss] = useState([]);
  const navigate = useNavigate();

  const delAss = async (uuid) => {
    console.log(uuid);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(urls.api + "assignments/" + uuid, config)
      .then(() => {
        getAss();
        toast.success("deleted successfully");
      })
      .catch(() => {
        getAss();
      });
    getAss();
  };

  const [editProfile, setEditProfile] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));

  const getAss = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      urls.api + "assignments/all/" + id,
      config
    );
    const data = await response.data;
    const data1 = await data.data;
    setAss(data1);
  };

  console.log(ass);
  console.log(token);

  useEffect(() => {
    getAss();
  }, []);

  return (
    <div>
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="Assignments" />
          <ToastContainer />

          <div className="row">
            <div class="col-xl-12 col-sm-6 col-12 mb-3">
              <Link to={"/trainer/add_assignment"} className="btn btn-primary">
                {" "}
                Add New <i className="fa fa-plus-square"></i>{" "}
              </Link>
            </div>
          </div>

          <div style={{ display: !editProfile ? `block` : `none` }}>
            <div className="row">
              <div class="col-xl-12 col-md-12 col-12">
                <div class="card">
                  <div class="card-body">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Question </th>
                            <th>Due Date </th>
                            <th>Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ass.map((x) => (
                            <tr>
                              <td>{x.topic}</td>
                              <td>{x.due_date}</td>
                              <td className="w-25">
                                <Link to={`/trainer/assignment1/${x.uuid}`}>
                                  <span className="text-success">
                                    {" "}
                                    View <i className="fa fa-eye"></i>{" "}
                                  </span>
                                </Link>
                                <br />{" "}
                                <span
                                  className="text-danger"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    delAss(x.uuid);
                                  }}
                                >
                                  {" "}
                                  Delete <i className="fa fa-times"></i>{" "}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerAssignments;
