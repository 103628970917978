import { FileText } from "phosphor-react";
import React from "react";
import PageBuilder from "../../components/layout/PageBuilder";
import { triggerDownloadPipe } from "../../helpers";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";

const TrainingCenterDataReports = () => {
  const Links = {
    RTC_LIST_EXPORT: {
      link: "/api/v1/rtc-reports/rtc-list-reports",
      filename: "RTC_LIST_EXPORT",
    },
  };

  const client = useClient();

  const download = ({ link, filename }) => {
    flashMessage({ message: "Generating report", type: "info" });
    client(link, {
      method: "GET",
      res: "file",
    })
      .then(triggerDownloadPipe(filename, "xlsx"))
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response;
          flashMessage({ message: data.message, type: "error" });
        } else {
          flashMessage({
            message:
              "Something went wrong generating your report, please try again later.",
            type: "error",
          });
        }
      });
  };
  return (
    <PageBuilder name="Reports">
      <div className="flex flex-col w-full p-4">
        <div className="flex flex-col mb-4">
          <div className="flex flex-wrap">
            {/* <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Training Feedback Comparison</span>
            </div> */}
            {/* <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">
                Overall Cumulative Trainee Result
              </span>
            </div> */}
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() => download(Links.RTC_LIST_EXPORT)}
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">RTC List Report</span>
            </button>
            {/* <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">RTC Posting List</span>
            </div>
            <div className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]">
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">RTC Posting Summary</span>
            </div> */}
          </div>
        </div>
      </div>
    </PageBuilder>
  );
};

export default TrainingCenterDataReports;
