import React, { useEffect, useState } from "react";
// import Select from "react-select";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { useNavigate } from "react-router-dom";

const AlumniRecords = () => {
  const {
    data: { alumni },
    loaders: { getAlumni },
    pagination: {
      alumni: { updatePage },
    },
  } = useData();

  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      getAlumni();
      setLoaded(true);
    }
  }, [loaded, getAlumni]);
  return (
    <PageBuilder name="Alumni Records">
      {/* <div className="flex w-full">
        <div className="flex flex-col w-3/5">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
            Filter Alumni Records
          </span>
          <div className="flex w-full mb-10">
            <div className="flex flex-col mr-[1.9rem]">
              <span className="text-black">Select Year</span>
              <Select
                options={[]}
                isSearchable={false}
                className="flex items-center justify-center min-w-[15rem]"
                classNamePrefix="select"
                placeholder="2020"
              />
            </div>
            <div className="flex flex-col">
              <span className="text-black">Course Type</span>
              <Select
                options={[]}
                isSearchable={false}
                className="flex items-center justify-center min-w-[15rem]"
                classNamePrefix="select"
                placeholder="All"
              />
            </div>
            <div className="flex flex-col">
              <button type="submit" className="mx-2 my-4 btn btn-primary">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <PaginatedDataTable
        title="List of Alumni Records"
        exports={{ excel: true, messagingList: true }}
        refresh
        columns={[
          { Header: "Trainee UUID", accessor: "userUuid" },
          { Header: "Trainee First Name", accessor: "firstName" },
          { Header: "Trainee Last Name", accessor: "surName" },
          { Header: "Course Title", accessor: "courseTitle" },
          { Header: "Assignment Score", accessor: "assignmentScore" },
          { Header: "Quiz Score", accessor: "quizScore" },
          { Header: "Exam Score", accessor: "examScore" },
          { Header: "Grade", accessor: "grade" },
          { Header: "Email Address", accessor: "email" },
          { Header: "Gender", accessor: "gender" },
          { Header: "Mobile Number", accessor: "phoneNumber" },
          { Header: "Completion Date", accessor: "completionDate" },
        ]}
        data={alumni?.data}
        count={alumni?.count}
        currentPage={alumni?.page}
        setCurrentPage={(page) => {
          updatePage({ newPage: page });
          setLoaded(false);
        }}
        onRowClick={(row) => {
          navigate(
            `/admin/manage/trainee/applicants/details/${row.applicationUuid}`
          );
        }}
      />
    </PageBuilder>
  );
};

export default AlumniRecords;
