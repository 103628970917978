import { Outlet, Link } from "react-router-dom";
import TraineeFooter from "./Footer";
import TraineeHeader from "./Header";
import TraineeNavMenu from "./NavMenu";

const TraineeMainLayout = () => {
  return (
    <>
      <TraineeHeader />
      <TraineeNavMenu />
      <div class="page-wrapper">
        <Outlet />
        <TraineeFooter />
      </div>
    </>
  );
};

export default TraineeMainLayout;
