import { useEffect, useState } from "react";
import { GrFormNextLink } from "react-icons/gr";
import { Link } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import urls from "../../utilities/urls";
import NavMobile from "../Layouts/NavMobile";
import axios from "axios";

const MyStudents = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [myCourses, setMyCourses] = useState([]);

  const getMyCourses = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      urls.api + "courses/all/my-courses",
      config
    );
    const data = response.data.data.courses;
    console.log(data);
    setMyCourses(data);
  };

  const [editProfile, setEditProfile] = useState(false);

  useEffect(() => {
    // alert("Hello world")
    getMyCourses();
  }, []);

  return (
    <div class=" overflow-x">
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w-100">
          <div>
            <PageTitle title="Select Course" />

            <div class="card overflow-x">
              <div class="card-body">
                <div style={{ display: !editProfile ? `block` : `none` }}>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Courses</th>
                          </tr>
                        </thead>
                        {/* <Link to={`/trainer/view_course/${myCourses.uuid}`}> */}
                        <tbody>
                          {myCourses.map((x) => (
                            <tr>
                              <td className="w-50 text-sm text-secondary">
                                {x.status == 0 ? (
                                  <span className="text-danger"></span>
                                ) : (
                                  <Link
                                    style={{
                                      display: "block",
                                      width: "100%",
                                      color: "black",
                                    }}
                                    to={`/trainer/viewStudents/${x.courseUuid}`}
                                    className="text-success"
                                  >
                                    <p style={{ color: "black" }}> {x.title}</p>
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* </Link> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyStudents;
