import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: {},
    loginTime: "",
    userToken: "",
  },
  reducers: {
    login: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload.user;
      state.loginTime = moment.now();
      state.userToken = payload.user.token;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = {};
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
