import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PageTitle from "./Layouts/PageTitle";
import TraineeCheckAccountStatus from "./registration-components/CheckAccountStatus";

const TraineeDashboard = () => {
  const search = useLocation().search;
  const id_ = new URLSearchParams(search).get("punch");

  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    if (id_ !== null && id_.length > 2) {
      localStorage.setItem("token", id_);
      setToken(id_);
    } else {
      if (localStorage.getItem("token") !== null) {
        setToken(localStorage.getItem("token"));
      }
    }
  }, []);


  return (
    <div class="content container-fluid">
      <PageTitle title="Dashboard" />

      <TraineeCheckAccountStatus _token={token} />

      <div class="row">
        <div class="col-xl-3 col-sm-6 col-12">
          <div class="card">
            <div class="card-body">
              <Link to={"/sponsor/personal_profile"} class="dash-widget-header">
                <span class="text-primary">
                <i class="fas fa-solid fa-user-tie fa-2x"></i>
                </span>
                <div class="dash-widget-info">
                  <h6 class="text-muted">Profile Updates</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 col-12">
          <div class="card">
            <div class="card-body">
              <Link to={"/sponsor/available_courses"} class="dash-widget-header">
                <span class="text-primary">
                  <i class="fas fa-users fa-2x"></i>
                </span>
                <div class="dash-widget-info">
                  <h6 class="text-muted">Group Training Sponsorships</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 col-12">
          <div class="card">
            <div class="card-body">
              <Link to={"/sponsor/payments"} class="dash-widget-header">
                <span class="text-primary">
                <i class="fas fa-solid fa-paperclip fa-2x"></i>
                </span>
                <div class="dash-widget-info">
                  <h6 class="text-muted">Applications</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 col-12">
          <div class="card">
            <div class="card-body">
              <Link to={"/sponsor/payments"} class="dash-widget-header">
                <span class="text-primary">
                <i class="fas fa-money-check fa-2x"></i>
                </span>
                <div class="dash-widget-info">
                  <h6 class="text-muted">Payments</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeDashboard;
