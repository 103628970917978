import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { FiEdit2 } from "react-icons/fi";
import axios from "axios";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import TrainerEditExamScore from "./TrainerEditExamScore";
import TrainerEditQuizScore from "./TrainerEditQuizScore";
import TrainerEditAss from "./TrainerEditAss";
import { toast } from "react-toastify";

const TrainerSingleCourse = () => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [currentResult, setCurrentResult] = useState("");
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    exam: 0,
    quiz: 0,
    assignment: 0,
  });

  const [exam_score, setExamScore] = useState();
  const [quiz_score, setQuizScore] = useState();
  const [assignment_score, setAssignmentScore] = useState();

  const [uuid, setUuid] = useState("");

  const [getCourse, setGetCourse] = useState([]);
  const total =
    parseInt(details.exam) +
    parseInt(details.assignment) +
    parseInt(details.quiz);

  const [studentsOfferingCourse, setStudentOfferingCourse] = useState([]);
  const [ass, setAss] = useState([]);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const postOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  };
  const onHandle = (e) => {
    const data = { ...details };
    data[e.target.id] = e.target.value;
    setDetails(data);
  };
  const onSubmit = async (e, traineeUuid) => {
    e.preventDefault();
    const info = {
      user_uuid: traineeUuid,
      course_uuid: id,
      assignment_score: details.assignment,
      quiz_score: details.quiz,
      exam_score: details.exam,
    };
    console.log(info);
    await axios
      .post(urls.api + "results/" + traineeUuid, info, config)
      .then((result) => {
        console.log(result);
        toast.success("Result Submitted");
      })
      .catch((error) => {
        toast.error("Unable to submit result");
      });
  };
  const getAss = async () => {
    const response = await axios.get(urls.api + "assignments/all", config);
    const data = await response.data;
    const data1 = await data.data;
    setAss(data1);
  };

  const { id } = useParams();

  const getResults = async (userId) => {
    await axios
      .get(urls.api + "results/trainee/" + userId, postOptions)
      .then((response) => {
        console.log("What the heck could the result be");
        console.log(response);
        setCurrentResult(userId);
        if (response.data.data.results.length) {
          const data = response.data.data.results;
          data.map((key) => {
            if (key.courseUuid === id) {
              setAssignmentScore(parseInt(key.assignmentScore));
              setExamScore(parseInt(key.examScore));
              setQuizScore(parseInt(key.quizScore));
            }
          });
          console.log(assignment_score, quiz_score, exam_score);
        }
      })
      .catch((error) => {
        console.log("an error has occured");
        console.log(error);
      });
  };
  const getCourseDetails = () => {
    setLoading(true);
    fetch(urls.api + "courses/" + id)
      .then((response) => response.json())
      // console.log(response);
      .then((json) => {
        console.log(json);
        if (json.success === true) {
          setGetCourse(json.data.title);
        } else {
          //alert("An error occured!")
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const getTraineeOfferingCourse = async () => {
    await axios
      .get(urls.api + "courses/trainee/" + id, config)
      .then((result) => {
        console.log("This is a result data", result.data.data);
        setStudentOfferingCourse(result.data.data.users);
        console.log(studentsOfferingCourse);
      })
      .catch((error) => {
        console.log("an error has occured", error);
      });
  };
  const getProfile = async () => {
    await axios
      .get(urls.api + "profile/my-profile", postOptions)
      .then((result) => {
        setUuid(result.data.data.user.bio.uuid);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getProfile();
    getTraineeOfferingCourse();
    getCourseDetails();
    getAss();
  }, [quiz_score, exam_score, assignment_score]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div class="content d-flex justify-content-center flex-column bg-red container-fluid">
      <PageTitle title="Course Details" />

      <div class="col rounded w-100">
        <p>{getCourse}</p>
        <div className="card">
          <div className="card-body">
            <div className="flex gt1">
              <h3>Trainee</h3>
              <div className="flex"></div>
            </div>
            <div className="row">
              <div className=" col-lg-2 ">
                <strong>Trainee Name</strong>
              </div>
              <div className=" col-lg-2 ">
                {" "}
                <strong>Exam Score</strong>
              </div>
              <div className=" col-lg-2 ">
                <strong>Quiz Score</strong>
              </div>
              <div className=" col-lg-2 ">
                <strong>Assignment Score</strong>
              </div>
            </div>

            {/* Databases query here  */}
            <div
              style={{
                height: "300px",
                margin: "4px 4px",
                padding: "4px",
                overflowX: "hidden",
                overflowY: "auto",
                textAlign: "justify",
              }}
            >
              {studentsOfferingCourse ? (
                studentsOfferingCourse.map((student) => {
                  return (
                    <>
                      <form
                        onSubmit={(e) => {
                          onSubmit(e, student.uuid);
                        }}
                      >
                        <div className="row py-3">
                          <div className=" col-lg-2 px-3">
                            <p>{student.firstName + " " + student.surname}</p>
                          </div>
                          <div className=" col-lg-2 ">
                            <input
                              defaultValue={
                                student.uuid === currentResult && exam_score
                              }
                              required
                              id="exam"
                              type="number"
                              className="form-control"
                              onChange={(e) => {
                                onHandle(e);
                              }}
                            />
                          </div>
                          <div className=" col-lg-2 ">
                            <input
                              defaultValue={
                                student.uuid === currentResult && quiz_score
                              }
                              required
                              id="quiz"
                              type="number"
                              className="form-control"
                              onChange={(e) => {
                                onHandle(e);
                              }}
                            />
                          </div>
                          <div className=" col-lg-2 ">
                            <input
                              defaultValue={
                                student.uuid === currentResult &&
                                assignment_score
                              }
                              required
                              id="assignment"
                              type="number"
                              className="form-control"
                              onChange={(e) => {
                                onHandle(e);
                              }}
                            />
                          </div>

                          <div className=" col-lg-4 ">
                            <i
                              onClick={() => {
                                getResults(student.uuid);
                              }}
                              className="fas fa-eye mx-1"
                              style={{ float: "left", marginTop: "7px" }}
                            ></i>
                            <input
                              type="submit"
                              value="Save"
                              className="btn btn-success"
                              style={{ marginTop: "-2px", float: "left" }}
                            />
                            <Link to={`/trainer/${id}/${student.uuid}`}>
                              <button
                                className=" mx-2 btn btn-secondary"
                                style={{
                                  marginTop: "-2px",
                                  float: "right",
                                  fontSize: "15px",
                                }}
                              >
                                View Assessment
                              </button>
                            </Link>
                          </div>
                        </div>
                      </form>
                    </>
                  );
                })
              ) : (
                <>
                  <center>
                    <div className="card">
                      <div className="card-body">
                        <h2>No Trainee available for this course</h2>
                      </div>
                    </div>
                  </center>
                </>
              )}
              {/* Databases query ends here  */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col w-50 rounded">
          <Link to={`/trainer/attendance/${id}`} style={{ color: "black" }}>
            <div className="card">
              <div className="card-header">
                <h1>Attendance</h1>
              </div>
              <div className="card-body">
                <p>List of Students</p>
              </div>
            </div>
          </Link>
        </div>
        <div class="col w-50 rounded">
          <Link to="#" style={{ color: "black" }}>
            <div className="card">
              <div className="card-header">
                <h1>Scores</h1>
              </div>
              <div className="card-body">
                <p>List of Students</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrainerSingleCourse;
