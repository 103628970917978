import { Tabs } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner } from "flowbite-react";
import { Modal } from "flowbite-react";

const AnnouncementDetails = () => {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const client = useClient();
  const navigate = useNavigate();

  const deleteAnnouncement = () => {
    client(`/api/v1/announcements/${id}`, {
      method: "DELETE",
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Announcement Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const editAnnouncement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const data = new FormData();
    data.append("title", form.title.value);
    data.append("banner", image);
    data.append("body", form.body.value);

    client("/api/v1/announcements", {
      method: "POST",
      json: false,
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  useEffect(() => {
    client(`/api/v1/announcements/${id}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        setAnnouncement(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoading(false);
      });
  }, [id, client]);

  return (
    <PageBuilder
      name={`Announcement Details - ${announcement?.title ?? "loading..."}`}
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
            onClick={() => deleteAnnouncement()}
          >
            Delete Announcement
          </button>
          <button
            type="button"
            className="mx-2 my-2 btn btn-secondary"
            onClick={() => setModalShow(true)}
          >
            Edit Announcement
          </button>

          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
                // window.location.reload();
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white"
                  id="editAnnouncementForm"
                  onSubmit={editAnnouncement}
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      Edit Announcement
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Edit Announcement</span>
                      </button>
                    </div>
                  </div>
                  {/* <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div> */}
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="title" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Announcement
                          Title
                        </label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Announcement Title"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          defaultValue={announcement?.title}
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="banner" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Announcement
                          Banner
                        </label>
                        <div className="flex">
                          <div className="relative w-full">
                            <input
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                              id="banner"
                              name="banner"
                              type="file"
                              accept="image/*"
                              onChange={(e) => setImage(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-full mr-16">
                        <label htmlFor="body" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>}
                          Body
                        </label>
                        <textarea
                          id="body"
                          name="body"
                          placeholder="Body"
                          className="flex w-full placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          defaultValue={announcement?.body}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : announcement ? (
          // eslint-disable-next-line
          <Tabs.Group style="underline">
            <Tabs.Item active={true} title="Info">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      Info
                    </h6>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        UUID
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {id}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Title
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {announcement?.title}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Body
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {announcement?.body}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">
              Unable to fetch records for announcement '{id}'
            </h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default AnnouncementDetails;
