import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NumberToNaira from "../utilities/numberToNaira";
import PageTitle from "./Layouts/PageTitle";
import axios from "axios";
import urls from "../utilities/urls";
import { toast } from "react-toastify";

const TraineePayments = () => {
  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState("");
  const [payNow, setPayNow] = useState("");
  const [myCourses, setMyCourses] = useState([]);
  const [generatedErr, setGeneratedErr] = useState(false);
  const [clickedCoursePayment, setClickedCoursePayment] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [benefactor, setBenefactors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [allBatch, setAllBatch] = useState([]);
  const [allCourse, setAllCourse] = useState([]);
  const [rrr, setRRR] = useState();

  let bactchIds = [];
  let courseNames = [];
  function makePayment(uuid) {
    postPay(uuid);
    try {
      let paymentEngine = window.RmPaymentEngine.init({
        key: process.env.REACT_APP_REMITA_PUBLIC_KEY,
        processRrr: true,
        transactionId: Math.floor(Math.random() * 1101233), // Replace with a reference you generated or remove the entire field for us to auto-generate a reference for you. Note that you will be able to check the status of this transaction using this transaction Id
        extendedData: {
          customFields: [
            {
              name: "rrr",
              value: payNow,
            },
          ],
        },
        onSuccess: function (response) {
          console.log("callback Successful Response", response);
          confirmPaymentForAuto();
          // navigate("/trainee/my_courses");
        },
        onError: function (response) {
          console.log("callback Error Response", response);
        },
        onClose: function () {
          console.log("closed");
        },
      });
      paymentEngine.showPaymentWidget();
    } catch (error) {
      toast.error("Unable to make payment");
      console.log(error);
    }
  }
  const postPay = async (uuid) => {
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(urls.api + "payment/batch-generateRRR/" + uuid, postOptions)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.success === true) {
          setPayNow(json.data[0].rrr);
          localStorage.setItem("rrr", application);
        } else {
          toast.error("Unable to make payment");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      });
  };
  const confirmPaymentForAuto = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(urls.api + "payment/confirm/" + payNow, config)
      .then((result) => {
        if (result.data.message !== "confirmed") {
          toast.warning(result.data.data.message);
          console.log(result);
        } else {
          toast.success(result.data.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const confirmPayment = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(urls.api + "payment/confirm/" + application, config)
      .then((result) => {
        console.log("working on resutt", result);
        console.log(result.data.data.status);
        toast.warning(result.data.data.status);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchApplications = async () => {
    axios
      .get(urls.api + "application/company-benefactors/", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        console.log(result);
        setBenefactors(result.data.data.benefactors);
        {
          result.data.data.benefactors.map((each) => {
            if (
              !bactchIds.includes(each.batchId) &&
              !courseNames.includes(each.course || each.program)
            ) {
              courseNames.push(each.course || each.program);
              bactchIds.push({
                batchId: each.batchId,
                course: each.course || each.program,
                status: each.applicationStatus,
              });
              courseNames.push(each.course);
            }
          });
          setAllBatch(bactchIds);
          setAllCourse(courseNames);
          console.log(allBatch);
          console.log(bactchIds);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoaded(true);
  };

  const generateErr = async (uuid) => {
    setClickedCoursePayment(uuid);

    console.log(clickedCoursePayment);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(urls.api + "payment/batch-generateRRR/" + uuid, postOptions)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.success === true) {
          setGeneratedErr(true);
          setApplication(json.data[0].rrr);
          localStorage.setItem("rrr", application);
          toast.success("Successfully Generated RRR");
          console.log("fetching the rrr");
        } else if (json.message !== null) {
        } else {
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        console.log("done");
      });
  };

  useEffect(() => {
    if (!loaded) {
      fetchApplications();
    }
  }, [loaded, benefactor, allBatch, allCourse]);

  return (
    <div class="content container-fluid">
      <PageTitle title="Payments" />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginTop: "-60px" }}>
          {" "}
          {application ? <p>RRR: {application}</p> : ""}
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div
            style={{
              height: "600px",
              margin: "4px 4px",
              padding: "4px",
              overflowX: "hidden",
              overflowY: "auto",
              textAlign: "justify",
            }}
          >
            <div>
              <div className="row">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {/* <th>Course</th> */}
                        <th>Course</th>
                        <th>Status</th>
                        <th>Payment Status</th>
                        <th>Make Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {/* {allCourse.length ? (
                        allCourse.map((key) => {
                          return (
                            <>
                              <tr>
                                {" "}
                                <td>{key}</td> */}
                      {allBatch.length ? (
                        allBatch.map((each) => {
                          return (
                            <>
                              <tr>
                                <td>{each.course}</td>
                                <td>
                                  {each.status == "prospective" ? (
                                    <span className="text-warning">
                                      Prospective
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {each.status == "confirmed" ? (
                                    <span className="text-success">
                                      Confirmed
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {each.status == "shortlisted" ? (
                                    <span className="text-warning">
                                      Shortlisted
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  {/* {each.status == "prospective" ? (
                                    <span className="text-warning">
                                      Pennding Approval
                                    </span>
                                  ) : (
                                    ""
                                  )} */}
                                  {each.status == "shortlisted" ? (
                                    <span className="text-warning">
                                      Pending Payment
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {each.status == "confirmed" ? (
                                    <span className="text-success">Paid</span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  {each.status !== "confirmed" &&
                                    each.status !== "prospective" && (
                                      <>
                                        {" "}
                                        <button
                                          style={{ float: "left" }}
                                          className=" rounded py-1 px-2"
                                          value={each}
                                          onClick={() => {
                                            makePayment(each.batchId);
                                          }}
                                        >
                                          Pay Now
                                        </button>
                                        {each.batchId ===
                                        clickedCoursePayment ? (
                                          <>
                                            <button
                                              style={{
                                                display: generatedErr
                                                  ? `inline-flex`
                                                  : `none`,
                                              }}
                                              className=" rounded py-1 px-2"
                                              value={each}
                                              onClick={() => {
                                                confirmPayment();
                                              }}
                                            >
                                              Confirm Payment
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              style={{ display: "inline-flex" }}
                                              className=" rounded py-1 px-2"
                                              value={each}
                                              onClick={() => {
                                                generateErr(each.batchId);
                                              }}
                                            >
                                              Generate RRR
                                            </button>
                                          </>
                                        )}
                                      </>
                                    )}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}{" "}
                      {/* </tr>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}{" "} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineePayments;
