import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDetails, reset } from "../../../features/details/detailsSlice";
import { Outlet, Link, useNavigate } from "react-router-dom";
import ErrorMessages from "../../utilities/ErrorMessages";
import Images from "../../utilities/Images";
import urls from "../../utilities/urls";
import axios from "axios";
import { toast } from "react-toastify";

const TraineeHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { details, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.details
  );

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [dp, setDp] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const postOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  };
  const getProfile = async () => {
    await axios
      .get(urls.api + "profile/my-profile", postOptions)
      .then((result) => {
        setFirstName(result.data.data.user.bio.firstName);
        setDp(result.data.data.user.bio.avatar);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProfile();
    if (isError) {
      console.log(message);
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {}, []);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          //alert(json.message)
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else {
          alert(ErrorMessages.UnkownRetry);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  return (
    <div class="header bg-white">
      <div className="float-left">
        <a href="javascript:void(0);" id="toggle_btn">
          <i class="fas fa-align-left text-dark"></i>
        </a>
      </div>
      <div className="float-right mb-3">
        <ul class="nav user-menu desk-">
          <li class="adj-left nav-item dropdown mt-2">
            <a
              href="javascript:void(0)"
              class="dropdown-toggle user-link  nav-link"
              data-toggle="dropdown"
            >
              <span class="user-img mb-3">
                <img
                  class="rounded-circle"
                  src={dp == null ? Images.avatar : dp}
                  width="40"
                  alt="User"
                />
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right ">
              <span class="dropdown-item" to="/sponsor/personal_profile">
                Hi, {firstName}
              </span>
              <Link class="dropdown-item" to="/sponsor/personal_profile">
                Profile
              </Link>
              <a onClick={() => logOut()} class="dropdown-item" href="#">
                Logout{" "}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TraineeHeader;
