import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";


const TrainerKitInformation = () =>{
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [gender, setGender] = useState(null)
    const [dateOfBirth, setDateOfBirth] = useState(null)
    const [accountTypeList, setAccountTypeList] = useState([{id: 1, type: "trainee", label: "Trainee"},{id: 2, type: "trainer", label: "Trainer"}])
    const [accountType, setAccountType] = useState(null)
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertTitle, setAlertTitle] = useState(null);

	const [nextBtn, setNextBtn] = useState(0)
	const [foundError, setFoundError] = useState(false)

	
    const [editProfile, setEditProfile] = useState(false);
		const EditBtn = () => {
		setEditProfile(true)
			}

    const [shoeSize, setShoeSize] = useState(null)
	const [overallSize, setOverallSize] = useState(null)
	const [tShirtSize, setTShirtSize] = useState(null)
	const addKitBtn = () =>{
        setShowAlert(false)
        setLoading(true)
		setFoundError(true)
    
        const postOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': true,
				'Authorization': 'Bearer '+token                 
            },
            body: JSON.stringify({
				shoeSize: shoeSize,
				overallSize: overallSize,
				tShirtSize: tShirtSize
			})
        };

        fetch(urls.api + 'profile/kit-update', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if(json.success === true) {
                    setAlertMessage(json.message)
					setFoundError(false)
                }
                else if(json.message !== null) {
                    setAlertMessage(json.message)
					setFoundError(true)
                }
                else{
                    setAlertMessage(ErrorMessages.UnkownRetry)
					setFoundError(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false));          
    }

    const getUserProfile = () =>{
        setFoundError(false)
        setAlertMessage(null)
        setLoading(true)
    
        const postOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Credentials': true
            }
        };
    
        fetch(urls.api + 'profile/my-profile', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if(json.success === true) {
                    //setProfile(json.data)
                    let xkit = json.data.user.kit
                    setShoeSize(xkit.shoeSize)
                    setOverallSize(xkit.overallSize)
                    setTShirtSize(xkit.tShirtSize)
                }                                    
                else {
                    console.log(json.message)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false));      
       
    }

    const successMsg = () =>{
		return (<SweetAlert 
			success
			title={alertMessage}
			onConfirm={() => {setFoundError(false); setAlertMessage(null); setEditProfile(false);}}
			/> )
		}
		
		const errorMsg = () =>{
			return (<SweetAlert 
				error
				title={alertMessage}
				onConfirm={() => {setFoundError(false); setAlertMessage(null);}}
				/>)
			}
		
				useEffect(()=>{
                    getUserProfile()
    }, [] )

    
    return ( <div class="content container-fluid">
        <PageTitle title="Kit Information"/>        
		
		<div class="card">
		<div class="card-body">

        <div style={{display: !editProfile ? `block` : `none`}}>
                <div className="row">

                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Shoe Size</h6>
					<label>{shoeSize} </label>					
					</div>

                    <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>T-shirt Size</h6>
					<label>{tShirtSize} </label>					
					</div>

                    <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Overall Size</h6>
					<label>{overallSize} </label>					
					</div>

                    <div className='col-md-12'>
                        <button onClick={() => EditBtn()} className="btn btn-block btn-success">Edit <i className="fa fa-pen"></i> </button>
                        </div>

                </div>
            </div>

		{/* Profile edit start */}
		<div style={{display: editProfile ? `block` : `none`}}>
			<h4>Edit</h4>
			<div className="row">
                    
           	
            <div class="col-xl-6 col-sm-6 col-12 mb-3">
					<label>Shoe Size</label>
					<select className="form-control" onChange={(e) => setShoeSize(e.target.value)}>
					<option value={""}>Select</option>
					<option value={"s"}>Small (S)</option>
					<option value={"m"}>Medium (M)</option>
					<option value={"l"}>Large (L)</option>
					<option value={"xl"}>Extra Large (XL)</option>
						</select>
					</div>
					
					<div class="col-xl-6 col-sm-6 col-12 mb-3">
					<label>Overall Size</label>
					<select className="form-control" onChange={(e) => setOverallSize(e.target.value)}>
					<option value={""}>Select</option>
					<option value={"s"}>Small (S)</option>
					<option value={"m"}>Medium (M)</option>
					<option value={"l"}>Large (L)</option>
					<option value={"xl"}>Extra Large (XL)</option>
						</select>
					</div>
					
					<div class="col-xl-6 col-sm-6 col-12 mb-3">
					<label>T-shirt Size</label>
					<select className="form-control" onChange={(e) => setTShirtSize(e.target.value)}>
					<option value={""}>Select</option>
					<option value={"s"}>Small (S)</option>
					<option value={"m"}>Medium (M)</option>
					<option value={"l"}>Large (L)</option>
					<option value={"xl"}>Extra Large (XL)</option>
						</select>
					</div>

                        <div className='col-md-12'>
                        <button onClick={() => addKitBtn() } style={{display: loading? `none` : `block` }} className="btn btn-block btn-success">Update <i className="fa fa-check"></i> </button>
                        <button style={{display: loading? `block` : `none` }} className="btn btn-block btn-success"> <Spinner/> </button>
                        </div>

                     
					</div>
					</div>
					{/* Profile edit end */}


					

				</div>
				</div>
                <div className="col">

{!foundError && alertMessage !== null ? successMsg() : '' }

{foundError && alertMessage !== null? errorMsg() : '' }

</div>
        </div>  

    )
}

export default TrainerKitInformation;