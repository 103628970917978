import { Pagination, Tabs } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner } from "flowbite-react";
import { format } from "date-fns";

const ApplicantDetails = () => {
  const { id } = useParams();
  const [applicant, setApplicant] = useState(null);
  const [loading, setLoading] = useState(true);
  const client = useClient();
  const navigate = useNavigate();

  const [loadingTraineeResults, setLoadingTraineeResults] = useState(false);
  const [traineeResults, setTraineeResults] = useState({
    results: [],
    page: 1,
    count: 1,
    maxPage: 1,
  });

  const rejectApplication = () => {
    client("/api/v1/module/trainee-management/update-application-status", {
      method: "PATCH",
      data: {
        applicationsUuid: [applicant?.applicationUuid],
        applicationStatus: "reject",
      },
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Application Rejected Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const shortlistCandidate = () => {
    client("/api/v1/module/trainee-management/update-application-status", {
      method: "PATCH",
      data: {
        applicationsUuid: [applicant?.applicationUuid],
        applicationStatus: "shortlisted",
      },
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Candidate Shortlisted Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const getTraineeResults = (page, uuid) => {
    setLoadingTraineeResults(true);
    client(`/api/v1/results/trainee/${uuid}?page=${page}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        if (res.success === true) {
          setTraineeResults({
            ...traineeResults,
            results: res.data.results,
            count: res.data.meta.total,
            page: page,
            maxPage: res.data.meta.totalPages,
          });
          setLoadingTraineeResults(false);
        } else {
          setLoadingTraineeResults(false);
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoadingTraineeResults(false);
      });
  };

  useEffect(() => {
    client(`/api/v1/module/trainee-management/trainee-detail/${id}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        if (res.success === true) {
          setApplicant(res.data);
          getTraineeResults(traineeResults.page, res.data?.user?.uuid);
          setLoading(false);
        } else {
          setApplicant(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, client, traineeResults.page]);

  return (
    <PageBuilder
      name={`Applicant Details - ${
        applicant?.user?.firstName
          ? `${applicant?.user?.firstName} ${applicant?.user?.surname}`
          : "loading..."
      }`}
      extras={
        applicant !== null ? (
          applicant?.applicationStatus === "prospective" ? (
            <div className="flex flex-row w-full h-full py-4">
              <button
                type="button"
                className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
                onClick={() => rejectApplication()}
              >
                Reject Application
              </button>
              <button
                type="button"
                className="mx-2 my-2 btn btn-secondary"
                onClick={() => shortlistCandidate()}
              >
                Shortlist Candidate
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : applicant !== null ? (
          // eslint-disable-next-line
          <Tabs.Group style="underline">
            <Tabs.Item active={true} title="Profile">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      Profile
                    </h6>
                    <span
                      className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                        applicant?.user?.verified
                          ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                          : "text-red-500 bg-red-200 border-red-400"
                      }`}
                    >
                      {" "}
                      {applicant?.user?.verified ? "verified" : "unverified"}
                    </span>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        First Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.firstName}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Last Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.surname}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Registration Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.regNumber}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Application Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {id}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Email Address
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.email}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Phone Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.phoneNumber}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        State of Origin
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.stateOfOrigin}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        LGA of Origin
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.lgaOfOrigin}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Gender
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.gender?.toUpperCase()}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Date of Birth
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.birthDate
                          ? format(
                              new Date(applicant?.user?.birthDate),
                              "dd/MM/yyyy"
                            )
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Application Date
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.createdAt
                          ? format(
                              new Date(applicant?.user?.createdAt),
                              "dd/MM/yyyy"
                            )
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Unique ID
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.uuid}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mb-12">
                  <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mb-3">
                    Address
                  </h6>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Home Address
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.residentialAddress}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        State of Residence
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.user?.stateOfResidence}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        LGA of Residence
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        N/A
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mb-12">
                  <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mb-3">
                    Next of Kin / Guardian
                  </h6>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        First Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.nextOfKin?.firstName}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Last Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.nextOfKin?.surname}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Phone Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.nextOfKin?.phoneNumber}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Address
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {applicant?.nextOfKin?.address}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Relationship
                      </span>
                      <span className="text-base text-gray-400 !font-[200] capitalize">
                        {applicant?.nextOfKin?.relationship}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mb-12">
                  <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mb-3">
                    Kit Information
                  </h6>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Shoe Size
                      </span>
                      <span className="text-base text-gray-400 !font-[200] uppercase">
                        {applicant?.kit?.shoeSize}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Overall Size
                      </span>
                      <span className="text-base text-gray-400 !font-[200] uppercase">
                        {applicant?.kit?.overallSize}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        T-Shirt Size
                      </span>
                      <span className="text-base text-gray-400 !font-[200] uppercase">
                        {applicant?.kit?.tShirtSize}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item title="Education">
              <div className="flex flex-col w-9/12">
                {applicant?.education?.map((ed) => (
                  <div className="flex items-center justify-between py-3 border-t-0 border-b border-primary-400">
                    <div className="flex flex-col">
                      <h4 className="text-2xl font-regular">
                        {ed.qualificationObtained}
                      </h4>
                      <span className="text-lg text-primary-400">
                        {`${ed.level} | ${ed.institutionName} | ${format(
                          new Date(ed.startDate),
                          "yyyy"
                        )} | ${format(new Date(ed.endDate), "yyyy")}`}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <a
                        href={ed.document}
                        type="button"
                        className="mx-2 my-2 btn btn-secondary"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.Item>
            <Tabs.Item title="Certifications">
              <div className="flex flex-col w-9/12">
                {applicant?.professionalCerts?.map((cert) => (
                  <div className="flex items-center justify-between py-3 border-t-0 border-b border-primary-400">
                    <div className="flex flex-col">
                      <h4 className="text-2xl font-regular">
                        {cert.certificationName}
                      </h4>
                      <span className="text-lg text-primary-400">
                        {`${cert.type} | ${cert.awardingBody} | ${format(
                          new Date(cert.awardDate),
                          "MMM yyyy"
                        )} - ${format(new Date(cert.expiryDate), "MMM yyyy")}`}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <a
                        href={cert.document}
                        type="button"
                        className="mx-2 my-2 btn btn-secondary"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.Item>
            <Tabs.Item title="Results">
              <div>
                {loadingTraineeResults ? (
                  <div className="flex flex-row justify-center w-100">
                    <Spinner size="xl" className="z-1" />
                  </div>
                ) : traineeResults.count === 0 ? (
                  <span className="block w-full my-5 text-2xl text-center">
                    No results found for this trainee
                  </span>
                ) : (
                  <div className="flex flex-col w-9/12">
                    {traineeResults.results.map((result) => (
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <h4 className="mr-2 text-2xl font-regular">
                            {result?.courseTitle}
                          </h4>
                          <span
                            className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                              result?.grade >= 80
                                ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                                : result?.grade >= 50 && result?.grade <= 80
                                ? "text-blue-500 bg-blue-200 border-blue-400"
                                : "text-yellow-500 bg-yellow-200 border-yellow-400"
                            }`}
                          >
                            {result?.grade}
                          </span>
                        </div>
                        <p className="text-lg text-primary-500">
                          Assignment Score: {result?.assignmentScore} {""}
                          Quiz Score: {result?.quizScore} {""}
                          Exam Score: {result?.examScore} {""}
                        </p>
                        <span className="text-primary-500">
                          Completed: {result?.completionDate}
                        </span>
                      </div>
                    ))}
                    <div className="flex items-center justify-end text-left">
                      <Pagination
                        currentPage={traineeResults.page}
                        layout="table"
                        onPageChange={(page) =>
                          setTraineeResults({ ...traineeResults, page: page })
                        }
                        showIcons={true}
                        totalPages={traineeResults.maxPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Tabs.Item>
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">Unable to fetch records for user '{id}'</h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default ApplicantDetails;
