import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDetails, reset } from "../../../features/details/detailsSlice";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";
import states from "../../utilities/NigeriaStates";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import NavMobile from "../Layouts/NavMobile";
import axios from "axios";

const TraineePersonalLocation = () => {
  const dispatch = useDispatch();
  const { details, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.details
  );
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [gender, setGender] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [accountTypeList, setAccountTypeList] = useState([
    { id: 1, type: "trainee", label: "Trainee" },
    { id: 2, type: "trainer", label: "Trainer" },
  ]);
  const [accountType, setAccountType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const [nextBtn, setNextBtn] = useState(0);
  const [foundError, setFoundError] = useState(false);

  const [statesList, setStatesList] = useState([]);

  const [stateOfResidence, setStateOfResidence] = useState(null);
  const [lgaOfResidence, setLgaOfResidence] = useState(null);
  const [stateOfOrigin, setStateOfOrigin] = useState(null);
  const [lgaOfOrigin, setLgaOfOrigin] = useState(null);
  const [homeAddress, setHomeAddress] = useState(null);
  const [stateOfResidenceStr, setStateOfResidenceStr] = useState(null);
  const [lgaOfResidenceStr, setLgaOfResidenceStr] = useState(null);
  const [stateOfOriginStr, setStateOfOriginStr] = useState(null);
  const [lgaOfOriginStr, setLgaOfOriginStr] = useState(null);
  const [homeAddressStr, setHomeAddressStr] = useState(null);

  const [bio, setBio] = useState([]);
  const [kit, setKit] = useState([]);
  const [nextOfKin, setNextOfKin] = useState([]);
  const [education, setEducation] = useState([]);
  const [professionalCerts, setProfessionalCerts] = useState([]);

  const getProfile = async () => {
    await axios
      .get(urls.api + "profile/my-profile", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        setStateOfResidenceStr(result.data.data.user.bio.stateOfResidence);
        setLgaOfOriginStr(result.data.data.user.bio.lgaOfOrigin);
        setHomeAddress(result.data.data.user.bio.residentialAddress);
        setStateOfOriginStr(result.data.data.user.bio.stateOfOrigin);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getProfile();
    if (isError) {
      console.log(message);
    }
    if (isSuccess) {
      console.log(details);
      setStateOfResidenceStr(details.data.user.bio.stateOfResidence);
      setLgaOfOriginStr(details.data.user.bio.lgaOfOrigin);
      setHomeAddress(details.data.user.bio.residentialAddress);
      setStateOfOriginStr(details.data.user.bio.stateOfOrigin);
    } else if (!details) {
      window.location.href = urls.home;
    }
    dispatch(getDetails());
    return () => {
      reset();
    };
  }, [dispatch, isSuccess]);

  //Get My LGA
  //   const getMyLga = (stateId, y) => {
  //     alert(stateId);
  //     const postOptions = {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Credentials": true,
  //       },
  //     };

  //     fetch(urls.api + "state/" + parseInt(stateId) + "/local-areas", postOptions)
  //       .then((response) => response.json())
  //       .then((json) => {
  //         if (json.success === true) {
  //           let lgas = json.data.filter((x) => x.id == parseInt(y));
  //           setLgaOfOriginStr(JSON.parse(JSON.stringify(lgas[0].name)));
  //           setStateOfOriginStr(
  //             JSON.parse(
  //               JSON.stringify(states.filter((x) => x.id == stateId)[0].name)
  //             )
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //       .finally(() => console.log("LGA"));
  //   };

  //Get LGA 1
  const [lgaList, setLgaList] = useState([]);
  const getLga = (x) => {
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "state/" + x + "/local-areas", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          //alert(json.message)
        } else if (json.success === true) {
          setLgaList(json.data);
        } else if (json.message !== null) {
          //alert(json.message)
        } else {
          //alert(ErrorMessages.UnkownRetry)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("LGAs"));
  };

  //Get LGA 2
  const [lgaList2, setLgaList2] = useState([]);
  const getLga2 = (x) => {
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "state/" + x + "/local-areas", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          //alert(json.message)
        } else if (json.success === true) {
          setLgaList2(json.data);
        } else if (json.message !== null) {
          //alert(json.message)
        } else {
          //alert(ErrorMessages.UnkownRetry)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("LGAs"));
  };

  const updateAddressBtn = () => {
    setFoundError(false);
    setAlertMessage(null);
    setLoading(true);

    const postOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        stateOfResidence: stateOfResidence,
        lgaOfResidence: lgaOfResidence,
        stateOfOrigin: stateOfOrigin,
        lgaOfOrigin: lgaOfOrigin,
        homeAddress: homeAddress,
      }),
    };

    fetch(urls.api + "profile/address-update", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setAlertMessage(json.message);
          setFoundError(false);
        } else if (json.message !== null) {
          setAlertMessage(json.message);
          setFoundError(true);
        } else {
          setAlertMessage(ErrorMessages.UnkownRetry);
          setFoundError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const [editProfile, setEditProfile] = useState(false);
  const EditBtn = () => {
    setEditProfile(true);
  };

  const successMsg = () => {
    return (
      <SweetAlert
        success
        title={alertMessage}
        onConfirm={() => {
          setFoundError(false);
          setAlertMessage(null);
          setEditProfile(false);
        }}
      />
    );
  };

  const errorMsg = () => {
    return (
      <SweetAlert
        error
        title={alertMessage}
        onConfirm={() => {
          setFoundError(false);
          setAlertMessage(null);
        }}
      />
    );
  };

  useEffect(() => {
    // getUserProfile();
    setStatesList(states);
    //alert(JSON.parse(stateOfOriginStr))
  }, []);

  // useEffect(()=>{
  // 	getMyLga(stateOfOrigin, lgaOfOrigin)

  // })

  return (
    <div class="">
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="Location Information" />

          <div class="card">
            <div class="card-body">
              <div style={{ display: !editProfile ? `block` : `none` }}>
                <div className="row">
                  <div class="col-xl-6 col-sm-6 col-12 mb-3">
                    <h6>State of Residence</h6>
                    <label> {stateOfResidenceStr} </label>
                  </div>

                  {/* <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>LGA of Residence</h6>
					<label> {lgaOfResidence} </label>					
					</div> */}

                  <div class="col-xl-6 col-sm-6 col-12 mb-3">
                    <h6>Home Address</h6>
                    <label> {homeAddress} </label>
                  </div>

                  <div class="col-xl-6 col-sm-6 col-12 mb-3">
                    <h6>State of Origin</h6>
                    <label> {stateOfOriginStr} </label>
                  </div>

                  <div class="col-xl-6 col-sm-6 col-12 mb-3">
                    <h6>LGA of Origin</h6>
                    <label> {lgaOfOriginStr} </label>
                  </div>

                  <div className="col-md-12">
                    <button
                      onClick={() => EditBtn()}
                      className="btn btn-block btn-success"
                    >
                      Edit <i className="fa fa-pen"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>

              {/* Profile edit start */}
              <div style={{ display: editProfile ? `block` : `none` }}>
                <h4>Edit </h4>
                <div className="row">
                  <div class="col-xl-6 col-sm-6 col-12 mb-3">
                    <span>*</span> <label>State of Residence</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setStateOfResidence(e.target.value);
                        getLga(e.target.value);
                      }}
                    >
                      <option value={""}>Select</option>
                      {statesList.map((x) => (
                        <option value={x.id}>{x.name}</option>
                      ))}
                    </select>
                  </div>

                  <div class="col-xl-6 col-sm-6 col-12 mb-3">
                    <span>*</span> <label>LGA of Residence</label>
                    <select
                      className="form-control"
                      onChange={(e) => setLgaOfResidence(e.target.value)}
                    >
                      <option value={""}>Select</option>
                      {lgaList.map((x) => (
                        <option value={x.id}>{x.name}</option>
                      ))}
                    </select>
                  </div>

                  <div class="col-xl-12 col-sm-12 col-12 mb-3">
                    <span>*</span> <label>Home Address</label>
                    <textarea
                      className="form-control"
                      onChange={(e) => setHomeAddress(e.target.value)}
                    ></textarea>
                  </div>

                  <div class="col-xl-6 col-sm-6 col-12 mb-3">
                    <span>*</span> <label>State of Origin</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setStateOfOrigin(e.target.value);
                        getLga2(e.target.value);
                      }}
                    >
                      <option value={""}>Select</option>
                      {statesList.map((x) => (
                        <option value={x.id}>{x.name}</option>
                      ))}
                    </select>
                  </div>

                  <div class="col-xl-6 col-sm-6 col-12 mb-3">
                    <span>*</span> <label>LGA of Origin</label>
                    <select
                      className="form-control"
                      onChange={(e) => setLgaOfOrigin(e.target.value)}
                    >
                      <option value={""}>Select</option>
                      {lgaList2.map((y) => (
                        <option value={y.id}>{y.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-12">
                    <button
                      onClick={() => updateAddressBtn()}
                      style={{ display: loading ? `none` : `block` }}
                      className="btn btn-block btn-success"
                    >
                      Update <i className="fa fa-check"></i>{" "}
                    </button>
                    <button
                      style={{ display: loading ? `block` : `none` }}
                      className="btn btn-block btn-success"
                    >
                      {" "}
                      <Spinner />{" "}
                    </button>
                  </div>
                </div>
              </div>
              {/* Profile edit end */}
            </div>
          </div>

          <div className="col">
            {!foundError && alertMessage !== null ? successMsg() : ""}

            {foundError && alertMessage !== null ? errorMsg() : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineePersonalLocation;
