import React, { Component } from 'react';

const Spinner = () => {   

    return (
        <div class="row justify-content-center">
        <span class="spinner-border spinner-border-sm" role="status"></span>
        </div>
    );
}

export default Spinner;