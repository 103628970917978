import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { CalendarBlank } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import Select from "react-select";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { AsyncData, useData } from "../../providers/DataProvider";
import Datetime from "react-datetime";
import { Spinner } from "flowbite-react";
// import { format } from "date-fns";

const TraineeManagementDashboard = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const [chartsData, setChartsData] = useState();

  const {
    data: { stats, states },
    loaders: { getStats, getStates },
  } = useData();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      getStats();
      getStates();
      setLoaded(true);
    }
  }, [loaded, getStats, getStates]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const genderData = {
    labels: ["Male", "Female", "Uncategorized"],
    datasets: [
      {
        label: "# of Applications",
        data: [
          chartsData?.gender?.m ?? 0,
          chartsData?.gender?.f ?? 0,
          chartsData?.gender[""] ?? 0,
        ],
        borderColor: [
          "rgba(200, 250, 186, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        backgroundColor: [
          "rgba(200, 250, 186, 0.3)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(255, 99, 132, 0.5)",
        ],
        borderWidth: 1,
        barThickness: 25,
        maxBarThickness: 25,
      },
    ],
  };

  const ageLabels = ["10-18", "18-30", "30-50", "50-70", "70+"];

  const ageData = {
    labels: ageLabels,
    datasets: [
      {
        label: "# of Applications",
        data: ageLabels.map((label) => chartsData?.age[label] ?? 0),
        borderColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        barThickness: 25,
        maxBarThickness: 25,
      },
    ],
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const applicationMonthData = {
    labels: months,
    datasets: [
      {
        label: "# of Applications",
        data: months.map((month) => chartsData?.applicationMonth[month] ?? 0),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(137, 230, 184, 0.2)",
          "rgba(79, 49, 48, 0.2)",
          "rgba(216, 189, 138, 0.2)",
          "rgba(0, 141, 213, 0.2)",
          "rgba(228, 63, 111, 0.2)",
          "rgba(55, 63, 81, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(137, 230, 184, 1)",
          "rgba(79, 49, 48, 1)",
          "rgba(216, 189, 138, 1)",
          "rgba(0, 141, 213, 1)",
          "rgba(228, 63, 111, 1)",
          "rgba(55, 63, 81, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const educationLevelData = {
    labels: [
      "Primary School",
      "Secondary School",
      "Tertiary Institution",
      "N/A",
    ],
    datasets: [
      {
        label: "# of Applications",
        data: [
          chartsData?.educationLevel?.primary ?? 0,
          chartsData?.educationLevel?.secondary ?? 0,
          chartsData?.educationLevel?.tertiary ?? 0,
          chartsData?.educationLevel?.none ?? 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const stateOfOriginData = {
    labels: states.map((state) => state.name),
    datasets: [
      {
        label: "# of Applications",
        data: states.map((state) => chartsData?.stateOfOrigin[state.name]),
        backgroundColor: [
          "#8594D6",
          "#C8A7B0",
          "#A3A1BA",
          "#69A7B5",
          "#C19871",
          "#515A90",
          "#605D83",
          "#6D87B0",
          "#99DA44",
          "#9494B3",
          "#AE92B5",
          "#101918",
          "#17632F",
          "#7BE09B",
          "#BC8A94",
          "#7775E6",
          "#8886E9",
          "#A0A0BA",
          "#9EE37D4",
          "#7E92C8",
          "#DFC853",
          "#FDB09B",
          "#C9A6B9",
          "#C285FF",
          "#FFFCF9",
          "#C9D2E4",
          "#62E4D3",
          "#B0A1CE",
          "#FAE1DF",
          "#B4A7B2",
          "#B9C5CB",
          "#4D50F9",
          "#F7F7F9",
          "#A6F2DF",
          "#FFFD82",
          "#FCD9E9",
        ],
        borderColor: [
          "#576BC7",
          "#8A5665",
          "#4E4C67",
          "#A3A1BA",
          "#8E653E",
          "#121420",
          "#515A90",
          "#1B2432",
          "#436614",
          "#3B3B54",
          "#513B56",
          "#101918",
          "#17632F",
          "#135327",
          "#4E2D33",
          "#1B198A",
          "#1F1C9B",
          "#4D4D6A",
          "#358600",
          "#222E50",
          "#9B861C",
          "#FC7A57",
          "#6D435A",
          "#340068",
          "#FF850A",
          "#3C4F76",
          "#136F63",
          "#3E2F5B",
          "#DE372B",
          "#30292F",
          "#5D737E",
          "#03045E",
          "#E86A92",
          "#41E2BA",
          "#8F8C00",
          "#ED217C",
        ],
        borderWidth: 1,
      },
    ],
  };

  const zones = [
    "North Central",
    "North East",
    "North West",
    "South East",
    "South South",
    "South West",
  ];

  const regionData = {
    labels: zones,
    datasets: [
      {
        label: "# of Applications",
        data: zones.map((zone) => chartsData?.region[zone] ?? 0),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const [chartsStatus, setChartsStatus] = useState();
  const [chartsDateRange, setChartsDateRange] = useState();
  const [loadingChartsData, setLoadingChartsData] = useState();
  const [loadedChartsData, setLoadedChartsData] = useState(false);

  const client = useClient();

  const generateCharts = async () => {
    if (chartsStatus?.value === undefined) {
      flashMessage({
        message: "Please select the trainee category to generate charts for!",
        type: "error",
      });
    } else if (
      chartsDateRange?.startDate === undefined ||
      chartsDateRange?.endDate === undefined
    ) {
      flashMessage({
        message: "Please select the duration to generate charts for!",
        type: "error",
      });
    } else {
      setLoadingChartsData(true);
      await client(`/api/v1/stats/trainee/${chartsStatus?.value}`, {
        method: "POST",
        data: {
          startDate: chartsDateRange?.startDate,
          endDate: chartsDateRange?.endDate,
        },
        credentials: true,
      })
        .then((res) => {
          if (res.success === true) {
            setChartsData(res.data);
            setLoadingChartsData(false);
            setLoadedChartsData(true);
          } else {
            flashMessage({ message: res.message, type: "error" });
            setLoadingChartsData(false);
          }
        })
        .catch((err) => {
          console.log(err);
          flashMessage({ message: err.message, type: "error" });
          setLoadingChartsData(false);
        });
    }
  };

  return (
    <PageBuilder name="Trainee Dashboard">
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full px-2 py-2 mb-10">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
            Trainee Application Summary
          </span>
          <div className="flex flex-wrap justify-center w-full sm:justify-start">
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-gray-400">
                Prospective Trainee Applications
              </span>
              <span className="text-4xl text-black sm:mt-3">
                <AsyncData>{stats?.trainees?.prospective ?? 0}</AsyncData>
              </span>
            </div>
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-gray-400">
                Shortlisted Trainee Applications
              </span>
              <span className="text-4xl text-black sm:mt-3">
                <AsyncData>{stats?.trainees?.shortlisted ?? 0}</AsyncData>
              </span>
            </div>
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-gray-400">
                Confirmed Trainee Applications
              </span>
              <span className="text-4xl text-black sm:mt-3">
                <AsyncData>{stats?.trainees?.confirmed ?? 0}</AsyncData>
              </span>
            </div>
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-gray-400">Alumni</span>
              <span className="text-4xl text-black sm:mt-3">
                <AsyncData>{stats?.trainees?.alumni ?? 0}</AsyncData>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full px-2 py-2 mb-3">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
            Statistics
          </span>
          <span className="mt-3 text-black">Trainee Category</span>
          <div className="flex flex-wrap items-center max-w-screen-sm">
            <div className="flex flex-col">
              <Select
                options={[
                  { label: "All", value: "all" },
                  { label: "Prospective Applications", value: "prospective" },
                  { label: "Confirmed Applications", value: "confirmed" },
                  { label: "Shortlisted Applications", value: "shortlisted" },
                ]}
                className="flex items-center justify-center w-[15rem] sm:w-[26rem] mr-2 my-2"
                classNamePrefix="select"
                placeholder="Trainee List"
                isSearchable={false}
                onChange={setChartsStatus}
              />
            </div>
            <div className="flex w-full mb-4">
              <div className="flex flex-col min-w-[24rem] mr-16">
                <label htmlFor="cost" className="mb-1 text-left">
                  Start Date
                </label>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "2020/02/12",
                      id: "start_date",
                      name: "start_date",
                      required: true,
                      autoComplete: "off",
                    }}
                    timeFormat={false}
                    dateFormat={"Y/MM/DD"}
                    onChange={(value) =>
                      setChartsDateRange({
                        ...chartsDateRange,
                        startDate: value.toISOString(),
                      })
                    }
                  />
                  <CalendarBlank
                    weight="bold"
                    className="z-10 w-5 h-5 -ml-10"
                  />
                </div>
              </div>
              <div className="flex flex-col min-w-[24rem]">
                <label htmlFor="type" className="mb-1 text-left">
                  End Date
                </label>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "2022/02/12",
                      id: "end_date",
                      name: "end_date",
                      required: true,
                      autoComplete: "off",
                    }}
                    timeFormat={false}
                    dateFormat={"Y/MM/DD"}
                    onChange={(value) =>
                      setChartsDateRange({
                        ...chartsDateRange,
                        endDate: value.toISOString(),
                      })
                    }
                  />
                  <CalendarBlank
                    weight="bold"
                    className="z-10 w-5 h-5 -ml-10"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row">
              <button
                type="button"
                className="btn btn-primary mx-1 my-0 w-[15rem] sm:w-[12rem]"
                onClick={() => generateCharts()}
              >
                Generate Charts
              </button>
              <button
                type="button"
                className="btn btn-primary mx-1 my-0 w-[15rem] sm:w-[12rem]"
                onClick={async () => {
                  await generateCharts();
                  setTimeout(() => window.print(), 1500);
                }}
              >
                Download Chart as PDF
              </button>
            </div>
          </div>
        </div>
        {loadedChartsData ? (
          loadingChartsData ? (
            <Spinner size={"xl"} className="z-1" />
          ) : (
            <>
              <div className="flex flex-col w-full sm:flex-row">
                <div className="flex flex-col w-full px-2 py-2 mb-3 sm:w-1/2">
                  <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
                    Gender Distribution
                  </span>
                  <div className="flex p-3 mt-3 border rounded-sm">
                    <Bar options={options} data={genderData} />
                  </div>
                </div>
                <div className="flex flex-col w-full px-2 py-2 mb-3 sm:w-1/2">
                  <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
                    Age Distribution
                  </span>
                  <div className="flex p-3 mt-3 border rounded-sm">
                    <Bar options={options} data={ageData} />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full sm:flex-row">
                <div className="flex flex-col w-full px-2 py-2 mb-3 sm:w-1/2">
                  <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
                    Month of Application Distribution
                  </span>
                  <div className="flex p-3 mt-3 border rounded-sm">
                    <Doughnut data={applicationMonthData} />
                  </div>
                </div>
                <div className="flex flex-col w-full px-2 py-2 mb-3 sm:w-1/2">
                  <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
                    Highest Level of Education Distribution
                  </span>
                  <div className="flex p-3 mt-3 border rounded-sm">
                    <Doughnut data={educationLevelData} />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full sm:flex-row">
                <div className="flex flex-col w-full px-2 py-2 mb-3 sm:w-1/2">
                  <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
                    Regional Distribution
                  </span>
                  <div className="flex p-3 mt-3 border rounded-sm">
                    <Doughnut data={regionData} />
                  </div>
                </div>
                <div className="flex flex-col w-full px-2 py-2 mb-3 sm:w-1/2">
                  <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
                    State of Origin Distribution
                  </span>
                  <div className="flex p-3 mt-3 border rounded-sm">
                    <Doughnut data={stateOfOriginData} />
                  </div>
                </div>
              </div>
            </>
          )
        ) : (
          <></>
        )}
      </div>
    </PageBuilder>
  );
};

export default TraineeManagementDashboard;
