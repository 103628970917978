import { Outlet, Link } from "react-router-dom";

const PageTitle = (props) => {   

    return (
        <div class="page-header">
					<div class="row">
						<div class="col-12">
							<h3 class="page-title">{props.title}</h3>
						</div>
					</div>
				</div>
    );
}

export default PageTitle;