/* eslint-disable react-hooks/exhaustive-deps */
import { CalendarBlank, Minus, Plus, WarningCircle } from "phosphor-react";
import { Tabs, Modal, Tooltip } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner } from "flowbite-react";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import Datetime from "react-datetime";
import { format } from "date-fns";
import { useData } from "../../providers/DataProvider";

const ProgramDetails = () => {
  const { id } = useParams();
  const [program, setProgram] = useState(null);
  const {
    data: { trainingCenters },
    loaders: { getTrainingCenters },
  } = useData();
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [numInstallments, setNumInstallments] = useState(1);
  const [sessions, setSessions] = useState({
    1: { startDate: null, endDate: null, payCloseDate: null },
  });
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [rtcs, setRtcs] = useState([]);

  const navigate = useNavigate();
  const client = useClient();

  useEffect(() => {
    if (!loaded) {
      getTrainingCenters();
      client(`/api/v1/program/${id}`, {
        method: "GET",
        credentials: true,
      })
        .then((res) => {
          if (res.success === true) {
            setProgram(res.data);
            setNumInstallments(res.data.noOfInstallments);
            setSelectedCourses(
              res.data.courses.map((course) => ({
                value: course.courseUuid,
                label: course.title,
              }))
            );
            setRtcs(
              res.data.regionalTrainingCentres.map((rtc) => ({
                value: rtc.uuid,
                label: rtc.name,
              }))
            );
            setSessions(
              res.data.calendars.length > 0
                ? Object.assign(
                    {},
                    res.data.calendars.map((calendar) => ({
                      startDate: calendar?.startDate,
                      endDate: calendar?.endDate,
                      payCloseDate: calendar?.payCloseDate,
                    }))
                  )
                : { 0: { startDate: null, endDate: null, payCloseDate: null } }
            );
            setLoading(false);
          } else {
            setProgram(null);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          flashMessage({ message: err.message, type: "error" });
          setLoading(false);
        });
      setLoaded(true);
    }
  }, [id, client, loaded, getTrainingCenters]);

  const [modalShow, setModalShow] = useState(false);

  const deleteProgram = () => {
    client(`/api/v1/program/${id}`, {
      method: "DELETE",
      credentials: true,
    })
      .then(() => {
        navigate(-1);
        flashMessage({
          message: "Program Deleted Successfully",
          type: "success",
          delay: 1000,
        });
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const editProgram = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;
    const data = new FormData();
    data.append("programName", form.name.value);
    data.append("cost", form.cost.value * 100);
    data.append("noOfInstallments", numInstallments);
    data.append(
      "minimumDeposit",
      form.minimumDeposit.value
        ? form.minimumDeposit.value * 100
        : form.minimumDeposit.value
    );

    if (form.minimumDeposit.value > form.cost.value) {
      flashMessage({
        message:
          "Minimum Deposit should be less than or equal to the cost of the program",
        type: "error",
      });
    }
    Object.keys(sessions).forEach((key, index) =>
      Object.keys(sessions[key]).forEach((sessionKey) =>
        data.append(
          `calendars[${index}][${sessionKey}]`,
          format(new Date(sessions[key][sessionKey]), "yyyy-MM-dd")
        )
      )
    );
    rtcs.forEach((rtc, index) => data.append(`rtcUuids[${index}]`, rtc.value));
    selectedCourses.forEach((key, index) =>
      data.append(`courseUuids[${index}]`, key.value)
    );

    client(`/api/v1/program/${id}/update`, {
      method: "POST",
      json: false,
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  const loadOptionsCourses = async (inputValue, callback) => {
    const { data } = await client(`/api/v1/search/course?q=${inputValue}`);

    const parsedCourses = data.map((course) => ({
      value: course.courseUuid,
      label: course.title,
    }));

    callback(parsedCourses);
  };

  return (
    <PageBuilder
      name={`Program Details - ${program?.name ?? "loading..."}`}
      extras={
        program ? (
          <div className="flex flex-row w-full h-full py-4">
            <button
              type="button"
              className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
              onClick={() => deleteProgram()}
            >
              Delete
            </button>
            <button
              type="button"
              className="mx-2 my-2 btn btn-secondary"
              onClick={() => setModalShow(true)}
            >
              Edit Program
            </button>
            <React.Fragment>
              <Modal
                show={modalShow}
                onClose={() => {
                  setModalShow(false);
                }}
                size="5xl"
              >
                <Modal.Body>
                  <form
                    className="flex flex-col bg-white max-h-[75vh] overflow-y-scroll"
                    onSubmit={editProgram}
                    id="editProgramForm"
                  >
                    <div className="flex items-center justify-between px-5">
                      <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                        Edit Program
                      </h1>
                      <div className="flex">
                        <button
                          type="button"
                          className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                          onClick={() => setModalShow(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="flex items-center mx-0 btn btn-secondary"
                        >
                          <span>Edit Program</span>
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center w-full px-5">
                      <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                      <span>
                        All fields in asterisk (
                        {<span className="text-red-600">*</span>}) are required.
                      </span>
                    </div>
                    <div className="flex flex-col w-auto px-5 my-4 bg-white">
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label htmlFor="title" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Program
                            Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Program Name"
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            defaultValue={program?.name}
                            required
                          />
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label htmlFor="title" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Program
                            Cost (₦)
                          </label>
                          <input
                            type="number"
                            id="cost"
                            name="cost"
                            placeholder="Program Cost"
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            defaultValue={
                              isNaN(
                                Number(
                                  (program?.cost.toString() ?? "")
                                    .slice(1)
                                    .split("")
                                    .filter((n) => !isNaN(Number(n)))
                                    .join("")
                                )
                              )
                                ? 0
                                : Number(
                                    (program?.cost.toString() ?? "")
                                      .slice(1)
                                      .split("")
                                      .filter((n) => !isNaN(Number(n)))
                                      .join("")
                                  )
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label
                            htmlFor="numOfInstallments"
                            className="mb-1 text-left"
                          >
                            {<span className="text-red-600">*</span>} Number Of
                            Payment Installments
                          </label>
                          <input
                            type="number"
                            id="numOfInstallments"
                            name="numOfInstallments"
                            placeholder="Number of Installments"
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            value={numInstallments}
                            onChange={(e) => setNumInstallments(e.target.value)}
                            min={1}
                            required
                          />
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label
                            htmlFor="minimumDeposit"
                            className="mb-1 text-left"
                          >
                            {numInstallments > 1 ? (
                              <span className="text-red-600">*</span>
                            ) : (
                              <></>
                            )}{" "}
                            Minimum Deposit (₦)
                          </label>
                          <input
                            type="number"
                            id="minimumDeposit"
                            name="minimumDeposit"
                            placeholder="Minimum Deposit"
                            defaultValue={
                              numInstallments > 1
                                ? isNaN(
                                    Number(
                                      (program?.minimumDeposit.toString() ?? "")
                                        .slice(1)
                                        .split("")
                                        .filter((n) => !isNaN(Number(n)))
                                        .join("")
                                    )
                                  )
                                  ? 0
                                  : Number(
                                      (program?.minimumDeposit.toString() ?? "")
                                        .slice(1)
                                        .split("")
                                        .filter((n) => !isNaN(Number(n)))
                                        .join("")
                                    )
                                : undefined
                            }
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            disabled={!(numInstallments > 1)}
                            required={numInstallments > 1}
                          />
                        </div>
                      </div>
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label htmlFor="rtcs" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Supported
                            Regional Training Centers
                          </label>
                          <Select
                            className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            placeholder="Supported RTCs"
                            classNamePrefix="select"
                            options={trainingCenters?.data?.map((rtc) => ({
                              value: rtc.uuid,
                              label: rtc.name,
                            }))}
                            isSearchable={true}
                            isMulti={true}
                            defaultValue={rtcs}
                            onChange={(newValue) => setRtcs(newValue)}
                            id="rtcs"
                            required
                          />
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label htmlFor="type" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Program
                            Courses
                          </label>
                          <AsyncSelect
                            className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            placeholder="Program Courses"
                            classNamePrefix="select"
                            isMulti={true}
                            isClearable
                            isSearchable
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            defaultInputValue=""
                            defaultValue={selectedCourses}
                            cacheOptions
                            loadOptions={loadOptionsCourses}
                            defaultOptions
                            backspaceRemovesValue
                            onChange={(e) => {
                              if (e) {
                                console.log(e);
                                setSelectedCourses(e);
                              } else {
                                setSelectedCourses([]);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <WarningCircle
                              weight="bold"
                              className="w-5 h-5 mr-2"
                            />
                            <span className="text-xl">Calendar Sessions</span>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex">
                            <button
                              type="button"
                              className="p-0 px-2 btn btn-white focus:ring-2 focus:ring-primary-700 focus:ring-offset-2"
                              onClick={() => {
                                const prev = Object.keys(sessions).length;
                                setSessions({
                                  ...sessions,
                                  [prev + 1]: {
                                    startDate: null,
                                    endDate: null,
                                    payCloseDate: null,
                                  },
                                });
                              }}
                            >
                              <Plus weight="bold" className="w-5 h-5" />
                            </button>
                            <button
                              type="button"
                              className="p-0 px-2 btn btn-white focus:ring-2 focus:ring-primary-700 focus:ring-offset-2"
                              onClick={() => {
                                const prev = Object.keys(sessions).length;
                                const now = Object.assign({ ...sessions });
                                delete now[prev];
                                setSessions(now);
                              }}
                              disabled={Object.keys(sessions).length === 1}
                            >
                              <Minus weight="bold" className="w-5 h-5" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {Object.keys(sessions).map((session, index) => (
                        <div className="flex flex-col mb-6" key={index}>
                          <h4 className="mb-1 text-lg text-left">
                            Session {index + 1}
                          </h4>
                          <div className="flex w-full mb-4">
                            <div className="flex flex-col min-w-[24rem] mr-16">
                              <label htmlFor="cost" className="mb-1 text-left">
                                {<span className="text-red-600">*</span>} Start
                                Date
                              </label>
                              <div className="flex items-center">
                                <Datetime
                                  inputProps={{
                                    className:
                                      "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                    placeholder: "01/02/2020",
                                    id: "start_date",
                                    name: "start_date",
                                    required: true,
                                    autoComplete: "off",
                                  }}
                                  timeFormat={false}
                                  onOpen={() => {
                                    const modal =
                                      document.getElementById("editCourseForm");
                                    modal.scrollTo({
                                      top: modal.scrollHeight,
                                      left: 0,
                                      behavior: "smooth",
                                    });
                                  }}
                                  initialValue={sessions[session]?.startDate}
                                  onChange={(value) =>
                                    setSessions({
                                      ...sessions,
                                      [session]: {
                                        ...sessions[session],
                                        startDate: value.toISOString(),
                                      },
                                    })
                                  }
                                />
                                <CalendarBlank
                                  weight="bold"
                                  className="z-10 w-5 h-5 -ml-10"
                                />
                              </div>
                            </div>
                            <div className="flex flex-col min-w-[24rem]">
                              <label htmlFor="type" className="mb-1 text-left">
                                {<span className="text-red-600">*</span>} End
                                Date
                              </label>
                              <div className="flex items-center">
                                <Datetime
                                  inputProps={{
                                    className:
                                      "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                    placeholder: "01/02/2020",
                                    id: "end_date",
                                    name: "end_date",
                                    required: true,
                                    autoComplete: "off",
                                  }}
                                  timeFormat={false}
                                  onOpen={() => {
                                    const modal =
                                      document.getElementById("editCourseForm");
                                    modal.scrollTo({
                                      top: modal.scrollHeight,
                                      left: 0,
                                      behavior: "smooth",
                                    });
                                  }}
                                  initialValue={sessions[session]?.endDate}
                                  onChange={(value) =>
                                    setSessions({
                                      ...sessions,
                                      [session]: {
                                        ...sessions[session],
                                        endDate: value.toISOString(),
                                      },
                                    })
                                  }
                                />
                                <CalendarBlank
                                  weight="bold"
                                  className="z-10 w-5 h-5 -ml-10"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col min-w-[24rem]">
                            <label htmlFor="type" className="mb-1 text-left">
                              {<span className="text-red-600">*</span>} Pay
                              Close Date
                            </label>
                            <div className="flex items-center">
                              <Datetime
                                inputProps={{
                                  className:
                                    "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                  placeholder: "01/02/2020",
                                  id: "pay_close_date",
                                  name: "pay_close_date",
                                  required: true,
                                  autoComplete: "off",
                                }}
                                timeFormat={false}
                                onOpen={() => {
                                  const modal =
                                    document.getElementById("editCourseForm");
                                  modal.scrollTo({
                                    top: modal.scrollHeight,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                }}
                                initialValue={sessions[session]?.payCloseDate}
                                onChange={(value) =>
                                  setSessions({
                                    ...sessions,
                                    [session]: {
                                      ...sessions[session],
                                      payCloseDate: value.toISOString(),
                                    },
                                  })
                                }
                              />
                              <CalendarBlank
                                weight="bold"
                                className="z-10 w-5 h-5 -ml-10"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </React.Fragment>
          </div>
        ) : (
          <></>
        )
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : program !== null ? (
          // eslint-disable-next-line
          <Tabs.Group style="underline">
            <Tabs.Item active={true} title="Program Information">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      Program Information
                    </h6>
                    <span
                      className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                        program?.status === "published"
                          ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                          : "text-yellow-500 bg-yellow-200 border-yellow-400"
                      }`}
                    >
                      {" "}
                      {program?.status}
                    </span>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Program Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {program?.name}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Program Cost
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {program?.cost}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Program Created At
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {program?.createdAt}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item title="Courses">
              <div className="flex flex-col w-9/12">
                {program?.courses?.map((course, index) => (
                  <div
                    className="flex items-center justify-between py-3 border-t-0 border-b border-primary-400"
                    key={index}
                  >
                    <div className="flex flex-col cursor-pointer">
                      <h4 className="text-2xl font-regular">{course?.title}</h4>
                      <span className="text-lg text-primary-400">
                        {course?.description} | {course?.cost}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.Item>
            <Tabs.Item title="RTCs">
              <div className="flex flex-col w-9/12">
                {program?.regionalTrainingCentres?.map((rtc, index) => (
                  <div
                    className="flex items-center justify-between py-3 border-t-0 border-b border-primary-400"
                    key={index}
                  >
                    <Tooltip
                      content={`Email: ${rtc?.email} | Phone: ${rtc?.phoneNumber}`}
                    >
                      <div className="flex flex-col cursor-pointer">
                        <h4 className="text-2xl font-regular">{rtc?.name}</h4>
                        <span className="text-lg text-primary-400">
                          {rtc?.address}
                        </span>
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </Tabs.Item>
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">
              Unable to fetch records for program '{id}'
            </h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default ProgramDetails;
