/* eslint-disable react-hooks/exhaustive-deps */
import { WarningCircle, Plus, Minus, CalendarBlank } from "phosphor-react";
import { Tabs, Modal, Tooltip, Pagination, Avatar } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner, Rating } from "flowbite-react";
import { differenceInDays, format, formatDistance, isEqual } from "date-fns";
import { useData } from "../../providers/DataProvider";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import Datetime from "react-datetime";
import { isBefore } from "date-fns/esm";

const CourseDetails = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const {
    data: { courseMetaData, trainingCenters },
    loaders: { getCourseMetaData, getTrainingCenters },
  } = useData();

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [numInstallments, setNumInstallments] = useState(1);
  const [trainer, setTrainer] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [courseCategories, setCourseCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [rtcs, setRtcs] = useState([]);
  const [sessions, setSessions] = useState({
    1: { startDate: null, endDate: null },
  });

  const [loadingCourseTrainees, setLoadingCourseTrainees] = useState(false);
  const [courseTrainees, setCourseTrainees] = useState({
    trainees: [],
    page: 1,
    count: 1,
    maxPage: 1,
  });

  const [loadingCourseFeedback, setLoadingCourseFeedback] = useState(false);
  const [courseFeedback, setCourseFeedback] = useState({
    feedback: [],
    page: 1,
    count: 1,
    maxPage: 1,
  });

  const [loadingTraineeResults, setLoadingTraineeResults] = useState(false);
  const [traineeResults, setTraineeResults] = useState({
    results: [],
    page: 1,
    count: 1,
    maxPage: 1,
  });

  const navigate = useNavigate();
  const client = useClient();

  const getCourseTrainees = (page) => {
    setLoadingCourseTrainees(true);
    client(`/api/v1/courses/trainee/${id}?page=${page}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        if (res.success === true) {
          setCourseTrainees({
            ...courseTrainees,
            trainees: res.data.users,
            count: res.data.meta.total,
            page: page,
            maxPage: res.data.meta.totalPages,
          });
          setLoadingCourseTrainees(false);
        } else {
          setLoadingCourseTrainees(false);
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoadingCourseTrainees(false);
      });
  };

  const getCourseFeedback = (page) => {
    setLoadingCourseFeedback(true);
    client(`/api/v1/course/feed-back/${id}?page=${page}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        if (res.success === true) {
          setCourseFeedback({
            ...courseFeedback,
            feedback: res.data.courseFeedBack,
            count: res.data.meta.total,
            page: page,
            maxPage: res.data.meta.totalPages,
          });
          setLoadingCourseFeedback(false);
        } else {
          setLoadingCourseFeedback(false);
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoadingCourseFeedback(false);
      });
  };

  const getTraineeResults = (page) => {
    setLoadingTraineeResults(true);
    client(`/api/v1/results/course/${id}?page=${page}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        if (res.success === true) {
          setTraineeResults({
            ...traineeResults,
            results: res.data.results,
            count: res.data.meta.total,
            page: page,
            maxPage: res.data.meta.totalPages,
          });
          setLoadingTraineeResults(false);
        } else {
          setLoadingTraineeResults(false);
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoadingTraineeResults(false);
      });
  };

  useEffect(() => {
    if (!loaded) {
      client(`/api/v1/courses/${id}`, {
        method: "GET",
        credentials: true,
      })
        .then((res) => {
          if (res.success === true) {
            setCourse(res.data);
            setNumInstallments(res.data.noOfInstallments);
            setCourseType({
              value: courseMetaData?.types.find(
                (type) => type.name === res.data.courseType
              )?.uuid,
              label: res.data.courseType,
            });
            setTrainer({
              value: res.data?.trainer?.uuid,
              label: `${res.data?.trainer?.surname} ${res.data?.trainer?.firstName}`,
            });
            setCourseCategories(
              res.data.courseCategories?.map((category) => ({
                value: category.uuid,
                label: category.name,
              }))
            );
            setRtcs(
              res.data.regionalTrainingCenters?.map((rtc) => ({
                value: rtc.uuid,
                label: rtc.name,
              }))
            );
            setSessions(
              res.data.calendars.length > 0
                ? Object.assign({}, res.data.calendars)
                : { 0: { startDate: null, endDate: null, payCloseDate: null } }
            );
            setLoading(false);
          } else {
            setCourse(null);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          flashMessage({ message: err.message, type: "error" });
          setLoading(false);
        });
      getCourseTrainees(courseTrainees.page);
      getCourseFeedback(courseFeedback.page);
      getTraineeResults(traineeResults.page);
      getTrainingCenters();
      getCourseMetaData();
      setLoaded(true);
    }
  }, [
    id,
    client,
    loaded,
    getCourseMetaData,
    getTrainingCenters,
    courseMetaData,
    courseTrainees.page,
    courseFeedback.page,
    getCourseFeedback,
    getCourseTrainees,
  ]);

  const [modalShow, setModalShow] = useState(false);

  const loadOptionsTrainers = async (inputValue, callback) => {
    const { data } = await client(`/api/v1/search/trainer?q=${inputValue}`);

    const parsedTrainers = data.map((trainer) => ({
      value: trainer.uuid,
      label: `${trainer.surname} ${trainer.firstName}`,
    }));

    callback(parsedTrainers);
  };

  const deleteCourse = () => {
    client(`/api/v1/courses/${id}`, {
      method: "DELETE",
      credentials: true,
    })
      .then(() => {
        navigate(-1);
        flashMessage({
          message: "Course Deleted Successfully",
          type: "success",
          delay: 1000,
        });
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const traineeInfo = (trainee) => {
    const days = course?.calendars
      ?.map((calendar) => {
        if (
          isBefore(new Date(calendar.startDate), new Date()) ||
          isEqual(new Date(calendar.startDate), new Date())
        ) {
          if (isBefore(new Date(calendar.endDate), new Date())) {
            return differenceInDays(
              new Date(calendar.endDate),
              new Date(calendar.startDate)
            );
          } else {
            return differenceInDays(new Date(), new Date(calendar.startDate));
          }
        } else {
          return 0;
        }
      })
      .reduce((a, b) => a + b);

    return `Reg Number: ${trainee?.regNumber} | Attendance: ${
      trainee?.attendance?.length
    }/${days} ${days > 1 ? "days" : "day"}`;
  };

  const editCourse = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const data = new FormData();
    if (trainer !== null) {
      data.append("trainerUuid", trainer.value);
    }
    if (form.minimumDeposit.value > form.cost.value) {
      flashMessage({
        message:
          "Minimum Deposit should be less than or equal to the cost of the course",
        type: "error",
      });
    }
    console.log(form.courseTypeUuid.value);
    data.append(
      "courseTypeUuid",
      form.courseTypeUuid.value ?? courseType.value
    );
    data.append("title", form.title.value);
    data.append("description", form.description.value);
    data.append("image", image);
    courseCategories.forEach((category, index) =>
      data.append(`courseCategoriesUuid[${index}]`, category.value)
    );
    data.append("cost", form.cost.value * 100);
    data.append("noOfInstallments", numInstallments);
    data.append("minimumDeposit", form.minimumDeposit.value * 100);
    data.append("status", form.status.value);
    rtcs.forEach((rtc, index) =>
      data.append(`regionalTrainingCentersUuid[${index}]`, rtc.value)
    );
    Object.keys(sessions).forEach((key, index) =>
      Object.keys(sessions[key]).forEach((sessionKey) => {
        if (
          sessionKey === "startDate" ||
          sessionKey === "endDate" ||
          sessionKey === "payCloseDate"
        ) {
          data.append(
            `calendars[${index}][${sessionKey}]`,
            format(new Date(sessions[key][sessionKey]), "yyyy-MM-dd")
          );
        }
      })
    );

    client(`/api/v1/courses/${id}`, {
      method: "POST",
      json: false,
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  // const viewTrainee = (traineeUuid) => {
  //   navigate(`/admin/manage/trainee/applicants/details/${traineeUuid}`);
  // };

  return (
    <PageBuilder
      name={`Course Details - ${course?.title ?? "loading..."}`}
      extras={
        course ? (
          <div className="flex flex-row w-full h-full py-4">
            <button
              type="button"
              className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
              onClick={() => deleteCourse()}
            >
              Delete
            </button>
            <button
              type="button"
              className="mx-2 my-2 btn btn-secondary"
              onClick={() => setModalShow(true)}
            >
              Edit
            </button>
            <React.Fragment>
              <Modal
                show={modalShow}
                onClose={() => {
                  setModalShow(false);
                }}
                size="5xl"
              >
                <Modal.Body>
                  <form
                    className="flex flex-col bg-white max-h-[75vh] overflow-y-scroll"
                    onSubmit={editCourse}
                    id="editCourseForm"
                  >
                    <div className="flex items-center justify-between px-5">
                      <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                        Edit Course
                      </h1>
                      <div className="flex">
                        <button
                          type="button"
                          className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                          onClick={() => setModalShow(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="flex items-center mx-0 btn btn-secondary"
                        >
                          <span>Edit Course</span>
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center w-full px-5">
                      <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                      <span>
                        All fields in asterisk (
                        {<span className="text-red-600">*</span>}) are required.
                      </span>
                    </div>
                    <div className="flex flex-col w-auto px-5 my-4 bg-white">
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label htmlFor="title" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Course
                            Title
                          </label>
                          <input
                            type="text"
                            id="title"
                            name="title"
                            defaultValue={course?.title}
                            placeholder="Course Title"
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            required
                          />
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label htmlFor="type" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Course
                            Type
                          </label>
                          <Select
                            className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            placeholder="Course Type"
                            classNamePrefix="select"
                            options={courseMetaData?.types?.map((type) => ({
                              value: type.uuid,
                              label: type.name,
                            }))}
                            isSearchable={false}
                            defaultValue={courseType}
                            id="type"
                            required
                            name="courseTypeUuid"
                          />
                        </div>
                      </div>
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label
                            htmlFor="description"
                            className="mb-1 text-left"
                          >
                            {<span className="text-red-600">*</span>}
                            Course Description
                          </label>
                          <textarea
                            id="description"
                            name="description"
                            placeholder="Course Description"
                            defaultValue={course?.description}
                            className="flex w-full placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            required
                          />
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label
                            htmlFor="categories"
                            className="mb-1 text-left"
                          >
                            {<span className="text-red-600">*</span>} Course
                            Categories
                          </label>
                          <Select
                            className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            placeholder="Course Categories"
                            classNamePrefix="select"
                            options={courseMetaData?.categories?.map(
                              (category) => ({
                                value: category.uuid,
                                label: category.name,
                              })
                            )}
                            defaultValue={courseCategories}
                            isSearchable={true}
                            isMulti={true}
                            id="categories"
                            onChange={(newValue) =>
                              setCourseCategories(newValue)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label htmlFor="status" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>}
                            Course Status
                          </label>
                          <Select
                            className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            placeholder="Course Status"
                            classNamePrefix="select"
                            options={["draft", "published", "submitted"].map(
                              (status) => ({
                                value: status,
                                label: status,
                              })
                            )}
                            defaultValue={{
                              value: course?.status,
                              label: course?.status,
                            }}
                            isSearchable={false}
                            isMulti={false}
                            id="status"
                            name="status"
                            required
                          />
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label htmlFor="type" className="mb-1 text-left">
                            Course Trainer
                          </label>
                          <AsyncSelect
                            className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            placeholder="Course Trainer"
                            classNamePrefix="select"
                            isMulti={false}
                            isClearable
                            isSearchable
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            defaultInputValue=""
                            defaultValue={trainer}
                            cacheOptions
                            loadOptions={loadOptionsTrainers}
                            defaultOptions
                            backspaceRemovesValue
                            onChange={(e) => {
                              if (e) {
                                setTrainer(e);
                              } else {
                                setTrainer(null);
                              }
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label htmlFor="image" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>}
                            Course Image
                          </label>
                          <div className="flex">
                            <div className="relative w-full">
                              <input
                                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                id="image"
                                name="image"
                                type="file"
                                accept="image/*"
                                onChange={(e) => setImage(e.target.files[0])}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label htmlFor="rtcs" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Supported
                            Regional Training Centers
                          </label>
                          <Select
                            className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            placeholder="Supported RTCs"
                            classNamePrefix="select"
                            options={trainingCenters?.data?.map((rtc) => ({
                              value: rtc.uuid,
                              label: rtc.name,
                            }))}
                            defaultValue={rtcs}
                            isSearchable={true}
                            isMulti={true}
                            onChange={(newValue) => setRtcs(newValue)}
                            id="rtcs"
                            required
                          />
                        </div>
                      </div>
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label htmlFor="cost" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Course
                            Cost (₦)
                          </label>
                          <input
                            type="number"
                            id="cost"
                            name="cost"
                            placeholder="Course Cost"
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            min={1000}
                            defaultValue={
                              isNaN(
                                Number(
                                  course?.cost
                                    .slice(1)
                                    .split("")
                                    .filter((n) => !isNaN(Number(n)))
                                    .join("")
                                )
                              )
                                ? 0
                                : Number(
                                    course?.cost
                                      .slice(1)
                                      .split("")
                                      .filter((n) => !isNaN(Number(n)))
                                      .join("")
                                  )
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label
                            htmlFor="numOfInstallments"
                            className="mb-1 text-left"
                          >
                            {<span className="text-red-600">*</span>} Number Of
                            Payment Installments
                          </label>
                          <input
                            type="number"
                            id="numOfInstallments"
                            name="numOfInstallments"
                            placeholder="Number of Installments"
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            value={numInstallments}
                            onChange={(e) => setNumInstallments(e.target.value)}
                            min={1}
                            required
                          />
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label
                            htmlFor="minimumDeposit"
                            className="mb-1 text-left"
                          >
                            {numInstallments > 1 ? (
                              <span className="text-red-600">*</span>
                            ) : (
                              <></>
                            )}{" "}
                            Minimum Deposit (₦)
                          </label>
                          <input
                            type="number"
                            id="minimumDeposit"
                            name="minimumDeposit"
                            placeholder="Minimum Deposit"
                            defaultValue={
                              numInstallments > 1
                                ? isNaN(
                                    Number(
                                      course?.minDeposit
                                        .slice(1)
                                        .split("")
                                        .filter((n) => !isNaN(Number(n)))
                                        .join("")
                                    )
                                  )
                                  ? 0
                                  : Number(
                                      course?.minDeposit
                                        .slice(1)
                                        .split("")
                                        .filter((n) => !isNaN(Number(n)))
                                        .join("")
                                    )
                                : undefined
                            }
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            disabled={!(numInstallments > 1)}
                            required={numInstallments > 1}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <WarningCircle
                              weight="bold"
                              className="w-5 h-5 mr-2"
                            />
                            <span className="text-xl">Calendar Sessions</span>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex">
                            <button
                              type="button"
                              className="p-0 px-2 btn btn-white focus:ring-2 focus:ring-primary-700 focus:ring-offset-2"
                              onClick={() => {
                                const prev = Object.keys(sessions).length;
                                setSessions({
                                  ...sessions,
                                  [prev + 1]: {
                                    startDate: null,
                                    endDate: null,
                                    payCloseDate: null,
                                  },
                                });
                              }}
                            >
                              <Plus weight="bold" className="w-5 h-5" />
                            </button>
                            <button
                              type="button"
                              className="p-0 px-2 btn btn-white focus:ring-2 focus:ring-primary-700 focus:ring-offset-2"
                              onClick={() => {
                                const prev = Object.keys(sessions).length;
                                const now = Object.assign({ ...sessions });
                                delete now[prev];
                                setSessions(now);
                              }}
                              disabled={Object.keys(sessions).length === 1}
                            >
                              <Minus weight="bold" className="w-5 h-5" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {Object.keys(sessions).map((session, index) => (
                        <div className="flex flex-col mb-6" key={index}>
                          <h4 className="mb-1 text-lg text-left">
                            Session {index + 1}
                          </h4>
                          <div className="flex w-full mb-4">
                            <div className="flex flex-col min-w-[24rem] mr-16">
                              <label htmlFor="cost" className="mb-1 text-left">
                                {<span className="text-red-600">*</span>} Start
                                Date
                              </label>
                              <div className="flex items-center">
                                <Datetime
                                  inputProps={{
                                    className:
                                      "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                    placeholder: "01/02/2020",
                                    id: "start_date",
                                    name: "start_date",
                                    required: true,
                                    autoComplete: "off",
                                  }}
                                  timeFormat={false}
                                  onOpen={() => {
                                    const modal =
                                      document.getElementById("editCourseForm");
                                    modal.scrollTo({
                                      top: modal.scrollHeight,
                                      left: 0,
                                      behavior: "smooth",
                                    });
                                  }}
                                  initialValue={sessions[session]?.startDate}
                                  onChange={(value) =>
                                    setSessions({
                                      ...sessions,
                                      [session]: {
                                        ...sessions[session],
                                        startDate: value.toISOString(),
                                      },
                                    })
                                  }
                                />
                                <CalendarBlank
                                  weight="bold"
                                  className="z-10 w-5 h-5 -ml-10"
                                />
                              </div>
                            </div>
                            <div className="flex flex-col min-w-[24rem]">
                              <label htmlFor="type" className="mb-1 text-left">
                                {<span className="text-red-600">*</span>} End
                                Date
                              </label>
                              <div className="flex items-center">
                                <Datetime
                                  inputProps={{
                                    className:
                                      "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                    placeholder: "01/02/2020",
                                    id: "end_date",
                                    name: "end_date",
                                    required: true,
                                    autoComplete: "off",
                                  }}
                                  timeFormat={false}
                                  onOpen={() => {
                                    const modal =
                                      document.getElementById("editCourseForm");
                                    modal.scrollTo({
                                      top: modal.scrollHeight,
                                      left: 0,
                                      behavior: "smooth",
                                    });
                                  }}
                                  initialValue={sessions[session]?.endDate}
                                  onChange={(value) =>
                                    setSessions({
                                      ...sessions,
                                      [session]: {
                                        ...sessions[session],
                                        endDate: value.toISOString(),
                                      },
                                    })
                                  }
                                />
                                <CalendarBlank
                                  weight="bold"
                                  className="z-10 w-5 h-5 -ml-10"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col min-w-[24rem]">
                            <label htmlFor="type" className="mb-1 text-left">
                              {<span className="text-red-600">*</span>} Pay
                              Close Date
                            </label>
                            <div className="flex items-center">
                              <Datetime
                                inputProps={{
                                  className:
                                    "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                  placeholder: "01/02/2020",
                                  id: "pay_close_date",
                                  name: "pay_close_date",
                                  required: true,
                                  autoComplete: "off",
                                }}
                                timeFormat={false}
                                onOpen={() => {
                                  const modal =
                                    document.getElementById("editCourseForm");
                                  modal.scrollTo({
                                    top: modal.scrollHeight,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                }}
                                initialValue={sessions[session]?.payCloseDate}
                                onChange={(value) =>
                                  setSessions({
                                    ...sessions,
                                    [session]: {
                                      ...sessions[session],
                                      payCloseDate: value.toISOString(),
                                    },
                                  })
                                }
                              />
                              <CalendarBlank
                                weight="bold"
                                className="z-10 w-5 h-5 -ml-10"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </React.Fragment>
          </div>
        ) : (
          <></>
        )
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : course !== null ? (
          // eslint-disable-next-line
          <Tabs.Group style="underline">
            <Tabs.Item active={true} title="Course Information">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      Course Information
                    </h6>
                    <span
                      className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                        course?.status === "published"
                          ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                          : "text-yellow-500 bg-yellow-200 border-yellow-400"
                      }`}
                    >
                      {" "}
                      {course?.status}
                    </span>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Course Title
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {course?.title}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Course Code
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {course?.courseCode}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Course Type
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {course?.courseType}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Course UUID
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {id}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Course Cost
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {course?.cost}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Date Published
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {course?.publishedDate ?? "N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Course Trainer
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {`${course?.trainer?.firstName} ${course?.trainer?.surname}`}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Course Description
                      </span>
                      <Tooltip content={course?.description}>
                        <span className="text-base text-gray-400 !font-[200] overflow-ellipsis">
                          {course?.description}
                        </span>
                      </Tooltip>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Course Categories
                      </span>
                      <div className="flex">
                        {course?.courseCategories?.map((category, index) => (
                          <span
                            className="py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] text-blue-400 bg-blue-200 border-blue-400"
                            key={index}
                          >
                            {category.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item title="RTCs">
              <div className="flex flex-col w-9/12">
                {course?.regionalTrainingCenters?.map((rtc, index) => (
                  <div
                    className="flex items-center justify-between py-3 border-t-0 border-b border-primary-400"
                    key={index}
                  >
                    <Tooltip
                      content={`Email: ${rtc?.email} | Phone: ${rtc?.phoneNumber}`}
                    >
                      <div className="flex flex-col cursor-pointer">
                        <h4 className="text-2xl font-regular">{rtc?.name}</h4>
                        <span className="text-lg text-primary-400">
                          {rtc?.address}
                        </span>
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </Tabs.Item>
            <Tabs.Item title="Trainee Information">
              <div>
                {loadingCourseTrainees ? (
                  <div className="flex flex-row justify-center w-100">
                    <Spinner size="xl" className="z-1" />
                  </div>
                ) : courseTrainees.count === 0 ? (
                  <span className="block w-full my-5 text-2xl text-center">
                    No trainees found for this course
                  </span>
                ) : (
                  <div className="flex flex-col w-9/12">
                    {courseTrainees.trainees?.map((trainee, index) => (
                      <div
                        className="flex flex-row justify-between my-2"
                        key={index}
                      >
                        <div className="flex flex-col">
                          <div className="flex flex-row">
                            <Avatar img={trainee?.avatar} rounded={true} />
                            <h4 className="ml-1 mr-2 text-2xl font-regular">
                              {trainee?.surname} {trainee?.firstName}
                            </h4>
                            <span
                              className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                                trainee?.verified
                                  ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                                  : "text-yellow-500 bg-yellow-200 border-yellow-400"
                              }`}
                            >
                              {trainee?.verified ? "VERIFIED" : "UNVERIFIED"}
                            </span>
                          </div>
                          <span className="text-lg text-primary-400">
                            {traineeInfo(trainee)}
                          </span>
                        </div>
                        {/* <div className="flex flex-col">
                          <button
                            type="button"
                            className="mx-2 my-2 btn btn-white"
                            onClick={() =>
                              viewTrainee(trainee?.applicationUuid)
                            }
                          >
                            View Details
                          </button>
                        </div> */}
                      </div>
                    ))}
                    <div className="flex items-center justify-end text-left">
                      <Pagination
                        currentPage={courseTrainees.page}
                        layout="table"
                        onPageChange={(page) =>
                          setCourseTrainees({ ...courseTrainees, page: page })
                        }
                        showIcons={true}
                        totalPages={courseTrainees.maxPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Tabs.Item>
            <Tabs.Item title="Trainee Results">
              <div>
                {loadingTraineeResults ? (
                  <div className="flex flex-row justify-center w-100">
                    <Spinner size="xl" className="z-1" />
                  </div>
                ) : traineeResults.count === 0 ? (
                  <span className="block w-full my-5 text-2xl text-center">
                    No results found for this trainee
                  </span>
                ) : (
                  <div className="flex flex-col w-9/12">
                    {traineeResults.results.map((result, index) => (
                      <div className="flex flex-col" key={index}>
                        <div className="flex flex-row">
                          <h4 className="mr-2 text-2xl font-regular">
                            {`${result?.firstName} ${result?.surName}`}
                          </h4>
                          <span
                            className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                              result?.grade >= 80
                                ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                                : result?.grade >= 50 && result?.grade <= 80
                                ? "text-blue-500 bg-blue-200 border-blue-400"
                                : "text-yellow-500 bg-yellow-200 border-yellow-400"
                            }`}
                          >
                            {result?.grade}
                          </span>
                        </div>
                        <p className="text-lg text-primary-500">
                          Assignment Score: {result?.assignmentScore} {""}
                          Quiz Score: {result?.quizScore} {""}
                          Exam Score: {result?.examScore} {""}
                        </p>
                        <span className="text-primary-500">
                          Completed: {result.completionDate}
                        </span>
                      </div>
                    ))}
                    <div className="flex items-center justify-end text-left">
                      <Pagination
                        currentPage={traineeResults.page}
                        layout="table"
                        onPageChange={(page) =>
                          setTraineeResults({ ...traineeResults, page: page })
                        }
                        showIcons={true}
                        totalPages={traineeResults.maxPage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </Tabs.Item>
            <Tabs.Item title="Training Feedback">
              {loadingCourseFeedback ? (
                <div className="flex flex-row justify-center w-100">
                  <Spinner size="xl" className="z-1" />
                </div>
              ) : courseFeedback.count === 0 ? (
                <span className="block w-full my-5 text-2xl text-center">
                  No feedback has been given for this course
                </span>
              ) : (
                <div className="flex flex-col w-9/12">
                  {courseFeedback.feedback?.map((entry, index) => (
                    <div className="flex flex-row justify-start" key={index}>
                      <div className="flex flex-col">
                        <h4 className="text-2xl font-regular">
                          {entry?.email}
                        </h4>
                        <Rating size="md">
                          {[1, 2, 3, 4, 5].map((num, index) =>
                            num <= entry?.courseRating ? (
                              <Rating.Star key={index} />
                            ) : (
                              <Rating.Star filled={false} key={index} />
                            )
                          )}
                          <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                            {entry?.createdAt}
                          </p>
                        </Rating>
                        <p className="text-md text-primary-400">
                          {entry?.comment}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div className="flex items-center justify-end text-left">
                    <Pagination
                      currentPage={courseFeedback.page}
                      layout="table"
                      onPageChange={(page) =>
                        setCourseFeedback({ ...courseFeedback, page: page })
                      }
                      showIcons={true}
                      totalPages={courseFeedback.maxPage}
                    />
                  </div>
                </div>
              )}
            </Tabs.Item>
            <Tabs.Item title="Sessions">
              <div className="flex flex-col w-9/12">
                {course?.calendars?.map((calendar, index) => (
                  <div
                    className="flex items-center justify-between py-3 border-t-0 border-b border-primary-400"
                    key={index}
                  >
                    <div className="flex flex-col">
                      <h4 className="text-2xl font-regular">{`${
                        course?.title
                      } #${index + 1}`}</h4>
                      <span className="text-lg text-primary-400">
                        {`Starts: ${format(
                          new Date(calendar.startDate),
                          "dd/MM/yyyy"
                        )} | Ends: ${format(
                          new Date(calendar.endDate),
                          "dd/MM/yyyy"
                        )} | Pay Close Date: ${format(
                          new Date(calendar.payCloseDate),
                          "dd/MM/yyyy"
                        )} | Duration: ${formatDistance(
                          new Date(calendar.startDate),
                          new Date(calendar.endDate),
                          { addSuffix: false }
                        )}`}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.Item>
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">
              Unable to fetch records for course '{id}'
            </h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default CourseDetails;
