// import { WarningCircle } from "phosphor-react";
import { Modal, Tabs } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner } from "flowbite-react";
import { WarningCircle } from "phosphor-react";
import Select from "react-select";

const TicketDetails = () => {
  const { id } = useParams();
  const [ticket, setTicket] = useState(null);

  const [loading, setLoading] = useState(true);
  const [changeStatusModalShow, setChangeStatusModalShow] = useState(false);
  // const [assignToUserModalShow, setAssignToUserModalShow] = useState(false);

  // const navigate = useNavigate();

  const client = useClient();

  useEffect(() => {
    client(`/api/v1/support-ticket/${id}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        if (res.success === true) {
          setTicket(res.data);
          setLoading(false);
        } else {
          setTicket(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoading(false);
      });
  }, [id, client]);

  const updateTicketStatus = (e) => {
    setChangeStatusModalShow(false);
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const data = {
      ticketUuid: [id],
      ticketStatus: form.status.value,
    };

    client(`/api/v1/support/ticket/management/update-ticket-status`, {
      method: "PATCH",
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  return (
    <PageBuilder
      name={`Ticket Details - ${ticket?.title ?? "loading..."}`}
      extras={
        ticket ? (
          <div className="flex flex-row w-full h-full py-4">
            {/* <button
              type="button"
              className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
              onClick={() => deleteFAQ()}
            >
              Delete
            </button> */}
            <button
              type="button"
              className="mx-2 my-2 btn btn-secondary"
              onClick={() => setChangeStatusModalShow(true)}
            >
              Update Status
            </button>
            <React.Fragment>
              <Modal
                show={changeStatusModalShow}
                onClose={() => {
                  setChangeStatusModalShow(false);
                }}
                size="5xl"
              >
                <Modal.Body>
                  <form
                    className="flex flex-col bg-white min-h-[50vh] overflow-y-scroll"
                    onSubmit={updateTicketStatus}
                    id="updateTicketStatusForm"
                  >
                    <div className="flex items-center justify-between px-5">
                      <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                        Update Ticket Status
                      </h1>
                      <div className="flex">
                        <button
                          type="button"
                          className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                          onClick={() => setChangeStatusModalShow(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="flex items-center mx-0 btn btn-secondary"
                        >
                          <span>Update</span>
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center w-full px-5">
                      <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                      <span>
                        All fields in asterisk (
                        {<span className="text-red-600">*</span>}) are required.
                      </span>
                    </div>
                    <div className="flex flex-col w-auto px-5 my-4 bg-white">
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="name" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} New Status
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="New Status"
                          classNamePrefix="select"
                          options={[
                            "not taken",
                            "pending",
                            "in progress",
                            "completed",
                          ].map((option) => ({
                            value: option,
                            label: option,
                          }))}
                          isSearchable={false}
                          name="status"
                          required
                        />
                      </div>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </React.Fragment>
          </div>
        ) : (
          <></>
        )
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : ticket !== null ? (
          // eslint-disable-next-line
          <Tabs.Group style="underline">
            <Tabs.Item active={true} title="Ticket Information">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      Ticket Information
                    </h6>
                    <span
                      className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                        ticket?.priority === "low"
                          ? "text-blue-500 bg-blue-200 border-blue-400"
                          : ticket?.priority === "medium"
                          ? "text-yellow-500 bg-yellow-200 border-yellow-400"
                          : "text-red-500 bg-red-200 border-red-400"
                      }`}
                    >
                      {" "}
                      {ticket?.priority}
                    </span>
                  </div>
                  <div className="flex flex-wrap w-full mb-10">
                    <div className="flex flex-col mr-12 mb-4 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Ticket Title
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {ticket?.title}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 mb-4 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Ticket Description
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {ticket?.description}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 mb-4 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Ticket Status
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {ticket?.status}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 mb-4 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Ticket Requester Email
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {ticket?.email}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 mb-4 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Ticket Priority
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {ticket?.priority}
                      </span>
                    </div>
                    {/* <div className="flex flex-col mr-12 mb-4 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Ticket Publish Date
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {ticket?.published_date}
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </Tabs.Item>
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">
              Unable to fetch records for ticket '{id}'
            </h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default TicketDetails;
