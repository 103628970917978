import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Modal, Spinner, Tabs } from "flowbite-react";
import { useData } from "../../providers/DataProvider";
import { WarningCircle } from "phosphor-react";
import CheckboxGroup from "react-checkbox-group";

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const client = useClient();
  const navigate = useNavigate();

  const {
    data: { permissions },
    loaders: { getPermissions },
  } = useData();

  const [modalShow, setModalShow] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [permissionsEdited, setPermissionsEdited] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const updateUserPermissions = () => {
    client(`/api/v1/user/management/admin/update/${id}`, {
      method: "POST",
      data: {
        permissions: userPermissions,
      },
      credentials: true,
    })
      .then((res) => {
        if (res.data.success) {
          flashMessage({
            message: "Admin Permissions Updated Successfully",
            type: "success",
          });
        }
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const deactivateUser = () => {
    client(`/api/v1/user/management/admin/trash/${id}`, {
      method: "DELETE",
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Admin Deactivated Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const deleteUser = () => {
    client(`/api/v1/user/management/admin/flush/${id}`, {
      method: "DELETE",
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Admin Deleted Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const reactivateUser = () => {
    client(`/api/v1/user/management/admin/restore/${id}`, {
      method: "POST",
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Admin Reactivated Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const editUser = (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    const userInfo = {
      email: form.email.value,
      phoneNumber: form.phoneNumber.value,
      firstName: form.firstName.value,
      surname: form.surname.value,
      userType: "admin",
    };

    client("/api/v1/user/management/create/admin", {
      method: "POST",
      data: userInfo,
      credentials: true,
    })
      .then((response) => {
        const res = response;
        if (res.success === true) {
          setModalShow(false);
          setLoaded(false);
          flashMessage({ message: res.message, type: "success" });
        } else {
          setModalShow(false);

          flashMessage({ message: res.message, type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response;
          setModalShow(false);

          flashMessage({ message: data.message, type: "error" });
        } else {
          setModalShow(false);

          flashMessage({
            message:
              "Something went wrong creating the user account, please try again later.",
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    client(`/api/v1/user/management/admin/${id}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        setUser(res.data);
        setUserPermissions(res.data.permissions);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoading(false);
      });
    if (!loaded) {
      getPermissions();
      setLoaded(true);
    }
  }, [id, client, loaded, getPermissions]);

  return (
    <PageBuilder
      name={`User Details - ${user?.name ?? "loading..."}`}
      extras={
        <div className="flex flex-row w-full h-full py-4">
          {/* <button
            type="button"
            className="mx-2 my-2 text-green-600 bg-green-100 btn hover:bg-green-300"
            onClick={() => setModalShow(true)}
          >
            Edit User
          </button> */}
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white"
                  onSubmit={editUser}
                  id="editUserForm"
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      Edit User
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Update</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="firstName" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} First Name
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="surname" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Last Name
                        </label>
                        <input
                          type="text"
                          id="surname"
                          name="surname"
                          placeholder="Last Name"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="email" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>}
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="phoneNumber" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Phone Number
                        </label>
                        <input
                          type="tel"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
          <button
            type="button"
            className={
              user?.active
                ? "btn bg-red-100 text-red-600 hover:bg-red-300 mx-2 my-2"
                : "btn bg-green-100 text-green-600 hover:bg-green-300 mx-2 my-2"
            }
            onClick={() => (user?.active ? deactivateUser() : reactivateUser())}
          >
            {user?.active ? "Deactivate User" : "Reactivate User"}
          </button>
          <button
            type="button"
            className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
            onClick={() => deleteUser()}
          >
            Delete User
          </button>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : user ? (
          <Tabs.Group>
            <Tabs.Item active title="Profile">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      Profile
                    </h6>
                    <span
                      className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                        user?.active
                          ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                          : "text-red-500 bg-red-200 border-red-400"
                      }`}
                    >
                      {" "}
                      {user?.active ? "active" : "inactive"}
                    </span>
                  </div>
                  <div className="flex flex-wrap w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {user?.name}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Email Address
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {user?.email}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Phone Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {user?.phoneNumber}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Created At
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {user?.createdAt}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Deactivated At
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {user?.deletedAt ?? "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
            {user.role === "admin" ? (
              <Tabs.Item title="Permissions">
                <CheckboxGroup
                  name="permissions"
                  value={userPermissions}
                  onChange={(newVal) => {
                    setPermissionsEdited(newVal !== userPermissions);
                    setUserPermissions(newVal);
                  }}
                >
                  {(Checkbox) => (
                    <div className="flex flex-row flex-wrap w-50 h-fit">
                      {permissions.map((permission, index) => (
                        <label
                          key={index}
                          className="block mx-5 my-2 !min-w-[3rem] max-w-[3rem]"
                        >
                          <Checkbox value={permission} /> {permission}
                        </label>
                      ))}
                    </div>
                  )}
                </CheckboxGroup>
                <button
                  type="button"
                  className="mx-2 my-2 text-green-600 bg-green-100 btn hover:bg-green-300"
                  disabled={!permissionsEdited}
                  onClick={() => updateUserPermissions()}
                >
                  Update User Permissions
                </button>
              </Tabs.Item>
            ) : (
              <></>
            )}
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">Unable to fetch records for user '{id}'</h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default UserDetails;
