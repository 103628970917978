import { CalendarBlank } from "phosphor-react";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import Select from "react-select";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useNavigate } from "react-router-dom";
import { useData } from "../../providers/DataProvider";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Modal } from "flowbite-react";

const TicketManagement = () => {
  const {
    data: { tickets },
    loaders: { getTickets },
    pagination: {
      tickets: { updatePage },
    },
  } = useData();

  const navigate = useNavigate();

  const client = useClient();
  const [modalShow, setModalShow] = useState(false);
  const [ticketUuids, setTicketUuids] = useState([]);

  const updateTicketStatus = (ticketUuids) => {
    setTicketUuids(ticketUuids);
    setModalShow(true);
  };

  const submitTicketStatusUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const status = form.status.value;

    client(`/api/v1/support/ticket/management/update-ticket-status`, {
      method: "PATCH",
      credentials: true,
      data: {
        ticketUuid: ticketUuids,
        ticketStatus: status,
      },
    })
      .then((res) => {
        if (res.success === true) {
          flashMessage({ message: res.message, type: "success" });
        } else {
          flashMessage({ message: res.message, type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      getTickets();
      setLoaded(true);
    }
  }, [loaded, getTickets]);

  return (
    <PageBuilder
      name="Ticket Management"
      extras={
        <div className="flex items-center">
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white max-h-[90vh] overflow-y-scroll"
                  onSubmit={submitTicketStatusUpdate}
                  id="selectNewStatusForm"
                >
                  <div className="flex justify-between items-center px-5">
                    <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
                      Select new status
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary mx-0 flex items-center"
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col px-5 bg-white my-4 w-auto">
                    <div className="flex w-full mb-20">
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="program" className="text-left mb-1">
                          Ticket Status
                        </label>
                        <Select
                          className="border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Course Program"
                          classNamePrefix="select"
                          options={[
                            "completed",
                            "not taken",
                            "pending",
                            "in progress",
                          ].map((status) => ({
                            label: status,
                            value: status,
                          }))}
                          isSearchable={false}
                          id="status"
                          required
                          name="status"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        <div className="flex w-full justify-between px-2 py-2">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Filter Tickets
            </span>
            <div className="flex w-full">
              <div className="flex flex-col mr-5">
                <span className="text-black">Start Date</span>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "01-02-2020",
                    }}
                  />
                  <CalendarBlank
                    weight="bold"
                    className="w-5 h-5 -ml-10 z-10"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-black">End Date</span>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "01-02-2020",
                    }}
                  />
                  <CalendarBlank
                    weight="bold"
                    className="w-5 h-5 -ml-10 z-10"
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full mt-4">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Priority Level</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black">Status</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <button type="submit" className="btn btn-primary mx-2 my-4">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <PaginatedDataTable
          title="List of Support Tickets"
          refresh
          columns={[
            { Header: "Title", accessor: "title" },
            { Header: "Requester Email", accessor: "email" },
            // {
            //   Header: "Age(Days)",
            //   accessor: "age",
            //   Filter: NumberRangeColumnFilter,
            // },
            {
              Header: "Status",
              accessor: "status",
              Filter: SelectColumnFilter,
            },
            {
              Header: "Priority Level",
              accessor: "priority",
              Filter: SelectColumnFilter,
            },
          ]}
          data={tickets?.data}
          count={tickets?.count}
          currentPage={tickets?.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(`/admin/manage/support/tickets/details/${row.uuid}`);
          }}
          multiSelectActions={(selectedFlatRows) => {
            if (selectedFlatRows.length === 5) {
              return (
                <div className="flex w-full h-full items-center justify-end py-2">
                  <button
                    type="button"
                    className="btn bg-red-100 text-red-600 hover:bg-red-300 mx-2 my-2 flex items-center justify-between"
                    onClick={() =>
                      updateTicketStatus(
                        selectedFlatRows.map((row) => row.original.courseUuid)
                      )
                    }
                  >
                    <span className="ml-1 lg:hidden">Update Status</span>
                    <span className="ml-1 hidden lg:flex">
                      Update Ticket Status
                    </span>
                  </button>
                </div>
              );
            }
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default TicketManagement;
