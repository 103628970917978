import { CalendarBlank } from "phosphor-react";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import Select from "react-select";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { triggerDownloadPipe } from "../../helpers";
// import { useNavigate } from "react-router-dom";

const PaymentRecords = () => {
  const {
    data: { payments },
    loaders: { getPayments },
    pagination: {
      payments: { updatePage },
    },
  } = useData();

  const client = useClient();

  const [loaded, setLoaded] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({
    label: "All",
    value: "all",
  });

  const exportPayments = (startDate, endDate, columns) => {
    flashMessage({ message: "Generating export", type: "info" });
    client("/api/v1/payment-excel-reports/payment-report", {
      method: "POST",
      res: "file",
      data: {
        startDate: startDate,
        endDate: endDate,
        columns: columns,
      },
      credentials: true,
    })
      .then(triggerDownloadPipe("PAYMENTS_EXPORT", "xlsx"))
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  useEffect(() => {
    if (!loaded) {
      getPayments(paymentStatus?.value);
      setLoaded(true);
    }
  }, [loaded, getPayments, paymentStatus]);

  return (
    <PageBuilder name="Payment Records">
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full px-2 py-2">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Filter Payment Records
            </span>
            <div className="flex w-full">
              <div className="flex flex-col mr-5">
                <span className="text-black">Start Date</span>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "01-02-2020",
                    }}
                  />
                  <CalendarBlank
                    weight="bold"
                    className="z-10 w-5 h-5 -ml-10"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-black">End Date</span>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "01-02-2020",
                    }}
                  />
                  <CalendarBlank
                    weight="bold"
                    className="z-10 w-5 h-5 -ml-10"
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full mt-4">
              <div className="flex flex-col">
                <span className="text-black">Status</span>
                <Select
                  options={[
                    { label: "All", value: "all" },
                    { label: "Successful", value: "Successful" },
                    {
                      label: "Transaction Pending",
                      value: "Transaction Pending",
                    },
                  ]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="Filter by Status"
                  onChange={setPaymentStatus}
                  defaultValue={paymentStatus}
                />
              </div>
              <div className="flex flex-col">
                <button
                  type="submit"
                  className="mx-2 my-4 btn btn-primary"
                  onClick={() => setLoaded(false)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <PaginatedDataTable
          title="List of Payment Records"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            { Header: "Transaction RRR", accessor: "rrr" },
            { Header: "Amount(₦)", accessor: "amount" },
            {
              Header: "Installment",
              accessor: (row) => {
                return row.installment
                  ? `Installment ${row.installment}`
                  : "Paid In Full";
              },
            },
            { Header: "Payee First Name", accessor: "trainee.firstName" },
            { Header: "Payee Last Name", accessor: "trainee.surname" },
            { Header: "Course", accessor: "course.title" },
            {
              Header: "Status",
              accessor: "status",
              Filter: SelectColumnFilter,
            },
            { Header: "Transaction Date", accessor: "paidOn" },
          ]}
          data={payments[paymentStatus?.value]?.data ?? []}
          // data={[]}
          count={payments[paymentStatus?.value]?.count ?? 0}
          currentPage={payments[paymentStatus?.value]?.page ?? 0}
          setCurrentPage={(page) => {
            updatePage({ status: paymentStatus?.value, newPage: page });
            setLoaded(false);
          }}
          exportToExcel={exportPayments}
          // onRowClick={(row) => {
          //   navigate(`/admin/manage/payments/details/${row.uuid}`);
          // }}
        />
      </div>
    </PageBuilder>
  );
};

export default PaymentRecords;
