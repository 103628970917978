import React from "react";
import Select from "react-select";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";

const ManageReports = () => {
  const options = ["Demo 1", "Demo 2", "Demo 3"].map((item) => ({
    value: item,
    label: item,
  }));

  return (
    <PageBuilder
      name="Available Company Reports"
      extras={
        <form className="flex flex-col sm:flex-row">
          <Select
            options={options}
            isSearchable={false}
            className="flex items-center justify-center md:min-w-[26rem] mr-2"
            classNamePrefix="select"
            placeholder="Select a Company"
          />
          <button type="submit" className="btn btn-secondary mx-0">
            Find Reports
          </button>
        </form>
      }
    >
      <PaginatedDataTable
        title="List of Company Reports"
        exports={{ excel: true, messagingList: true }}
        columns={[
          { Header: "Report Title", accessor: "title" },
          {
            Header: "Description",
            accessor: "ticketStatus",
          },
          {
            Header: "Type",
            accessor: "ticketType",
            Filter: SelectColumnFilter,
          },
          { Header: "Assignee", accessor: "assignee" },
          { Header: "Created At", accessor: "createdAt" },
          { Header: "Updated At", accessor: "updatedAt" },
        ]}
        data={[]}
      />
    </PageBuilder>
  );
};

export default ManageReports;
