import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import detailsService from "./detailsService";

//get user details from local storage

const details = JSON.parse(localStorage.getItem("details"));

const initialState = {
  details: details ? details : {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//get user details
export const getDetails = createAsyncThunk(
  "/details/getAll",
  async (_, thunkAPI) => {
    try {
      return await detailsService.getDetails();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const detailsSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    reset: (state) => state.initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.details = action.payload;
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = detailsSlice.actions;
export default detailsSlice.reducer;
