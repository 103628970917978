import React, { useState } from "react";
import { Link } from "react-router-dom";
import urls from "../../utilities/urls";
import ErrorMessages from "../../utilities/ErrorMessages";
import {
  MdOutlineAnnouncement,
  MdCalendarToday,
  MdOutlineEventNote,
} from "react-icons/md";
import { IoTicketOutline } from "react-icons/io5";
import { ImHome } from "react-icons/im";
import "./Nav.css";

function NavMobile() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          // alert(json.message)
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else {
          alert(ErrorMessages.UnkownRetry);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  const clickDropDown = (x) => {
    if (x == 1) {
      if (dropDown1) setDropDown1(false);
      else setDropDown1(true);
    }
    if (x == 2) {
      if (dropDown2) setDropDown2(false);
      else setDropDown2(true);
    }
  };

  return (
    <div className="navMobile">
      <a
        href={urls.home}
        data-toggle="tooltip"
        data-placement="right"
        title="Home"
      >
        <ImHome size={30} color="green" />
      </a>
      <Link
        to="/trainee/dashboard"
        data-toggle="tooltip"
        data-placement="right"
        className="red-tooltip ml-2"
        title="Dashboard"
      >
        <i class="fas fa-columns fa-2x" style={{ color: "green" }}></i>
        <span style={{ fontSize: "10px" }}> Dashboard</span>
      </Link>
      <ul>
        <li class="submenu" onClick={() => clickDropDown(1)}>
          <Link
            className={dropDown1 ? `subdrop ml-2` : ` ml-2`}
            to="#"
            data-toggle="tooltip"
            data-placement="right"
            title="Courses"
          >
            <i class="fas fa-layer-group fa-2x" style={{ color: "green" }}></i>
            <span style={{ fontSize: "10px" }}> Courses</span>
          </Link>
          <ul
            style={{
              display: dropDown1 ? `block` : `none`,
              marginLeft: dropDown1 ? `5px` : ``,
            }}
          >
            <li style={{ marginTop: "10px" }}>
              <Link
                to="/trainee/available_courses"
                data-toggle="tooltip"
                data-placement="right"
                className="red-tooltip"
                title="Available Courses"
              >
                <i
                  class="fas fa-layer-group fa-x"
                  style={{ color: "green" }}
                ></i>{" "}
              </Link>
            </li>
            <li style={{ marginTop: "10px" }}>
              <Link
                to="/trainee/my_courses"
                data-toggle="tooltip"
                data-placement="right"
                className="red-tooltip"
                title="My Courses"
              >
                <MdOutlineEventNote color="green" size={20} />
              </Link>
            </li>
          </ul>
        </li>
      </ul>
      <Link
        to="/trainee/profile"
        data-toggle="tooltip"
        data-placement="right"
        className="red-tooltip"
        title="Profile"
      >
        <i class="fas fa-user fa-2x" style={{ color: "green" }}></i>
        <br />
        <span style={{ fontSize: "10px" }}> Profile</span>
      </Link>
      <Link
        to="/trainee/payments"
        data-toggle="tooltip"
        data-placement="right"
        className="red-tooltip"
        title="Payments"
      >
        <i class="fas fa-hashtag fa-2x" style={{ color: "green" }}></i> <br />
        <span style={{ fontSize: "10px" }}> Payment</span>
      </Link>
      <Link
        to="/trainee/programs"
        data-toggle="tooltip"
        data-placement="right"
        className="red-tooltip"
        title="Appliation"
      >
        <i class="fas fa-globe fa-2x" style={{ color: "green" }}></i>
        <br />
        <span style={{ fontSize: "10px" }}> Program</span>
      </Link>
      <Link
        to="/trainee/application"
        data-toggle="tooltip"
        data-placement="right"
        className="red-tooltip"
        title="Appliation"
      >
        <i class="fas fa-user-shield fa-2x" style={{ color: "green" }}></i>
        <br />
        <span style={{ fontSize: "10px" }}> Appliation</span>
      </Link>
      <Link
        to="/trainee/assignment"
        data-toggle="tooltip"
        data-placement="right"
        className="red-tooltip"
        title="Assignments"
      >
        <i class="far fa-list-alt fa-2x" style={{ color: "green" }}></i>
      </Link>
      <Link
        to="/trainee/quizzes"
        data-toggle="tooltip"
        data-placement="right"
        className="red-tooltip"
        title="Quizzes"
      >
        <i class="fas fa-pen fa-2x" style={{ color: "green" }}></i>
        <br />
        <span style={{ fontSize: "10px" }}> Quizzes</span>
      </Link>
      <ul>
        <li class="submenu" onClick={() => clickDropDown(2)}>
          <Link
            to="#"
            className={dropDown2 ? `subdrop` : ``}
            data-toggle="tooltip"
            data-placement="right"
            title="Institution"
          >
            <i class="fas fa-border-all fa-2x" style={{ color: "green" }}></i>
            <br />
            <span style={{ fontSize: "10px" }}> Institution</span>
          </Link>
          <ul
            style={{
              display: dropDown2 ? `block` : `none`,
              marginLeft: dropDown2 ? `5px` : ``,
            }}
          >
            <li style={{ marginTop: "10px" }}>
              <Link
                to="/trainee/announcement"
                data-toggle="tooltip"
                data-placement="right"
                className="red-tooltip"
                title="Announcement"
              >
                <MdOutlineAnnouncement color="green" size={20} />
              </Link>
            </li>
            <li style={{ marginTop: "10px" }}>
              <Link
                to="/trainee/calendar"
                data-toggle="tooltip"
                data-placement="right"
                className="red-tooltip"
                title="Calender"
              >
                <MdCalendarToday size={20} color="green" />
              </Link>
            </li>
            <li style={{ marginTop: "10px" }}>
              <Link to="/trainee/tickets">
                <IoTicketOutline size={20} color="green" />
              </Link>
            </li>
          </ul>
        </li>
      </ul>
      <div onClick={logOut}>
        <Link to={"#"}>
          <i class="fas fa-power-off fa-2x" style={{ color: "green" }}></i>
        </Link>
      </div>
    </div>
  );
}

export default NavMobile;
