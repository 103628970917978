export const triggerDownloadPipe =
  (filename, filetype = "pdf") =>
  (blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `${filename}-${new Date().toISOString()}.${filetype}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
