import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DateTimeToDate from "../../utilities/DateTimeToDate";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import TraineeCheckAccountStatus from "../registration-components/CheckAccountStatus";
import axios from "axios";
import NavMobile from "../Layouts/NavMobile";

const TraineeMyCourses = () => {
  const token = localStorage.getItem("token");

  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [myCourses, setMyCourses] = useState([]);

  console.log(myCourses);
  const coursesApplied = () => {
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "application/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          console.log(json.data.applications);
          setMyCourses(json.data.applications);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    coursesApplied();
  }, []);

  return (
    <div>
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="My Courses" />

          <TraineeCheckAccountStatus _token={token} />

          <div
            style={{
              display: !editProfile ? `block` : `none`,
              width: "100%",
            }}
            className="row"
          >
            <div className="row" style={{ width: "100%" }}>
              {myCourses.map((x) =>
                x.status == "confirmed" ? (
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div class="card">
                      <div class="card-body">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {DateTimeToDate.dateTimeToString(x.createdAt)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Course Title</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{x.courseTitle}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{x.status}</td>
                              <td>
                                {x.status == 0 ? (
                                  <span className="text-danger">Pending</span>
                                ) : (
                                  <Link to={""} className="text-success">
                                    Enrolled
                                  </Link>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : x.status == "shortlisted" ? (
                  <></>
                ) : (
                  <></>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeMyCourses;
