import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";
import TraineeCheckAccountStatus from "../registration-components/CheckAccountStatus";
import urls from "../../utilities/urls";

const TakeAss = () => {
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const [editProfile, setEditProfile] = useState(false);
  const [ass, setAss] = useState([]);

  const getAss = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      urls.api + "assignments/all/" + id,
      config
    );
    const data = response.data;
    // const data1 = data.data.exams;
    console.log(data.data);
    setAss(data.data);
  };

  useEffect(() => {
    getAss();
  }, []);

  return (
    <div class="content container-fluid">
      <PageTitle title="Assignments" />

      <TraineeCheckAccountStatus _token={token} />

      <div>
        <div className="row">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card">
              <div class="card-body">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Question </th>
                        <th>Due Date </th>
                        <th>Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ass.map((x) => (
                        <tr>
                          <td>{x.topic}</td>
                          <td>{x.due_date}</td>
                          <td className="w-25">
                            <Link to={`/trainee/takeassignment1/${x.uuid}`}>
                              <span className="text-success">
                                {" "}
                                View <i className="fa fa-eye"></i>{" "}
                              </span>
                            </Link>
                            <br />{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakeAss;
