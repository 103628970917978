import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import TraineeCheckAccountStatus from "../registration-components/CheckAccountStatus";
import urls from "../../utilities/urls";
import axios from "axios";
import NavMobile from "../Layouts/NavMobile";

const TraineeQuizzes = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [myCourses1, setMyCourses] = useState([]);

  console.log(myCourses1);

  const [editProfile, setEditProfile] = useState(false);
  const token = localStorage.getItem("token");
  const coursesApplied = () => {
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "application/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          console.log(json.data.applications);
          setMyCourses(json.data.applications);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    coursesApplied();
  }, []);
  useEffect(() => {
    // alert("Hello world")
  }, []);

  const data = {
    email: "generahben2@gmail.com",
    password: "password",
  };
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  useEffect(() => {
    (async () => {
      axios
        .post(urls.api + "auth/login", data, { headers })
        .then((result) => {
          console.log("success");
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });

      axios.get(urls.api + "quizzes/1").then((res) => {
        const products = res.data.result.items;
        setProducts(products);
        console.log(products);
        console.log("pass");
      });
    })();
  }, []);

  return (
    <div className="navMenu">
      <div className="t">
        <NavMobile />
      </div>
      <div className="w">
        <PageTitle title="Quizzes" />

        <TraineeCheckAccountStatus _token={token} />

        <div class="card">
          <div class="card-body">
            <div style={{ display: !editProfile ? `block` : `none` }}>
              <div className="row">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Course Title</th>
                        <th>Date</th>
                        <th>Due Date </th>
                        <th>Score </th>
                        <th>Status </th>
                      </tr>
                    </thead>
                    <tbody>
                      {myCourses1.map((x) =>
                        x.status == "confirmed" ? (
                          <tr className="text-secondary w-100">
                            <td className="w-10">{x.courseTitle}</td>
                            <td>{x.date}</td>
                            <td>{x.date_due}</td>
                            <td>{x.score}%</td>
                            <td>
                              {x.status == "confirmed" ? (
                                <Link
                                  to={`/trainee/takequizzes/${x.courseUuid}`}
                                >
                                  <span className="text-info">Take Quiz</span>
                                </Link>
                              ) : (
                                ""
                              )}
                              {x.status == 1 ? (
                                <span className="text-success">View</span>
                              ) : (
                                ""
                              )}
                              {x.status == 2 ? (
                                <span className="text-secondary">
                                  Not Taken
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeQuizzes;
