import React from "react";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";

const ExamConfiguration = () => {
  return (
    <PageBuilder name={"Manage Exam Definition"}>
      <div className="flex w-full pt-3">
        <PaginatedDataTable
          title="List of Exam Configurations"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            { Header: "Training Course Title", accessor: "applicationUuid" },
            { Header: "Program Type", accessor: "firstName" },
            { Header: "Defined?", accessor: "surname" },
            { Header: "Definition Count", accessor: "courseTitle" },
          ]}
          data={[]}
        />
      </div>
    </PageBuilder>
  );
};

export default ExamConfiguration;
