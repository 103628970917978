import {
  CalendarBlank,
  Minus,
  Plus,
  // TextAlignJustify,
  WarningCircle,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { Modal } from "flowbite-react";
import { useData } from "../../providers/DataProvider";
import { useClient } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { flashMessage } from "../../helpers/flash";
import Datetime from "react-datetime";
import { compareAsc, format, formatDistance } from "date-fns";

const ManageCourses = () => {
  const {
    data: { courses, courseMetaData, trainingCenters, programs },
    loaders: { getCourses, getCourseMetaData, getTrainingCenters, getPrograms },
    pagination: {
      courses: { updatePage },
    },
  } = useData();

  const client = useClient();

  const [loaded, setLoaded] = useState(false);
  const [numInstallments, setNumInstallments] = useState(1);
  const [trainer, setTrainer] = useState(null);
  const [courseCategories, setCourseCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [rtcs, setRtcs] = useState([]);
  const [sessions, setSessions] = useState({
    1: { startDate: null, endDate: null, payCloseDate: null },
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      getTrainingCenters();
      getCourses();
      getPrograms();
      getCourseMetaData();
      setLoaded(true);
    }
  }, [loaded, getCourses, getCourseMetaData, getTrainingCenters, getPrograms]);

  const [modalShow, setModalShow] = useState(false);

  const [modal2Show, setModal2Show] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const loadOptionsTrainers = async (inputValue, callback) => {
    const { data } = await client(`/api/v1/search/trainer?q=${inputValue}`);

    const parsedTrainers = data.map((trainer) => ({
      value: trainer.uuid,
      label: `${trainer.surname} ${trainer.firstName}`,
    }));

    callback(parsedTrainers);
  };

  const createCourse = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const data = new FormData();
    if (trainer !== null) {
      data.append("trainerUuid", trainer.value);
    }
    if (form.minimumDeposit.value > form.cost.value) {
      flashMessage({
        message:
          "Minimum Deposit should be less than or equal to the cost of the course",
        type: "error",
      });
    }
    data.append("courseTypeUuid", form.courseTypeUuid.value);
    data.append("title", form.title.value);
    data.append("description", form.description.value);
    data.append("image", image);
    courseCategories.forEach((category, index) =>
      data.append(`courseCategoriesUuid[${index}]`, category.value)
    );
    data.append("cost", form.cost.value * 100);
    data.append("noOfInstallments", numInstallments);
    data.append(
      "minimumDeposit",
      form.minimumDeposit.value
        ? form.minimumDeposit.value * 100
        : form.minimumDeposit.value
    );
    data.append("status", form.status.value);
    rtcs.forEach((rtc, index) =>
      data.append(`regionalTrainingCentersUuid[${index}]`, rtc.value)
    );
    Object.keys(sessions).forEach((key, index) =>
      Object.keys(sessions[key]).forEach((sessionKey) =>
        data.append(
          `calendars[${index}][${sessionKey}]`,
          format(new Date(sessions[key][sessionKey]), "yyyy-MM-dd")
        )
      )
    );

    client("/api/v1/courses", {
      method: "POST",
      json: false,
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  const addCoursesToProgram = (courses) => {
    setSelectedCourses(courses);
    setModal2Show(true);
  };

  const submitCoursesToProgram = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const program = form.program.value;

    let data = {
      programUuid: program,
      courseUuid: selectedCourses,
    };

    client("/api/v1/program/add/course", {
      method: "POST",
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  return (
    <PageBuilder
      name={"Courses"}
      extras={
        <div className="flex w-full h-full py-4">
          {/* <button className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between">
            <TextAlignJustify weight="bold" className="w-5 h-5 mr-2" />
            <span>Course Summary</span>
          </button> */}
          <button
            className="flex items-center mx-0 btn btn-secondary"
            onClick={() => setModalShow(true)}
          >
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Add New Course</span>
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white max-h-[75vh] overflow-y-scroll"
                  onSubmit={createCourse}
                  id="createCourseForm"
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      New Course
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Create Course</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="title" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Course Title
                        </label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Course Title"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="type" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Course Type
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Course Type"
                          classNamePrefix="select"
                          options={courseMetaData?.types?.map((type) => ({
                            value: type.uuid,
                            label: type.name,
                          }))}
                          isSearchable={false}
                          id="type"
                          required
                          name="courseTypeUuid"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="description" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>}
                          Course Description
                        </label>
                        <textarea
                          id="description"
                          name="description"
                          placeholder="Course Description"
                          className="flex w-full placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="categories" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Course
                          Categories
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Course Categories"
                          classNamePrefix="select"
                          options={courseMetaData?.categories?.map(
                            (category) => ({
                              value: category.uuid,
                              label: category.name,
                            })
                          )}
                          isSearchable={true}
                          isMulti={true}
                          id="categories"
                          onChange={(newValue) => setCourseCategories(newValue)}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="status" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>}
                          Course Status
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Course Status"
                          classNamePrefix="select"
                          options={["draft", "published", "submitted"].map(
                            (status) => ({
                              value: status,
                              label: status,
                            })
                          )}
                          isSearchable={false}
                          isMulti={false}
                          id="status"
                          name="status"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="type" className="mb-1 text-left">
                          Course Trainer
                        </label>
                        <AsyncSelect
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Course Trainer"
                          classNamePrefix="select"
                          isMulti={false}
                          isClearable
                          isSearchable
                          filterOption={createFilter({
                            ignoreAccents: false,
                          })}
                          defaultInputValue=""
                          defaultValue=""
                          cacheOptions
                          loadOptions={loadOptionsTrainers}
                          defaultOptions
                          backspaceRemovesValue
                          onChange={(e) => {
                            if (e) {
                              setTrainer(e);
                            } else {
                              setTrainer(null);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="image" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>}
                          Course Image
                        </label>
                        <div className="flex">
                          <div className="relative w-full">
                            <input
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                              id="image"
                              name="image"
                              type="file"
                              required
                              accept="image/*"
                              onChange={(e) => setImage(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="rtcs" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Supported
                          Regional Training Centers
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Supported RTCs"
                          classNamePrefix="select"
                          options={trainingCenters?.data?.map((rtc) => ({
                            value: rtc.uuid,
                            label: rtc.name,
                          }))}
                          isSearchable={true}
                          isMulti={true}
                          onChange={(newValue) => setRtcs(newValue)}
                          id="rtcs"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="cost" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Course Cost
                          (₦)
                        </label>
                        <input
                          type="number"
                          id="cost"
                          name="cost"
                          placeholder="Course Cost"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          min={1000}
                          defaultValue={1000}
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label
                          htmlFor="minimumDeposit"
                          className="mb-1 text-left"
                        >
                          {numInstallments > 1 ? (
                            <span className="text-red-600">*</span>
                          ) : (
                            <></>
                          )}{" "}
                          Minimum Deposit (₦)
                        </label>
                        <input
                          type="number"
                          id="minimumDeposit"
                          name="minimumDeposit"
                          placeholder="Minimum Deposit"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          disabled={!(numInstallments > 1)}
                          required={numInstallments > 1}
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label
                          htmlFor="numOfInstallments"
                          className="mb-1 text-left"
                        >
                          {<span className="text-red-600">*</span>} Number Of
                          Payment Installments
                        </label>
                        <input
                          type="number"
                          id="numOfInstallments"
                          name="numOfInstallments"
                          placeholder="Number of Installments"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          value={numInstallments}
                          onChange={(e) => setNumInstallments(e.target.value)}
                          min={1}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <WarningCircle
                            weight="bold"
                            className="w-5 h-5 mr-2"
                          />
                          <span className="text-xl">Calendar Sessions</span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex">
                          <button
                            type="button"
                            className="p-0 px-2 btn btn-white focus:ring-2 focus:ring-primary-700 focus:ring-offset-2"
                            onClick={() => {
                              const prev = Object.keys(sessions).length;
                              setSessions({
                                ...sessions,
                                [prev + 1]: {
                                  startDate: null,
                                  endDate: null,
                                  payCloseDate: null,
                                },
                              });
                            }}
                          >
                            <Plus weight="bold" className="w-5 h-5" />
                          </button>
                          <button
                            type="button"
                            className="p-0 px-2 btn btn-white focus:ring-2 focus:ring-primary-700 focus:ring-offset-2"
                            onClick={() => {
                              const prev = Object.keys(sessions).length;
                              const now = Object.assign({ ...sessions });
                              delete now[prev];
                              setSessions(now);
                            }}
                            disabled={Object.keys(sessions).length === 1}
                          >
                            <Minus weight="bold" className="w-5 h-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {Object.keys(sessions).map((session, index) => (
                      <div className="flex flex-col mb-6">
                        <h4 className="mb-1 text-lg text-left">
                          Session {index + 1}
                        </h4>
                        <div className="flex w-full mb-4" key={index}>
                          <div className="flex flex-col min-w-[24rem] mr-16">
                            <label htmlFor="cost" className="mb-1 text-left">
                              {<span className="text-red-600">*</span>} Start
                              Date
                            </label>
                            <div className="flex items-center">
                              <Datetime
                                inputProps={{
                                  className:
                                    "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                  placeholder: "01/02/2020",
                                  id: "start_date",
                                  name: "start_date",
                                  required: true,
                                  autoComplete: "off",
                                }}
                                timeFormat={false}
                                onOpen={() => {
                                  const modal =
                                    document.getElementById("createCourseForm");
                                  modal.scrollTo({
                                    top: modal.scrollHeight,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                }}
                                onChange={(value) =>
                                  setSessions({
                                    ...sessions,
                                    [session]: {
                                      ...sessions[session],
                                      startDate: value.toISOString(),
                                    },
                                  })
                                }
                              />
                              <CalendarBlank
                                weight="bold"
                                className="z-10 w-5 h-5 -ml-10"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col min-w-[24rem]">
                            <label htmlFor="type" className="mb-1 text-left">
                              {<span className="text-red-600">*</span>} End Date
                            </label>
                            <div className="flex items-center">
                              <Datetime
                                inputProps={{
                                  className:
                                    "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                  placeholder: "01/02/2020",
                                  id: "end_date",
                                  name: "end_date",
                                  required: true,
                                  autoComplete: "off",
                                }}
                                timeFormat={false}
                                onOpen={() => {
                                  const modal =
                                    document.getElementById("createCourseForm");
                                  modal.scrollTo({
                                    top: modal.scrollHeight,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                }}
                                onChange={(value) =>
                                  setSessions({
                                    ...sessions,
                                    [session]: {
                                      ...sessions[session],
                                      endDate: value.toISOString(),
                                    },
                                  })
                                }
                              />
                              <CalendarBlank
                                weight="bold"
                                className="z-10 w-5 h-5 -ml-10"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label htmlFor="type" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Pay Close
                            Date
                          </label>
                          <div className="flex items-center">
                            <Datetime
                              inputProps={{
                                className:
                                  "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                placeholder: "01/02/2020",
                                id: "pay_close_date",
                                name: "pay_close_date",
                                required: true,
                                autoComplete: "off",
                              }}
                              timeFormat={false}
                              onOpen={() => {
                                const modal =
                                  document.getElementById("createCourseForm");
                                modal.scrollTo({
                                  top: modal.scrollHeight,
                                  left: 0,
                                  behavior: "smooth",
                                });
                              }}
                              onChange={(value) =>
                                setSessions({
                                  ...sessions,
                                  [session]: {
                                    ...sessions[session],
                                    payCloseDate: value.toISOString(),
                                  },
                                })
                              }
                            />
                            <CalendarBlank
                              weight="bold"
                              className="z-10 w-5 h-5 -ml-10"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
          <React.Fragment>
            <Modal
              show={modal2Show}
              onClose={() => {
                setModal2Show(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white max-h-[90vh] overflow-y-scroll"
                  onSubmit={submitCoursesToProgram}
                  id="addCoursesToProgramForm"
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      Select Program to Add Selected Courses to
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModal2Show(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Add Courses</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex w-full mb-20">
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="program" className="mb-1 text-left">
                          Course Program
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Course Program"
                          classNamePrefix="select"
                          options={programs?.map((program) => ({
                            value: program.uuid,
                            label: program.name,
                          }))}
                          onOpen={() => {
                            const modal = document.getElementById(
                              "addCoursesToProgramForm"
                            );
                            modal.scrollTo({
                              top: modal.scrollHeight,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          isSearchable={false}
                          id="program"
                          required
                          name="program"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col w-full pt-3 pl-2">
        {/* <div className="flex w-full">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Search Courses
            </span>
            <div className="flex flex-col">
              <span className="text-black">Search by Course Title or Code</span>
            </div>
          </div>
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Filter Courses
            </span>
            <div className="flex w-full">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Course Type</span>
                <Select
                  options={courseMetaData?.types?.map((type) => ({
                    value: type.uuid,
                    label: type.name,
                  }))}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black">Course Category</span>
                <Select
                  options={courseMetaData?.categories?.map((category) => ({
                    value: category.uuid,
                    label: category.name,
                  }))}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <button type="submit" className="mx-2 my-4 btn btn-primary">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <PaginatedDataTable
          title="List of Training Courses"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            { Header: "Course Code", accessor: "courseCode" },
            { Header: "Course Title", accessor: "title" },
            {
              Header: "Course Type",
              accessor: "courseType",
              Filter: SelectColumnFilter,
            },
            {
              Header: "Course Categories",
              accessor: (x) => {
                return x.courseCategories.map((c) => c.name).join(", ");
              },
            },
            {
              Header: "Duration",
              accessor: (row) => {
                const sorted = row.calendars.sort((a, b) =>
                  compareAsc(new Date(a.startDate), new Date(b.startDate))
                );

                const earliest = sorted[0];
                const latest = sorted[sorted.length - 1];

                return formatDistance(
                  new Date(latest?.endDate ?? new Date()),
                  new Date(earliest?.startDate ?? new Date())
                );
              },
            },
            { Header: "Cost(₦)", accessor: "cost" },
            {
              Header: "Number of Payment Installments",
              accessor: "noOfInstallments",
            },
            { Header: "Minimum Deposit(₦)", accessor: "minDeposit" },
            {
              Header: "Status",
              accessor: "status",
              Filter: SelectColumnFilter,
            },
            {
              Header: "Sponsored?",
              accessor: (row) => {
                return row.isSponsored === 0 ? "no" : "yes";
              },
              Filter: SelectColumnFilter,
            },
            { Header: "Published By", accessor: "publishedBy" },
            { Header: "Last Updated By", accessor: "lastUpdatedBy" },
          ]}
          data={courses?.data}
          count={courses?.count}
          currentPage={courses?.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(
              `/admin/manage/training/courses/details/${row.courseUuid}`
            );
          }}
          multiSelectActions={(selectedFlatRows) => {
            return (
              <div className="flex items-center justify-end w-full h-full py-2">
                <button
                  type="button"
                  className="flex items-center justify-between mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
                  onClick={() =>
                    addCoursesToProgram(
                      selectedFlatRows.map((row) => row.original.courseUuid)
                    )
                  }
                >
                  <Plus weight="bold" className="w-4 h-4" />
                  <span className="ml-1 lg:hidden">Add</span>
                  <span className="hidden ml-1 lg:flex">
                    Add selected to program
                  </span>
                </button>
              </div>
            );
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default ManageCourses;
