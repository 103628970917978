import React from 'react';
import { Link } from 'react-router-dom';

const SideBarItem = ({ name, icon, link }) => {
  return (
    <li className="flex align-center px-6 my-2 cursor-pointer h-12 transition-colors duration-200 ease-in-out hover:bg-slate-200">
      <Link
        to={link}
        className="text-black hover:text-black h-12 w-full flex items-center"
      >
        {icon}
        <span className="ml-2 3xl:!ml-8 text-sm sm:text-base 3xl:!text-xl">{name}</span>
      </Link>
    </li>
  );
}

export default SideBarItem