import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import PageBuilder from "../../components/layout/PageBuilder";
import { AsyncData, useData } from "../../providers/DataProvider";

const UserAnalytics = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  const {
    data: { stats },
    loaders: { getStats },
  } = useData();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      getStats();
      setLoaded(true);
    }
  }, [loaded, getStats]);

  return (
    <PageBuilder name="User Dashboard">
      <div className="flex flex-col w-full">
        <div className="flex flex-col w-full px-2 py-2 mb-10">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
            User Summary
          </span>
          <div className="flex justify-center sm:justify-start w-full flex-wrap">
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-gray-400">Active Users</span>
              <span className="text-black text-4xl sm:mt-3">
                <AsyncData>{stats?.users?.active}</AsyncData>
              </span>
            </div>
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-gray-400">Inactive Users</span>
              <span className="text-black text-4xl sm:mt-3">
                <AsyncData>{stats?.users?.inactive}</AsyncData>
              </span>
            </div>
          </div>
        </div>
      </div>
    </PageBuilder>
  );
};

export default UserAnalytics;
