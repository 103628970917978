import React from "react";

const PageBuilder = ({ name = "Empty Page", extras = <></>, children }) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between h-fit md:h-24 px-10 bg-white mx-8 mb-0.5 w-auto">
        <h1 className="my-3 md:my-6 text-xl md:text-xl lg:text-2xl xl:text-3xl text-center md:text-left text-bold text-gray-700 mr-4">
          {name}
        </h1>
        <div className="flex items-center h-full">{extras}</div>
      </div>
      <div className="flex flex-col h-auto min-h-[48rem] px-4 py-2 bg-white mx-8 mb-0.5 w-auto">
        {children}
      </div>
    </div>
  );
};

export default PageBuilder;
