import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GiNotebook } from "react-icons/gi";
import { ImHome } from "react-icons/im";
import ErrorMessages from "../../utilities/ErrorMessages";
import urls from "../../utilities/urls";

const TrainerNavMenu = () => {
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);
  const [dropDown3, setDropDown3] = useState(false)

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          // alert(json.message)
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else {
          alert(ErrorMessages.UnkownRetry);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  const clickDropDown = (x) => {
    if (x == 1) {
      if (dropDown1) setDropDown1(false);
      else setDropDown1(true);
    }
    if (x == 2) {
      if (dropDown2) setDropDown2(false);
      else setDropDown2(true);
    }
    if(x == 3){
      if(dropDown3)setDropDown3(false)
      else setDropDown3(true)
    }
  };

  return (
    <div class="sidebar" id="sidebar">
      <div class="sidebar-logo">
        <a href="https://tmis.naptinportal.com/">
          <img src="assets/img/logo-icon.png" class="img-fluid" alt="" />
        </a>
      </div>
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="submenu">
              <a href={urls.home}>
                <ImHome size={20} />
                <span>Home</span>
              </a>
            </li>
            <li class="active">
              <Link to="/trainer/dashboard">
                <i class="fas fa-columns"></i> <span>Dashboard</span>
              </Link>
            </li>
                <li class="submenu" onClick={() => clickDropDown(3)}>
              <Link className={dropDown3 ? `subdrop` : ``} to="#">
                <i class="fas fa-video"></i> <span> Host Class </span>{" "}
                <span class="menu-arrow"></span>
              </Link>
              <ul style={{ display: dropDown3 ? `block` : `none` }}>
                {/* <li><Link to="/trainer/available_courses"> View Courses </Link></li> */}
                <li>
                  <a href="https://meet.google.com/"> Google Meet </a>
                </li>
              </ul>
            </li>
            <li class="submenu" onClick={() => clickDropDown(1)}>
              <Link className={dropDown1 ? `subdrop` : ``} to="#">
                <i class="fas fa-layer-group"></i> <span> Courses </span>{" "}
                <span class="menu-arrow"></span>
              </Link>
              <ul style={{ display: dropDown1 ? `block` : `none` }}>
                {/* <li><Link to="/trainer/available_courses"> View Courses </Link></li> */}
                <li>
                  <Link to="/trainer/my_courses"> Courses </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/trainer/profile">
                <i class="fas fa-user"></i> <span> Profile</span>
              </Link>
            </li>
            <li>
              <Link to="/trainer/students">
                <i class="fas fa-users"></i> <span> Students</span>
              </Link>
            </li>
            <li>
              <Link to="/trainer/assignment">
                <i class="far fa-list-alt"></i> <span> Assignments</span>
              </Link>
            </li>
            <li>
              <Link to="/trainer/quizzes">
                <i class="fas fa-pen"></i> <span> Quizzes</span>
              </Link>
            </li>
            <li>
              <Link to="/trainer/exams">
                <GiNotebook size={25} /> <span> Exams</span>
              </Link>
            </li>
            <li class="submenu" onClick={() => clickDropDown(2)}>
              <Link to="#" className={dropDown2 ? `subdrop` : ``}>
                <i class="fas fa-border-all"></i> <span> Institution</span>{" "}
                <span class="menu-arrow"></span>
              </Link>
              <ul style={{ display: dropDown2 ? `block` : `none` }}>
                <li>
                  <Link to="/trainer/announcement">Announcements</Link>
                </li>
                <li>
                  <Link to="/trainer/calendar">Calendar</Link>
                </li>
                <li>
                  <Link to="/trainer/tickets">Tickets</Link>
                </li>
              </ul>
            </li>
            <li
              onClick={() => {
                logOut();
              }}
            >
              <Link to={"#"}>
                <i class="fas fa-power-off"></i> <span> Logout</span>
              </Link>
            </li>
            <li>
              <Link to={""}> </Link>
            </li>
            <li>
              <Link to={""}> </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TrainerNavMenu;
