import React from "react";
import logo from "../../assets/images/naptin-logo.svg";
import { login } from "../../redux/features/authSlice";
import { flashMessage } from "../../helpers/flash";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import client from "../../helpers/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const to = location.state?.from?.pathname || "/admin/dashboard";

  const onSubmit = async (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    const userData = {
      email: form.email.value,
      password: form.password.value,
    };
    await client("/auth/generate/csrf-cookie", {
      method: "GET",
      credentials: true,
    }).catch((err) => {
      console.log(err);
      if (err.response) {
        flashMessage({
          message: "Unable to contact server",
          type: "error",
        });
      } else {
        flashMessage({
          message:
            "Something went wrong signing you in, please try again later.",
          type: "error",
        });
      }
    });
    client("/api/v1/auth/login", {
      method: "POST",
      data: userData,
      credentials: true,
    })
      .then((response) => {
        const res = response;
        if (res.success === true) {
          dispatch(
            login({
              user: {
                ...res.data.user,
                roles: res.data.roles,
                permissions: res.data.permissions,
                token: res.data["auth_token"],
              },
            })
          );
          navigate(to, { replace: true });
          flashMessage({ message: res.message, type: "success" });
        } else {
          flashMessage({ message: res.message, type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response;
          flashMessage({ message: data.message, type: "error" });
        } else {
          flashMessage({
            message:
              "Something went wrong signing you in, please try again later.",
            type: "error",
          });
        }
      });
  };

  return (
    <div className="container px-6 lg:w-9/12">
      <div>
        <ToastContainer />
      </div>
      <div className="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
        <div className="flex flex-col md:flex-row w-full md:w-full lg:w-full mx-auto md:mx-0">
          <div className="bg-white p-10 flex flex-col w-full shadow-xl rounded-l-xl">
            <img src={logo} alt="Naptin Logo" className="w-24 h-20 mx-3 my-6" />
            <h2 className="text-lg font-bold text-gray-800 text-left">
              Welcome back
            </h2>
            <h5 className="text-md font-thin text-gray-500 text-left mb-3">
              Happy to see you again!
            </h5>
            <form onSubmit={onSubmit} className="w-full">
              <div id="input" className="flex flex-col w-full my-3">
                <label htmlFor="email" className="text-gray-500 mb-1 text-left">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Please enter your email"
                  className="border border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  required
                />
              </div>
              <div id="input" className="flex flex-col w-full my-3">
                <label
                  htmlFor="password"
                  className="text-gray-500 mb-1 text-left"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Please enter your password"
                  className="border border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  required
                />
              </div>
              <div id="button" className="flex flex-col w-full mt-5 mb-3">
                <button
                  type="submit"
                  className="w-full py-4 bg-green-600 rounded-lg text-green-100"
                >
                  <div className="flex flex-row items-center justify-center">
                    <div className="font-bold">Login</div>
                  </div>
                </button>
              </div>
              <div className="flex flex-row w-full justify-end mb-4">
                <a href="/admin/forgot" className="link">Forgot Password?</a>
              </div>
            </form>
          </div>
          <div className="flex flex-col w-full shadow-xl rounded-r-xl bg-custom-login"></div>
        </div>
      </div>
    </div>
  );
};

export default Login;
