import PageTitle from "./PageTitle";

const TrainerPageNotFound = () => {    
    return <div class="content container-fluid">
    <PageTitle title="Page Not Found"/>
    <div class="row">               
                <div class="col-xl-12 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="dash-widget-header">
                            <span class="text-primary">
                                    <i class="fas fa-times fa-2x"></i>
                                </span>
                                <div class="dash-widget-info">
                                    <h3>Hey!</h3>
                                    <h6 class="text-muted">Sorry, we can't find what you are looking for</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>  
    
    }
    
    export default TrainerPageNotFound;