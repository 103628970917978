import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import axios from 'axios'
import urls from "../../utilities/urls";


const TrainerViewAnnouncement = () =>{
    // const {id} = useParams()
    const [announcement, setAnnouncement] = useState([]);

    useEffect(() => {
      (async() => {
          const announcements = urls.api + 'announcements/'
          await axios.get(announcements)
          .then((result) => {
              const announcement = result.data.data.announcements
              setAnnouncement(announcement)
              console.log(announcement)
          })
      })();
    }, []);

    
    return ( <div class="content container-fluid">
        <PageTitle title={announcement.title}/>
        {announcement.map(x =>
        <div class="row">
		<div class="col-xl-12 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="dash-widget-header">
                            <span class="text-primary">
                                    <i class="fas fa-bullhorn fa-2x"></i>
                                </span>
                                <div class="dash-widget-info">
                                    <h3>{x.title}</h3>
                                    <h6 class="text-muted">{x.body}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				</div>
        )}
        </div>  

    )
}

export default TrainerViewAnnouncement;