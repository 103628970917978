import React from "react";
import logo from "../../assets/images/naptin-logo.svg";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";

const ResetPassword = () => {
  const client = useClient();
  const onSubmit = async (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    const data = {
      currentPassword: form.currentPassword.value,
      newPassword: form.newPassword.value,
      newPassword_confirmation: form.confirmNewPassword.value,
    };
    if (data.newPassword === data.newPassword_confirmation) {
      client("/api/v1/auth/change-password", {
        method: "POST",
        data: data,
        credentials: true,
      })
        .then((response) => {
          const res = response;
          if (res.success === true) {
            flashMessage({ message: res.message, type: "success" });
          } else {
            flashMessage({ message: res.message, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            const data = err.response;
            flashMessage({ message: data.message, type: "error" });
          } else {
            flashMessage({
              message:
                "Something went wrong changing your password, please try again later.",
              type: "error",
            });
          }
        });
    } else {
      flashMessage({
        message: "Please ensure new password confirmation is correct!",
        type: "error",
      });
    }
  };

  return (
    <div className="container px-6 lg:w-9/12">
      <div className="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
        <div className="flex flex-col md:flex-row w-full md:w-full lg:w-full mx-auto md:mx-0 items-center justify-center">
          <div className="bg-white p-10 flex flex-col w-50 shadow-xl rounded-xl">
            <div className="flex flex-col items-center justify-center">
              <img
                src={logo}
                alt="Naptin Logo"
                className="w-24 h-20 mx-3 my-6"
              />
              <h2 className="text-lg font-bold text-gray-800 text-left">
                Change your password
              </h2>
            </div>
            <form onSubmit={onSubmit} className="w-full">
              <div id="input" className="flex flex-col w-full my-3">
                <input
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Enter Current Password"
                  className="border border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  required
                />
              </div>
              <div id="input" className="flex flex-col w-full my-3">
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter New Password"
                  className="border border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  required
                />
              </div>
              <div id="input" className="flex flex-col w-full my-3">
                <input
                  type="password"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  placeholder="Confirm New Password"
                  className="border border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  required
                />
              </div>
              <div id="button" className="flex flex-col w-full my-3">
                <button
                  type="submit"
                  className="w-full py-3 bg-green-600 rounded-lg text-green-100"
                >
                  <div className="flex flex-row items-center justify-center">
                    <div className="font-bold">Change Password</div>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
