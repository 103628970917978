// import { Table, WarningCircle, X, Check } from "phosphor-react";
// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
// import { useData } from "../../providers/DataProvider";

const Testimonials = () => {
  // const {
  //   data: { trainees },
  //   loaders: { getTrainees },
  //   pagination: {
  //     trainees: { updatePage },
  //   },
  // } = useData();

  // const [alert, setAlert] = useState(true);
  // const [loaded, setLoaded] = useState(false);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!loaded) {
  //     getTrainees({
  //       getProspective: true,
  //     });
  //     setLoaded(true);
  //   }
  // }, [loaded, getTrainees]);

  return (
    <PageBuilder name="Testimonials">
      <div className="flex flex-col">
        <PaginatedDataTable
          title="List of Testimonials"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            { Header: "Attestor Name", accessor: "surname" },
            { Header: "Comment", accessor: "phoneNumber" },
            { Header: "Created At", accessor: "createdAt" },
            { Header: "Last Modified At", accessor: "updatedAt" },
          ]}
          data={[]}
          count={0}
          currentPage={0}
          // setCurrentPage={(page) => {
          //   updatePage({ newPage: page });
          //   setLoaded(false);
          // }}
        />
      </div>
    </PageBuilder>
  );
};

export default Testimonials;
