import { Tabs } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner } from "flowbite-react";
import { format } from "date-fns";

const TrainerDetails = () => {
  const { id } = useParams();
  const [trainer, setTrainer] = useState(null);
  const [loading, setLoading] = useState(true);
  const client = useClient();
  const navigate = useNavigate();

  const rejectTrainer = () => {
    client("/api/v1/module/trainer-management/update-trainer-status", {
      method: "PATCH",
      data: {
        trainersUuid: [id],
        status: "reject",
      },
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Application Rejected Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const approveTrainer = () => {
    client("/api/v1/module/trainer-management/update-trainer-status", {
      method: "PATCH",
      data: {
        trainersUuid: [id],
        status: "confirmed",
      },
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Trainer Approved Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  useEffect(() => {
    client(`/api/v1/module/trainer-management/trainer-detail/${id}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        if (res.success === true) {
          setTrainer(res.data);
          setLoading(false);
        } else {
          setTrainer(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoading(false);
      });
  }, [id, client]);

  return (
    <PageBuilder
      name={`Trainer Details - ${
        trainer?.user?.firstName
          ? `${trainer?.user?.firstName} ${trainer?.user?.surname}`
          : "loading..."
      }`}
      extras={
        trainer !== null ? (
          trainer?.user?.status === "prospective" ? (
            <div className="flex flex-row w-full h-full py-4">
              <button
                type="button"
                className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
                onClick={() => rejectTrainer()}
              >
                Reject Trainer
              </button>
              <button
                type="button"
                className="mx-2 my-2 btn btn-secondary"
                onClick={() => approveTrainer()}
              >
                Approve Trainer
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : trainer !== null ? (
          // eslint-disable-next-line
          <Tabs.Group style="underline">
            <Tabs.Item active={true} title="Profile">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      Profile
                    </h6>
                    <span
                      className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                        trainer?.user?.verified
                          ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                          : "text-red-500 bg-red-200 border-red-400"
                      }`}
                    >
                      {" "}
                      {trainer?.user?.verified ? "verified" : "unverified"}
                    </span>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        First Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.firstName}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Last Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.surname}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Registration Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.regNumber}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Application Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {id}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Email Address
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.email}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Phone Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.phoneNumber}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        State of Origin
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.stateOfOrigin}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        LGA of Origin
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.lgaOfOrigin}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Gender
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.gender?.toUpperCase()}
                      </span>
                    </div>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Date of Birth
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.birthDate
                          ? format(
                              new Date(trainer?.user?.birthDate),
                              "dd/MM/yyyy"
                            )
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Application Date
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.createdAt
                          ? format(
                              new Date(trainer?.user?.createdAt),
                              "dd/MM/yyyy"
                            )
                          : "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Unique ID
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.uuid}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mb-12">
                  <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mb-3">
                    Address
                  </h6>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        Home Address
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.residentialAddress}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        State of Residence
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainer?.user?.stateOfResidence}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        LGA of Residence
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        N/A
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
            {/* <Tabs.Item title="Education">
              <div className="flex flex-col w-9/12">
                {trainer?.user?.education?.map((ed) => (
                  <div className="flex items-center justify-between py-3 border-t-0 border-b border-primary-400">
                    <div className="flex flex-col">
                      <h4 className="text-2xl font-regular">
                        {ed.qualificationObtained}
                      </h4>
                      <span className="text-lg text-primary-400">
                        {`${ed.level} | ${ed.institutionName} | ${format(
                          new Date(ed.startDate),
                          "yyyy"
                        )} | ${format(new Date(ed.endDate), "yyyy")}`}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <a
                        href={ed.document}
                        type="button"
                        className="mx-2 my-2 btn btn-secondary"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.Item>
            <Tabs.Item title="Certifications">
              <div className="flex flex-col w-9/12">
                {trainer?.user?.professionalCerts?.map((cert) => (
                  <div className="flex items-center justify-between py-3 border-t-0 border-b border-primary-400">
                    <div className="flex flex-col">
                      <h4 className="text-2xl font-regular">
                        {cert.certificationName}
                      </h4>
                      <span className="text-lg text-primary-400">
                        {`${cert.type} | ${cert.awardingBody} | ${format(
                          new Date(cert.awardDate),
                          "MMM yyyy"
                        )} - ${format(new Date(cert.expiryDate), "MMM yyyy")}`}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <a
                        href={cert.document}
                        type="button"
                        className="mx-2 my-2 btn btn-secondary"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </Tabs.Item> */}
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">
              Unable to fetch records for trainer '{id}'
            </h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default TrainerDetails;
