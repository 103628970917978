import { useEffect, useState } from "react";
import { GrFormNextLink } from "react-icons/gr";
import { Link } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import urls from "../../utilities/urls";
import NavMobile from "../Layouts/NavMobile";
import axios from "axios";

const TrainerMyCourses = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [myCourses, setMyCourses] = useState([]);
  const [enrolled, setEnrolled] = useState("");
  const [courseUuid, setCourseUuid] = useState("");

  const getMyCourses = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      urls.api + "courses/all/my-courses",
      config
    );
    const data = response.data.data.courses;
    console.log(data);
    setMyCourses(data);
  };

  const [editProfile, setEditProfile] = useState(false);

  const getEnrolled = async (courseUuid) => {
    setCourseUuid(courseUuid);
    await axios
      .get(urls.api + "courses/trainee/" + courseUuid, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // console.log(result.data.data.meta)
        setEnrolled(result.data.data.meta.count);
        console.log(enrolled);
      })
      .catch((error) => {
        console.log("an error has occured " + error);
      });
  };

  useEffect(() => {
    getMyCourses();
  }, []);
  return (
    <div class="overflow-x">
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w-100">
          <div>
            <PageTitle title="Courses" />

            <div className="row">
              <div class="col-xl-12 col-sm-6 col-12 mb-3">
                <Link to={"/trainer/add_course"} className="btn btn-primary">
                  {" "}
                  Add New <i className="fa fa-plus-square"></i>{" "}
                </Link>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div style={{ display: !editProfile ? `block` : `none` }}>
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Course Title</th>
                            <th>Total Enrolled</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        {/* <Link to={`/trainer/view_course/${myCourses.uuid}`}> */}
                        <tbody>
                          {myCourses.map((x) => {
                            return (
                              <tr>
                                <td className="w-50 text-sm text-secondary">
                                  {x.title}
                                </td>
                                <td>
                                  {" "}
                                  <i
                                    onClick={() => {
                                      getEnrolled(x.courseUuid);
                                    }}
                                    className="fas fa-eye mx-4"
                                  >
                                    {x.courseUuid === courseUuid ? (
                                      <>{enrolled}</>
                                    ) : (
                                      <></>
                                    )}
                                  </i>
                                </td>
                                <td>
                                  {x.status == 0 ? (
                                    <span className="text-danger">Pending</span>
                                  ) : (
                                    <span>{x.status}</span>
                                  )}
                                </td>
                                <td>
                                  {x.status == 0 ? (
                                    <span className="text-danger"></span>
                                  ) : (
                                    <Link
                                      to={`/trainer/single_course/${
                                        x.courseUuid ? "course" : "program"
                                      }/${
                                        x.courseUuid
                                          ? x.courseUuid
                                          : x.programUuid
                                      }`}
                                      className="text-success"
                                    >
                                      <GrFormNextLink />
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        {/* </Link> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerMyCourses;
