import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { Plus, WarningCircle } from "phosphor-react";
import { Modal } from "flowbite-react";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";
import { triggerDownloadPipe } from "../../helpers";

const Announcements = () => {
  const {
    data: { announcements },
    loaders: { getAnnouncements },
    pagination: {
      trainees: { updatePage },
    },
  } = useData();

  const client = useClient();

  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  const exportAnnouncements = (startDate, endDate, columns) => {
    flashMessage({ message: "Generating export", type: "info" });
    client("/api/v1/announcement-excel-reports/announcement", {
      method: "POST",
      res: "file",
      data: {
        startDate: startDate,
        endDate: endDate,
        columns: columns,
      },
      credentials: true,
    })
      .then(triggerDownloadPipe("ANNOUNCEMENTS_EXPORT", "xlsx"))
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  useEffect(() => {
    if (!loaded) {
      getAnnouncements();
      setLoaded(true);
    }
  }, [loaded, getAnnouncements]);

  const createAnnouncement = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const data = new FormData();
    data.append("title", form.title.value);
    data.append("banner", image);
    data.append("body", form.body.value);

    client("/api/v1/announcements", {
      method: "POST",
      json: false,
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  return (
    <PageBuilder
      name="Announcements"
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="btn btn-secondary mx-0 flex items-center"
            onClick={() => setModalShow(true)}
          >
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Create New Announcement</span>
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
                // window.location.reload();
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white"
                  id="createAnnouncementForm"
                  onSubmit={createAnnouncement}
                >
                  <div className="flex justify-between items-center px-5">
                    <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
                      New Announcement
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary mx-0 flex items-center"
                      >
                        <span>Create Announcement</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col px-5 bg-white my-4 w-auto">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="title" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} Announcement
                          Title
                        </label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Announcement Title"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="banner" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} Announcement
                          Banner
                        </label>
                        <div className="flex">
                          <div className="relative w-full">
                            <input
                              className="rounded-lg block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 text-sm"
                              id="banner"
                              name="banner"
                              type="file"
                              accept="image/*"
                              onChange={(e) => setImage(e.target.files[0])}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-full mr-16">
                        <label htmlFor="body" className="text-left mb-1">
                          {<span className="text-red-600">*</span>}
                          Body
                        </label>
                        <textarea
                          id="body"
                          name="body"
                          placeholder="Body"
                          className="flex w-full border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col">
        <PaginatedDataTable
          title="List of Announcements"
          exports={{ excel: true, messagingList: true }}
          columns={[
            { Header: "Announcement Title", accessor: "title" },
            { Header: "Announcement Banner", accessor: "banner" },
            { Header: "Announcement Body", accessor: "body" },
            { Header: "Created At", accessor: "createdAt" },
            { Header: "Updated At", accessor: "updatedAt" },
          ]}
          data={announcements?.data}
          count={announcements?.count}
          currentPage={announcements?.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(`/admin/manage/institution/announcement/${row.uuid}`);
          }}
          exportToExcel={exportAnnouncements}
        />
      </div>
    </PageBuilder>
  );
};

export default Announcements;
