import { WarningCircle, X, Check } from "phosphor-react";
import React, { useEffect, useState } from "react";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useNavigate } from "react-router-dom";
import { useData } from "../../providers/DataProvider";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";
import { triggerDownloadPipe } from "../../helpers";

const ProspectiveSupervisors = () => {
  const {
    data: { trainers },
    loaders: { getTrainers },
    pagination: {
      trainers: { updatePage },
    },
  } = useData();

  const [alert, setAlert] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const client = useClient();

  const rejectTrainers = (trainers) => {
    client("/api/v1/module/trainer-management/update-trainer-status", {
      method: "PATCH",
      data: JSON.stringify({
        trainersUuid: trainers,
        status: "reject",
      }),
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Selected Trainers Rejected Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const approveTrainers = (applications) => {
    client("/api/v1/module/trainer-management/update-trainer-status", {
      method: "PATCH",
      data: {
        trainersUuid: applications,
        status: "confirmed",
      },
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Selected Trainers Approved Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const exportTrainers = (startDate, endDate, columns) => {
    flashMessage({ message: "Generating export", type: "info" });
    client("/api/v1/trainer-excel-reports/prospective-trainer", {
      method: "POST",
      res: "file",
      data: {
        startDate: startDate,
        endDate: endDate,
        columns: columns,
      },
      credentials: true,
    })
      .then(triggerDownloadPipe("PROSPECTIVE_TRAINERS_EXPORT", "xlsx"))
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  useEffect(() => {
    if (!loaded) {
      getTrainers({
        getProspective: true,
      });
      setLoaded(true);
    }
  }, [loaded, getTrainers]);
  return (
    <PageBuilder
      name="Prospective Supervisors"
      extras={
        <div className="flex-row hidden w-full h-full sm:flex md:py-4">
          <button type="submit" className="mx-0 btn btn-secondary">
            Search
          </button>
        </div>
      }
    >
      <div className="flex flex-col">
        {trainers?.prospective?.count === 0 ? (
          alert ? (
            <div className="flex items-center justify-between w-full px-3 py-2 mb-16 shadow-md">
              <div className="flex items-center">
                <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                <span>
                  There are no prospective trainer registrations awaiting review
                  for this month
                </span>
              </div>
              <button
                className="btn btn-white !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                onClick={() => setAlert(false)}
              >
                <span className="ml-1">Dismiss</span>
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <PaginatedDataTable
          title="List of Prospective Trainers"
          exports={{ excel: true, messagingList: true }}
          columns={[
            { Header: "Trainer Registration Number", accessor: "regNumber" },
            { Header: "Trainer First Name", accessor: "firstName" },
            { Header: "Trainer Last Name", accessor: "surname" },
            { Header: "Email Address", accessor: "email" },
            { Header: "Mobile Number", accessor: "phoneNumber" },
            {
              Header: "Gender",
              accessor: "gender",
              Filter: SelectColumnFilter,
            },
            {
              Header: "Verified?",
              accessor: (row) => {
                return row.verified.toString();
              },
              Filter: SelectColumnFilter,
            },
            { Header: "Created At", accessor: "createdAt" },
            // { Header: "Updated At", accessor: "updatedAt" },
          ]}
          data={trainers?.prospective.data}
          count={trainers?.prospective.count}
          currentPage={trainers?.prospective.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page, category: "prospective" });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(`/admin/manage/trainer/supervisors/details/${row.uuid}`);
          }}
          multiSelectActions={(selectedFlatRows) => {
            return (
              <div className="flex items-center justify-end w-full h-full py-2">
                <button
                  type="button"
                  className="flex items-center justify-between mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
                  onClick={() =>
                    rejectTrainers(
                      selectedFlatRows.map((row) => row.original.uuid)
                    )
                  }
                >
                  <X weight="bold" className="w-4 h-4" />
                  <span className="ml-1 lg:hidden">Reject</span>
                  <span className="hidden ml-1 lg:flex">
                    Reject Selected Trainers
                  </span>
                </button>
                <button
                  type="button"
                  className="flex items-center justify-between mx-2 my-2 btn btn-secondary"
                  onClick={() =>
                    approveTrainers(
                      selectedFlatRows.map((row) => row.original.uuid)
                    )
                  }
                >
                  <Check weight="bold" className="w-4 h-4" />
                  <span className="ml-1 lg:hidden">Shortlist</span>
                  <span className="hidden ml-1 lg:flex">
                    Approve Selected Trainers
                  </span>
                </button>
              </div>
            );
          }}
          exportToExcel={exportTrainers}
        />
      </div>
    </PageBuilder>
  );
};

export default ProspectiveSupervisors;
