import React, { useState } from "react";
import { Link } from "react-router-dom";
import urls from "../../utilities/urls";
import ErrorMessages from "../../utilities/ErrorMessages";

function NavMobile() {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          // alert(json.message)
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else {
          alert(ErrorMessages.UnkownRetry);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  return (
    <div className="navMobile">
      <Link to="/trainer/dashboard">
        <i class="fas fa-columns fa-2x" style={{ color: "green" }}></i>
      </Link>
      <Link to="/trainer/my_courses">
        <i class="fas fa-layer-group fa-2x" style={{ color: "green" }}></i>
      </Link>
      <Link to="/trainer/profile">
        <i class="fas fa-user fa-2x" style={{ color: "green" }}></i>
      </Link>
      <Link to="/trainer/students">
        <i class="fas fa-users fa-2x" style={{ color: "green" }}></i>
      </Link>
      <Link to="/trainer/assignment">
        <i class="far fa-list-alt fa-2x" style={{ color: "green" }}></i>
      </Link>
      <Link to="/trainer/quizzes">
        <i class="fas fa-pen fa-2x" style={{ color: "green" }}></i>
      </Link>
      <div onClick={logOut}>
        <Link to={"#"}>
          <i class="fas fa-power-off fa-2x" style={{ color: "green" }}></i>
        </Link>
      </div>
    </div>
  );
}

export default NavMobile;
