import { Tabs } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner } from "flowbite-react";
import { Modal } from "flowbite-react";
import { useData } from "../../providers/DataProvider";
import Select from "react-select";

const TrainingCenterDetails = () => {
  const { id } = useParams();

  const {
    data: { states },
    loaders: { getStates },
  } = useData();

  const [trainingCenter, setTrainingCenter] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const client = useClient();
  const navigate = useNavigate();

  const deleteRTC = () => {
    client(`/api/v1/regional-training-centers/${id}`, {
      method: "DELETE",
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        flashMessage({ message: "RTC Deleted Successfully", type: "success" });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  const editRTC = (e) => {
    e.preventDefault();
    const form = e.target;
    const rtcInfo = {
      name: form.name.value,
      email: form.email.value,
      phoneNumber: form.phoneNumber.value,
      address: form.address.value,
      stateId: form.state.value,
    };

    client(`/api/v1/regional-training-centers/${id}`, {
      method: "PUT",
      data: rtcInfo,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  useEffect(() => {
    client(`/api/v1/regional-training-centers/${id}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        console.log(res.data);
        setTrainingCenter(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoading(false);
      });
    if (!loaded) {
      getStates();
      setLoaded(true);
    }
  }, [id, client, getStates, loaded]);

  return (
    <PageBuilder
      name={`Training Center Details - ${trainingCenter?.name ?? "loading..."}`}
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
            onClick={() => deleteRTC()}
          >
            Delete RTC
          </button>
          <button
            type="button"
            className="mx-2 my-2 btn btn-secondary"
            onClick={() => setModalShow(true)}
          >
            Edit RTC
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
                // window.location.reload();
              }}
              size="5xl"
            >
              <Modal.Body>
                <form className="flex flex-col bg-white" onSubmit={editRTC}>
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      Edit Regional Training Center
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Edit RTC</span>
                      </button>
                    </div>
                  </div>
                  {/* <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div> */}
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="name" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} RTC Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="RTC Name"
                          defaultValue={trainingCenter?.name}
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="email" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} RTC Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="RTC Email"
                          defaultValue={trainingCenter?.email}
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="address" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} RTC Address
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="RTC Address"
                          defaultValue={trainingCenter?.address}
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="state" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} State of
                          Location
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="State of Location"
                          classNamePrefix="select"
                          options={states?.map((state) => ({
                            value: state.id,
                            label: state.name,
                          }))}
                          defaultValue={states
                            ?.map((state) => ({
                              value: state.id,
                              label: state.name,
                            }))
                            .find(
                              (state) => state.label === trainingCenter?.state
                            )}
                          isSearchable={false}
                          required
                          name="state"
                          id="state"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="tel" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} RTC Phone
                          Number
                        </label>
                        <input
                          type="tel"
                          id="tel"
                          name="phoneNumber"
                          placeholder="RTC Phone Number"
                          defaultValue={trainingCenter?.phoneNumber}
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : trainingCenter ? (
          // eslint-disable-next-line react/style-prop-object
          <Tabs.Group style="underline">
            <Tabs.Item active={true} title="RTC Info">
              <div className="flex flex-col w-full">
                <div className="flex flex-col flex-wrap w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      RTC Info
                    </h6>
                  </div>
                  <div className="flex flex-wrap w-full mb-10">
                    <div className="flex flex-col mr-5 mb-3 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        RTC UUID
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainingCenter?.uuid}
                      </span>
                    </div>
                    <div className="flex flex-col mr-5 mb-3 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        RTC Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainingCenter?.name}
                      </span>
                    </div>
                    <div className="flex flex-col mr-5 mb-3 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        RTC Email
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainingCenter?.email}
                      </span>
                    </div>
                    <div className="flex flex-col mr-5 mb-3 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        RTC Phone Number
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainingCenter?.phoneNumber}
                      </span>
                    </div>
                    <div className="flex flex-col mr-5 mb-3 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        RTC Address
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainingCenter?.address}
                      </span>
                    </div>
                    <div className="flex flex-col mr-5 mb-3 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        RTC State
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {trainingCenter?.state}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">Unable to fetch records for RTC '{id}'</h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default TrainingCenterDetails;
