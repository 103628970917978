import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../utilities/Alert";
import ErrorMessages from "../../utilities/ErrorMessages";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";

const TraineeVerifyEmail = () => {
  const navigate = useNavigate();
  const [foundError, setFoundError] = useState(false);
  const [verified, setVerified] = useState(null);

  const [token, setToken] = useState(null);
  const [token1, setToken1] = useState(localStorage.getItem("token"));

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const getUserProfile = () => {
    setLoading(true);
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token1}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "profile/my-profile", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setVerified(json.data.user.bio.verified);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const resendVerificationAction = () => {
    setShowAlert(false);
    setLoading(true);
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/verify/resend", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        } else if (json.success === true) {
          setAlertMessage(json.message);
          setAlertTitle("Successful!");
          setAlertType("success");
          setShowAlert(true);
          setFoundError(false);
        } else if (json.message !== null) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        } else {
          setAlertMessage(ErrorMessages.UnkownRetry);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        }
      })
      .catch((error) => {
        setAlertMessage(ErrorMessages.UnkownRetry);
        setAlertTitle("Error!");
        setAlertType("danger");
        setShowAlert(true);
        setFoundError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {}, []);

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      setToken(localStorage.getItem("token"));
    }
  }, []);

  if (verified == true) {
    navigate("/trainee");
  }
  if (loading) {
    return <Spinner />;
  }

  return (
    <div class="content container-fluid">
      <PageTitle title="Verify Email" />

      <div class="card">
        {verified ? (
          <p className="p-10">Verification Successful</p>
        ) : (
          <div class="card-body">
            <div>
              <div className="row">
                <div class="col-xl-12 col-sm-6 col-12 mb-3">
                  <p>
                    Your email address is not verified. We have sent you a
                    verification link. If you did not received the verification
                    mail, kindly click the button below to resend.
                  </p>

                  {/*Alert Section start*/}
                  {showAlert ? (
                    <Alert
                      type={alertType}
                      title={alertTitle}
                      message={alertMessage}
                    />
                  ) : (
                    ""
                  )}
                  {/*Alert Section end*/}

                  <div style={{ display: loading ? `block` : `none` }}>
                    <Spinner />
                  </div>
                  <div style={{ display: !loading ? `block` : `none` }}>
                    <button
                      onClick={() => resendVerificationAction()}
                      className="btn btn-primary btn-block btn-sm"
                    >
                      {" "}
                      Resend Verification <i className="fa fa-refresh"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TraineeVerifyEmail;
