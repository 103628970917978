import React from "react";
import NavMobile from "../Layouts/NavMobile";
import PageTitle from "../Layouts/PageTitle";

function Scores() {
  return (
    <div>
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="Scores" />

          <div style={{ display: "block" }}>
            <div className="row">
              <h1>Scores</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scores;
