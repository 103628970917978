import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";

const TrainerViewTicket = () => {
  let announcements = [
    { title: "I cannot find my course", date: "Jan 2, 2022", status: 1, id: 1 },
    { title: "My admission is pending", date: "Jan 2, 2022", status: 0, id: 2 },
  ];

  const { id } = useParams();

  const [announcement, setAnnouncement] = useState([]);
  const [announcementTitle, setAnnouncementTitle] = useState(null);

  const setTitle = () => {
    announcements = announcements.filter((x) => x.id == id);
    setAnnouncementTitle(announcements[0].title);
  };

  useEffect(() => {
    setTitle();
  }, []);

  return (
    <div class="content container-fluid">
      <PageTitle title={announcementTitle} />
      <div class="row">
        <div class="col-xl-12 col-sm-6 col-12">
          <div class="card">
            <div class="card-body">
              <div class="dash-widget-header">
                <span class="text-primary">
                  <i class="fas fa-bullhorn fa-2x"></i>
                </span>
                <div class="dash-widget-info">
                  <h3>Sample</h3>
                  <h6 class="text-muted">Sample X</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerViewTicket;
