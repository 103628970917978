import { Plus, WarningCircle } from "phosphor-react";
import React, { useState, useEffect } from "react";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { Modal } from "flowbite-react";
// import { useNavigate } from "react-router-dom";
import { useData } from "../../providers/DataProvider";
import Select from "react-select";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";

const ManageSponsors = () => {
  const {
    data: { sponsors },
    loaders: { getSponsors },
    pagination: {
      sponsors: { updatePage },
    },
  } = useData();

  const client = useClient();

  const [loaded, setLoaded] = useState(false);
  // const navigate = useNavigate();

  const createSponsor = (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    const sponsorInfo = {
      email: form.email.value,
      type: form.type.value,
      name: form.name.value,
    };

    client("/api/v1/module/company-management/create-company", {
      method: "POST",
      data: sponsorInfo,
      credentials: true,
    })
      .then((response) => {
        const res = response;
        if (res.success === true) {
          setModalShow(false);
          setLoaded(false);
          flashMessage({ message: res.message, type: "success" });
        } else {
          setModalShow(false);

          flashMessage({ message: res.message, type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response;
          setModalShow(false);

          flashMessage({ message: data.message, type: "error" });
        } else {
          setModalShow(false);

          flashMessage({
            message:
              "Something went wrong signing you in, please try again later.",
            type: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (!loaded) {
      getSponsors();
      setLoaded(true);
    }
  }, [loaded, getSponsors]);

  const [modalShow, setModalShow] = useState(false);

  return (
    <PageBuilder
      name="List of Sponsors"
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="btn btn-secondary mx-0 flex items-center"
            onClick={() => setModalShow(true)}
          >
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Add New Sponsor</span>
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
                // window.location.reload();
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white"
                  onSubmit={createSponsor}
                >
                  <div className="flex justify-between items-center px-5">
                    <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
                      New Sponsor
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary mx-0 flex items-center"
                      >
                        <span>Create Sponsor</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col px-5 bg-white my-4 w-auto">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="name" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} Sponsor Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Sponsor Name"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="name" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} Sponsor Type
                        </label>
                        <Select
                          className="border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Sponsor Type"
                          classNamePrefix="select"
                          options={["private", "government"].map((option) => ({
                            value: option,
                            label: option,
                          }))}
                          isSearchable={false}
                          name="type"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="email" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} Sponsor
                          Email Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Sponsor Email Address"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <PaginatedDataTable
        title="List of Registered Sponsors"
        exports={{ excel: true, messagingList: true }}
        columns={[
          { Header: "Sponsor Name", accessor: "name" },
          { Header: "Sponsor Registration Number", accessor: "regNumber" },
          {
            Header: "Type",
            accessor: "type",
            Filter: SelectColumnFilter,
          },
          { Header: "Sponsor Email Address", accessor: "email" },
          { Header: "Sponsor Address", accessor: "address" },
          { Header: "Sponsor Mobile Number", accessor: "phoneNumber" },
          {
            Header: "Verified",
            accessor: "verified",
            Filter: SelectColumnFilter,
          },
          { Header: "Created At", accessor: "createdAt" },
        ]}
        data={sponsors?.data}
        count={sponsors?.count}
        currentPage={sponsors?.page}
        setCurrentPage={(page) => {
          updatePage({ newPage: page });
          setLoaded(false);
        }}
        // onRowClick={(row) => {
        //   navigate(`/admin/manage/sponsors/details/${row.uuid}`);
        // }}
      />
    </PageBuilder>
  );
};

export default ManageSponsors;
