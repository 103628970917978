import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import urls from "../../utilities/urls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";
function TakeQuiz() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [exam, setExam] = useState([]);
  const [chosen, setChosen] = useState();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const token = localStorage.getItem("token");

  console.log(id);

  const getQuiz = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .get(urls.api + "quizzes/questions/" + id, config)
      .then((response) => {
        console.log(response);
        setExam(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onHandle = (e) => {
    setChosen(e.target.value);
  };

  const max = exam.length;
  const send_result_to_database = async (question, quizUuid) => {
    console.log(quizUuid);
    const nextQuestion = currentQuestion + 1;
    setCurrentQuestion(nextQuestion);
    console.log(question, quizUuid);
    const result = {
      question: question,
      quizUuid: quizUuid,
      answerChosen: chosen,
    };
    console.log(result);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(urls.api + "quiz/submission/submit/", result, config)
      .then((response) => {
        if (
          response.data.success === true &&
          response.data.message === "Trainee has Already Submitted Quiz"
        ) {
          toast.warning("Quiz has already been taken by you");
          navigate("/trainee");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const send_result_to_database1 = async (question, quizUuid) => {
    console.log(question);
    const result = {
      question: question,
      answerChosen: chosen,
      quizUuid: quizUuid,
    };
    console.log(result);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(urls.api + "quiz/submission/submit/", result, config)
      .then((response) => {
        //console.log(response.data.success);
        if (
          response.data.success === true &&
          response.data.message === "Trainee has Already Submitted Quiz"
        ) {
          toast.warning("Quiz has already been taken by you");
          navigate("/trainee");
        } else if (
          response.data.success === true &&
          response.data.message === "Quiz Submitted Successfully"
        ) {
          toast.success("Quiz Submitted");
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const errorMsg = () => {
    return (
      <SweetAlert
        warning
        title="No Quiz for this course"
        onConfirm={() => {
          navigate("/trainee");
        }}
      />
    );
  };
  useEffect(() => {
    getQuiz();
  }, []);
  return (
    <div>
      {exam.length ? (
        <div className="exam">
          <div class="card w-50">
            <div class="card-body">
              <h5 class="card-title title"></h5>
              <p class="card-text question">{exam[currentQuestion].question}</p>
              <div className="radio__btn">
                <div className="form-check">
                  <input
                    onChange={onHandle}
                    value={exam[currentQuestion].Option1}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    {exam[currentQuestion].Option1}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    onChange={onHandle}
                    value={exam[currentQuestion].Option2}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    {exam[currentQuestion].Option2}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    onChange={onHandle}
                    value={exam[currentQuestion].Option3}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    {exam[currentQuestion].Option3}
                  </label>
                </div>
                <div class="form-check">
                  <input
                    onChange={onHandle}
                    value={exam[currentQuestion].Option4}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    {exam[currentQuestion].Option4}
                  </label>
                </div>
              </div>
              {max > currentQuestion + 1 ? (
                <div className="edit">
                  <button
                    class="btn btn-secondary"
                    onClick={() =>
                      send_result_to_database(
                        exam[currentQuestion].question,
                        exam[currentQuestion].quizUuid
                      )
                    }
                  >
                    Next
                  </button>
                </div>
              ) : (
                <div className="edit">
                  <button
                    class="btn btn-secondary"
                    onClick={() =>
                      send_result_to_database1(
                        exam[currentQuestion].question,
                        exam[currentQuestion].quizUuid
                      )
                    }
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>{errorMsg()}</>
      )}
    </div>
  );
}

export default TakeQuiz;
