import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";
import NavMobile from "../Layouts/NavMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TrainerQuizzes = () => {
  const [myQuizes, getMyQuizes] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

  console.log(token);

  const getQuizes = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(urls.api + "quizzes/all", config);
    console.log(response.data.data.quizzes);
    getMyQuizes(response.data.data.quizzes);
  };

  const [editProfile, setEditProfile] = useState(false);

  const delQuiz = async (uuid) => {
    console.log(uuid);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(urls.api + "quizzes/" + uuid, config)
      .then(() => {
        getQuizes();
        toast.success("deleted successfully");
      })
      .catch(() => {
        getQuizes();
      });
    getQuizes();
  };

  useEffect(() => {
    getQuizes();
  }, []);

  return (
    <div>
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="Quizzes" />

          <div className="row">
            <div class="col-xl-12 col-sm-6 col-12 mb-3">
              <Link to={"/trainer/add_quiz"} className="btn btn-primary">
                {" "}
                Add New <i className="fa fa-plus-square"></i>{" "}
              </Link>
            </div>
          </div>

          <div style={{ display: !editProfile ? `block` : `none` }}>
            <div className="row">
              <div class="col-xl-12 col-md-12 col-12">
                <div class="card">
                  <div class="card-body">
                    <div
                      style={{
                        height: "500px",
                        margin: "4px 4px",
                        padding: "4px",
                        overflowX: "hidden",
                        overflowY: "auto",
                        textAlign: "justify",
                      }}
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Question </th>
                              <th>Action </th>
                            </tr>
                          </thead>
                          <tbody>
                            {myQuizes.map((x) => (
                              <tr>
                                <td>{x.question}</td>

                                <td className="">
                                  <span className="">
                                    <Link
                                      to={`/trainer/quizzes1/${x.quizUuid}`}
                                    >
                                      <span className="text-success">
                                        {" "}
                                        View <i className="fa fa-eye"></i>{" "}
                                      </span>
                                    </Link>
                                    <br />{" "}
                                    <span
                                      className="text-danger"
                                      onClick={() => {
                                        delQuiz(x.quizUuid);
                                      }}
                                    >
                                      {" "}
                                      <button>
                                        Delete <i className="fa fa-times"></i>
                                      </button>{" "}
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerQuizzes;
