import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDetails, reset } from "../../../features/details/detailsSlice";
import { Outlet, Link, useNavigate } from "react-router-dom";
import ErrorMessages from "../../utilities/ErrorMessages";
import Images from "../../utilities/Images";
import urls from "../../utilities/urls";
import axios from "axios";

const TrainerHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { details, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.details
  );
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [dp, setDp] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const getProfile = async () => {
    await axios
      .get(urls.api + "profile/my-profile", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        setFirstName(result.data.data.user.bio.firstName);
        setDp(result.data.data.user.bio.avatar);
        if (result.data.data.user.bio.verified == false) {
          navigate("/trainer/verify_email");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getProfile();
    if (isError) {
      console.log(message);
      // window.location.href = urls.home;
    }
    if (isSuccess) {
      console.log(details.data.user.roles[0]);
      setDp(details.data.user.bio.avatar);
      setFirstName(details.data.user.bio.firstName);
      if (details.data.user.bio.verified == false) {
        navigate("/trainer/verify_email");
      } else {
        navigate("/trainer");
      }
      // else if (details.message == "Unauthenticated.") {
      //   window.location.href = urls.home + "logout";
      // }
    }
    console.log(details);
    dispatch(getDetails());
    return () => {
      reset();
    };
  }, [dispatch, isSuccess]);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          // alert(json.message)
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else {
          // alert(ErrorMessages.UnkownRetry);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };
  useEffect(() => {
    setTimeout(function () {
      if (!token) {
        window.location.href = urls.home;
      }
    }, 10000);
  }, []);

  return (
    <div class="header bg-white">
      <a href="javascript:void(0);" id="toggle_btn">
        <i class="fas fa-align-left text-dark"></i>
      </a>
      <a class="mobile_btn" id="mobile_btn" href="javascript:void(0);">
        <i class="fas fa-align-left text-dark"></i>
      </a>

      <ul class="nav user-menu">
        <li class="nav-item dropdown noti-dropdown">
          <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown">
            <i class="far fa-bell"></i> <span class="badge badge-pill"></span>
          </a>
        </li>

        <li class="nav-item dropdown">
          <a
            href="javascript:void(0)"
            class="dropdown-toggle user-link  nav-link"
            data-toggle="dropdown"
          >
            <span class="user-img">
              <img
                class="rounded-circle"
                src={dp == null ? Images.avatar : dp}
                width="40"
                alt="User"
              />
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <span class="dropdown-item" to="/trainer/personal_profile">
              Hi, {firstName}
            </span>
            <Link class="dropdown-item" to="/trainer/personal_profile">
              Profile
            </Link>
            <a onClick={() => logOut()} class="dropdown-item" href="#">
              Logout{" "}
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default TrainerHeader;
