import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PageTitle from "./Layouts/PageTitle";
import "./Dashboard.css";
import NavMobile from "./Layouts/NavMobile";

const TrainerDashbaord = () => {
  let menuItems = [
    {
      title: "Assigments",
      icon: "fa fa-list fa-2x",
      url: "/trainer/assignment",
      id: 1,
    },
    {
      title: "Courses",
      icon: "fas fa-layer-group fa-2x",
      url: "/trainer/my_courses",
      id: 1,
    },
    {
      title: "Quizzes",
      icon: "fa fa-pen fa-2x",
      url: "/trainer/quizzes",
      id: 1,
    },
    { title: "Exams", icon: "fa fa-book fa-2x", url: "/trainer/exams", id: 1 },
    {
      title: "Students",
      icon: "fa fa-users fa-2x",
      url: "/trainer/students",
      id: 1,
    },
  ];

  const search = useLocation().search;
  const id_ = new URLSearchParams(search).get("punch");

  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    if (id_ !== null && id_.length > 2) {
      localStorage.setItem("token", id_);
      setToken(id_);
    } else {
      if (localStorage.getItem("token") !== null) {
        setToken(localStorage.getItem("token"));
      }
    }
  }, []);

  return (
    <div class="">
      {/* content container-fluid */}
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <div style={{ marginLeft: "10px" }}>
            <PageTitle title="Dashboard" />
          </div>
          <div class="row" style={{ marginLeft: "10px" }}>
            {menuItems.map((x) => (
              <div class="col-xl-4 col-sm-6 col-12">
                <Link to={x.url}>
                  <div class="card">
                    <div class="card-body">
                      <div class="dash-widget-header">
                        <span class="text-primary">
                          <i class={x.icon}></i>
                        </span>
                        <div class="dash-widget-info">
                          <h6 class="text-muted">{x.title}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerDashbaord;
