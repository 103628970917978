import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import urls from "../utilities/urls";
import NavMobile from "./Layouts/NavMobile";
import PageTitle from "./Layouts/PageTitle";

const TraineePosting = () => {
  const [availableCourses, setAvailableCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  // const getCourse = () => {
  //   setLoading(true);
  //   fetch(urls.api + "courses")
  //     .then((response) => response.json())
  //     .then((json) => {
  //       if (json.status == 200) {
  //         setAvailableCourses(
  //           json.data.data.filter((x) => x.status == "published")
  //         );
  //       } else {
  //         alert("An error occured!");
  //       }
  //     })
  //     .catch((error) => {})
  //     .finally(() => setLoading(false));
  // };

  const [editProfile, setEditProfile] = useState(false);

  useEffect(() => {
    //  getCourse();
  }, []);

  return (
    <div class="navMenu">
      <div className="t">
        <NavMobile />
      </div>
      <div className="w">
        <PageTitle title="Posting" />

        <div style={{ display: !editProfile ? `block` : `none` }}>
          <div class="row">
            <div class="col-lg-12 col-md-8">
              <div class="card">
                <div class="card-body">
                  State:
                  <br />
                  Kano <hr />
                  City:
                  <br />
                  Kano <hr />
                  Centre Number:
                  <br />
                  1K23 <hr />
                  Centre Address: Address here
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineePosting;
