import { ArrowLeft } from "phosphor-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import logo from "../../assets/images/naptin-logo.svg";
import client from "../../helpers/client";
import { flashMessage } from "../../helpers/flash";

const Forgot = () => {
  const navigate = useNavigate();

  const onSubmit = async (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    const data = {
      email: form.email.value,
    };
    await client("/auth/generate/csrf-cookie", {
      method: "GET",
      credentials: true,
    }).catch((err) => {
      console.log(err);
      if (err.response) {
        flashMessage({
          message: "Unable to contact server",
          type: "error",
        });
      } else {
        flashMessage({
          message:
            "Something went wrong requesting your password reset email, please try again later.",
          type: "error",
        });
      }
    });
    client("/api/v1/auth/forgot-password", {
      method: "POST",
      data: data,
      credentials: true,
    })
      .then((response) => {
        const res = response;
        if (res.success === true) {
          navigate("/admin/login", { replace: true });
          flashMessage({ message: res.message, type: "success" });
        } else {
          flashMessage({ message: res.message, type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response;
          flashMessage({ message: data.message, type: "error" });
        } else {
          flashMessage({
            message:
              "Something went wrong requesting your reset email, please try again later.",
            type: "error",
          });
        }
      });
  };

  return (
    <div className="container px-6 lg:w-9/12">
      <div>
        <ToastContainer />
      </div>
      <div className="flex flex-col text-center md:text-left md:flex-row h-screen justify-evenly md:items-center">
        <div className="flex flex-col md:flex-row w-full md:w-full lg:w-full mx-auto md:mx-0 items-center justify-center">
          <div className="bg-white p-10 flex flex-col w-50 shadow-xl rounded-xl">
            <div className="flex flex-col items-center justify-center">
              <img
                src={logo}
                alt="Naptin Logo"
                className="w-24 h-20 mx-3 my-6"
              />
              <h2 className="text-lg font-bold text-gray-800 text-left">
                Forgot your password?
              </h2>
              <h5 className="text-md font-thin text-gray-500 text-center mb-3">
                Request a password reset email below, if the email has an
                account you will recieve an email soon
              </h5>
            </div>
            <form onSubmit={onSubmit} className="w-full">
              <div id="input" className="flex flex-col w-full my-3">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Please enter your email"
                  className="border border-gray-100 rounded-lg px-4 py-3 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  required
                />
              </div>
              <div id="button" className="flex flex-col w-full my-3">
                <button
                  type="submit"
                  className="w-full py-3 bg-green-600 rounded-lg text-green-100"
                >
                  <div className="flex flex-row items-center justify-center">
                    <div className="font-bold">Request reset email</div>
                  </div>
                </button>
              </div>
              <div className="flex flex-row w-full justify-start mb-4">
                <a
                  href="/admin/login"
                  className="link flex items-center justify-center"
                >
                  <ArrowLeft className="mr-2" /> Back to login
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
