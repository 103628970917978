import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ErrorMessages from "../../utilities/ErrorMessages";
import urls from "../../utilities/urls";
import { ImHome } from "react-icons/im";

const TraineeNavMenu = () => {
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          //  alert(json.message)
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = urls.home + "logout";
        } else {
          alert(ErrorMessages.UnkownRetry);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  const clickDropDown = (x) => {
    if (x == 1) {
      if (dropDown1) setDropDown1(false);
      else setDropDown1(true);
    }
    if (x == 2) {
      if (dropDown2) setDropDown2(false);
      else setDropDown2(true);
    }
  };

  return (
    <div class="sidebar mt-20 fixed" id="sidebar">
      {/* <div class="sidebar-logo">
        <a href="https://tmis.naptinportal.com/">
          <img src="assets/img/logo-icon.png" class="img-fluid" alt="" />
        </a>
      </div> */}
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu md:block">
          <ul>
            <li class="submenu">
              <a href={urls.home}>
                <ImHome size={20} />
                <span>Home</span>
              </a>
            </li>
            <li class="active">
              <Link to="/trainee/dashboard">
                <i class="fas fa-columns"></i> <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/trainee/profile">
                <i class="fas fa-user"></i> <span> Profile</span>
              </Link>
            </li>
            <li class="submenu" onClick={() => clickDropDown(1)}>
              <Link className={dropDown1 ? `subdrop` : ``} to="#">
                <i class="fas fa-layer-group"></i> <span> APPLY HERE </span>{" "}
                <span class="menu-arrow"></span>
              </Link>
              <ul style={{ display: dropDown1 ? `block` : `none` }}>
                <li>
                  <Link to="/trainee/available_courses">
                    {" "}
                    Available Courses{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/trainee/my_courses"> My Courses </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/trainee/programs">
                <i class="fas fa-globe"></i> <span>Programs</span>
              </Link>
            </li>
            <li>
              <Link to="/trainee/application">
                <i class="fas fa-user-shield"></i> <span>My Applications</span>
              </Link>
            </li>
            <li>
              <Link to="/trainee/payments">
                <i class="fas fa-hashtag"></i> <span> Payments</span>
              </Link>
            </li>

            <li>
              <Link to="/trainee/assignment">
                <i class="far fa-list-alt"></i> <span> Assignments</span>
              </Link>
            </li>
            <li>
              <Link to="/trainee/quizzes">
                <i class="fas fa-pen"></i> <span> Quizzes</span>
              </Link>
            </li>
            <li>
              <Link to="/trainee/exam">
                <i class="fas fa-pen"></i> <span> Exam</span>
              </Link>
            </li>
            <li class="submenu" onClick={() => clickDropDown(2)}>
              <Link to="#" className={dropDown2 ? `subdrop` : ``}>
                <i class="fas fa-border-all"></i> <span> Institution</span>{" "}
                <span class="menu-arrow"></span>
              </Link>
              <ul style={{ display: dropDown2 ? `block` : `none` }}>
                <li>
                  <Link to="/trainee/announcement">Announcements</Link>
                </li>
                <li>
                  <Link to="/trainee/calendar">Calendar</Link>
                </li>
                <li>
                  <Link to="/trainee/tickets">Tickets</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/trainee/check_result">
                <i class="fa fa-list"></i> <span> Check Result</span>
              </Link>
            </li>
            <li>
              <Link to="/trainee/feedback">
                <i class="fas fa-envelope"></i>{" "}
                <span> Feedback ( Rating )</span>
              </Link>
            </li>
            <li>
              <Link to="/trainee/feedbackSurvey">
                <i class="fas fa-envelope"></i>{" "}
                <span> Feedback ( Survey )</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/trainee/posting">
                <i class="fa fa-map-marker"></i> <span> Posting</span>
              </Link>
            </li> */}
            <li
              onClick={() => {
                logOut();
              }}
            >
              <Link to={"#"}>
                <i class="fas fa-power-off"></i> <span> Logout</span>
              </Link>
            </li>
            <li>
              <Link to={""}> </Link>
            </li>
            <li>
              <Link to={""}> </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TraineeNavMenu;
