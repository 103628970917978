export const capitalize = ({
  word = "",
  headOnly = false,
  headsOnly = false,
}) => {
  if (headOnly) {
    return capitalize({ word: word.charAt(0) }) + word.substring(1);
  } else {
    if (headsOnly) {
      return word.includes(" ")
        ? word
            .split(" ")
            .map((x) => capitalize({ word: x, headOnly: true }))
            .join(" ")
        : capitalize({ word: word, headOnly: true });
    } else {
      return word
        .split("")
        .map((x) => x.toUpperCase())
        .join("");
    }
  }
};
