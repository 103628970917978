import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import urls from "../../utilities/urls";
import axios from "axios";

const ViewAssessment = () => {
  const { courseUuid, traineeUuid } = useParams();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [db, setDp] = useState(null);
  const [exams, setExams] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [ass, setAss] = useState([]);

  const postOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  };
  const allExams = async () => {
    await axios
      .get(urls.api + "exams/submission/completed/" + courseUuid, postOptions)
      .then((seenResult) => {
        console.log(seenResult.data.data.examSubmitted);
        setExams(seenResult.data.data.examSubmitted);
      })
      .catch((error) => {});
  };
  const allQuiz = async () => {
    await axios
      .get(urls.api + "quiz/submission/completed/" + courseUuid, postOptions)
      .then((quizResponse) => {
        console.log(quizResponse.data.data.quizSubmitted);
        setQuiz(quizResponse.data.data.quizSubmitted);
      })
      .catch((error) => {});
  };
  const allAss = async () => {
    await axios
      .get(
        urls.api + "assignment/submission/completed/" + courseUuid,
        postOptions
      )
      .then((assignmentResponse) => {
        console.log(assignmentResponse.data.data.assignmentSubmission);
        setAss(assignmentResponse.data.data.assignmentSubmission);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    allExams();
    allQuiz();
    allAss();
  });

  return (
    <>
      <div className="card mx-3">
        <div className="card-body">
          <div
            style={{
              height: "600px",
              margin: "15px 15px 15px 15px",
              padding: "4px",
              overflowX: "hidden",
              overflowY: "auto",
              textAlign: "justify",
            }}
          >
            <strong style={{ fontSize: "25px" }}>Exams</strong>
            <div className=" my-3">
              {exams[0]?.map((exam) => {
                return (
                  <>
                    {exam.traineeUuid == traineeUuid ? (
                      <>
                        <p key={exam.id}>Question: {exam.question}</p>
                        <p> Answer: {exam.answerChosen}</p>
                        <br></br>
                      </>
                    ) : (
                      <>No Exam available</>
                    )}
                  </>
                );
              })}
            </div>
            <strong style={{ fontSize: "25px" }}>Quiz</strong>
            <div className=" my-3">
              {quiz[0]?.map((quiz) => {
                return (
                  <>
                    {quiz.traineeUuid === traineeUuid ? (
                      <>
                        <p>
                          {" "}
                          <span></span>Question: {quiz.question}
                        </p>
                        <p>Answer: {quiz.answerChosen}</p>
                        <br></br>
                      </>
                    ) : (
                      <>No quiz available</>
                    )}
                  </>
                );
              })}
            </div>
            <strong style={{ fontSize: "25px" }}>Assignment</strong>
            <p>Click the file to download </p>
            <div className="my-4">
              {ass[0]?.map((ass) => {
                return (
                  <>
                    {ass.traineeUuid === traineeUuid ? (
                      <>
                        <p>
                          {" "}
                          <span></span>Question: {ass.topic}
                        </p>
                        <p>Answer: {ass.answer}</p>
                        <p>Submitted File</p>
                        <a href={ass.attachment} download="assignment">
                          {" "}
                          <div class="avatar avatar-xxl my-3">
                            <img
                              alt="Assignment Attachment"
                              style={{ width: "350px" }}
                              className="avatar-img"
                              src={ass.attachment ? ass.attachment : <></>}
                            />{" "}
                          </div>{" "}
                        </a>

                        <br></br>
                      </>
                    ) : (
                      <>No assignment</>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewAssessment;
