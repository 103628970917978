import { CheckCircle, WarningCircle, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";
import { triggerDownloadPipe } from "../../helpers";

const ConfirmedTrainers = () => {
  const {
    data: { trainers },
    loaders: { getTrainers },
    pagination: {
      trainers: { updatePage },
    },
  } = useData();

  const [loaded, setLoaded] = useState(false);
  const [alert, setAlert] = useState(true);
  const navigate = useNavigate();
  const client = useClient();

  const exportTrainers = (startDate, endDate, columns) => {
    flashMessage({ message: "Generating export", type: "info" });
    client("/api/v1/trainer-excel-reports/confirmed-trainer", {
      method: "POST",
      res: "file",
      data: {
        startDate: startDate,
        endDate: endDate,
        columns: columns,
      },
      credentials: true,
    })
      .then(triggerDownloadPipe("CONFIRMED_TRAINERS_EXPORT", "xlsx"))
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  useEffect(() => {
    if (!loaded) {
      getTrainers({
        getConfirmed: true,
      });
      setLoaded(true);
    }
  }, [loaded, getTrainers]);

  return (
    <PageBuilder name="Confirmed Trainers">
      <div className="flex flex-col w-full">
        {trainers?.confirmed?.count === 0
          ? alert ?? (
              <div className="flex justify-between items-center w-full py-2 px-3 shadow-md mb-6 bg-yellow-100 text-yellow-400">
                <div className="flex items-center">
                  <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                  <span>No confirmed trainer record was found</span>
                </div>
                <button
                  className="btn bg-yellow-100 hover:bg-yellow-100 focus:bg-yellow-100 focus-within:bg-yellow-100 !h-6 !px-1 !py-0 my-0 !border-0 outline-0 flex items-center justify-between"
                  onClick={() => setAlert(false)}
                >
                  <X weight="bold" className="w-4 h-4 text-yellow-400" />
                </button>
              </div>
            )
          : alert ?? (
              <div className="flex justify-between items-center w-full py-2 px-4 shadow-md mb-6 bg-green-100 text-green-400">
                <div className="flex items-center">
                  <CheckCircle weight="bold" className="w-5 h-5 mr-2" />
                  <span>
                    Request Processed Successfully. Total Retrieved Confirmed
                    Trainer Records:
                    {trainers?.confirmed?.count}
                  </span>
                </div>
                <button className="btn bg-green-100 hover:bg-green-100 focus:bg-green-100 focus-within:bg-green-100 !h-6 !px-1 !py-0 my-0 !border-0 outline-0 flex items-center justify-between">
                  <X weight="bold" className="w-4 h-4 text-green-400" />
                </button>
              </div>
            )}
      </div>
      <PaginatedDataTable
        title="List of Confirmed Trainers"
        exports={{ excel: true, messagingList: true }}
        columns={[
          { Header: "Trainer Registration Number", accessor: "regNumber" },
          { Header: "Trainer First Name", accessor: "firstName" },
          { Header: "Trainer Last Name", accessor: "surname" },
          { Header: "Email Address", accessor: "email" },
          { Header: "Mobile Number", accessor: "phoneNumber" },
          {
            Header: "Gender",
            accessor: "gender",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Verified?",
            accessor: (row) => {
              return row.verified.toString();
            },
            Filter: SelectColumnFilter,
          },
          { Header: "Created At", accessor: "createdAt" },
          // { Header: "Updated At", accessor: "updatedAt" },
        ]}
        data={trainers?.confirmed.data}
        count={trainers?.confirmed.count}
        currentPage={trainers?.confirmed.page}
        setCurrentPage={(page) => {
          updatePage({ newPage: page, category: "confirmed" });
          setLoaded(false);
        }}
        onRowClick={(row) => {
          navigate(`/admin/manage/trainer/supervisors/details/${row.uuid}`);
        }}
        exportToExcel={exportTrainers}
      />
    </PageBuilder>
  );
};

export default ConfirmedTrainers;
