import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DateTimeToDate from "../../utilities/DateTimeToDate";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import NavMobile from "../Layouts/NavMobile";

const ViewProgramCourses = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();

  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [courses, setCourses] = useState([]);

  const ProgramCourses = () => {
    setLoading(true);
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + `program/${id}`, postOptions)
      .then((response) => response.json())
      .then((json) => {
        setCourses(json.data.courses);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    ProgramCourses();
  }, []);
  return (
    <div class="">
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <div class="content container-fluid">
            <PageTitle title="Courses" />

            <div
              style={{
                display: !editProfile ? `block` : `none`,
                width: "100%",
              }}
              className="row"
            >
              <div className="row" style={{ width: "100%" }}>
                {courses.length > 0 ? (
                  courses.map((x) => (
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="image">
                            <img
                              src={x.image}
                              alt=""
                              className="co_img"
                              style={{ height: "250px" }}
                            />
                          </div>
                          <div className="mx-2 my-4">
                            <strong style={{ float: "left" }}>
                              Course Title
                            </strong>
                            <p style={{ float: "right" }}>{x.title}</p>
                          </div>
                          <br></br>
                          <hr></hr>
                          <div className="mx-2 my-4">
                            <strong style={{ float: "left" }}>
                              Course Type
                            </strong>
                            <p style={{ float: "right" }}>{x.courseType}</p>
                          </div>
                          <br></br>
                          <hr></hr>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <p>${x.description}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* <div className="mx-2 my-4">
                            <strong style={{ float: "left" }}>Cost</strong>
                            <p style={{ float: "right" }}>{x.cost}</p>
                          </div> */}
                          <br></br>
                          <hr></hr>
                          {/* <div className="mx-2 my-4">
                            <strong style={{ float: "left" }}>
                              Minimun Deposit
                            </strong>
                            <p style={{ float: "right" }}>{x.minDeposit}</p>
                          </div> */}
                          <br></br>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div class="card mx-5">
                      <div class="card-body ">
                        <h1>No Courses available</h1>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProgramCourses;
