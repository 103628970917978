import axios from "axios";
import urls from "../../components/utilities/urls";

const API_URL = urls.api + "profile/my-profile";

// get user Details

const getDetails = async () => {
  const token = localStorage.getItem("token");
  const postOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  };
  const response = await axios.get(API_URL, postOptions);
  return response.data;
};
const detailsService = {
  getDetails,
};

export default detailsService;
