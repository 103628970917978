import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./exam.css";
import urls from "../../utilities/urls";
import axios from "axios";
import PageTitle from "../Layouts/PageTitle";
import Spinner from "../../utilities/spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TrainerSIngleExam() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [answerType, setAnswerType] = useState(null);
  const [enterOptions, setEnterOptions] = useState(false);
  const [singExam, setSingExam] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myCourses, setMyCourses] = useState([]);
  const [uuid, setUuid] = useState("");
  const [course_code, setCourse_code] = useState("");
  const [title, setTitle] = useState("");
  const [question, setQuestion] = useState("");
  const [answer_option_1, setAnswer_option_1] = useState("");
  const [answer_option_2, setAnswer_option_2] = useState("");
  const [answer_option_3, setAnswer_option_3] = useState("");
  const [answer_option_4, setAnswer_option_4] = useState("");
  const [time_limit, setTime_limit] = useState("");
  const [correct_answer, setCorrect_answer] = useState("");
  const { id } = useParams();
  console.log(id);
  console.log(singExam);

  const selectedAnswerType = (x) => {
    if (x == "options") {
      setEnterOptions(true);
    } else {
      setEnterOptions(false);
    }
  };

  const postExam = async () => {
    const formData = new FormData();
    formData.append("course_code", course_code);
    formData.append("title", title);
    formData.append("question", question);
    formData.append("answer_option_1", answer_option_1);
    formData.append("answer_option_2", answer_option_2);
    formData.append("answer_option_3", answer_option_3);
    formData.append("answer_option_4", answer_option_4);
    formData.append("time_limit", time_limit);
    formData.append("correct_answer", correct_answer);

    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: formData,
    };
    fetch(urls.api + "exams/" + id, postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          toast.success(json.message);
          console.log(json);
        } else if (json.message !== null) {
          toast.error(json.message);
          console.log(json);
        } else {
          console.log("else statement");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      })
      .finally(() => {
        navigate("/trainer/exams");
        console.log("done");
        setCourse_code("");
        setQuestion("");
        setTitle("");
        setUuid("");
        setAnswer_option_1("");
        setAnswer_option_2("");
        setAnswer_option_3("");
        setAnswer_option_4("");
        setCorrect_answer("");
      });
  };

  const getMyCourses = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      urls.api + "courses/all/my-courses",
      config
    );
    const data = response.data.data.courses;
    // console.log(data);
    setMyCourses(data);
  };

  const getSingleExam = () => {
    setLoading(true);
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    fetch(urls.api + "exams/" + id, postOptions)
      .then((response) => response.json())
      .then((json) => {
        console.log(json.data);
        if (json.success === true) {
          setSingExam(json.data);
        } else {
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // alert("Hello world")
    getSingleExam();
    getMyCourses();
  }, []);

  const getEdit = () => {
    setEdit(true);
  };

  return (
    <div className="content container-fluid">
      <PageTitle title="Exam details" />

      <div style={{ display: loading ? `block` : `none` }}>
        <Spinner />
      </div>
      {edit ? (
        <div>
          <div className="row">
            <div class="card">
              <div class="card-body">
                <div className="row">
                  <div class="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Starts</label>
                    <input type={"date"} className="form-control" />
                  </div>
                  <div class="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Ends</label>
                    <input
                      type={"datetime-local"}
                      value={time_limit}
                      className="form-control"
                      onChange={(e) => setTime_limit(e.target.value)}
                    />
                  </div>
                  <div class="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Title</label>
                    <input
                      type={"text"}
                      value={title}
                      name="title"
                      onChange={(e) => setTitle(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div class="col-xl-10 col-md-6 col-12 mb-3">
                    <label>Question</label>
                    <textarea
                      className="form-control"
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                    ></textarea>
                  </div>
                  {/* <div class="col-xl-6 col-md-6 col-12 mb-3">
                <label>Attach Image (Optional)</label>
                <input type={"file"} className="form-control" />
              </div> */}
                  <div class="col-xl-6 col-md-6 col-12 mb-3">
                    <label>Answer Type</label>
                    <select
                      onChange={(e) => {
                        setAnswerType(e.target.value);
                        selectedAnswerType(e.target.value);
                      }}
                      className="form-control"
                    >
                      <option value={""}>Select</option>
                      {/* <option value={"true_or_false"}>True of False</option> */}
                      <option value={"options"}>Options</option>
                    </select>
                  </div>
                </div>

                <div style={{ display: enterOptions ? `block` : `none` }}>
                  <div className="row">
                    <div class="col-xl-6 col-md-12 col-12 mb-3">
                      <label>option 1</label>
                      <input
                        className="form-control"
                        value={answer_option_1}
                        onChange={(e) => setAnswer_option_1(e.target.value)}
                      />
                    </div>
                    <div class="col-xl-6 col-md-12 col-12 mb-3">
                      <label>option 2</label>
                      <input
                        className="form-control"
                        value={answer_option_2}
                        onChange={(e) => setAnswer_option_2(e.target.value)}
                      />
                    </div>
                    <div class="col-xl-6 col-md-12 col-12 mb-3">
                      <label>option 3</label>
                      <input
                        className="form-control"
                        value={answer_option_3}
                        onChange={(e) => setAnswer_option_3(e.target.value)}
                      />
                    </div>
                    <div class="col-xl-6 col-md-12 col-12 mb-3">
                      <label>option 4</label>
                      <input
                        className="form-control"
                        value={answer_option_4}
                        onChange={(e) => setAnswer_option_4(e.target.value)}
                      />
                    </div>
                    <div class="col-xl-6 col-md-12 col-12 mb-3">
                      <label>correct answer</label>
                      <input
                        className="form-control"
                        value={correct_answer}
                        onChange={(e) => setCorrect_answer(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: answerType !== null ? `block` : `none` }}
                >
                  <div className="row">
                    <div class="col-xl-6 col-md-12 col-12">
                      {/* <button className="btn btn-secondary">
                    Add More <i className="fa fa-plus-square"></i>
                  </button> */}
                    </div>
                    <div class="col-xl-6 col-md-12 col-12 text-right">
                      <button className="btn btn-success" onClick={postExam}>
                        Submit <i className="fa fa-check"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-md-12 col-12 text-right">
                  <button className="btn btn-success" onClick={postExam}>
                    Submit <i className="fa fa-check"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: !loading ? `block` : `none` }}>
          <div className="exam">
            <div class="card w-50">
              <div class="card-body">
                <h5 class="card-title title">{singExam.title}</h5>
                <p class="card-text question">{singExam.question}</p>
                <div className="radio__btn">
                  <div className="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      {singExam.Option1}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {singExam.Option2}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {singExam.Option3}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {singExam.Option4}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {singExam.correctAnswer}(correct answer)
                    </label>
                  </div>
                </div>
                <div className="edit">
                  <button class="btn btn-primary" onClick={getEdit}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrainerSIngleExam;
