import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TrainerAddAssignments = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [myCourses, setMyCourses] = useState([]);
  const [answerType, setAnswerType] = useState(null);
  const [enterOptions, setEnterOptions] = useState(false);
  const [uuid, setUuid] = useState("");
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [due_date, setDue_date] = useState("");
  const [attachments, setAttachment] = useState(null);

  const getMyCourses = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      urls.api + "courses/all/my-courses",
      config
    );
    const data = response.data.data.courses;
    // console.log(data);
    setMyCourses(data);
  };

  const postAss = async () => {
    const formData = new FormData();
    formData.append("courseUuid", uuid);
    formData.append("topic", topic);
    formData.append("description", description);
    formData.append("dueDate", due_date);
    formData.append("attachment", attachments);

    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        // "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Credentials": true,
      },
      body: formData,
    };
    fetch(urls.api + "assignments/create-new-assignment/", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          toast.success(json.message);
          console.log(json);
        } else if (json.message !== null) {
          toast.success(json.message);
          console.log(json);
        } else {
          console.log("else statement");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      })
      .finally(() => console.log("done"));
  };

  console.log(topic);
  console.log(description);
  console.log(due_date);
  console.log(uuid);
  console.log(attachments);

  useEffect(() => {
    // alert("Hello world")
    getMyCourses();
  }, []);

  return (
    <div class="content container-fluid">
      <PageTitle title="Create Assignment" />
      <ToastContainer />

      <div className="row">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div class="col-xl-4 col-sm-6 col-12 mb-3">
                <label>Course</label>
                <select
                  className="form-control"
                  onChange={(e) => setUuid(e.target.value)}
                >
                  <option>Select</option>
                  {myCourses.map((id) => {
                    return (
                      <option value={id.courseUuid} name="courseUuid">
                        {id.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div class="col-xl-4 col-md-6 col-12 mb-3">
                <label>Due Date</label>
                <input
                  type={"datetime-local"}
                  value={due_date}
                  name="due_date"
                  onChange={(e) => setDue_date(e.target.value)}
                  className="form-control"
                />
              </div>
              <div class="col-xl-4 col-md-6 col-12 mb-3">
                <label>Topic</label>
                <input
                  type={"text"}
                  value={topic}
                  name="topic"
                  onChange={(e) => setTopic(e.target.value)}
                  className="form-control"
                />
              </div>
              <div class="col-xl-12 col-md-6 col-12 mb-3">
                <label>Question</label>
                <textarea
                  className="form-control"
                  value={description}
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
              <div class="col-xl-6 col-md-6 col-12 mb-3">
                <label>Attach Image (Optional)</label>
                <input
                  type={"file"}
                  className="form-control"
                  onChange={(e) => setAttachment(e.target.files[0])}
                />
              </div>
            </div>

            <div style={{ display: enterOptions ? `block` : `none` }}>
              <div className="row">
                <div class="col-xl-6 col-md-12 col-12 mb-3">
                  <label>
                    Right Answer <i className="text-success fa fa-check"></i>
                  </label>
                  <input className="form-control" />
                </div>
                <div class="col-xl-6 col-md-12 col-12 mb-3">
                  <label>
                    Wrong Answer <i className="text-danger fa fa-times"></i>{" "}
                  </label>
                  <input className="form-control" />
                </div>
                <div class="col-xl-6 col-md-12 col-12 mb-3">
                  <label>
                    Wrong Answer <i className="text-danger fa fa-times"></i>
                  </label>
                  <input className="form-control" />
                </div>
                <div class="col-xl-6 col-md-12 col-12 mb-3">
                  <label>
                    Wrong Answer <i className="text-danger fa fa-times"></i>
                  </label>
                  <input className="form-control" />
                </div>
              </div>
            </div>

            <div style={{ display: answerType !== null ? `block` : `block` }}>
              <div className="row">
                <div class="col-xl-6 col-md-12 col-12 text-right">
                  <button className="btn btn-success" onClick={postAss}>
                    Submit <i className="fa fa-check"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerAddAssignments;
