import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import urls from "../../utilities/urls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TrainerSingleAss() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [singleAss, setSingleAss] = useState({});
  const [edit, setEdit] = useState(false);
  const [myCourses, setMyCourses] = useState([]);
  const [answerType, setAnswerType] = useState(null);
  const [enterOptions, setEnterOptions] = useState(false);
  const [uuid, setUuid] = useState("");
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [due_date, setDue_date] = useState("");
  const [attachments, setAttachment] = useState({});
  const { id } = useParams();
  console.log(id);

  const getSingleAss = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(urls.api + "assignments/" + id, config);
    const data = response.data.data;
    console.log(data);
    setSingleAss(data);
  };

  const getMyCourses = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      urls.api + "courses/all/my-courses",
      config
    );
    const data = response.data.data.courses;
    // console.log(data);
    setMyCourses(data);
  };

  useEffect(() => {
    getSingleAss();
    getMyCourses();
  }, []);

  const postAss = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("uuid", uuid);
    formData.append("topic", topic);
    formData.append("description", description);
    formData.append("due_date", due_date);
    formData.append("attachments", attachments);

    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: formData,
    };
    fetch(urls.api + "assignments/" + id, postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          toast.success(json.message);
          console.log(json);
          navigate("/trainer/assignment");
        } else if (json.message !== null) {
          toast.success(json.message);
          console.log(json);
        } else {
          console.log("else statement");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      })
      .finally(() => {
        console.log("done");
        setEdit(false);
      });
  };

  const getEdit = () => {
    setEdit(true);
  };

  const submit = async (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={submit}>
      {edit ? (
        <div className="container">
          <div className="row">
            <div class="card">
              <div class="card-body">
                <div className="row">
                  <div class="col-xl-4 col-sm-6 col-12 mb-3">
                    <label>Course</label>
                    <select
                      className="form-control"
                      onChange={(e) => setUuid(e.target.value)}
                    >
                      <option>Select</option>
                      {myCourses.map((id) => {
                        return (
                          <option value={id.uuid} name="courseUuid">
                            {id.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div class="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Due Date</label>
                    <input
                      type={"datetime-local"}
                      value={due_date}
                      name="due_date"
                      onChange={(e) => setDue_date(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div class="col-xl-4 col-md-6 col-12 mb-3">
                    <label>Topic</label>
                    <input
                      type={"text"}
                      value={topic}
                      name="topic"
                      onChange={(e) => setTopic(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div class="col-xl-12 col-md-6 col-12 mb-3">
                    <label>Question</label>
                    <textarea
                      className="form-control"
                      value={description}
                      name="description"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div class="col-xl-6 col-md-6 col-12 mb-3">
                    <label>Attach Image (Optional)</label>
                    <input
                      type={"file"}
                      className="form-control"
                      onChange={(e) => setAttachment(e.target.files[0])}
                    />
                  </div>
                </div>

                <div
                  style={{ display: answerType !== null ? `block` : `block` }}
                >
                  <div className="row">
                    <div class="col-xl-6 col-md-12 col-12 text-right">
                      <button className="btn btn-success" onClick={postAss}>
                        Submit <i className="fa fa-check"></i>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div class="card w-25">
              <div class="card-body">
                <div style={{ marginBottom: "20%", marginTop: "20%" }}>
                  <h5 class="card-title">{singleAss.topic}</h5>
                  <p class="card-text">{singleAss.description}</p>
                </div>
                <button href="#" class="btn btn-primary" onClick={getEdit}>
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="exam">
          <div class="card w-25">
            <div class="card-body">
              <div style={{ marginBottom: "20%", marginTop: "20%" }}>
                <h5 class="card-title">{singleAss.topic}</h5>
                <p class="card-text">{singleAss.description}</p>
              </div>
              <button href="#" class="btn btn-primary" onClick={getEdit}>
                Edit
              </button>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default TrainerSingleAss;
