import React, { useEffect, useState } from "react";
import PageBuilder from "../components/layout/PageBuilder";
import { useAuth } from "../providers/AuthProvider";
import { AsyncData, useData } from "../providers/DataProvider";

const AdminDashboard = () => {
  const {
    data: { stats },
    loaders: { getStats },
  } = useData();

  const { permissionBasedRendering } = useAuth();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      getStats();
      setLoaded(true);
    }
  }, [loaded, getStats]);

  return (
    <PageBuilder name="Dashboard">
      {permissionBasedRendering(
        "super",
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full px-2 py-2 mb-10">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
              People
            </span>
            <div className="flex justify-center sm:justify-start w-full flex-wrap">
              {permissionBasedRendering(
                "trainee management",
                <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                  <span className="text-gray-400">
                    Total Signed Up Trainees
                  </span>
                  <span className="text-black text-4xl mt-3">
                    <AsyncData>{stats?.trainees?.total ?? 0}</AsyncData>
                  </span>
                </div>
              )}
              {permissionBasedRendering(
                "trainer management",
                <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                  <span className="text-gray-400">
                    Total Signed Up Trainers
                  </span>
                  <span className="text-black text-4xl mt-3">
                    <AsyncData>{stats?.trainers?.total ?? 0}</AsyncData>
                  </span>
                </div>
              )}
              {permissionBasedRendering(
                "super",
                <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                  <span className="text-gray-400">Total System Users</span>
                  <span className="text-black text-4xl mt-3">
                    <AsyncData>{stats?.users?.total ?? 0}</AsyncData>
                  </span>
                </div>
              )}
              {permissionBasedRendering(
                "trainee company management",
                <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                  <span className="text-gray-400">
                    Total Signed Up Sponsors
                  </span>
                  <span className="text-black text-4xl mt-3">
                    <AsyncData>{stats?.totalSponsors ?? 0}</AsyncData>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full px-2 py-2 !mb-10">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
              Academics
            </span>
            <div className="flex justify-center sm:justify-start w-full flex-wrap">
              <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                <span className="text-gray-400">
                  Total Regional Training Centers
                </span>
                <span className="text-black text-4xl mt-3">
                  <AsyncData>{stats?.totalRTCs ?? 0}</AsyncData>
                </span>
              </div>
              <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                <span className="text-gray-400">Total Uploaded Courses</span>
                <span className="text-black text-4xl mt-3">
                  <AsyncData>{stats?.totalCourses ?? 0}</AsyncData>
                </span>
              </div>
              <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                <span className="text-gray-400">Total Uploaded Quizzes</span>
                <span className="text-black text-4xl mt-3">
                  <AsyncData>{stats?.totalQuizzes ?? 0}</AsyncData>
                </span>
              </div>
              <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                <span className="text-gray-400">
                  Total Uploaded Assignments
                </span>
                <span className="text-black text-4xl mt-3">
                  <AsyncData>{stats?.totalAssignments ?? 0}</AsyncData>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full px-2 py-2 mb-10">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
              Support
            </span>
            <div className="flex justify-center sm:justify-start w-full flex-wrap">
              <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                <span className="text-gray-400">Total Tickets</span>
                <span className="text-black text-4xl mt-3">
                  <AsyncData>{stats?.tickets?.total ?? 0}</AsyncData>
                </span>
              </div>
              <div className="flex flex-col border-[1.5px] !min-w-[240px] !max-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
                <span className="text-gray-400">Total FAQs</span>
                <span className="text-black text-4xl mt-3">
                  <AsyncData>{stats?.totalFAQs ?? 0}</AsyncData>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </PageBuilder>
  );
};

export default AdminDashboard;
