import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";

const SystemUsers = () => {
  const {
    data: { systemUsers },
    loaders: { getSystemUsers },
    pagination: {
      systemUsers: { updatePage },
    },
  } = useData();

  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      getSystemUsers({
        getAll: true,
      });
      setLoaded(true);
    }
  }, [loaded, getSystemUsers]);

  return (
    <PageBuilder name="System Users">
      <div className="flex flex-col">
        <PaginatedDataTable
          title="List of System Users"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            { Header: "Registration Number", accessor: "registrationNumber" },
            { Header: "Full Name", accessor: "name" },
            {
              Header: "Status",
              accessor: (row) => {
                return row.active ? "active" : "inactive";
              },
            },
            {
              Header: "Role(s)",
              accessor: (row) => {
                return row.role?.join(", ") ?? "N/A";
              },
            },
            { Header: "Email Address", accessor: "email" },
            { Header: "Mobile Number", accessor: "phoneNumber" },
          ]}
          data={systemUsers["all"]?.data ?? []}
          count={systemUsers["all"]?.count ?? 0}
          currentPage={systemUsers["all"]?.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page, category: "all" });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(`/admin/manage/users/details/${row.uuid}`);
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default SystemUsers;
