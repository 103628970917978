import { Plus, WarningCircle } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { Modal } from "flowbite-react";
import Select from "react-select";
import { useData } from "../../providers/DataProvider";

const FieldLocations = () => {
  const {
    data: { trainingCenters },
    loaders: { getTrainingCenters },
    // pagination: {
    //   trainingCenters: { updatePage },
    // },
  } = useData();

  const [loaded, setLoaded] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      getTrainingCenters();
      setLoaded(true);
    }
  }, [loaded, getTrainingCenters]);

  return (
    <PageBuilder
      name="Manage Field Locations"
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="btn btn-secondary mx-0 flex items-center"
            onClick={() => setModalShow(true)}
          >
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Add New RTC</span>
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
                // window.location.reload();
              }}
              size="5xl"
            >
              <Modal.Body>
                <div className="flex flex-col bg-white">
                  <div className="flex justify-between items-center px-5">
                    <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
                      New Regional Training Center
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary mx-0 flex items-center"
                      >
                        <span>Create RTC</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col px-5 bg-white my-4 w-auto">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="name" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} RTC Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="RTC Name"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="name" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} RTC Type
                        </label>
                        <Select
                          className="border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="RTC Type"
                          classNamePrefix="select"
                          options={["Managfer", "Ckasmke"].map((option) => ({
                            value: option,
                            label: option,
                          }))}
                          isSearchable={false}
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="email" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} RTC Address
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="RTC Address"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="name" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} State of
                          Location
                        </label>
                        <Select
                          className="border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="State of Location"
                          classNamePrefix="select"
                          options={["Managfer", "Ckasmke"].map((option) => ({
                            value: option,
                            label: option,
                          }))}
                          isSearchable={false}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col w-full p-4">
        <div className="flex flex-col">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
            Filter Field Locations
          </span>
          <div className="flex w-full">
            <div className="flex flex-col mr-2">
              <span className="text-black">Field Type</span>
              <Select
                options={[]}
                isSearchable={false}
                className="flex items-center justify-center min-w-[15rem]"
                classNamePrefix="select"
                placeholder="All"
              />
            </div>
            <div className="flex flex-col">
              <button type="submit" className="btn btn-primary mx-0 my-4">
                Apply
              </button>
            </div>
          </div>
        </div>
        <PaginatedDataTable
          title="List of Trainees Awaiting Assessments"
          columns={[
            {
              Header: "Type",
              accessor: "applicantStatus",
              Filter: SelectColumnFilter,
            },
            { Header: "State of Location", accessor: "user.location" },
            { Header: "Email Address", accessor: "user.email" },
            { Header: "Contact Telephone", accessor: "user.phoneNumber" },
            { Header: "Address", accessor: "user.Address" },
            { Header: "City", accessor: "user.admissionNumber" },
          ]}
          data={trainingCenters?.data}
          count={trainingCenters?.count}
          // currentPage={trainingCenters?.page}
          // setCurrentPage={(page) => {
          //   updatePage({ newPage: page });
          //   setLoaded(false);
          // }}
          onRowClick={(row) => {
            navigate(`/admin/manage/center/${row.uuid}`);
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default FieldLocations;
