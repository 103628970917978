import { toast } from "react-toastify";

const configs = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

export const flashMessage = ({ message, type, delay = undefined }) => {
  switch (type) {
    case "success":
      toast.success(message, { ...configs, delay });
      break;
    case "info":
      toast.info(message, { ...configs, delay });
      break;
    case "error":
      toast.error(message, { ...configs, delay });
      break;
    default:
      toast.text(message, { ...configs, delay });
  }
};
