import React, { useState, useEffect } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay, add } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import enUS from "date-fns/locale/en-US";
import axios from "axios";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";

const TrainingCalendar = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: add(new Date(), { months: 4 }),
  });

  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  useEffect(() => {
    (async () => {
      const calendars = urls.api + "calendar/course-calendar/";
      await axios
        .post(calendars, range)
        .then((result) => {
          console.log(result.data);
          setEvents(result.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    })();
  }, [events, setEvents]);

  return (
    <>
      <div className="m-4">
        <PageTitle title="Calendar" />
        <div
          style={{
            marginLeft: "auto",
            marginRight: "0px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <a href="http://tinyurl.com/4n4a7vsj">
            {" "}
            <button className="m-2 btn btn-secondary">Download Calender</button>
          </a>
        </div>
        <Calendar
          startAccessor="startDate"
          endAccessor="endDate"
          titleAccessor="courseTitle"
          resourceIdAccessor="uuid"
          localizer={localizer}
          popup
          events={events}
          showMultiDayTimes
          views={["month", "week", "day"]}
          step={60}
          onRangeChange={(newRange) =>
            setRange({ startDate: newRange.start, endDate: newRange.end })
          }
          style={{ height: 750 }}
          onSelectEvent={(event) => {
            navigate(`/trainer/${event.courseUuid}/view_course`);
          }}
        />
      </div>
    </>
  );
};

export default TrainingCalendar;
