import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";
import urls from "../../utilities/urls";
import { toast } from "react-toastify";

function TakeAss1() {
  const { id } = useParams();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [singleAss, setSingleAss] = useState({});
  console.log(id);
  const [answer, setAnswer] = useState("");
  const [attachment, setAttachment] = useState("");

  const getSingleAss = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(urls.api + "assignments/" + id, config);
    const data = response.data.data;
    console.log(data);
    setSingleAss(data);
  };

  const onHandle = (e) => {
    setAnswer(e.target.value);
  };
  const onHandleFile = (e) => {
    let name = e.target.files[0];
    setAttachment(name);
  };
  const onSubmitHandle = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("assignmentUuid", singleAss.uuid);
    formData.append("topic", singleAss.topic);
    formData.append("answer", answer);
    formData.append("attachment", attachment);
    console.log(formData);

    await axios
      .post(urls.api + "assignment/submission/submit", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        console.log(result);
        if (result.data.success === true) {
          toast.success("Submitted Successfully");
        } else {
          toast.error("Unable to Submit ");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Invalid file attachment ");
        toast.error("Only Pdf and Docs are allowed ");
      });
  };
  useEffect(() => {
    getSingleAss();
  }, []);
  return (
    <div class="content container-fluid">
      <PageTitle title="Assignments" />

      <div>
        <div className="row">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card">
              <div class="card-body">
                <div className="">
                  <form
                    onSubmit={(e) => {
                      onSubmitHandle(e);
                    }}
                  >
                    {" "}
                    <div className="m-3">
                      <p>Topic: {singleAss.topic}</p>
                    </div>
                    <div className="m-3">
                      <p> Question: {singleAss.description}</p>
                    </div>
                    <div className="m-3">
                      <textarea
                        onChange={(e) => {
                          onHandle(e);
                        }}
                        style={{
                          borderRadius: "13px",
                          height: "250px",
                          width: "300px",
                        }}
                        required
                        className=""
                        placeholder="Answer"
                        name="answer"
                        id="answer"
                      />
                    </div>
                    <div className="m-3">
                      <input
                        onChange={(e) => {
                          onHandleFile(e);
                        }}
                        required
                        type={"file"}
                        name="attachment"
                        id="attachment"
                      />
                      <input
                        type="submit"
                        className="btn btn-secondary"
                        value="Submit"
                      />
                      <div>
                        <p>Valid till : {singleAss.dueDate}</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TakeAss1;
