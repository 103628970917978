import axios from "axios";
import { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import NavMobile from "./Layouts/NavMobile";
import PageTitle from "./Layouts/PageTitle";
import { toast } from "react-toastify";
import urls from "../utilities/urls";

const TraineeCheckResult = () => {
  const [getCourseId, setGetCourseId] = useState("");
  const [userId, setUserId] = useState("");
  const [getCalendar, setGetCalendar] = useState([]);
  const [enable, setEnable] = useState(false);
  const [result, setResult] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [applicableType, setApplicableType] = useState("");
  const [applicableUuid, setApplicableUuid] = useState("");
  const [calenderUuid, setCalenderUuid] = useState("");
  const [myCourses, setMyCourses] = useState([]);
  const token = localStorage.getItem("token");

  const postOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  };
  const coursesApplied = () => {
    fetch(urls.api + "application/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          //console.log('Logging my courses')
          // console.log(json.data.applications);
          setMyCourses(json.data.applications);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUserProfile = async () => {
    await axios
      .get(urls.api + "profile/my-profile", postOptions)
      .then((result) => {
        setUserId(result.data.data.user.bio.uuid);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onHandleValues = async (e) => {
    const inputString = e.target.value;
    const parts = inputString.split("+");
    setApplicableType(parts[1]);
    setApplicableUuid(parts[0]);
    if (parts[1] === "course") {
      setEnable(enable === true ? false : false);
      await setGetCourseId(parts[0]);
      await axios
        .get(urls.api + "results/trainee/" + userId, postOptions)
        .then((response) => {
          console.log("What the heck could the result be");
          setResult(response.data.data.results);
          console.log(response.data.data.results);
          console.log(getCourseId);
        })
        .catch((error) => {
          console.log("an error has occured");
          console.log(error);
        });
    } else {
      const url = urls.api + "program/" + parts[0];
      axios
        .get(url)
        .then((result) => {
          console.log(result.data.data);
          setGetCalendar(result.data.data.calendars);
          console.log(getCalendar);
        })
        .catch((error) => {
          console.log(error);
        });
      //  console.log(applicableType, applicableUuid);
      // setCourseUuid(e.target.value);
      setEnable(true);
      setResult([]);
    }
  };
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  // const onSubmitHandle = async (e) => {
  //   e.preventDefault();
  //   // getCourseId userId
  //   // alert(getCourseId)
  //   if (getCourseId !== "") {
  //     await axios
  //       .get(urls.api + "results/trainee/" + userId, postOptions)
  //       .then((response) => {
  //         console.log("What the heck could the result be");
  //         setResult(response.data.data.results);
  //         console.log(response.data.data.results);
  //         console.log(getCourseId);
  //       })
  //       .catch((error) => {
  //         console.log("an error has occured");
  //         console.log(error);
  //       });
  //   }
  // };
  const printResult = async (courseUuid) => {
    await axios
      .get(urls.api + "results/print/" + courseUuid, {
        ...postOptions,
        responseType: "blob",
      })
      .then((res) => {
        // create file link in browser's memory
        const href = window.URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "file.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err);
        console.log("an error occured");
      });
  };
  const onDownloadProgram = async (programCalender) => {
    console.log(programCalender, applicableUuid);
    await axios
      .get(
        urls.api +
          "results/program/print/" +
          applicableUuid +
          "/" +
          programCalender,
        {
          ...postOptions,
          responseType: "blob",
        }
      )
      .then((res) => {
        // create file link in browser's memory
        const href = window.URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "file.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err);
        console.log("an error occured");
      });
  };
  useEffect(() => {
    getUserProfile();
    coursesApplied();
    console.log(enable);
  }, [getCourseId, result, enable, applicableUuid, getCalendar]);

  return (
    <div class="navMenu">
      <div className="t">
        <NavMobile />
      </div>
      <div className="w">
        <PageTitle title="Check Result" />

        <div>
          <div class="card">
            <div class="card-body">
              <div className="row">
                <div
                  class={
                    enable
                      ? "col-md-8 col-sm-8 col-lg-8"
                      : "col-md-12 col-sm-12 col-lg-12"
                  }
                >
                  {/* <form
                    onSubmit={(e) => {
                   //   onSubmitHandle(e);
                    }}
                  > */}
                  <label>Result</label>

                  <select
                    className="form-control"
                    id="select_result "
                    onChange={(e) => {
                      onHandleValues(e);
                    }}
                  >
                    <option value="">Select Courses</option>

                    {myCourses ? (
                      myCourses.map((course) => {
                        return (
                          <>
                            {course.status  === "confirmed" ? (
                              <option
                                value={
                                  course.courseUuid
                                    ? course.courseUuid + "+course"
                                    : course.programUuid + "+program"
                                }
                              >
                                {course.courseTitle || course.programName}
                              </option>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </select>

                  {/* <input
                    type="submit"
                    className="btn btn-secondary"
                    style={{ float: "right" }}
                    value="Check Result"
                  /> */}
                  {/* </form> */}
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4 my-4">
                  <select
                    value=""
                    // disabled={enable ? false : true}
                    className="form-control mt-2"
                    required
                    style={{
                      width: "100%",
                      float: "right",
                      display: enable ? "block" : "none",
                    }}
                    id="calendarUuid"
                    name="calendarUuid"
                    onChange={(e) => {
                      onDownloadProgram(e.target.value);
                      //   setCalenderUuid(e.target.value);
                    }}
                  >
                    <option defaultValue={true}>Select Session</option>
                    {getCalendar &&
                      getCalendar.map((each) => {
                        return (
                          <>
                            {" "}
                            <option value={each.uuid}>
                              {new Date(each.startDate).toLocaleDateString(
                                "en-US",
                                DATE_OPTIONS
                              ) +
                                " - " +
                                new Date(each.endDate).toLocaleDateString(
                                  "en-US",
                                  DATE_OPTIONS
                                )}
                            </option>
                            ;
                          </>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="row m-5">
                <div className="col-lg-12">
                  <strongs style={{ fontSize: "25px" }}>Results</strongs>
                </div>
                <div className="table-responsive">
                  {" "}
                  <table className="table">
                    <thead>
                      <th>Quiz</th>
                      <th>Assignment</th>
                      <th>Exam</th>
                      <th>Grade</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      {result.map((result) => {
                        return (
                          <>
                            {result.courseUuid === getCourseId ? (
                              <>
                                {" "}
                                <tr>
                                  <td>{result.quizScore}</td>
                                  <td>{result.assignmentScore}</td>
                                  <td>{result.examScore}</td>
                                  <td>{result.grade.slice(0, 5)}</td>
                                  <td>
                                    <button
                                      className="btn btn-secondary"
                                      style={{
                                        marginTop: "-5px",
                                        marginLeft: "-4px",
                                      }}
                                      onClick={() => {
                                        printResult(result.courseUuid);
                                      }}
                                    >
                                      Print Result
                                    </button>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* <div className="col-lg-3">Quiz</div>
                <div className="col-lg-3">Assignment</div>
                <div className="col-lg-3">Exam</div>
                <div className="col-lg-3">Grade</div>
                {result.map((result) => {
                  return (
                    <>
                      {result.courseUuid === getCourseId ? (
                        <>
                          {" "}
                          <div className="col-lg-3">{result.quizScore}</div>
                          <div className="col-lg-3">
                            {result.assignmentScore}
                          </div>
                          <div className="col-lg-3">{result.examScore}</div>
                          <div className="col-lg-3">
                            {result.grade.slice(0, 5)}
                          </div>
                          <div className="col-lg-2">
                            <button className="btn btn-success">hello</button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Select Course end*/}
    </div>
  );
};

export default TraineeCheckResult;
