import { Plus, WarningCircle } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { Modal } from "flowbite-react";
import Select from "react-select";
import { useData } from "../../providers/DataProvider";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";
import { triggerDownloadPipe } from "../../helpers";

const TrainingCenters = () => {
  const {
    data: { trainingCenters, states },
    loaders: { getTrainingCenters, getStates },
  } = useData();

  const [loaded, setLoaded] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();
  const client = useClient();

  useEffect(() => {
    if (!loaded) {
      getTrainingCenters();
      getStates();
      setLoaded(true);
    }
  }, [loaded, getTrainingCenters, getStates]);

  const createRTC = (e) => {
    e.preventDefault();
    const form = e.target;
    const rtcInfo = {
      name: form.name.value,
      email: form.email.value,
      phoneNumber: form.phoneNumber.value,
      address: form.address.value,
      stateId: form.state.value,
    };

    client("/api/v1/regional-training-centers", {
      method: "POST",
      data: rtcInfo,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  const exportRTCS = (startDate, endDate, columns) => {
    flashMessage({ message: "Generating export", type: "info" });
    client("/api/v1/rtc-excel-reports/rtc-export", {
      method: "POST",
      res: "file",
      data: {
        startDate: startDate,
        endDate: endDate,
        columns: columns,
      },
      credentials: true,
    })
      .then(triggerDownloadPipe("RTC_LIST_EXPORT", "xlsx"))
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  return (
    <PageBuilder
      name="Regional Training Centers"
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="btn btn-secondary mx-0 flex items-center"
            onClick={() => setModalShow(true)}
          >
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Add New RTC</span>
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
                // window.location.reload();
              }}
              size="5xl"
            >
              <Modal.Body>
                <form className="flex flex-col bg-white" onSubmit={createRTC}>
                  <div className="flex justify-between items-center px-5">
                    <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
                      New Regional Training Center
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary mx-0 flex items-center"
                      >
                        <span>Create RTC</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col px-5 bg-white my-4 w-auto">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="name" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} RTC Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="RTC Name"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="email" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} RTC Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="RTC Email"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="address" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} RTC Address
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          placeholder="RTC Address"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="state" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} State of
                          Location
                        </label>
                        <Select
                          className="border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="State of Location"
                          classNamePrefix="select"
                          options={states?.map((state) => ({
                            value: state.id,
                            label: state.name,
                          }))}
                          isSearchable={false}
                          required
                          name="state"
                          id="state"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="tel" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} RTC Phone
                          Number
                        </label>
                        <input
                          type="tel"
                          id="tel"
                          name="phoneNumber"
                          placeholder="RTC Phone Number"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col">
        <PaginatedDataTable
          title="List of Training Centers"
          exports={{ excel: true, messagingList: true }}
          columns={[
            { Header: "Center Name", accessor: "name" },
            { Header: "Center Email", accessor: "email" },
            { Header: "Center Phone Number", accessor: "phoneNumber" },
            { Header: "Center Address", accessor: "address" },
            { Header: "Center State", accessor: "state" },
          ]}
          data={trainingCenters?.data}
          count={trainingCenters?.count}
          onRowClick={(row) => {
            navigate(`/admin/manage/rtc/${row.uuid}`);
          }}
          exportToExcel={exportRTCS}
        />
      </div>
    </PageBuilder>
  );
};

export default TrainingCenters;
