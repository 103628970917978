import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";
import TraineeCheckAccountStatus from "../registration-components/CheckAccountStatus";
import "./ViewCourse.css";
import axios from "axios";
import NavMobile from "../Layouts/NavMobile";

const TraineeViewCourse = () => {
  const [loading, setLoading] = useState(false);
  const [selectedCat, setselectedCat] = useState(0);
  const [response, setResponse] = useState(null);
  const [rtc, setRtc] = useState("");
  const token = localStorage.getItem("token");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chosenDate, setChosenDate] = useState([]);
  const [calenderUuid, setCalenderUuid] = useState("");
  const [getCourse, setGetCourse] = useState([]);
  const [getCalendar, setGetCalendar] = useState([]);
  const [getRtc, setGetRtc] = useState([]);
  const [duration, setDuration] = useState();
  const [closeDate, setCloseDate] = useState("");
  const { id } = useParams();

  function findObjectByDate(array, date) {
    for (let i = 0; i < array.length; i++) {
      if (new Date(array[i].startDate).getTime() === date.getTime()) {
        return array[i];
      }
    }
    return null;
  }
  const calculateDuration = (e) => {
    const dateStr = e.target.value;
    const dates = dateStr.split("|");
    const date1 = new Date(dates[0]);
    const date2 = new Date(dates[1]);
    setCalenderUuid(dates[2]);
    setStartDate(new Date(date1).toLocaleDateString("en-US"));
    setEndDate(new Date(date2).toLocaleDateString("en-US"));
    const Difference_In_Time =
      new Date(date2).getTime() - new Date(date1).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setDuration(Difference_In_Days + 1 + " Days");
    const searchDate = new Date(date1);
    const result = findObjectByDate(getCalendar, searchDate);
    setCloseDate(new Date(result.payCloseDate).toLocaleDateString("en-US"));
  };

  const getCourseDetails = () => {
    setLoading(true);
    fetch(urls.api + "courses")
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setGetCourse(json.data.courses.filter((x) => x.courseUuid === id));
          // alert(id)
          // alert(json.data.courses.filter(x => x.courseUuid == courseId))
          //setGetCourse(json.data.courses)
          fetch(urls.api + "courses/" + id)
            .then((response) => response.json())
            .then((json) => {
              const getCourse = json.data;
              setGetCourse(getCourse);
              const getCalendar = json.data.calendars;
              setGetCalendar(getCalendar);
              const getRtc = json.data.regionalTrainingCenters;
              setGetRtc(getRtc);
            });
        } else {
          //alert("An error occured!")
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const showCourseCat = (x) => {
    setApplyClicked(false);
    setselectedCat(x);
    if (x == 2) window.location.href = urls.e_portal;
  };

  const [applyClicked, setApplyClicked] = useState(false);
  const handle = (e) => {
    setRtc(e.target.value);
    console.log(rtc);
  };
  const applyForProgrmme = (courseId) => {
    setLoading(true);
    setResponse(null);

    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        applicableUuid: courseId,
        sponsorship: "self",
        applicableType: "course",
        rtcUuid: rtc ? rtc : "",
        payCloseDate: new Date(closeDate),
        calendarUuid: calenderUuid,
      }),
    };

    fetch(urls.api + "application/create", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setResponse(json.message);
        }
        if (json.success === true) {
          setApplyClicked(true);
          //setResponse(json.message)
        } else {
          setResponse(json.message);
        }
      })
      .catch((error) => {
        setResponse(ErrorMessages.UnkownRetry);
      })
      .finally(() => setLoading(false));
  };
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const successMsg = () => {
    return (
      <SweetAlert
        success
        title={
          <span>
            Application sent!
            <br />
            <small>Your application will be reviewed before approval</small>
          </span>
        }
        onConfirm={() => setApplyClicked(false)}
      />
    );
  };

  const errorMsg = () => {
    return (
      <SweetAlert
        error
        title={response}
        onConfirm={() => {
          setApplyClicked(false);
          setResponse(null);
        }}
      />
    );
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  return (
    <div class="content container overflow-x-auto">
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="Course Details" />

          <TraineeCheckAccountStatus _token={token} />

          <div style={{ display: loading ? `block` : `none` }}>
            <Spinner />
          </div>

          <div style={{ display: !loading ? `block` : `none` }}>
            <div class="row">
              {/* {getCourse.map(x =>  */}
              <div class="col-xl-12 col-sm-12 col-12">
                <Link to={"#"}>
                  <div class="card hover:shadow-lg shadow-cyan-500/50  pr-5">
                    <div class="card-body-d w-100">
                      <div class="">
                        <div className="row">
                          <div class=" col-12 text-center mb-3">
                            <div
                              class="w-100"
                              style={{ width: " 250px", height: "300px" }}
                            >
                              <img
                                className="avatar-img rounded w-100 mt-3 "
                                src={getCourse.image}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="mb-5">
                              <b>
                                <h6 class="text-muted text-center">
                                  {getCourse.title}
                                </h6>
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>

                      <table className="table text-xs sm:text-base ">
                        <tbody>
                          {/* <tr>
                <th>Starts</th>
                <th className="text-right">Duration</th>
                </tr> */}
                          <tr>
                            <td>Starts</td>
                            <th className=" r-w-small text-right">
                              {/* {getCalendar.map((value, startDate) => {
                                return (
                                  <span key={startDate}>
                                    {value.startDate} {" | "}
                                  </span>
                                );
                              })} */}
                              {/* <p>
                                {new Date(startDate).toLocaleDateString(
                                  "en-US",
                                  DATE_OPTIONS
                                )}
                                {"|"}
                                {new Date(endDate).toLocaleDateString(
                                  "en-US",
                                  DATE_OPTIONS
                                )}
                              </p> */}
                              <select
                                id="date"
                                class="form-control "
                                onChange={(e) => {
                                  calculateDuration(e);
                                }}
                              >
                                <option>Select</option>
                                {getCalendar.length ? (
                                  getCalendar.map((each) => {
                                    return (
                                      <>
                                        {" "}
                                        <option
                                          value={
                                            new Date(
                                              each.startDate
                                            ).toLocaleDateString(
                                              "en-US",
                                              DATE_OPTIONS
                                            ) +
                                            " | " +
                                            new Date(
                                              each.endDate
                                            ).toLocaleDateString(
                                              "en-US",
                                              DATE_OPTIONS
                                            ) +
                                            " | " +
                                            each.uuid
                                          }
                                        >
                                          {new Date(
                                            each.startDate
                                          ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                          ) +
                                            " - " +
                                            new Date(
                                              each.endDate
                                            ).toLocaleDateString(
                                              "en-US",
                                              DATE_OPTIONS
                                            )}
                                        </option>
                                        ;
                                      </>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </select>
                            </th>
                          </tr>
                          <tr>
                            <td>Duration</td>
                            <th className="text-right r-w-small  f-right">
                              <span>{duration}</span>
                            </th>
                          </tr>
                          <tr>
                            <td>Payment Close Date</td>
                            <th className="text-right">
                              <span>
                                {closeDate
                                  ? new Date(closeDate).toLocaleDateString(
                                      "en-US",
                                      DATE_OPTIONS
                                    )
                                  : ""}
                              </span>
                            </th>
                          </tr>
                          <tr>
                            <td>Course Type</td>
                            <th className="text-right">
                              {getCourse.courseType}
                            </th>
                          </tr>
                          <tr>
                            <td>Description</td>
                            <th className="text-right">
                              {getCourse.description}
                            </th>
                          </tr>
                          <tr>
                            <td>Regional Training Centers </td>
                            <th className="text-right">
                              <select
                                id="rtc"
                                className="form-control f-right "
                                onChange={(e) => {
                                  handle(e);
                                }}
                              >
                                <option>Select</option>
                                {getRtc.map((value, index) => {
                                  return (
                                    <option value={value.uuid} key={index}>
                                      {value.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </th>
                          </tr>
                          <tr>
                            <td>Cost</td>
                            <th className="text-right">{getCourse.cost}</th>
                          </tr>
                          <tr>
                            <td>Min. Deposit</td>
                            <th className="text-right">
                              {getCourse.minDeposit}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                      <div className="text-right">
                        <button
                          onClick={() => applyForProgrmme(getCourse.courseUuid)}
                          className="btn btn-sm btn-primary"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              {/* )}	 */}
            </div>
          </div>

          <div className="col">
            {applyClicked ? successMsg() : ""}

            {response !== null ? errorMsg() : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeViewCourse;
