import React from "react";
import { Outlet } from "react-router-dom";
import "../../output.css";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import AuthProvider from "../../providers/AuthProvider";
import DataProvider from "../../providers/DataProvider";

const PageWrapper = () => {
  return (
    <AuthProvider>
      <div className="flex w-screen max-w-screen h-full text-black bg-slate-100">
        <DataProvider>
          <div className="flex w-screen h-full">
            <div className="flex flex-col w-0 xl:w-2/12 h-full">
              <SideBar />
            </div>
            <div className="flex flex-col w-screen xl:w-10/12">
              <NavBar />
              <Outlet />
            </div>
          </div>
        </DataProvider>
      </div>
    </AuthProvider>
  );
};

export default PageWrapper;
