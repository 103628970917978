import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import urls from "../../utilities/urls";
import { useDispatch, useSelector } from "react-redux";
import { getDetails, reset } from "../../../features/details/detailsSlice";
import PageTitle from "../Layouts/PageTitle";

const TrainerEducationalQualifications = () => {
  const dispatch = useDispatch();
  const { details, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.details
  );

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  //education  Qualificatiom

  const [level, setLevel] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [institutionName, setInstitutionName] = useState(null);
  const [qualificationObtained, setQualificationObtained] = useState(null);
  const [document, setDocument] = useState(null);

  const [editOrAdd, setEditOrAdd] = useState(null);
  const [foundError, setFoundError] = useState(false);

  const [editProfile, setEditProfile] = useState(false);
  const [education, setEducation] = useState([]);

  const EditBtn = () => {
    setEditOrAdd("Edit");
    setEditProfile(true);
  };
  const AddBtn = () => {
    setEditOrAdd("Add New");
    setEditProfile(true);
  };

  const successMsg = () => {
    return (
      <SweetAlert
        success
        title={alertMessage}
        onConfirm={() => {
          setFoundError(false);
          setAlertMessage(null);
          setEditProfile(false);
        }}
      />
    );
  };

  const errorMsg = () => {
    return (
      <SweetAlert
        error
        title={alertMessage}
        onConfirm={() => {
          setFoundError(false);
          setAlertMessage(null);
        }}
      />
    );
  };

  console.log(details);

  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    if (isSuccess) {
      console.log(details.data.user.education[0]);
      setEducation(details.data.user.education);
    }
    console.log(details);
    dispatch(getDetails());
    return () => {
      reset();
    };
  }, [dispatch, isSuccess]);

  const updateEduBtn = () => {
    setFoundError(false);
    setAlertMessage(null);
    setLoading(true);
    // Create an object of formData
    const formData = new FormData();
    formData.append("document", document);
    formData.append("level", level);
    formData.append("startDate", startDate);
    formData.append("institutionName", institutionName);
    formData.append("qualificationObtained", qualificationObtained);
    formData.append("endDate", endDate);

    // Update the formData object
    console.log(FormData);

    const postOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + token,
      },
      body: formData,
    };
    console.log(postOptions.body);

    fetch(urls.api + "profile/educational-level-update", postOptions)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.success === true) {
          setAlertMessage(json.message);
          setFoundError(false);
        } else if (json.message !== null) {
          setAlertMessage(json.message);
          setFoundError(true);
        } else {
          // setAlertMessage(ErrorMessages.UnkownRetry);
          setFoundError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div class="content container-fluid">
      <PageTitle title="Educational Qualifications" />

      <div class="card">
        <div class="card-body">
          <div style={{ display: !editProfile ? `block` : `none` }}>
            <div className="row">
              <div
                style={{ display: education == "" ? `block` : `none` }}
                className="col-12"
              >
                <h6>No record found</h6>
              </div>

              <div
                style={{ display: education != "" ? `block` : `none` }}
                className="table-responsive"
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Institution</th>
                      <th>Qualification</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {education.map((x) => (
                      <tr>
                        <td>{x.level}</td>
                        <td>{x.startDate}</td>
                        <td>{x.endDate}</td>
                        <td>{x.institutionName}</td>
                        <td>{x.qualificationObtained}</td>
                        <td>
                          {" "}
                          <span
                            onClick={() => EditBtn()}
                            className="text-info cursor-pointer"
                          >
                            Edit
                          </span>{" "}
                          &nbsp;{" "}
                          <span className="text-danger cursor-pointer">
                            Delete
                          </span>{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="col-md-12">
                <button
                  onClick={() => AddBtn()}
                  className="btn btn-block btn-success"
                >
                  Add New <i className="fa fa-plus-square"></i>{" "}
                </button>
              </div>
            </div>
          </div>

          {/* Profile edit start */}
          <div style={{ display: editProfile ? `block` : `none` }}>
            <h4>{editOrAdd}</h4>
            <div className="row">
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label>Qualification Type</label>
                <select
                  className="form-control"
                  onChange={(e) => setLevel(e.target.value)}
                >
                  <option value={""}>Select</option>
                  <option value={"primary"}>Primary</option>
                  <option value={"secondary"}>Secondary</option>
                  <option value={"tertiary"}>Tertiary</option>
                </select>
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label>Start Date</label>
                <input
                  className="form-control"
                  type={"date"}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label>End Date</label>
                <input
                  className="form-control"
                  type={"date"}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label>Name of Institution</label>
                <input
                  className="form-control"
                  onChange={(e) => setInstitutionName(e.target.value)}
                />
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label>Qualification Obtained</label>
                <input
                  className="form-control"
                  onChange={(e) => setQualificationObtained(e.target.value)}
                />
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label>Upload Certificate</label>
                <input
                  className="form-control"
                  type={"file"}
                  accept="image/*,.pdf"
                  onChange={(e) => setDocument(e.target.files[0])}
                />
              </div>

              <div className="col-md-12">
                <button
                  className="btn btn-block btn-success"
                  onClick={() => {
                    updateEduBtn();
                  }}
                >
                  Update <i className="fa fa-check"></i>{" "}
                </button>
              </div>
            </div>
          </div>
          {/* Profile edit end */}
        </div>
      </div>

      <div className="col">
        {!foundError && alertMessage !== null ? successMsg() : ""}

        {foundError && alertMessage !== null ? errorMsg() : ""}
      </div>
    </div>
  );
};

export default TrainerEducationalQualifications;
