import { useEffect, useState } from "react";
import Alert from "../../utilities/Alert";
import ErrorMessages from "../../utilities/ErrorMessages";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../../trainer-dashboard/Layouts/PageTitle";

const TrainerCheckAccountStatus = (props) => {
  const [foundError, setFoundError] = useState(false);

  const [token, setToken] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props._token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        /* if(json.success === false) {
                    alert(json.message)
                    }
                                        
                else if(json.success === true) {
                    window.location.href= urls.home
                }
                else if(json.message !== null) {
                    alert(json.message)
                }
                else{
					alert(ErrorMessages.UnkownRetry)
                }*/
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  return <></>;
};

export default TrainerCheckAccountStatus;
