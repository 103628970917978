import React from "react";
import PageBuilder from "../../components/layout/PageBuilder";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay, add } from "date-fns";
import { useState } from "react";
import { useEffect } from "react";
import { useClient } from "../../providers/AuthProvider";
import { enUS } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { flashMessage } from "../../helpers/flash";

const TrainingCalendar = () => {
  const [events, setEvents] = useState([]);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: add(new Date(), { months: 1 }),
  });

  const client = useClient();
  const navigate = useNavigate();

  useEffect(() => {
    client("/api/v1/calendar/course-calendar", {
      method: "POST",
      data: range,
    })
      .then((res) => {
        setEvents(res.data);
        console.log(res);
      })
      .catch((err) => {
        flashMessage({
          message:
            "Unable to retrieve events from the server. Please try again later",
          type: "error",
        });
      });
  }, [range, client, setEvents]);

  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  return (
    <PageBuilder name="Training Calendar">
      <div className="flex flex-col">
        <Calendar
          startAccessor="startDate"
          endAccessor="endDate"
          titleAccessor="courseTitle"
          resourceIdAccessor="uuid"
          popup
          showMultiDayTimes
          localizer={localizer}
          events={events}
          style={{ height: 750 }}
          step={60}
          views={["month", "week", "day"]}
          onRangeChange={(newRange) =>
            setRange({ startDate: newRange.start, endDate: newRange.end })
          }
          onSelectEvent={(event) => {
            navigate(
              `/admin/manage/training/courses/details/${event.courseUuid}`
            );
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default TrainingCalendar;
