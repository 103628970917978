import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import PageBuilder from "../../components/layout/PageBuilder";
import { AsyncData, useData } from "../../providers/DataProvider";

const Dashboard = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const {
    data: { stats },
    loaders: { getStats },
  } = useData();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      getStats();
      setLoaded(true);
    }
  }, [loaded, getStats]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: ["Pending", "Processing"],
    datasets: [
      {
        data: [
          stats?.tickets?.status?.pending ?? 0,
          stats?.tickets?.status?.processing ?? 0,
        ],
        borderColor: ["rgba(227, 160, 8, 1)", "rgba(118, 169, 250, 1)"],
        backgroundColor: ["rgba(227, 160, 8, 0.2)", "rgba(118, 169, 250, 0.2)"],
        borderWidth: 1,
      },
    ],
  };

  const priority = {
    labels: ["Low", "Medium", "High"],
    datasets: [
      {
        data: [
          stats?.tickets?.priority?.low ?? 0,
          stats?.tickets?.priority?.medium ?? 0,
          stats?.tickets?.priority?.high ?? 0,
        ],
        borderColor: [
          "rgba(118, 169, 250, 1)",
          "rgba(227, 160, 8, 1)",
          "rgba(253, 242, 242, 1)",
        ],
        backgroundColor: [
          "rgba(118, 169, 250, 0.2)",
          "rgba(227, 160, 8, 0.2)",
          "rgba(253, 242, 242, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <PageBuilder name="Support">
      <div className="flex flex-col w-full h-full py-2">
        <div className="flex flex-col w-full px-2 py-2 mb-10">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
            Support Ticket Summary
          </span>
          <div className="flex justify-center sm:justify-start w-full flex-wrap">
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-gray-400 rounded-md bg-gray-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-gray-400">Total Support Tickets</span>
              <span className="text-black text-4xl mt-3">
                <AsyncData>{stats?.tickets?.total}</AsyncData>
              </span>
            </div>
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-red-400 rounded-md bg-red-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-red-500">Unassigned Requests</span>
              <span className="text-red-500 text-4xl mt-3">
                <AsyncData>{stats?.tickets?.status?.unassigned ?? 0}</AsyncData>
              </span>
            </div>
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-yellow-400 rounded-md bg-yellow-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-yellow-400">Pending Requests</span>
              <span className="text-black text-4xl mt-3">
                <AsyncData>{stats?.tickets?.status?.pending ?? 0}</AsyncData>
              </span>
            </div>
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-blue-400 rounded-md bg-blue-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-blue-400">Processing Requests</span>
              <span className="text-black text-4xl mt-3">
                <AsyncData>{stats?.tickets?.status?.processing ?? 0}</AsyncData>
              </span>
            </div>
            <div className="flex flex-col border-[1.5px] !min-w-[240px] border-green-400 rounded-md bg-green-50 py-3 pl-4 m-2 sm:mt-3 sm:mr-10">
              <span className="text-green-500">Resolved Requests</span>
              <span className="text-black text-4xl mt-3">
                <AsyncData>{stats?.tickets?.status?.resolved ?? 0}</AsyncData>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full">
          <div className="flex flex-col w-full sm:w-1/2 px-2 py-2 mb-3">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
              Pending vs. Processing
            </span>
            <div className="flex mt-3 border rounded-sm p-3">
              <Bar options={options} data={data} />
            </div>
          </div>
          <div className="flex flex-col w-full sm:w-1/2 px-2 py-2 mb-3">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600">
              Priority Distribution
            </span>
            <div className="flex mt-3 border rounded-sm p-3">
              <Bar options={options} data={priority} />
            </div>
          </div>
        </div>
      </div>
    </PageBuilder>
  );
};

export default Dashboard;
