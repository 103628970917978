import React, { useEffect, useState } from "react";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { compareAsc, formatDistance, formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";

const TrainingAdverts = () => {
  const {
    data: { courses },
    loaders: { getCourses },
    pagination: {
      courses: { updatePage },
    },
  } = useData();

  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      getCourses();
      setLoaded(true);
    }
  }, [loaded, getCourses]);

  return (
    <PageBuilder
      name="Advertised Courses"
      extras={
        <div className="flex flex-col w-full h-full py-4 md:flex-row">
          <div className="flex">
            {/* <button className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between">
              <TextAlignJustify weight="bold" className="w-5 h-5 mr-2" />
              <span className="ml-1 2xl:hidden">Summary</span>
              <span className="hidden ml-1 2xl:flex">Advert Summary</span>
            </button>
            <button className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between">
              <ArrowCircleDown weight="bold" className="w-5 h-5 mr-2" />
              <span className="ml-1 2xl:hidden">Merge</span>
              <span className="hidden ml-1 2xl:flex">Merge Trainings</span>
            </button>
          </div>
          <div className="flex">
            <button className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between">
              <ChartBar weight="bold" className="w-5 h-5 mr-2" />
              <span className="ml-1 2xl:hidden">Monitor</span>
              <span className="hidden ml-1 2xl:flex">Monitor Advert</span>
            </button> */}
            {/* <button className="flex items-center btn btn-secondary">
              <Plus weight="bold" className="w-5 h-5 mr-2" />
              <span className="ml-1 2xl:hidden">Create</span>
              <span className="hidden ml-1 2xl:flex">Create New Advert</span>
            </button> */}
          </div>
        </div>
      }
    >
      <div className="flex flex-col w-full p-4">
        {/* <div className="flex">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Search Adverts
            </span>

            <div className="flex flex-col">
              <span className="text-black">
                Search by Advert Code or Course Title
              </span>
            </div>
          </div>
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Filter Adverts
            </span>
            <div className="flex w-full">
              <div className="flex flex-col w-full">
                <span className="text-black">Fiscal Year</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
            </div>
            <div className="flex w-full mt-4">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Publication Status</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black">Application Window</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <button type="submit" className="mx-2 my-4 btn btn-primary">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <PaginatedDataTable
          title="List of Training Adverts"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            { Header: "Advert Code", accessor: "courseCode" },
            { Header: "Course Title", accessor: "title" },
            { Header: "Cost(₦)", accessor: "cost" },
            {
              Header: "Training Start Date",
              accessor: (row) => {
                const sorted = row.calendars.sort((a, b) =>
                  compareAsc(new Date(a.startDate), new Date(b.startDate))
                );

                const earliest = sorted[0];
                return earliest.startDate;
              },
            },
            {
              Header: "Duration",
              accessor: (row) => {
                const sorted = row.calendars.sort((a, b) =>
                  compareAsc(new Date(a.startDate), new Date(b.startDate))
                );

                const earliest = sorted[0];
                const latest = sorted[sorted.length - 1];

                return formatDistance(
                  new Date(latest.endDate),
                  new Date(earliest.startDate)
                );
              },
            },
            {
              Header: "Status",
              accessor: "status",
              Filter: SelectColumnFilter,
            },
            {
              Header: "Payment Deadline",
              accessor: "payCloseDate",
            },
            {
              Header: "Days Left",
              accessor: (row) => {
                const deadline = new Date(row.payCloseDate);
                return compareAsc(new Date(), deadline) === -1
                  ? formatDistanceToNow(deadline)
                  : "N/A";
              },
            },
            { Header: "Created At", accessor: "createdAt" },
            { Header: "Updated At", accessor: "updatedAt" },
          ]}
          data={courses?.data}
          count={courses?.count}
          currentPage={courses?.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(
              `/admin/manage/training/courses/details/${row.courseUuid}`
            );
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default TrainingAdverts;
