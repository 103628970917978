import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NumberToNaira from "../../utilities/numberToNaira";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";
import TraineeCheckAccountStatus from "../registration-components/CheckAccountStatus";
import "./course.css";

const TraineeViewCourse = () => {
  const [loading, setLoading] = useState(false);
  const [selectedCat, setselectedCat] = useState(0);
  const [response, setResponse] = useState(null);

  const token = localStorage.getItem("token");

  const [getCourse, setGetCourse] = useState([]);
  const [getCalendar, setGetCalendar] = useState([]);
  const [getRtc, setGetRtc] = useState([]);
  const [screen, setScreen] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [rtc, setRtc] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chosenDate, setChosenDate] = useState([]);
  const [input, setInput] = useState(1);
  const [duration, setDuration] = useState();
  const [closeDate, setCloseDate] = useState("");
  const [csvFile, setCsvFile] = useState("");

  const { id } = useParams();
  function findObjectByDate(array, date) {
    for (let i = 0; i < array.length; i++) {
      if (new Date(array[i].startDate).getTime() === date.getTime()) {
        return array[i];
      }
    }
    return null;
  }
  const calculateDuration = (e) => {
    const dateStr = e.target.value;
    const dates = dateStr.split("-");
    const date1 = new Date(dates[0]);
    const date2 = new Date(dates[1]);
    setStartDate(new Date(date1).toLocaleDateString("en-US"));
    setEndDate(new Date(date2).toLocaleDateString("en-US"));
    const Difference_In_Time =
      new Date(date2).getTime() - new Date(date1).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setDuration(Difference_In_Days + 1 + " Days");
    const searchDate = new Date(date1);
    const result = findObjectByDate(getCalendar, searchDate);
    setCloseDate(new Date(result.payCloseDate).toLocaleDateString("en-US"));
  };
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const handleScreen = () => {
    setScreen((prevState) => ({
      screen: prevState.screen + 1,
    }));
  };

  const handleNewInput = () => {
    setInput((prevState) => prevState + 1);
  };
  const handle = (e) => {
    setRtc(e.target.value);
    console.log(rtc);
  };

  const getCourseDetails = () => {
    setLoading(true);
    fetch(urls.api + "courses")
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setGetCourse(json.data.courses.filter((x) => x.courseUuid === id));
          // alert(id)
          // alert(json.data.courses.filter(x => x.courseUuid == courseId))
          //setGetCourse(json.data.courses)
          fetch(urls.api + "courses/" + id)
            .then((response) => response.json())
            .then((json) => {
              const getCourse = json.data;
              setGetCourse(getCourse);

              const getCalendar = json.data.calendars;
              setGetCalendar(getCalendar);

              const getRtc = json.data.regionalTrainingCenters;
              setGetRtc(getRtc);
            });
        } else {
          //alert("An error occured!")
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const showCourseCat = (x) => {
    setApplyClicked(false);
    setselectedCat(x);
    if (x == 2) window.location.href = urls.e_portal;
  };

  const [applyClicked, setApplyClicked] = useState(false);

  const applyForProgrmme = (courseId) => {
    setLoading(true);
    setResponse(null);

    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        courseUuid: courseId,
        sponsorship: "self",
      }),
    };

    fetch(urls.api + "application/create", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setResponse(json.message);
        }
        if (json.success === true) {
          setApplyClicked(true);
          //setResponse(json.message)
        } else {
          setResponse(json.message);
        }
      })
      .catch((error) => {
        setResponse(ErrorMessages.UnkownRetry);
      })
      .finally(() => setLoading(false));
  };

  const successMsg = () => {
    return (
      <SweetAlert
        success
        title={
          <span>
            Application sent!
            <br />
            <small>Your application will be reviewed before approval</small>
          </span>
        }
        onConfirm={() => setApplyClicked(false)}
      />
    );
  };

  const errorMsg = () => {
    return (
      <SweetAlert
        error
        title={response}
        onConfirm={() => {
          setApplyClicked(false);
          setResponse(null);
        }}
      />
    );
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  return (
    <div class="content container-fluid @media (max-width: 575.98px) { w-100 } w-50">
      <PageTitle title="Course Details" />

      <TraineeCheckAccountStatus _token={token} />

      <div style={{ display: loading ? `block` : `none` }}>
        <Spinner />
      </div>

      <div style={{ display: !loading ? `block` : `none` }}>
        <div class="row">
          {/* {getCourse.map(x =>  */}
          <div class="col-xl-12 col-sm-12 col-12">
            {/* <Link to={"#"}> */}
            <div class="card hover:shadow-lg shadow-cyan-500/50">
              <div class="card-body">
                <div class="">
                  <div className="row">
                    <div class=" col-12 text-center mb-3">
                      <div class="w-100">
                        <img
                          className="avatar-img rounded-3xl w-100 object-cover "
                          src={getCourse.image}
                          style={{ height: "12rem" }}
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-5">
                        <b>
                          <h6 class="text-muted text-center">
                            {getCourse.title}
                          </h6>
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
                {screen === 1 ? (
                  <>
                    <table className="table text-xs sm:text-base">
                      <tbody>
                        {/* <tr>
                <th>Starts</th>
                <th className="text-right">Duration</th>
                </tr> */}
                        <tr>
                          <td>Starts</td>
                          <th className="r-w-small text-right">
                            <select
                              id="date"
                              class=" r-w-small form-control"
                              style={{ width: "500px", float: "right" }}
                              onChange={(e) => {
                                calculateDuration(e);
                              }}
                            >
                              <option>Select</option>
                              {getCalendar.length ? (
                                getCalendar.map((each) => {
                                  return (
                                    <>
                                      {" "}
                                      <option
                                        value={
                                          new Date(
                                            each.startDate
                                          ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                          ) +
                                          " - " +
                                          new Date(
                                            each.endDate
                                          ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                          )
                                        }
                                      >
                                        {new Date(
                                          each.startDate
                                        ).toLocaleDateString(
                                          "en-US",
                                          DATE_OPTIONS
                                        ) +
                                          " - " +
                                          new Date(
                                            each.endDate
                                          ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                          )}
                                      </option>
                                      ;
                                    </>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </select>
                          </th>
                        </tr>
                        <tr>
                          <td>Duration</td>
                          <th className="text-right">
                            <span>{duration}</span>
                          </th>
                        </tr>
                        <tr>
                          <td>Payment Close Date</td>
                          <th className="text-right">
                            <span>
                              {closeDate
                                ? new Date(closeDate).toLocaleDateString(
                                    "en-US",
                                    DATE_OPTIONS
                                  )
                                : ""}
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <td>Course Type</td>
                          <th className="text-right">{getCourse.courseType}</th>
                        </tr>
                        <tr>
                          <td>Description</td>
                          <th className="text-right w-50 text-m text-secondary">
                            {getCourse.description}
                          </th>
                        </tr>
                        <tr>
                          <td>Regional Training Centers </td>
                          <th className="text-right text-primary">
                            {getRtc.length != 0 ? (
                              <>
                                {getRtc.map((value, index) => {
                                  return (
                                    <a href="https://naptinportal.com/training-centres">
                                      <span key={index}>
                                        {value.name} {"|"}{" "}
                                      </span>
                                    </a>
                                  );
                                })}
                              </>
                            ) : (
                              <p>None</p>
                            )}
                          </th>
                        </tr>
                        <tr>
                          <td>Cost</td>
                          <th className="text-right">{getCourse.cost}</th>
                        </tr>
                        <tr>
                          <td>Min. Deposit</td>
                          <th className="text-right">{getCourse.minDeposit}</th>
                        </tr>
                      </tbody>
                    </table>
                    <div className="text-right"></div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* </Link> */}
          </div>
          {/* )}	 */}
        </div>
      </div>

      <div className="col">
        {applyClicked ? successMsg() : ""}

        {response !== null ? errorMsg() : ""}
      </div>
    </div>
  );
};

export default TraineeViewCourse;
