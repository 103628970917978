import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import urls from "../../utilities/urls";
import NavMobile from "../Layouts/NavMobile";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";

const TraineeProfessionalCertificates = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [certificationName, setCertificationName] = useState("");
  const [awardingBody, setAwardingBody] = useState("");
  const [type, setType] = useState("");
  const [awardDate, setAwardDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [document, setDocument] = useState("");

  const [editOrAdd, setEditOrAdd] = useState(null);
  const [foundError, setFoundError] = useState(false);

  const [editProfile, setEditProfile] = useState(false);
  const [professionalCerts, setProfessionalCerts] = useState([]);

  const EditBtn = () => {
    setEditOrAdd("Edit");
    setEditProfile(true);
  };
  const AddBtn = () => {
    setEditOrAdd("Add New");
    setEditProfile(true);
  };
  const getProfile = async () => {
    await axios
      .get(urls.api + "profile/my-profile", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        setProfessionalCerts(result.data.data.user.professionalCerts);
        console.log(result.data.data.user.professionalCerts);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const successMsg = () => {
    return (
      <SweetAlert
        success
        title={alertMessage}
        onConfirm={() => {
          setFoundError(false);
          setAlertMessage(null);
          setEditProfile(false);
        }}
      />
    );
  };

  const errorMsg = () => {
    return (
      <SweetAlert
        error
        title={alertMessage}
        onConfirm={() => {
          setFoundError(false);
          setAlertMessage(null);
        }}
      />
    );
  };

  const getUserProfile = () => {
    setFoundError(false);
    setAlertMessage(null);
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "profile/my-profile", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          //setProfile(json.data)
          let pro = json.data.user.professionalCerts;
          //setEducation(edu)
          console.log(json.data.user);
          setProfessionalCerts(pro);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const updateCert = () => {
    setFoundError(false);
    setAlertMessage(null);
    setLoading(true);
    // Create an object of formData
    const formData = new FormData();
    formData.append("document", document);
    formData.append("certificationName", certificationName);
    formData.append("awardDate", awardDate);
    formData.append("awardingBody", awardingBody);
    formData.append("type", type);
    formData.append("expiryDate", expiryDate);

    // Update the formData object
    console.log(FormData);

    const postOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + token,
      },
      body: formData,
    };
    console.log(postOptions.body);

    fetch(urls.api + "profile/professional-certificate-update", postOptions)
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.success === true) {
          setAlertMessage(json.message);
          setFoundError(false);
        } else if (json.message !== null) {
          setAlertMessage(json.message);
          setFoundError(true);
        } else {
          // setAlertMessage(ErrorMessages.UnkownRetry);
          setFoundError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getProfile();
    // alert("Hello world")
  }, []);

  return (
    <div class="navMenu">
      <div className="t">
        <NavMobile />
      </div>
      <div className="w">
        <PageTitle title="Professional Certificates" />

        <div class="card">
          <div class="card-body">
            <div style={{ display: !editProfile ? `block` : `none` }}>
              <div className="row">
                <div
                  style={{
                    display: professionalCerts == "" ? `block` : `none`,
                  }}
                  className="col-12"
                >
                  <h6>No record found</h6>
                </div>

                <div
                  style={{
                    display: professionalCerts != "" ? `block` : `none`,
                  }}
                  className="table-responsive"
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Certification Name</th>
                        <th>Awarding Body</th>
                        <th>Date Awarded</th>
                        <th>Date Expiring</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {professionalCerts.map((x) => (
                        <tr>
                          <td>{x.type}</td>
                          <td>{x.certificationName}</td>
                          <td>{x.awardingBody}</td>
                          <td>{x.awardDate}</td>
                          <td>{x.expiryDate}</td>
                          <td>
                            {" "}
                            <span
                              onClick={() => EditBtn()}
                              className="text-info"
                            >
                              Edit
                            </span>{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="col-md-12">
                  <button
                    onClick={() => AddBtn()}
                    className="btn btn-block btn-success"
                  >
                    Add New <i className="fa fa-plus-square"></i>{" "}
                  </button>
                </div>
              </div>
            </div>

            {/* Profile edit start */}
            <div style={{ display: editProfile ? `block` : `none` }}>
              <h4>{editOrAdd}</h4>
              <div className="row">
                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                  <span>*</span> <label>Certification Name</label>
                  <input
                    className="form-control"
                    type={"text"}
                    onChange={(e) => setCertificationName(e.target.value)}
                  />
                </div>

                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                  <span>*</span> <label>Awarding Body</label>
                  <input
                    className="form-control"
                    type={"text"}
                    onChange={(e) => setAwardingBody(e.target.value)}
                  />
                </div>

                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                  <span>*</span> <label>Type</label>
                  <select
                    className="form-control"
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value={"professional"}>Professional</option>
                    <option value={"vocational"}>Vocational</option>
                  </select>
                </div>

                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                  <span>*</span> <label>Date Awarded</label>
                  <input
                    className="form-control"
                    type={"date"}
                    onChange={(e) => setAwardDate(e.target.value)}
                  />
                </div>

                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                  <span>*</span> <label>Expiring Date</label>
                  <input
                    className="form-control"
                    type={"date"}
                    onChange={(e) => setExpiryDate(e.target.value)}
                  />
                </div>

                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                  <span>*</span> <label>Upload Certificate</label>
                  <input
                    className="form-control"
                    type={"file"}
                    onChange={(e) => setDocument(e.target.files[0])}
                  />
                </div>

                <div className="col-md-12">
                  <button
                    className="btn btn-block btn-success"
                    onClick={updateCert}
                  >
                    Update <i className="fa fa-check"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
            {/* Profile edit end */}
          </div>
        </div>

        <div className="col">
          {!foundError && alertMessage !== null ? successMsg() : ""}

          {foundError && alertMessage !== null ? errorMsg() : ""}
        </div>
      </div>
    </div>
  );
};

export default TraineeProfessionalCertificates;
