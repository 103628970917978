import { Plus, XCircle } from "phosphor-react";
import React from "react";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";

const AdmissionLetterTemplates = () => {
  return (
    <PageBuilder
      name={"Manage Admission Letter Templates"}
      extras={
        <div className="flex w-full h-full py-4">
          <button className="btn btn-secondary mx-0 flex items-center">
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Add New Template</span>
          </button>
        </div>
      }
    >
      <div className="flex w-full pt-3">
        <PaginatedDataTable
          title="List of Admission Letter Templates"
          refresh
          columns={[
            { Header: "Title", accessor: "applicationUuid" },
            { Header: "Letter Stage", accessor: "firstName" },
            { Header: "Sponsorship Category", accessor: "surname" },
            { Header: "Last Modified", accessor: "updatedAt" },
          ]}
          data={[]}
        />
      </div>
    </PageBuilder>
  );
};

export default AdmissionLetterTemplates;
