import { WarningCircle } from "phosphor-react";
import React, { useEffect } from "react";
import { useState } from "react";
import Creatable from "react-select/creatable";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { useData } from "../../providers/DataProvider";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EmailConsole = () => {
  const {
    data: { messagingList },
  } = useData();

  const [content, setContent] = useState(EditorState.createEmpty());
  const [contentProcessed, setContentProcessed] = useState(null);

  const defaultList = () => {
    const distinct = new Set(messagingList?.map((x) => x.value));
    return Array.from(distinct).map((x) =>
      messagingList?.find((y) => y.value === x)
    );
  };

  const [recipients, setRecipients] = useState(defaultList());

  useEffect(() => {
    const m = draftToHtml(convertToRaw(content.getCurrentContent()));
    const defaults = draftToHtml(
      convertToRaw(EditorState.createEmpty().getCurrentContent())
    );

    if (m === defaults) {
      setContentProcessed(null);
    } else {
      setContentProcessed(m);
    }
  }, [content]);

  const client = useClient();

  const sendEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;
    const data = {
      email: recipients.map((x) => x.value),
      subject: form.subject.value,
      message: contentProcessed,
    };

    client("/api/v1/email/send", {
      method: "POST",
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  return (
    <PageBuilder name={"Email Console"}>
      <div className="flex w-full h-screen justify-center pt-10">
        <form className="flex flex-col bg-white md:w-9/12" onSubmit={sendEmail}>
          <div className="flex justify-between items-center px-5">
            <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
              Send Bulk Email
            </h1>
            <div className="flex">
              <button
                type="button"
                className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                // onClick={() => setModalShow(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-secondary mx-0 flex items-center"
              >
                <span>Send</span>
              </button>
            </div>
          </div>
          <div className="flex items-center w-full px-5">
            <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
            <span>
              All fields in asterisk ({<span className="text-red-600">*</span>})
              are required.
            </span>
          </div>
          <div className="flex flex-col px-5 bg-white my-4 w-auto">
            <div className="flex w-full mb-4">
              <div className="flex flex-col min-w-[24rem] w-full">
                <label htmlFor="recipients" className="text-left mb-1">
                  {<span className="text-red-600">*</span>} Recipient List
                </label>
                <Creatable
                  className="border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  placeholder="Select Recipients"
                  classNamePrefix="select"
                  defaultValue={() => defaultList()}
                  options={messagingList}
                  isSearchable={true}
                  id="recipients"
                  required
                  name="recipients"
                  value={recipients}
                  isMulti
                  onChange={(value) => setRecipients(value)}
                />
              </div>
            </div>
            <div className="flex w-full mb-4">
              <div className="flex flex-col w-full">
                <label htmlFor="message" className="text-left mb-1">
                  {<span className="text-red-600">*</span>}
                  Subject
                </label>
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  placeholder="Subject"
                  className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  required
                ></input>
              </div>
            </div>
            <div className="flex w-full mb-4">
              <div className="flex flex-col w-full">
                <label htmlFor="message" className="text-left mb-1">
                  {<span className="text-red-600">*</span>}
                  Message
                </label>
                <div className="flex flex-col w-full">
                  <Editor
                    editorState={content}
                    editorClassName="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                    // wrapperClassName="mb-5"
                    onEditorStateChange={(editorState) => {
                      setContent(editorState);
                    }}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <input
                    type="text"
                    className="hidden mt-5"
                    name="content"
                    required
                    value={contentProcessed}
                    onChange={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </PageBuilder>
  );
};

export default EmailConsole;
