import React, { useContext, useCallback, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import client from "../helpers/client";
import { logout } from "../redux/features/authSlice";
import { flashMessage } from "../helpers/flash";
import Unauthorized from "../pages/utility/Unauthorized";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { isAuthenticated, user, userToken } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const canViewPage = () => {
    const { pathname } = location;
    const pages = [
      {
        permission: "trainee company management",
        url: "/admin/manage/sponsor/companies",
      },
      {
        permission: "trainee company management",
        url: "/admin/manage/sponsor/details/:id",
      },
      {
        permission: "trainee company management",
        url: "/admin/manage/sponsor/reports",
      },
      {
        permission: "trainee company management",
        url: "/admin/manage/sponsor/group-trainings",
      },
      {
        permission: "support and helpdesk management",
        url: "/admin/manage/support/dashboard",
      },
      {
        permission: "support and helpdesk management",
        url: "/admin/manage/support/faqs",
      },
      {
        permission: "support and helpdesk management",
        url: "/admin/manage/support/faqs/details/:id",
      },
      {
        permission: "support and helpdesk management",
        url: "/admin/manage/support/payment",
      },
      {
        permission: "support and helpdesk management",
        url: "/admin/manage/support/tickets",
      },
      {
        permission: "support and helpdesk management",
        url: "/admin/manage/support/tickets/details/:id",
      },
      {
        permission: "support and helpdesk management",
        url: "/admin/manage/support/testimonials",
      },
      {
        permission: "trainee management",
        url: "/admin/manage/trainee/dashboard",
      },
      {
        permission: "trainee management",
        url: "/admin/manage/trainee/prospective",
      },
      {
        permission: "trainee management",
        url: "/admin/manage/trainee/confirmed",
      },
      {
        permission: "trainee management",
        url: "/admin/manage/trainee/shortlisted",
      },
      { permission: "trainee management", url: "/admin/manage/trainee/alumni" },
      {
        permission: "trainee management",
        url: "/admin/manage/trainee/applicants/details/:id",
      },
      {
        permission: "trainee management",
        url: "/admin/manage/trainee/reports",
      },
      {
        permission: "training program management",
        url: "/admin/manage/training/adverts",
      },
      {
        permission: "training program management",
        url: "/admin/manage/training/configure/exams",
      },
      {
        permission: "training program management",
        url: "/admin/manage/training/admissions/templates",
      },
      {
        permission: "training program management",
        url: "/admin/manage/training/courses",
      },
      {
        permission: "training program management",
        url: "/admin/manage/training/courses/details/:id",
      },
      {
        permission: "training program management",
        url: "/admin/manage/training/suggestions",
      },
      {
        permission: "training program management",
        url: "/admin/manage/training/reports",
      },
      {
        permission: "trainer management",
        url: "/admin/manage/trainer/supervisors/prospectives",
      },
      {
        permission: "trainer management",
        url: "/admin/manage/trainer/supervisors/confirmed",
      },
      {
        permission: "trainer management",
        url: "/admin/manage/trainer/supervisors/details/:id",
      },
      {
        permission: "trainer management",
        url: "/admin/manage/trainer/supervisors/reports",
      },
      {
        permission: "training center management",
        url: "/admin/manage/rtc/postings",
      },
      {
        permission: "training center management",
        url: "/admin/manage/rtc/assessments",
      },
      {
        permission: "training center management",
        url: "/admin/manage/rtc/locations",
      },
      {
        permission: "training center management",
        url: "/admin/manage/rtc/all",
      },
      {
        permission: "training center management",
        url: "/admin/manage/rtc/:id",
      },
      {
        permission: "training center management",
        url: "/admin/manage/rtc/reports",
      },
      {
        permission: "training center management",
        url: "/admin/manage/rtc/users",
      },
      {
        permission: "training center management",
        url: "/admin/manage/institution/announcements",
      },
      {
        permission: "training center management",
        url: "/admin/manage/institution/announcement/:id",
      },
      {
        permission: "training center management",
        url: "/admin/manage/institution/calendar",
      },
      {
        permission: "training center management",
        url: "/admin/manage/institution/forums",
      },
      {
        permission: "finance management",
        url: "/admin/manage/payment/types",
      },
      {
        permission: "finance management",
        url: "/admin/manage/payment/records",
      },
      {
        permission: "messaging management",
        url: "/admin/manage/messaging/emails",
      },
      {
        permission: "messaging management",
        url: "/admin/manage/messaging/smses",
      },
      {
        permission: "any",
        url: "/admin/manage/account/password",
      },
      {
        permission: "any",
        url: "/admin/dashboard",
      },
    ];

    const filterPage = (page, path) => {
      const p = path.split("/");
      return path.includes(":id")
        ? page.url.includes(p.slice(0, p.length - 2).join("/"))
        : page.url === path;
    };

    const urlObj = pages.find((page) => filterPage(page, pathname));
    return (
      user.roles?.includes("super admin") ||
      (user.roles?.includes("admin") &&
        (urlObj?.permission === "any" ||
          user.permissions?.includes(urlObj?.permission)))
    );
  };

  const permissionBasedRendering = (permission, content) => {
    if (permission === "super") {
      if (user.roles?.includes("super admin")) {
        return content;
      } else {
        return <></>;
      }
    } else {
      if (
        user.roles?.includes("super admin") ||
        (user.roles?.includes("admin") &&
          (permission === "any" || user.permissions?.includes(permission)))
      ) {
        return content;
      } else {
        return <></>;
      }
    }
  };

  const hasPermission = (permission) => {
    if (permission === "super") {
      return user.roles?.includes("super admin");
    } else if (permission === "any") {
      return true;
    } else {
      return (
        user.roles?.includes("super admin") ||
        user.permissions?.includes(permission)
      );
    }
  };

  const hasRole = (role) => {
    return user.roles?.includes(role);
  };

  const value = {
    user: user,
    userToken: userToken,
    canViewPage: canViewPage,
    hasPermission: hasPermission,
    hasRole: hasRole,
    permissionBasedRendering: permissionBasedRendering,
    logout: useCallback(() => {
      client("/api/v1/auth/logout", {
        method: "POST",
        token: user?.token,
        credentials: true,
      })
        .then((res) => {
          dispatch(logout());
          navigate("/admin/login", { replace: true });
          flashMessage({
            message: res.message || "You have been logged out successfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          flashMessage({
            message:
              "Something went wrong signing you in, please try again later.",
            type: "error",
          });
        });
    }, [dispatch, navigate, user?.token]),
  };

  if (isAuthenticated) {
    if (canViewPage()) {
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
    } else {
      return <Unauthorized />;
    }
  } else {
    return <Navigate to={"/admin/login"} state={{ from: location }} replace />;
  }
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export const useClient = () => {
  const { user } = useAuth();
  const token = user?.token;
  return useCallback(
    (endpoint, config) =>
      client(endpoint, { ...config, token, credentials: true }),
    [token]
  );
};

export const usePermissions = () => {
  const { user } = useAuth();
  return user?.permissions;
};

export const useRoles = () => {
  const { user } = useAuth();
  return user?.roles;
};

export default AuthProvider;
