const e_portal = "https://e-naptin.com/";
let home, traineeUrl, trainerUrl, api;

if (process.env.REACT_APP_ENVIRONMENT === "local") {
  api = "https://staging-api.naptinportal.com/api/v1/";

  home = "http://localhost:3001/";
  traineeUrl = "http://localhost:3000/trainee/";
  trainerUrl = "http://localhost:3000/trainer/";
} else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  api = "https://staging-api.naptinportal.com/api/v1/";
  trainerUrl = "https://staging-dashboard.naptinportal.com/trainer/";
  traineeUrl = "https://staging-dashboard.naptinportal.com/trainee/";
  home = "https://staging.naptinportal.com/";
} else {
  api = "https://api.naptinportal.com/api/v1/";
  trainerUrl = "https://dashboard.naptinportal.com/trainer/";
  traineeUrl = "https://dashboard.naptinportal.com/trainee/";
  home = "https://naptinportal.com/";
}

export default { api, home, e_portal, traineeUrl, trainerUrl, e_portal };
