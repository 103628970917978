import React from "react";
import PageBuilder from "../components/layout/PageBuilder";

const EmptyPage = () => {
  return (
    <PageBuilder name={"Empty Page"}>
      <div className="flex w-full h-screen">
        <h2>
          The content of this page has not been implemented please come back
          later!
        </h2>
      </div>
    </PageBuilder>
  );
};

export default EmptyPage;
