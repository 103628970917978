import React from "react";

const NotFound = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-indigo-500 bg-fixed bg-cover bg-bottom error-bg bg-container">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 offset-sm-2 text-gray-50 text-center -mt-52">
            <div className="relative ">
              <h1 className="relative text-9xl tracking-tighter-less text-shadow font-sans font-bold">
                <span>4</span>
                <span>0</span>
                <span>4</span>
              </h1>
              <span className="absolute top-0 -ml-12 -mt-2 text-gray-700 font-semibold text-4xl">
                Oops!
              </span>
            </div>
            <h5 className="text-gray-700 font-semibold ">Page not found</h5>
            <p className="text-gray-700 mt-2 mb-6">We can't seem to find the page you're looking for</p>
            <a
              className="bg-green-600 hover:text-green-200 px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-gray-50 rounded-full hover:shadow-lg"
              href="/admin/dashboard"
            >
              Go Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
