import React, { useEffect, useState } from "react";
import NavMobile from "../Layouts/NavMobile";
import PageTitle from "../Layouts/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./exam.css";
import urls from "../../utilities/urls";

function ViewStudentsForCourse() {
  const [check, setCheck] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [day_present, setDay_present] = useState(false);
  const [chosen, setChosen] = useState([]);
  const [getCourseId, setGetCourseId] = useState("");
  const { id } = useParams();
  const [date, setDate] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [studentsOfferingCourse, setStudentOfferingCourse] = useState([]);
  const [message, setMessge] = useState();
  let phoneNumbers = [];
  console.log(check);
  console.log(chosen);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const onHandle = (e) => {
    setMessge(e.target.value);
  };
  const sendBulkMessage = async (e) => {
    e.preventDefault();
    if (studentsOfferingCourse.length) {
      studentsOfferingCourse.map((student) => {
        phoneNumbers.push(student.phoneNumber);
      });
      await axios
        .post(urls.api + "sms/send", { phoneNumbers, message }, config)
        .then((result) => {
          toast.success("Sent Successfully");
        })
        .catch((error) => {
          toast.warning("Bulk Message not available");
        });
    }
  };
  const sendUnitMessage = async (phoneNumbers) => {
    if (message === "") {
      toast.warning("Invalid detail");
    } else {
      await axios
        .post(urls.api + "sms/send", { phoneNumbers, message }, config)
        .then((result) => {
          toast.success("Message Sent");
        })
        .catch((error) => {
          toast.warning("Unable to send Message");
        });
    }
  };
  const getTraineeOfferingCourse = async () => {
    await axios
      .get(urls.api + "courses/trainee/" + id, config)
      .then((result) => {
        console.log("This is a result data", result.data.data);
        setStudentOfferingCourse(result.data.data.users);
        console.log(studentsOfferingCourse);
      })
      .catch((error) => {
        console.log("an error has occured", error);
      });
  };

  const GettingStartAndEndDatePeriodCourse = async () => {
    await axios
      .get(urls.api + "courses/" + id)
      .then((result) => {
        console.log(result.data.data.calendars);
        const all = result.data.data.calendars;
        const getSomething = all.find((item) => item.startDate);
        const startDateToString = getSomething.startDate;
        setStartDate(startDateToString.toString());
        console.log(startDate);
        const getEndDate = all.find((item) => item.endDate);
        const gettingEndDateString = getEndDate.endDate;
        setEndDate(gettingEndDateString.toString());
        console.log(startDate);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setGetCourseId(id);
    console.log(getCourseId);
    getTraineeOfferingCourse();
    GettingStartAndEndDatePeriodCourse();
    const date = new Date();
    const converter = date.toString();
    setDate(converter);
  }, []);
  return (
    <div className="">
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <ToastContainer />

          <div
            className="exam overflow-x-auto"
            style={{
              marginLeft: "-15px",
              alignItems: "stretch",
              minHeight: "10%",
              maxHeight: "100%",
              width: "100%",
            }}
          >
            <div className="card" style={{ width: "100%" }}>
              <div style={{ overflowX: "auto", width: "100%" }}>
                <div class=" " style={{ width: "100%" }}>
                  <div class=" card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-5 col-lg-5">
                        <h5
                          class="card-title title my-2"
                          style={{ marginLeft: "-30px" }}
                        >
                          Registered Students
                        </h5>
                      </div>
                      <div className="col-sm-4 col-md-7 col-lg-7">
                        <h5 class="card-title title">
                          <form
                            onSubmit={(e) => {
                              sendBulkMessage(e);
                            }}
                          >
                            <div style={{ alignItem: "flex-center" }}>
                              <textarea
                                required
                                className="f-left bulk-area"
                                onChange={(e) => {
                                  onHandle(e);
                                }}
                                placeholder="Message"
                                id="messge"
                                style={{ borderRadius: "14px" }}
                              />
                              <input
                                className="btn btn-primary mx-3 f-right bulk-area-btn"
                                type="submit"
                                value="Send Bulk Message"
                              />
                            </div>
                          </form>
                        </h5>
                      </div>
                    </div>

                    <p>
                      {" "}
                      Date:
                      {date.slice(0, 10)}
                    </p>
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Gender</th>
                        </tr>
                      </thead>

                      {studentsOfferingCourse ? (
                        studentsOfferingCourse.map((student) => {
                          return (
                            <>
                              <tbody>
                                <tr key={student.id}>
                                  <td>
                                    {student.firstName + " "}
                                    {student.surname}
                                  </td>
                                  <td>{student.email}</td>
                                  <td>+{student.phoneNumber}</td>
                                  <td className="px-4">
                                    {student.gender === "m" ? "Male" : "Female"}
                                  </td>

                                  <td>
                                    <input
                                      className="btn btn-secondary mx-3"
                                      type="submit"
                                      value="Send Message"
                                      style={{ marginTop: "-5px" }}
                                      onClick={() => {
                                        sendUnitMessage(student.phoneNumber);
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <center>
                            <h2>No Trainee available for this course</h2>
                          </center>
                        </>
                      )}
                      <div className="edit">
                        {/* <button
                  class="btn btn-secondary"
                  onClick={() => console.log(day_present)}
                >
                  Submit
                </button> */}
                      </div>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewStudentsForCourse;
