import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DateTimeToDate from "../../utilities/DateTimeToDate";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import Spinner from "../../utilities/spinner";
import axios from "axios";

const Assignments = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState("");
  const [myCourses, setMyCourses] = useState([]);

  const coursesApplied = () => {
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "application/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          console.log(json.data.applications);
          setMyCourses(json.data.applications);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    coursesApplied();
  }, []);

  return (
    <div class="content container-fluid">
      <PageTitle title="Applications" />

      <div class="card">
        <div class="card-body">
          <div style={{ display: loading ? `block` : `none` }}>
            <Spinner />
          </div>
          <div
            style={{ display: !editProfile ? `block` : `none` }}
            className="row"
          >
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Course Title</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {myCourses?.map((x) =>
                    x.status === "confirmed" ? (
                      <>
                        <tr>
                          <td>{x.courseTitle}</td>
                          <td>
                            <Link
                              to={`/trainee/takeassignment/${x.courseUuid}`}
                            >
                              Take Assignment
                            </Link>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assignments;
