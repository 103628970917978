import { useEffect, useState } from "react";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";
import urls from "../../utilities/urls";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getDetails, reset } from "../../../features/details/detailsSlice";
import "react-toastify/dist/ReactToastify.css";

const TrainerAddCourses = () => {
  const dispatch = useDispatch();
  const { details, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.details
  );
  const [state, setState] = useState([]);
  const [image, setImage] = useState(null);
  const [courseCategories, setCourseCategories] = useState([]);
  const [courseCategories1, setCourseCategories1] = useState([]);
  const [courseTypes, setCourseTypes] = useState([" "]);
  const [rtcTypes, setRtcTypes] = useState([" "]);
  const [rtcTypes1, setRtcTypes1] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [closeDate, setCloseDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [status, setStatus] = useState("draft");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [trainerUuid, setTrainerUuid] = useState();

  // console.log(trainerUuid);

  const getProfile = async () => {
    await axios
      .get(urls.api + "profile/my-profile", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        setTrainerUuid(result.data.user.bio.uuid);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    dispatch(getDetails());
    return () => {
      reset();
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    (async () => {
      const allCategories = urls.api + "course-categories";
      axios.get(allCategories).then((result) => {
        const courseCategories = result.data.data;
        setCourseCategories(courseCategories);
      });

      const allTypes = urls.api + "course-types";
      axios.get(allTypes).then((result) => {
        const courseTypes = result.data.data;
        setCourseTypes(courseTypes);
      });

      const allRtc = urls.api + "regional-training-centers";
      axios.get(allRtc).then((result) => {
        const rtcTypes = result.data.data;
        setRtcTypes(rtcTypes);
      });
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const id = toast.loading("Loading...");

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    };

    const data = new FormData();

    courseCategories1.forEach((item) => {
      data.append("courseCategoriesUuid[]", item.value);
    });

    rtcTypes1.map((item) => {
      console.log(item.value);
      data.append("regionalTrainingCentersUuid[]", item.value);
    });
    data.append("calendars[*][startDate]", startDate);
    data.append("calendars[*][endDate]", endDate);
    data.append("calendars[*][payCloseDate]", closeDate);
    data.append("cost", form.cost.value * 100);
    data.append("courseTypeUuid", form.courseTypeUuid.value);
    data.append("title", form.title.value);
    data.append("description", form.description.value);
    data.append("noOfInstallments", form.noOfInstallments.value);
    data.append("minimumDeposit", form.minimumDeposit.value * 100);
    data.append("status", status);
    data.append("image", image);
    data.append("trainerUuid", trainerUuid);

    axios
      .post(urls.api + "courses", data, { headers })
      .then((result) => {
        // console.log(result)
        toast.update(id, {
          render: result.data.message,
          type: "success",
          isLoading: false,
          toastId: 1,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        // console.log(error)
        toast.update(id, {
          render: error.response.data.message,
          type: "error",
          isLoading: false,
          toastId: 2,
          autoClose: 5000,
        });
      });
  };

  return (
    <div class="content container-fluid">
      <ToastContainer pauseOnFocusLoss={false} />
      <form onSubmit={handleSubmit} id="form">
        <PageTitle title="Add Course" />

        <div class="card">
          <div class="card-body">
            <div className="row">
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Course Title</label>
                <input
                  className="form-control"
                  name="title"
                  value={state.title}
                  onChange={handleChange}
                ></input>
              </div>
              <div class="col-xl-6 col-md-6 col-12 mb-3">
                <label>Course Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={state.description}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Cost</label>
                <input
                  min={1}
                  type="number"
                  className="rounded border border-gray-300 form-control"
                  name="cost"
                  value={state.cost}
                  onChange={handleChange}
                ></input>
              </div>
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Course Categories Uuid</label>
                <Select
                  className="basic-multi-select border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  placeholder="Course Categories"
                  classNamePrefix="select"
                  options={courseCategories?.map((categories) => ({
                    value: categories.uuid,
                    label: categories.name,
                  }))}
                  isMulti
                  isSearchable={false}
                  id="type"
                  required
                  onChange={setCourseCategories1}
                  name="courseCategoriesUuid"
                />
              </div>
              <div className="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Start Date</label>
                <input
                  className="form-control"
                  name="status"
                  type={"date"}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></input>
              </div>
              <div className="col-xl-6 col-sm-6 col-12 mb-3">
                <label> End Date</label>
                <input
                  className="form-control"
                  name="status"
                  type={"date"}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></input>
              </div>
              <div className="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Pay Close Date</label>
                <input
                  className="form-control"
                  name="status"
                  type={"date"}
                  value={closeDate}
                  onChange={(e) => setCloseDate(e.target.value)}
                ></input>
              </div>
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Course Type Uuid</label>
                <br />
                <Select
                  className="border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  placeholder="Course Type"
                  classNamePrefix="select"
                  options={courseTypes?.map((type) => ({
                    value: type.uuid,
                    label: type.name,
                  }))}
                  isSearchable={false}
                  id="type"
                  required
                  name="courseTypeUuid"
                />
              </div>
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Image</label>
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  className="form-control"
                  name="image"
                  value={state.image}
                  onChange={(e) => setImage(e.target.files[0])}
                ></input>
              </div>
              {/* {document.querySelector('#noinstallements')} */}
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label> No Of Installments</label>
                <input
                  id="noinstallments"
                  min={1}
                  type="number"
                  className="rounded border border-gray-300 form-control"
                  name="noOfInstallments"
                  value={state.noOfInstallments}
                  onChange={handleChange}
                ></input>
              </div>
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Minimum Deposit</label>
                <input
                  id="noinstallments"
                  min={1}
                  type="number"
                  className="rounded border border-gray-300 form-control"
                  name="minimumDeposit"
                  value={state.minimumDeposit}
                  onChange={handleChange}
                ></input>
              </div>
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <label> Regional Training Centres</label>
                <Select
                  className="basic-multi-select border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                  placeholder="Regional Training Centres"
                  classNamePrefix="select"
                  options={rtcTypes?.map((rtc) => ({
                    value: rtc.uuid,
                    label: rtc.name,
                  }))}
                  isMulti
                  isSearchable={true}
                  id="type"
                  required
                  onChange={setRtcTypes1}
                  name="regionalTrainingCentersUuid"
                />
              </div>

              {/*  */}
              <input
                className="hidden"
                name="status"
                value={"draft"}
                onChange={handleChange}
              ></input>

              {/* <div class="col-xl-6 col-md-12 col-12">
    <button className="btn btn-secondary">Add More <i className="fa fa-plus-square"></i></button>
            </div> */}
              <div class="col-xl-12 col-md-12 col-12 text-right">
                <button className="btn btn-success" type="submit">
                  Submit <i className="fa fa-check"></i>{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TrainerAddCourses;
