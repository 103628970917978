                    const relationships = [
    {
        "id": 1,
        "name": "Brother"
    },
    {
        "id": 2,
        "name": "Sister"
    },
    {
        "id": 3,
        "name": "Wife"
    },
    {
        "id": 4,
        "name": "Husband"
    },
    {
        "id": 5,
        "name": "Cousin"
    },
    {
        "id": 6,
        "name": "Other"
    }
] 

export default  relationships