import { CalendarBlank, Plus, WarningCircle } from "phosphor-react";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { Modal } from "flowbite-react";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";
import { useNavigate } from "react-router-dom";

const FAQs = () => {
  const {
    data: { faqs },
    loaders: { getFAQs },
    pagination: {
      faqs: { updatePage },
    },
  } = useData();

  const navigate = useNavigate();
  const client = useClient();

  const [loaded, setLoaded] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (!loaded) {
      getFAQs();
      setLoaded(true);
    }
  }, [loaded, getFAQs]);

  const createFAQ = (e) => {
    setModalShow(false);
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const data = {
      faq_name: form.name.value,
      faq_content: form.content.value,
      published_status: "published",
    };

    client("/api/v1/faqs/create-new-faq", {
      method: "POST",
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  return (
    <PageBuilder
      name="FAQs"
      extras={
        <div className="flex flex-row w-full h-full py-4">
          <button
            type="button"
            className="btn btn-secondary mx-0 flex items-center"
            onClick={() => setModalShow(true)}
          >
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Create New FAQ</span>
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form className="flex flex-col bg-white" onSubmit={createFAQ}>
                  <div className="flex justify-between items-center px-5">
                    <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
                      New FAQ
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary mx-0 flex items-center"
                      >
                        <span>Create FAQ</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col px-5 bg-white my-4 w-auto">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="name" className="text-left mb-1">
                          {<span className="text-red-600">*</span>} FAQ Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="FAQ Name"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col w-full mr-16">
                        <label htmlFor="content" className="text-left mb-1">
                          {<span className="text-red-600">*</span>}
                          FAQ Content
                        </label>
                        <textarea
                          id="content"
                          name="content"
                          placeholder="FAQ Content"
                          className="border border-gray-100 rounded-lg placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-col">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
            Filter FAQs
          </span>
          <div className="flex w-full">
            <div className="flex flex-col mr-5">
              <span className="text-black">Start Date</span>
              <div className="flex items-center">
                <Datetime
                  inputProps={{
                    className:
                      "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                    placeholder: "01-02-2020",
                  }}
                />
                <CalendarBlank weight="bold" className="w-5 h-5 -ml-10 z-10" />
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-black">End Date</span>
              <div className="flex items-center">
                <Datetime
                  inputProps={{
                    className:
                      "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                    placeholder: "01-02-2020",
                  }}
                />
                <CalendarBlank weight="bold" className="w-5 h-5 -ml-10 z-10" />
              </div>
            </div>
            <div className="flex flex-col">
              <button type="submit" className="btn btn-primary mx-5 my-4">
                Apply
              </button>
            </div>
          </div>
        </div>
        <PaginatedDataTable
          title="List of FAQs"
          refresh
          columns={[
            { Header: "FAQ Name", accessor: "faq_name" },
            { Header: "FAQ Content", accessor: "faq_content" },
            { Header: "Published On", accessor: "published_date" },
          ]}
          data={faqs?.data}
          count={faqs?.count}
          currentPage={faqs?.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(`/admin/manage/support/faqs/details/${row.uuid}`);
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default FAQs;
