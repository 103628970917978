import React, { useEffect, useState } from "react";
import NavMobile from "../Layouts/NavMobile";
import PageTitle from "../Layouts/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import urls from "../../utilities/urls";

function Attendance() {
  const [check, setCheck] = useState(false);
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [day_present, setDay_present] = useState(false);
  const [chosen, setChosen] = useState([]);
  const [getCourseId, setGetCourseId] = useState('')
  const { id } = useParams();
  const [date, setDate] = useState('')
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [studentsOfferingCourse, setStudentOfferingCourse] = useState([])
  console.log(check);
  console.log(chosen);
      const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }

  const getTraineeOfferingCourse = async()=>{
    await axios.get(urls.api + 'courses/trainee/' + id,config)
    .then((result)=>{
      console.log("This is a result data",result.data.data)
        setStudentOfferingCourse(result.data.data.users)
        console.log(studentsOfferingCourse)
    }).catch((error)=>{
      console.log('an error has occured', error)

    })
  }
  const submitAttendance = async(uuid,name)=>{
    const formData = new FormData()
    formData.append("day_present",1)
    formData.append("session",`${startDate.slice(0,4)} / ${endDate.slice(0,4)}`)
    formData.append("courseUuid",id)
    formData.append("traineeUuid",uuid)
    await axios.post(urls.api + 'attendance/submit',formData, config)
    .then((result)=>{
      console.log('sending result of attendance',result.data.success)
       if(result.data.success === true){
          toast.success(`${name} Marked Present`);
          }
    }).catch((error)=>{
      toast.err('An error has occured')
      console.log('an error has occured',error)
    })
  }

  const GettingStartAndEndDatePeriodCourse = async()=>{
      await axios.get(urls.api + 'courses/'+ id)
      .then((result)=>{
        console.log(result.data.data.calendars)
        const all = result.data.data.calendars
        const getSomething =  all.find(item => item.startDate)
        const startDateToString = getSomething.startDate
      setStartDate(startDateToString.toString())
      console.log(startDate)
        const getEndDate =  all.find(item => item.endDate)
        const gettingEndDateString = getEndDate.endDate
      setEndDate(gettingEndDateString.toString())
      console.log(startDate)
      }).catch((error)=>{
        console.log(error)
        
      })
  }

  useEffect(()=>{
    setGetCourseId(id)
    console.log(getCourseId)
    getTraineeOfferingCourse()
    GettingStartAndEndDatePeriodCourse();
    const date = new Date();
 const converter = date.toString()
 setDate(converter)
  },[]);
  return (
    <div>
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="Attendance" />
          <ToastContainer />

          <div className="exam" style={{ minHeight: "10%", maxHeight: "100%" }}>
            <div class="card">
              <div class="card-body">
                      <h5 class="card-title title">
                       All Registered Students
                      
                       
                      </h5>
                       <p> Date:  
                        {
                       date.slice(0,10)
                       
                       }
                      </p>
              <table className="table">
                <thead>
                <tr>
                  <th >Student Name</th>
                  <th >Student Email</th>
                  <th >Attendance</th>
                </tr>
                </thead>
 
              { studentsOfferingCourse ? studentsOfferingCourse.map((student) => {
                return (
                  <>
                     <tbody>
                       <tr key={student.id}>
                          <td >{student.firstName}</td>
                          <td >{student.email}</td>
                          <td>
                            <button
                            style={{marginTop:'-5px'}}
                       onClick={()=>{submitAttendance(student.uuid,student.firstName)}}
                       className = "btn btn-primary"
                       >Present</button>
                          </td>
                        </tr>
                        </tbody>
                      
                   
                  </>
                );
              }):<>
              <center>
                  <h2>No Trainee available for this course</h2>
                </center></>}
              <div className="edit">
                {/* <button
                  class="btn btn-secondary"
                  onClick={() => console.log(day_present)}
                >
                  Submit
                </button> */}
              </div>
              </table>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
