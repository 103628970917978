import { useState, useEffect } from "react";
import urls from "../utilities/urls";
import axios from "axios";
const Benefactors = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [benefactor, setBenefactors] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const fetchApplications = async () => {
    axios
      .get(urls.api + "application/company-benefactors/", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        setBenefactors(result.data.data.benefactors);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoaded(true);
  };
  useEffect(() => {
    if (!loaded) {
      fetchApplications();
    }
  }, [benefactor]);
  return (
    <>
      <div class="card mx-4">
        <div class="card-body">
          <div
            style={{
              height: "600px",
              margin: "4px 4px",
              padding: "4px",
              overflowX: "hidden",
              overflowY: "auto",
              textAlign: "justify",
            }}
          >
            <div>
              <div className="row">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Course</th>
                        <th>Email </th>
                        <th>Phone</th>
                        <th>Bach Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      {benefactor.length ? (
                        benefactor.map((key) => {
                          return (
                            <>
                              <tr>
                                <td>{key.firstName + "  " + key.surname}</td>
                                <td>{key.course}</td>
                                <td>{key.email}</td>
                                <td>{key.phoneNumber}</td>
                                <td>{key.batchId}</td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ;
    </>
  );
};
export default Benefactors;
