import { CalendarBlank } from "phosphor-react";
import React from "react";
import Datetime from "react-datetime";
import Select from "react-select";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";

const PaymentSupport = () => {
  return (
    <PageBuilder name="Payment Support">
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full px-2 py-2">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Filter Payment Transactions
            </span>
            <div className="flex w-full">
              <div className="flex flex-col mr-5">
                <span className="text-black">Start Date</span>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "01-02-2020",
                    }}
                  />
                  <CalendarBlank
                    weight="bold"
                    className="z-10 w-5 h-5 -ml-10"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-black">End Date</span>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "01-02-2020",
                    }}
                  />
                  <CalendarBlank
                    weight="bold"
                    className="z-10 w-5 h-5 -ml-10"
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full mt-4">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Priority Level</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black">Status</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <button type="submit" className="mx-2 my-4 btn btn-primary">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <PaginatedDataTable
          title="List of Payment Transactions"
          refresh
          columns={[
            { Header: "Amount(₦)", accessor: "m.email" },
            { Header: "Payment Description", accessor: "e.email" },
            {
              Header: "Status",
              accessor: "ticketStatus",
              Filter: SelectColumnFilter,
            },
            { Header: "Sponsor", accessor: "user.email" },
            { Header: "Transaction Date", accessor: "createdAt" },
          ]}
          data={[]}
        />
      </div>
    </PageBuilder>
  );
};

export default PaymentSupport;
