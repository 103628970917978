import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";


const TrainerUpdatePassword = () =>{

    const [token, setToken] = useState(localStorage.getItem("token"));
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [foundError, setFoundError] = useState(false)

    const [currentPassword, setCurrentPassword] = useState(null)
    const [password, setPassword] = useState(null)
    const [password2, setPassword2] = useState(null)

    const successMsg = () =>{
        return (<SweetAlert 
            success
            title={alertMessage}
            onConfirm={() => {setFoundError(false); setAlertMessage(null);}}
            /> )
        }
        
        const errorMsg = () =>{
            return (<SweetAlert 
                error
                title={alertMessage}
                onConfirm={() => {setFoundError(false); setAlertMessage(null);}}
                />)
            }

    const updatePasswordBtn = () =>{
        setFoundError(false)
                        setAlertMessage(null)
                        setLoading(true)
                    
        const postOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': true,
                Authorization: `Bearer ${token}`              
            },
            body: JSON.stringify({
    currentPassword : currentPassword,
    newPassword : password,
    newPassword_confirmation : password2
})
        };

        fetch(urls.api + 'auth/change-password', postOptions)
            .then((response) => response.json())
            .then((json) => {
                if(json.success === true) {
                    setAlertMessage(json.message)
					setFoundError(false)
                }
                else if(json.message !== null) {
                    setAlertMessage(json.message)
					setFoundError(true)
                }
                else{
                    setAlertMessage(ErrorMessages.UnkownRetry)
					setFoundError(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false));
       // })
       
    }
	
    const [editProfile, setEditProfile] = useState(true);
		const EditBtn = () => {
		setEditProfile(true)
			}
		
				
    return ( <div class="content container-fluid">
        <PageTitle title="Update Password"/>
        
		
		<div class="card">
		<div class="card-body">

		{/* Profile edit start */}
		<div style={{display: editProfile ? `block` : `none`}}>
			<div className="row">
                        
            <div className='col-md-6'>
                        <div class="form-group">
                            <label for="password">Current Password:</label>
                            <input value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type="password" class="form-control" />
                        </div>
                        </div>

                        <div className='col-md-6'>
                        <div class="form-group">
                            <label for="password">New Password:</label>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" class="form-control" />
                        </div>
                        </div>

                        <div className='col-md-6'>
                        <div class="form-group">
                            <label for="password">Confirm New Password:</label>
                            <input value={password2} onChange={(e) => setPassword2(e.target.value)} type="password" class="form-control" />
                        </div>
                        </div>                       

                        <div className='col-md-12'>
                        <button onClick={() => updatePasswordBtn() } style={{display: loading? `none` : `block` }} className="btn btn-block btn-success">Update <i className="fa fa-check"></i> </button>
                        <button style={{display: loading? `block` : `none` }} className="btn btn-block btn-success"> <Spinner/> </button>
                         </div>
                     
					</div>
					</div>
					{/* Profile edit end */}

					

				</div>
				</div>
                <div className="col">
{!foundError && alertMessage !== null ? successMsg() : '' }

{foundError && alertMessage !== null? errorMsg() : '' }
</div>

        </div>  

    )
}

export default TrainerUpdatePassword;