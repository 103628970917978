import { useEffect, useState } from "react";
import ReactStars from "react-stars";
import urls from "../utilities/urls";
import NavMobile from "./Layouts/NavMobile";
import PageTitle from "./Layouts/PageTitle";
import { toast } from "react-toastify";
import TraineeCheckAccountStatus from "./registration-components/CheckAccountStatus";
import axios from "axios";

const TraineeFeedbackSurvey = () => {
  const [availableCourses, setAvailableCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [courseUuid, setCourseUuid] = useState("");
  const [courseRating, setCourseRating] = useState(0);
  const [feedback, setFeedback] = useState({
    courseUuid: "",
    comment: "",
  });
  const token = localStorage.getItem("token");

  //getting the item of all the form
  const onHandle = (e) => {
    const confirming_array = { ...feedback };
    confirming_array[e.target.id] = e.target.value;
    setFeedback(confirming_array);
    console.log(feedback);
  };

  //on submitting feedback form data to api
  const onSubmitHandle = async (e) => {
    // alert('testing')
    e.preventDefault();
    feedback.rating = courseRating;
    await axios
      .post(urls.api + "course-feed-back/new/", feedback, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        if (!result.success === "true") toast.error(result.message);
        toast.success(result.message);
        toast.success("feedback sent");
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(availableCourses);

  // const postFeedback = async () => {
  //   const formData = new FormData();

  //   formData.append("courseUuid", courseUuid);
  //   formData.append("courseRating", courseRating);
  //   formData.append("comment", comment);
  //   console.log(formData);

  //   const postOptions = {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       Accept: "application/json",
  //       "Access-Control-Allow-Credentials": true,
  //     },
  //     body: formData,
  //   };
  //   fetch(urls.api + "course-feed-back/new/", postOptions)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       if (json.success === true) {
  //         toast.success(json.message);
  //         toast.success("feedback sent");
  //         console.log(json);
  //       } else if (json.message !== null) {
  //         toast.error(json.message);
  //         console.log(json);
  //       } else {
  //         console.log("else statement");
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       console.log("done");
  //     });
  // };

  const coursesApplied = () => {
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "application/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          console.log(json.data.applications);
          setAvailableCourses(json.data.applications);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const [editProfile, setEditProfile] = useState(false);

  useEffect(() => {
    coursesApplied();
  }, []);

  return (
    <div class="navMenu">
      <div className="t">
        <NavMobile />
      </div>
      <div className="w">
        <PageTitle title="Feedback" />

        <TraineeCheckAccountStatus _token={token} />

        <div style={{ display: !editProfile ? `block` : `none` }}>
          <div class="row">
            <div class="col-lg-12 col-md-8">
              <div class="card">
                <div class="card-body">
                  To help improve our courses and serve you better kindly take 5
                  minutes to fill our training questionnaire
                </div>
              </div>
            </div>
          </div>

          <div class="row ">
            <div class="col-lg-12 col-md-8">
              <div class="card">
                <div class="card-body">
                  <div
                    className="mx-5"
                    style={{
                      height: "500px",
                      margin: "4px 4px",
                      padding: "4px",
                      overflowX: "hidden",
                      overflowY: "auto",
                      textAlign: "justify",
                    }}
                  >
                    <iframe
                      src="https://forms.gle/XpUPFBCsSs1aQPNi7"
                      width="740"
                      height="1736"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                    >
                      Loading…
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeFeedbackSurvey;
