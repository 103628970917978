import { flashMessage } from "./flash";

const client = async (
  endpoint,
  {
    method,
    data = undefined,
    json = true,
    res = "json",
    token,
    credentials,
    headers: customHeaders,
    ...customConfig
  } = {}
) => {
  const config = () => {
    if (json) {
      return {
        method: method,
        body: json ? JSON.stringify(data) : data,
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Credentials": credentials,
          ...customHeaders,
        },
        withCredentials: credentials,
        ...customConfig,
      };
    } else {
      return {
        method: method,
        body: json ? JSON.stringify(data) : data,
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
          Accept: "application/json",
          "Access-Control-Allow-Credentials": credentials,
          ...customHeaders,
        },
        withCredentials: credentials,
        ...customConfig,
      };
    }
  };
  if (res === "file") {
    return fetch(process.env.REACT_APP_API_BASE_URL + endpoint, config())
      .then((response) => {
        return response.blob();
      })
      .catch((err) => {
        console.log(err);
        if (err.message !== "Unexpected end of JSON input") {
          flashMessage({ message: err.message, type: "error" });
        }
      });
  } else {
    return fetch(process.env.REACT_APP_API_BASE_URL + endpoint, config())
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
        if (err.message !== "Unexpected end of JSON input") {
          flashMessage({ message: err.message, type: "error" });
        }
      });
  }
};

export default client;
