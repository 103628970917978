import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NumberToNaira from "../../utilities/numberToNaira";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";

const TrainerViewCourse = () => {
  document.title = "NAPTIN";

  const [loading, setLoading] = useState(false);
  const [getDetails, setGetDetails] = useState([]);
  const [getCalendar, setGetCalendar] = useState([]);
  const [getRtc, setGetRtc] = useState([]);

  const { id } = useParams();

  const getCourseDetails = () => {
    setLoading(true);
    fetch(urls.api + "courses/" + id)
      .then((response) => response.json())
      .then((json) => {
        const getDetails = json.data;
        setGetDetails(getDetails);

        const getCalendar = getDetails.calendars;
        setGetCalendar(getCalendar);

        const getRtc = getDetails.regionalTrainingCenters;
        setGetRtc(getRtc);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  return (
    <div class="content container-fluid ">
      <div style={{ display: loading ? `block` : `none` }}>
        <Spinner />
      </div>

      <div class="row">
        <div class="col-lg-8 col-12 mx-auto">
          <Link to={"#"}>
            <div class="card">
              <div class="card-body">
                <div class="">
                  <div className="row">
                    <div class="col-12 text-center">
                      <div
                        class="w-100"
                        style={{ width: " 250px", height: "300px" }}
                      >
                        <img
                          className="avatar-img rounded w-100 mt-3 "
                          src={getDetails.image}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="dash-widget-info">
                        <h6 class="text-success text-center py-5">
                          {getDetails.title}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <table className="table text-dark">
                  <tbody>
                    <tr>
                      <td>Starts</td>
                      <th className="text-right">
                        {getCalendar.map((value, startDate) => {
                          return (
                            <span key={startDate}>
                              {value.startDate} {" | "}
                            </span>
                          );
                        })}
                      </th>
                    </tr>
                    <tr>
                      <td>Duration</td>
                      <th className="text-right">
                        {getCalendar.map((value, index) => {
                          return (
                            <span key={index}>
                              {value.duration} weeks {"|"}{" "}
                            </span>
                          );
                        })}
                      </th>
                    </tr>
                    <tr>
                      <td>Course Type</td>
                      <th className="text-right">{getDetails.courseType}</th>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <th className="text-right">{getDetails.description}</th>
                    </tr>
                    <tr>
                      <td>Trainer</td>
                      <th className="text-right">
                        {getDetails.trainer != null ? (
                          getDetails.trainer.firstName +
                          " " +
                          getDetails.trainer.surname
                        ) : (
                          <p>None</p>
                        )}
                      </th>
                    </tr>
                    <tr>
                      <td>Cost</td>
                      <th className="text-right">{getDetails.cost}</th>
                    </tr>
                    <tr>
                      <td>Min. Deposit</td>
                      <th className="text-right">{getDetails.minDeposit}</th>
                    </tr>
                    <tr>
                      <td>Regional Training Centres</td>
                      <th className="text-right">
                        {getRtc.length != 0 ? (
                          <>
                            {getRtc.map((value, index) => {
                              return (
                                <span key={index}>
                                  {value.address} {"|"}{" "}
                                </span>
                              );
                            })}
                          </>
                        ) : (
                          <p>None</p>
                        )}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrainerViewCourse;
