import { Menu, Transition } from "@headlessui/react";
import { CaretDown, Door, List } from "phosphor-react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/images/naptinlogo.png";
import { capitalize } from "../../helpers/stringUtils";
import { useAuth } from "../../providers/AuthProvider";
import SideBar from "./SideBar";

const NavBar = () => {
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  return (
    <nav className="flex flex-row-reverse fixed z-50 w-[84%] md:w-auto justify-between px-8 md:relative xl:justify-start xl:p-0 h-16 bg-white mx-8 mb-4">
      <Menu as="div" className="">
        <Menu.Button className="flex w-fit xl:w-48 h-full bg-white py-3 font-medium text-black hover:bg-gray-100 focus:outline-none">
          <div>
            <img
              src={avatar}
              alt="User Profile"
              className="w-10 h-10 rounded-xl mx-3"
            />
          </div>
          <div className="hidden xl:flex flex-col text-start">
            <span className="text-sm">{`${user.surname} ${user.firstName}`}</span>
            <sub className="text-gray-400 mt-1">
              {capitalize({ word: user.roles[0], headsOnly: true })}
            </sub>
          </div>
          <CaretDown className="hidden xl:flex xl:m-auto" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-8 mt-2 w-48 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-secondary-700 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => navigate("/admin/manage/account/password")}
                  >
                    <Door className="mr-2 h-5 w-5" aria-hidden="true" />
                    Reset Password
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-secondary-700 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => logout()}
                  >
                    <Door className="mr-2 h-5 w-5" aria-hidden="true" />
                    Logout
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <Menu as="div" className="flex xl:hidden">
        <Menu.Button className="flex w-auto h-full bg-white py-3 font-medium text-black hover:bg-gray-100 focus:outline-none">
          <List className="my-2 w-5 h-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-[10000] left-8 top-20 mt-2 w-48 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-h-full h-full max-w-sm min-w-[15rem] sm:min-w-[22rem]">
            <div className="flex flex-col bg-white max-h-full min-h-full h-full overflow-y-clip max-w-sm min-w-[15rem] sm:min-w-[22rem]">
              <SideBar show />
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </nav>
  );
};

export default NavBar;
