import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import urls from "../../utilities/urls";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const initialState = {
  email: "",
  title: "",
  priority: "",
  description: "",
};

const TrainerTickets = () => {
  const [ticketCategoryList, setTicketCategoryList] = useState([
    { id: 1, cat: "Low" },
    { id: 2, cat: "Medium" },
    { id: 3, cat: "High" },
  ]);

  const [createNewTicket, setCreateNewTicket] = useState(false);
  const [ticket, setTicket] = useState([]);
  const createTicket = () => {
    setCreateNewTicket(true);
  };
  // console.log(createNewTicket);
  const token = localStorage.getItem("token");
  const readyTicket = async () => {
    await axios
      .get(urls.api + "support-ticket", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        console.log(result.data.data.supportTicket);
        setTicket(result.data.data.supportTicket);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [state, setState] = useState(initialState);

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  const submitTicket = (e) => {
    e.preventDefault();
    //  const id = toast.loading("Loading...");
    try {
      axios
        .post(urls.api + "support-ticket/create-new-support-ticket", state, {
          headers,
        })
        .then((result) => {
          console.log(result.data.success);
          if (result.data.success === true) {
            toast.success("Ticket Created Successfully");
          }
          // window.location = "/";
        });
    } catch (error) {
      toast.error("Unable to Create Tickect");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    readyTicket();
  });

  return (
    <div class="content container-fluid">
      <PageTitle title="Tickets" />
      <ToastContainer pauseOnFocusLoss={false} />
      <button onClick={() => createTicket()} className="btn btn-success mb-3">
        New Ticket <i className="fa fa-plus-square"></i>{" "}
      </button>

      <div class="card">
        <div class="card-body">
          <form
            style={{ display: createNewTicket ? `block` : `none` }}
            onSubmit={submitTicket}
          >
            <label>Email</label>
            <input
              className="form-control mb-3"
              name="email"
              onChange={handleChange}
              value={state.email}
              placeholder="Email"
              required
            />

            <label>Ticket Title</label>
            <input
              className="form-control mb-3"
              name="title"
              onChange={handleChange}
              value={state.title}
              placeholder="Subject"
              required
            />

            <label>Ticket Priority</label>
            <select
              className="form-control mb-3"
              name="priority"
              onChange={handleChange}
              value={state.priority}
              required
            >
              {ticketCategoryList.map((y) => (
                <option>{y.cat}</option>
              ))}
            </select>

            <label>Ticket Description</label>
            <textarea
              placeholder="Enter your feedback"
              name="description"
              onChange={handleChange}
              value={state.description}
              className="form-control mb-3"
              required
            ></textarea>
            <button className="btn btn-success">
              Create <i className="fa fa-check"></i>{" "}
            </button>
          </form>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div
            className="table-responsive"
            style={{
              height: "400px",
              margin: "4px 4px",
              padding: "4px",
              overflowX: "hidden",
              overflowY: "auto",
              textAlign: "justify",
              display: createNewTicket ? `none` : `block`,
            }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Title</th>
                  <th>Priority</th>
                  <th>Status</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {ticket.length ? (
                  ticket.map((key) => {
                    return (
                      <>
                        <tr>
                          <td>{key.email}</td>
                          <td>{key.title}</td>
                          <td>{key.priority}</td>
                          <td>{key.status}</td>
                          <td>{key.description}</td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerTickets;
