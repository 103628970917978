import {
  CalendarBlank,
  Download,
  Minus,
  Plus,
  Upload,
  WarningCircle,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { Modal } from "flowbite-react";
import { useData } from "../../providers/DataProvider";
import { useClient } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { flashMessage } from "../../helpers/flash";
import { compareAsc, format, formatDistance } from "date-fns";
import Datetime from "react-datetime";
import AsyncSelect from "react-select/async";
import Select, { createFilter } from "react-select";
import { triggerDownloadPipe } from "../../helpers";

const ManagePrograms = () => {
  const {
    data: { programs, trainingCenters },
    loaders: { getPrograms, getTrainingCenters },
  } = useData();

  const client = useClient();

  const [loaded, setLoaded] = useState(false);
  const [numInstallments, setNumInstallments] = useState(1);
  const [sessions, setSessions] = useState({
    1: { startDate: null, endDate: null, payCloseDate: null },
  });
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProgramCourse, setSelectedProgramCourse] = useState(null);
  const [selectedProgramSession, setSelectedProgramSession] = useState(null);
  const [resultsFile, setResultsFile] = useState(null);
  const [rtcs, setRtcs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      getPrograms();
      getTrainingCenters();
      setLoaded(true);
    }
  }, [loaded, getPrograms, getTrainingCenters]);

  const [modalShow, setModalShow] = useState(false);
  const [modal2Show, setModal2Show] = useState(false);
  const [modal3Show, setModal3Show] = useState(false);
  const createProgram = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;
    const data = new FormData();
    data.append("programName", form.name.value);
    data.append("cost", form.cost.value * 100);
    data.append("noOfInstallments", numInstallments);
    data.append(
      "minimumDeposit",
      form.minimumDeposit.value
        ? form.minimumDeposit.value * 100
        : form.minimumDeposit.value
    );

    if (form.minimumDeposit.value > form.cost.value) {
      flashMessage({
        message:
          "Minimum Deposit should be less than or equal to the cost of the program",
        type: "error",
      });
    }

    Object.keys(sessions).forEach((key, index) =>
      Object.keys(sessions[key]).forEach((sessionKey) =>
        data.append(
          `calendars[${index}][${sessionKey}]`,
          format(new Date(sessions[key][sessionKey]), "yyyy-MM-dd")
        )
      )
    );
    rtcs.forEach((rtc, index) => data.append(`rtcUuids[${index}]`, rtc.value));
    selectedCourses.forEach((key, index) =>
      data.append(`courseUuids[${index}]`, key.value)
    );

    client("/api/v1/program/create", {
      method: "POST",
      json: false,
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  const uploadProgramResults = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = new FormData();
    data.append("programUuid", selectedProgram.value);
    data.append("calendarUuid", selectedProgramSession.value);
    data.append("csvFile", resultsFile);

    client("/api/v1/results/program/upload", {
      method: "POST",
      json: false,
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        window.location.reload();
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  const generateUploadTemplate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = new FormData();
    data.append("programUuid", selectedProgram.value);
    data.append("courseUuid", selectedProgramCourse.value);
    data.append("sessionUuid", selectedProgramSession.value);

    client("/api/v1/results/program/download", {
      method: "POST",
      json: false,
      data: data,
      res: "file",
      credentials: true,
    })
      .then(triggerDownloadPipe("PROGRAM_RESULT_UPLOAD_TEMPLATE", "xlsx"))
      .then((res) => {
        if (res.success === true) {
          window.location.reload();
          flashMessage({ message: res.message, type: "success" });
        } else {
          flashMessage({ message: res.message, type: "error" });
        }
      });
  };

  const loadOptionsCourses = async (inputValue, callback) => {
    const { data } = await client(`/api/v1/search/course?q=${inputValue}`);

    const parsedCourses = data.map((course) => ({
      value: course.courseUuid,
      label: course.title,
    }));

    callback(parsedCourses);
  };

  return (
    <PageBuilder
      name={"Programs"}
      extras={
        <div className="flex w-full h-full gap-3 py-4">
          <button
            className="flex items-center mx-0 btn btn-secondary"
            onClick={() => setModalShow(true)}
          >
            <Plus weight="bold" className="w-5 h-5 mr-2" />
            <span>Add New Program</span>
          </button>
          <button
            className="flex items-center mx-0 btn btn-secondary"
            onClick={() => setModal2Show(true)}
          >
            <Upload weight="bold" className="w-5 h-5 mr-2" />
            <span>Upload Program Results</span>
          </button>
          <button
            className="flex items-center mx-0 btn btn-secondary"
            onClick={() => setModal3Show(true)}
          >
            <Download weight="bold" className="w-5 h-5 mr-2" />
            <span>Download Result Template</span>
          </button>
          <React.Fragment>
            <Modal
              show={modalShow}
              onClose={() => {
                setModalShow(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white max-h-[75vh] overflow-y-scroll"
                  onSubmit={createProgram}
                  id="createProgramForm"
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      New Program
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Create Program</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="title" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Program Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Program Name"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="title" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Program Cost
                          (₦)
                        </label>
                        <input
                          type="number"
                          id="cost"
                          name="cost"
                          placeholder="Program Cost"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label
                          htmlFor="numOfInstallments"
                          className="mb-1 text-left"
                        >
                          {<span className="text-red-600">*</span>} Number Of
                          Payment Installments
                        </label>
                        <input
                          type="number"
                          id="numOfInstallments"
                          name="numOfInstallments"
                          placeholder="Number of Installments"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          value={numInstallments}
                          onChange={(e) => setNumInstallments(e.target.value)}
                          min={1}
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label
                          htmlFor="minimumDeposit"
                          className="mb-1 text-left"
                        >
                          {numInstallments > 1 ? (
                            <span className="text-red-600">*</span>
                          ) : (
                            <></>
                          )}{" "}
                          Minimum Deposit (₦)
                        </label>
                        <input
                          type="number"
                          id="minimumDeposit"
                          name="minimumDeposit"
                          placeholder="Minimum Deposit"
                          className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          disabled={!(numInstallments > 1)}
                          required={numInstallments > 1}
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-4">
                      <div className="flex flex-col min-w-[24rem] mr-16">
                        <label htmlFor="rtcs" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Supported
                          Regional Training Centers
                        </label>
                        <Select
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Supported RTCs"
                          classNamePrefix="select"
                          options={trainingCenters?.data?.map((rtc) => ({
                            value: rtc.uuid,
                            label: rtc.name,
                          }))}
                          isSearchable={true}
                          isMulti={true}
                          onChange={(newValue) => setRtcs(newValue)}
                          id="rtcs"
                          required
                        />
                      </div>
                      <div className="flex flex-col min-w-[24rem]">
                        <label htmlFor="type" className="mb-1 text-left">
                          {<span className="text-red-600">*</span>} Program
                          Courses
                        </label>
                        <AsyncSelect
                          className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                          placeholder="Program Courses"
                          classNamePrefix="select"
                          isMulti={true}
                          isClearable
                          isSearchable
                          filterOption={createFilter({
                            ignoreAccents: false,
                          })}
                          defaultInputValue=""
                          defaultValue=""
                          cacheOptions
                          required
                          loadOptions={loadOptionsCourses}
                          defaultOptions
                          backspaceRemovesValue
                          onChange={(e) => {
                            if (e) {
                              setSelectedCourses(e);
                            } else {
                              setSelectedCourses([]);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <WarningCircle
                            weight="bold"
                            className="w-5 h-5 mr-2"
                          />
                          <span className="text-xl">Calendar Sessions</span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex">
                          <button
                            type="button"
                            className="p-0 px-2 btn btn-white focus:ring-2 focus:ring-primary-700 focus:ring-offset-2"
                            onClick={() => {
                              const prev = Object.keys(sessions).length;
                              setSessions({
                                ...sessions,
                                [prev + 1]: {
                                  startDate: null,
                                  endDate: null,
                                  payCloseDate: null,
                                },
                              });
                            }}
                          >
                            <Plus weight="bold" className="w-5 h-5" />
                          </button>
                          <button
                            type="button"
                            className="p-0 px-2 btn btn-white focus:ring-2 focus:ring-primary-700 focus:ring-offset-2"
                            onClick={() => {
                              const prev = Object.keys(sessions).length;
                              const now = Object.assign({ ...sessions });
                              delete now[prev];
                              setSessions(now);
                            }}
                            disabled={Object.keys(sessions).length === 1}
                          >
                            <Minus weight="bold" className="w-5 h-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {Object.keys(sessions).map((session, index) => (
                      <div className="flex flex-col mb-6" key={index}>
                        <h4 className="mb-1 text-lg text-left">
                          Session {index + 1}
                        </h4>
                        <div className="flex w-full mb-4">
                          <div className="flex flex-col min-w-[24rem] mr-16">
                            <label htmlFor="cost" className="mb-1 text-left">
                              {<span className="text-red-600">*</span>} Start
                              Date
                            </label>
                            <div className="flex items-center">
                              <Datetime
                                inputProps={{
                                  className:
                                    "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                  placeholder: "01/02/2020",
                                  id: "start_date",
                                  name: "start_date",
                                  required: true,
                                  autoComplete: "off",
                                }}
                                timeFormat={false}
                                onOpen={() => {
                                  const modal =
                                    document.getElementById(
                                      "createProgramForm"
                                    );
                                  modal.scrollTo({
                                    top: modal.scrollHeight,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                }}
                                onChange={(value) =>
                                  setSessions({
                                    ...sessions,
                                    [session]: {
                                      ...sessions[session],
                                      startDate: value.toISOString(),
                                    },
                                  })
                                }
                              />
                              <CalendarBlank
                                weight="bold"
                                className="z-10 w-5 h-5 -ml-10"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col min-w-[24rem]">
                            <label htmlFor="type" className="mb-1 text-left">
                              {<span className="text-red-600">*</span>} End Date
                            </label>
                            <div className="flex items-center">
                              <Datetime
                                inputProps={{
                                  className:
                                    "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                  placeholder: "01/02/2020",
                                  id: "end_date",
                                  name: "end_date",
                                  required: true,
                                  autoComplete: "off",
                                }}
                                timeFormat={false}
                                onOpen={() => {
                                  const modal =
                                    document.getElementById(
                                      "createProgramForm"
                                    );
                                  modal.scrollTo({
                                    top: modal.scrollHeight,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                }}
                                onChange={(value) =>
                                  setSessions({
                                    ...sessions,
                                    [session]: {
                                      ...sessions[session],
                                      endDate: value.toISOString(),
                                    },
                                  })
                                }
                              />
                              <CalendarBlank
                                weight="bold"
                                className="z-10 w-5 h-5 -ml-10"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label htmlFor="type" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} Pay Close
                            Date
                          </label>
                          <div className="flex items-center">
                            <Datetime
                              inputProps={{
                                className:
                                  "bg-gray-50 w-[24rem] min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                placeholder: "01/02/2020",
                                id: "pay_close_date",
                                name: "pay_close_date",
                                required: true,
                                autoComplete: "off",
                              }}
                              timeFormat={false}
                              onOpen={() => {
                                const modal =
                                  document.getElementById("createProgramForm");
                                modal.scrollTo({
                                  top: modal.scrollHeight,
                                  left: 0,
                                  behavior: "smooth",
                                });
                              }}
                              onChange={(value) =>
                                setSessions({
                                  ...sessions,
                                  [session]: {
                                    ...sessions[session],
                                    payCloseDate: value.toISOString(),
                                  },
                                })
                              }
                            />
                            <CalendarBlank
                              weight="bold"
                              className="z-10 w-5 h-5 -ml-10"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
          <React.Fragment>
            <Modal
              show={modal2Show}
              onClose={() => {
                setModal2Show(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white"
                  onSubmit={uploadProgramResults}
                  id="uploadProgramResultsForm"
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      Upload Results for Program
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModal2Show(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Upload</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex flex-col w-full mb-4">
                      <label htmlFor="start_date" className="mb-1 text-left">
                        {<span className="text-red-600">*</span>} Program
                      </label>
                      <Select
                        className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                        placeholder="Program Course"
                        classNamePrefix="select"
                        options={programs.map((program) => ({
                          value: program.uuid,
                          label: program.name,
                        }))}
                        isSearchable={true}
                        isMulti={false}
                        onChange={(newValue) => setSelectedProgram(newValue)}
                        id="course"
                        required
                      />
                    </div>
                    <div className="flex flex-col w-full mb-4">
                      <label htmlFor="start_date" className="mb-1 text-left">
                        {<span className="text-red-600">*</span>} Session
                      </label>
                      <Select
                        className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                        placeholder="Course Session"
                        classNamePrefix="select"
                        options={programs
                          .find(
                            (program) => program.uuid === selectedProgram?.value
                          )
                          ?.calendars?.map((calendar) => ({
                            value: calendar.uuid,
                            label: `${format(
                              new Date(calendar.startDate),
                              "MMM Mo, yyyy"
                            )} - ${format(
                              new Date(calendar.endDate),
                              "MMM Mo, yyyy"
                            )}`,
                          }))}
                        isSearchable={false}
                        isMulti={false}
                        onChange={(newValue) =>
                          setSelectedProgramSession(newValue)
                        }
                        id="rtcs"
                        required
                      />
                    </div>
                    <div className="flex flex-col w-full mb-4">
                      <label htmlFor="results" className="mb-1 text-left">
                        {<span className="text-red-600">*</span>} Results
                      </label>
                      <div className="flex">
                        <div className="relative w-full">
                          <input
                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            id="results"
                            name="results"
                            type="file"
                            accept=".csv"
                            required
                            onChange={(e) => setResultsFile(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
          <React.Fragment>
            <Modal
              show={modal3Show}
              onClose={() => {
                setModal3Show(false);
              }}
              size="5xl"
            >
              <Modal.Body>
                <form
                  className="flex flex-col bg-white"
                  onSubmit={generateUploadTemplate}
                  id="generateUploadTemplateForm"
                >
                  <div className="flex items-center justify-between px-5">
                    <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                      Generate Result Template for Program
                    </h1>
                    <div className="flex">
                      <button
                        type="button"
                        className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                        onClick={() => setModal3Show(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="flex items-center mx-0 btn btn-secondary"
                      >
                        <span>Generate</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center w-full px-5">
                    <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                    <span>
                      All fields in asterisk (
                      {<span className="text-red-600">*</span>}) are required.
                    </span>
                  </div>
                  <div className="flex flex-col w-auto px-5 my-4 bg-white">
                    <div className="flex flex-col w-full mb-4">
                      <label htmlFor="start_date" className="mb-1 text-left">
                        {<span className="text-red-600">*</span>} Program
                      </label>
                      <Select
                        className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                        placeholder="Program Course"
                        classNamePrefix="select"
                        options={programs.map((program) => ({
                          value: program.uuid,
                          label: program.name,
                        }))}
                        isSearchable={true}
                        isMulti={false}
                        onChange={(newValue) => setSelectedProgram(newValue)}
                        id="course"
                        required
                      />
                    </div>
                    <div className="flex flex-col w-full mb-4">
                      <label htmlFor="start_date" className="mb-1 text-left">
                        {<span className="text-red-600">*</span>} Course
                      </label>
                      <Select
                        className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                        placeholder="Program Course"
                        classNamePrefix="select"
                        options={programs
                          .find(
                            (program) => program.uuid === selectedProgram?.value
                          )
                          ?.courses?.map((course) => ({
                            value: course.courseUuid,
                            label: course.title,
                          }))}
                        isSearchable={true}
                        isMulti={false}
                        onChange={(newValue) =>
                          setSelectedProgramCourse(newValue)
                        }
                        id="course"
                        required
                      />
                    </div>
                    <div className="flex flex-col w-full mb-4">
                      <label htmlFor="start_date" className="mb-1 text-left">
                        {<span className="text-red-600">*</span>} Session
                      </label>
                      <Select
                        className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                        placeholder="Course Session"
                        classNamePrefix="select"
                        options={programs
                          .find(
                            (program) => program.uuid === selectedProgram?.value
                          )
                          ?.calendars?.map((calendar) => ({
                            value: calendar.uuid,
                            label: `${format(
                              new Date(calendar.startDate),
                              "MMM Mo, yyyy"
                            )} - ${format(
                              new Date(calendar.endDate),
                              "MMM Mo, yyyy"
                            )}`,
                          }))}
                        isSearchable={false}
                        isMulti={false}
                        onChange={(newValue) =>
                          setSelectedProgramSession(newValue)
                        }
                        id="rtcs"
                        required
                      />
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        </div>
      }
    >
      <div className="flex flex-col w-full pt-3 pl-2">
        {/* <div className="flex w-full">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Search Programs
            </span>
            <div className="flex flex-col">
              <span className="text-black">Search by Program Title or Code</span>
            </div>
          </div>
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Filter Programs
            </span>
            <div className="flex w-full">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Program Type</span>
                <Select
                  options={programMetaData?.types?.map((type) => ({
                    value: type.uuid,
                    label: type.name,
                  }))}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black">Program Category</span>
                <Select
                  options={programMetaData?.categories?.map((category) => ({
                    value: category.uuid,
                    label: category.name,
                  }))}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <button type="submit" className="mx-2 my-4 btn btn-primary">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <PaginatedDataTable
          title="List of Training Programs"
          exports={{ excel: false, messagingList: false }}
          refresh
          columns={[
            { Header: "Program Name", accessor: "name" },
            {
              Header: "Duration",
              accessor: (row) => {
                const sorted = row.calendars.sort((a, b) =>
                  compareAsc(new Date(a.startDate), new Date(b.startDate))
                );

                const earliest = sorted[0];
                const latest = sorted[sorted.length - 1];

                return formatDistance(
                  new Date(latest?.endDate ?? new Date()),
                  new Date(earliest?.startDate ?? new Date())
                );
              },
            },
            { Header: "Cost(₦)", accessor: "cost" },
            {
              Header: "Number of Payment Installments",
              accessor: "noOfInstallments",
            },
            { Header: "Minimum Deposit(₦)", accessor: "minimumDeposit" },
          ]}
          data={programs}
          count={programs?.length}
          onRowClick={(row) => {
            navigate(`/admin/manage/training/programs/details/${row.uuid}`);
          }}
          // multiSelectActions={(selectedFlatRows) => {
          //   return (
          //     <div className="flex items-center justify-end w-full h-full py-2">
          //       <button
          //         type="button"
          //         className="flex items-center justify-between mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
          //         onClick={() =>

          //           () => {}}
          //       >
          //         <Plus weight="bold" className="w-4 h-4" />
          //         <span className="ml-1 lg:hidden">Add</span>
          //         <span className="hidden ml-1 lg:flex">
          //           Add selected to program
          //         </span>
          //       </button>
          //     </div>
          //   );
          // }}
        />
      </div>
    </PageBuilder>
  );
};

export default ManagePrograms;
