import React, { useEffect, useState } from "react";
import Select from "react-select";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { useNavigate } from "react-router-dom";

const GroupTrainingApplications = () => {
  const {
    data: { groupApplications, sponsors },
    loaders: { getGroupApplications, getSponsors },
    pagination: {
      groupApplications: { updatePage },
    },
  } = useData();

  const [loaded, setLoaded] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!loaded) {
      getGroupApplications(companyId);
      getSponsors();
      setLoaded(true);
    }
  }, [loaded, getGroupApplications, getSponsors, companyId]);

  return (
    <PageBuilder name="Manage Group Training Applications">
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full px-2 py-2">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Filter Group Training Applications
            </span>
            <div className="flex w-full mt-4">
              {/* <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Advert Code</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div> */}
              <div className="flex flex-col">
                <span className="text-black">Organization</span>
                <Select
                  options={sponsors.data.map((sponsor) => ({
                    value: sponsor.uuid,
                    label: sponsor.name,
                  }))}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                  onChange={(newValue) => setCompanyId(newValue.value)}
                />
              </div>
              <div className="flex flex-col">
                <button
                  type="button"
                  className="mx-2 my-4 btn btn-primary"
                  onClick={() => getGroupApplications(companyId)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <PaginatedDataTable
          title="List of Group Training Applications"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            {
              Header: "Batch ID",
              accessor: "batchId",
              Filter: SelectColumnFilter,
            },
            { Header: "First Name", accessor: "firstName" },
            { Header: "Last Name", accessor: "surname" },
            { Header: "Phone Number", accessor: "phoneNumber" },
            { Header: "Email Address", accessor: "email" },
            {
              Header: "Course",
              accessor: "course",
              Filter: SelectColumnFilter,
            },
            {
              Header: "Application Status",
              accessor: "applicationStatus",
              Filter: SelectColumnFilter,
            },
          ]}
          data={groupApplications?.data}
          count={groupApplications?.count}
          currentPage={groupApplications?.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(`/admin/manage/trainee/applicants/details/${row.uuid}`);
          }}
        />
      </div>
    </PageBuilder>
  );
};

export default GroupTrainingApplications;
