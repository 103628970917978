import React from "react";
import Select from "react-select";
import PageBuilder from "../../components/layout/PageBuilder";
// import SearchBar from "../../components/SearchBar";

const TraineePostings = () => {
  return (
    <PageBuilder
      name={"Trainee Posting"}
      // extras={
      //   <div className="flex flex-row w-full h-full py-4">
      //     <SearchBar placeholder="Search by Advert Code or Course Title" />
      //     <button type="submit" className="mx-0 btn btn-secondary">
      //       Search
      //     </button>
      //   </div>
      // }
    >
      <div className="flex flex-col w-full p-4">
        <div className="flex">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mt-4 mb-2">
              Generic Filters
            </span>
            <div className="flex w-full mt-4">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Post Type</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black">Post Count</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
            </div>
            <div className="flex w-full mt-4">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Post Status</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <button className="my-6 btn btn-primary">
                  Filter Trainee Posting
                </button>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col w-1/2">
            <div className="flex flex-col mt-20">
              <span className="text-black">Admission Number Search</span>
              <SearchBar placeholder="Enter Admission Number" hasButton />
            </div>
            <div className="flex flex-col">
              <span className="text-black">Posting Code/Ref Number Search</span>
              <SearchBar placeholder="Enter Posting Code" hasButton />
            </div>
          </div> */}
        </div>
      </div>
    </PageBuilder>
  );
};

export default TraineePostings;
