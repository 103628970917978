import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NumberToNaira from "../../utilities/numberToNaira";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";
import TraineeCheckAccountStatus from "../registration-components/CheckAccountStatus";
import axios from "axios";
import NavMobile from "../Layouts/NavMobile";
import Urls from "../../utilities/urls";

const TraineeAvailableCourses = () => {
  const [availableCourses, setAvailableCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCat, setselectedCat] = useState(0);
  const [response, setResponse] = useState(null);
  const token = localStorage.getItem("token");
  const [type, setType] = useState([]);

  const CourseCats = [
    { id: 1, title: "Onsite", icon: "fa fa-home fa-2x" },
    { id: 2, title: "Online", icon: "fa fa-link fa-2x" },
  ];

  const showCourseCat = async (uuid, name) => {
    setselectedCat(uuid);
    if (name == "Onsite") {
      const allCategories = urls.api + "courses/type/" + uuid;
      await axios
        .get(allCategories)
        .then((result) => {
          // const uuid = "0188ef25-e3d9-481f-af4a-4e2ccc33d0ac";
          // const uuid = result.data.data[0].uuid;
          // const specificCategory = urls.api + "courses";
          // axios.get(specificCategory).then((courses) => {
          const availableCourses = result.data.data.courses;
          console.log(availableCourses);
          // console.log(availableCourses);
          setAvailableCourses(availableCourses);
          // console.log(availableCourses);
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      window.location.href = Urls.e_portal;
    }
    setApplyClicked(false);
  };

  const courseType = async () => {
    axios
      .get(urls.api + "course-types")
      .then((result) => {
        setType(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategories = async () => {
    const allCategories = urls.api + "courses/type/" + selectedCat;
    axios
      .get(allCategories)
      .then((result) => {
        // const uuid = "0188ef25-e3d9-481f-af4a-4e2ccc33d0ac";
        // const uuid = result.data.data[0].uuid;
        // const specificCategory = urls.api + "courses";
        // axios.get(specificCategory).then((courses) => {
        const availableCourses = result.data.data.courses;
        console.log(availableCourses);
        // console.log(availableCourses);
        setAvailableCourses(availableCourses);
        // console.log(availableCourses);
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    //getCategories();
    courseType();
  }, []);

  const [applyClicked, setApplyClicked] = useState(false);

  const successMsg = () => {
    return (
      <SweetAlert
        success
        title={
          <span>
            Application sent!
            <br />
            <small>Your application will be reviewed before approval</small>
          </span>
        }
        onConfirm={() => setApplyClicked(false)}
      />
    );
  };
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const errorMsg = () => {
    return (
      <SweetAlert
        error
        title={response}
        onConfirm={() => {
          setApplyClicked(false);
          setResponse(null);
        }}
      />
    );
  };

  return (
    <div class="">
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="Available Courses" />

          <TraineeCheckAccountStatus _token={token} />

          <div style={{ display: loading ? `block` : `none` }}>
            <Spinner />
          </div>

          <div
            style={{
              display: !loading && selectedCat === 0 ? `block` : `none`,
            }}
          >
            <div class="row">
              {type.map((x) => (
                <div
                  onClick={() => {
                    showCourseCat(x.uuid, x.name);
                  }}
                  class="col-xl-4 col-sm-6 col-12"
                >
                  <div class="card cursor-pointer">
                    <div class="card-body">
                      <div class="dash-widget-header">
                        <span class="text-primary">
                          <i
                            class={
                              x.name === "Onsite"
                                ? "fa fa-home fa-2x"
                                : x.name === "Online"
                                ? "fa fa-link fa-2x"
                                : "fa fa-globe fa-2x"
                            }
                          ></i>
                        </span>
                        <div class="dash-widget-info">
                          <h6 class="text-muted">{x.name}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              display: !loading && selectedCat !== 0 ? `block` : `none`,
            }}
          >
            <div class="row">
              {availableCourses.map((x) => (
                <div class="col-xl-4 col-sm-6 col-12">
                  <Link to={"#"}>
                    <div class="card">
                      <div class="card-body hover:shadow-lg">
                        <div class="dash-widget-header">
                          <div className="row">
                            <div class="col-xl-12 col-sm-6  text-center content-center mb-3">
                              <div class="avatar avatar-xxl w-100">
                                <img
                                  className="avatar-img rounded-md content-center"
                                  src={x.image}
                                />
                              </div>
                            </div>
                            <div class="col-xl-12 col-sm-6 col-12">
                              <div class="dash-widget-info">
                                <h6 class="text-muted text-center">
                                  {x.title}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table className="table">
                          <tbody>
                            {/* <tr>
                              <th>Starts</th>
                              <th className="text-right">Duration</th>
                            </tr>
                            <tr>
                              <td>
                                {new Date(
                                  x.calendars[0].startDate
                                ).toLocaleDateString("en-US", DATE_OPTIONS)}
                              </td>
                              <td className="text-right">
                                {new Date(
                                  x.calendars[0].endDate
                                ).toLocaleDateString("en-US", DATE_OPTIONS)}
                              </td>
                            </tr> */}
                            <tr>
                              <td>Cost</td>
                              <th className="text-right">{x.cost}</th>
                              {/* <th className="text-right">{x.cost}</th> */}
                            </tr>
                            <tr>
                              <td>Min. Deposit</td>
                              <th className="text-right">{x.minDeposit}</th>
                              {/* <th className="text-right">{NumberToNaira(x.minDeposit)}</th> */}
                            </tr>
                          </tbody>
                        </table>
                        <div className="text-right mb-3">
                          {/* <button
                            onClick={() => applyForProgrmme(x.courseUuid)}
                            className="btn btn-sm btn-primary"
                          >
                            Apply
                          </button> */}
                        </div>
                        <div className="text-right">
                          <Link
                            to={"/trainee/" + x.courseUuid + "/view_course"}
                          >
                            Course Details <i className="fa fa-arrow-right"></i>
                          </Link>{" "}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <div className="col">
            {applyClicked ? successMsg() : ""}

            {response !== null ? errorMsg() : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeAvailableCourses;
