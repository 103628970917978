import { WarningCircle, X, Check } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";
import { triggerDownloadPipe } from "../../helpers";

const ProspectiveTrainees = () => {
  const {
    data: { trainees },
    loaders: { getTrainees },
    pagination: {
      trainees: { updatePage },
    },
  } = useData();

  const [alert, setAlert] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const client = useClient();

  const rejectApplications = (applications) => {
    client("/api/v1/module/trainee-management/update-application-status", {
      method: "PATCH",
      data: JSON.stringify({
        applicationsUuid: applications,
        applicationStatus: "reject",
      }),
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Selected Applications Rejected Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const shortlistCandidates = (applications) => {
    client("/api/v1/module/trainee-management/update-application-status", {
      method: "PATCH",
      data: {
        applicationsUuid: applications,
        applicationStatus: "shortlisted",
      },
      credentials: true,
    })
      .then(() => {
        flashMessage({
          message: "Selected Candidates Shortlisted Successfully",
          type: "success",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const exportTrainees = (startDate, endDate, columns) => {
    flashMessage({ message: "Generating export", type: "info" });
    client("/api/v1/trainee-excel-reports/prospective-trainee", {
      method: "POST",
      res: "file",
      data: {
        startDate: startDate,
        endDate: endDate,
        columns: columns,
      },
      credentials: true,
    })
      .then(triggerDownloadPipe("PROSPECTIVE_TRAINEES_EXPORT", "xlsx"))
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  useEffect(() => {
    if (!loaded) {
      getTrainees({
        getProspective: true,
      });
      setLoaded(true);
    }
  }, [loaded, getTrainees]);

  return (
    <PageBuilder name="Prospective Trainees">
      <div className="flex flex-col">
        {trainees?.prospective?.count === 0 ? (
          alert ? (
            <div className="flex items-center justify-between w-full px-3 py-2 mb-16 shadow-md">
              <div className="flex items-center">
                <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                <span>
                  There are no prospective trainee registrations awaiting review
                  for this month
                </span>
              </div>
              <button
                className="btn btn-white !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                onClick={() => setAlert(false)}
              >
                <span className="ml-1">Dismiss</span>
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <PaginatedDataTable
          title="List of Prospective Trainees"
          exports={{ excel: true, messagingList: true }}
          refresh
          columns={[
            { Header: "Trainee First Name", accessor: "firstName" },
            { Header: "Trainee Last Name", accessor: "surname" },
            {
              Header: "Title",
              accessor: (row) => {
                return row.courseUuid ? row.courseTitle : row.programName;
              },
            },
            { Header: "RTC", accessor: "rtc" },
            {
              Header: "Education Level",
              accessor: "educationLevel[0].qualificationObtained",
            },
            {
              Header: "Session Start Date",
              accessor: "calendar.startDate",
            },
            {
              Header: "Session End Date",
              accessor: "calendar.endDate",
            },
            {
              Header: "Session Payment Deadline",
              accessor: "calendar.payCloseDate",
            },
            {
              Header: "Application Status",
              accessor: "applicationStatus",
              Filter: SelectColumnFilter,
            },
            { Header: "Email Address", accessor: "email" },
            {
              Header: "Gender",
              accessor: "gender",
              Filter: SelectColumnFilter,
            },
            { Header: "Mobile Number", accessor: "phoneNumber" },
            { Header: "Created At", accessor: "createdAt" },
            { Header: "Updated At", accessor: "updatedAt" },
          ]}
          data={trainees?.prospective.data}
          count={trainees?.prospective.count}
          currentPage={trainees?.prospective.page}
          setCurrentPage={(page) => {
            updatePage({ newPage: page, category: "prospective" });
            setLoaded(false);
          }}
          onRowClick={(row) => {
            navigate(
              `/admin/manage/trainee/applicants/details/${row.applicationUuid}`
            );
          }}
          multiSelectActions={(selectedFlatRows) => {
            return (
              <div className="flex items-center justify-end w-full h-full py-2">
                <button
                  type="button"
                  className="flex items-center justify-between mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
                  onClick={() =>
                    rejectApplications(
                      selectedFlatRows.map(
                        (row) => row.original.applicationUuid
                      )
                    )
                  }
                >
                  <X weight="bold" className="w-4 h-4" />
                  <span className="ml-1 lg:hidden">Reject</span>
                  <span className="hidden ml-1 lg:flex">
                    Reject Selected Candidates
                  </span>
                </button>
                <button
                  type="button"
                  className="flex items-center justify-between mx-2 my-2 btn btn-secondary"
                  onClick={() =>
                    shortlistCandidates(
                      selectedFlatRows.map(
                        (row) => row.original.applicationUuid
                      )
                    )
                  }
                >
                  <Check weight="bold" className="w-4 h-4" />
                  <span className="ml-1 lg:hidden">Shortlist</span>
                  <span className="hidden ml-1 lg:flex">
                    Shortlist Selected Candidates
                  </span>
                </button>
              </div>
            );
          }}
          exportToExcel={exportTrainees}
        />
      </div>
    </PageBuilder>
  );
};

export default ProspectiveTrainees;
