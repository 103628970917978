import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import axios from 'axios'
import urls from "../../utilities/urls";

const TrainerAnnouncements = () =>{	
    const [editProfile, setEditProfile] = useState(false);
    const [announcement, setAnnouncement] = useState([]);
    
	useEffect(() => {
        (async() => {
            const announcements = urls.api + 'announcements/'
            await axios.get(announcements)
            .then((result) => {
                const announcement = result.data.data.announcements
                setAnnouncement(announcement)
                console.log(announcement)
            })
        })();
      }, []);
    
      return (
        <div class="content container-fluid">
          <PageTitle title="Announcements" />
    
          <div class="card">
            <div class="card-body">
              <div style={{ display: !editProfile ? `block` : `none` }}>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Title</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {announcement.map((x) => (
                          <tr className="text-secondary">
                            <td>{x.createdAt}</td>
                            <td>{x.title}</td>
                            <td>
                              <Link
                                to={"/trainer/" + x.uuid + "/view_announcement"}
                                className="text-info"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default TrainerAnnouncements;