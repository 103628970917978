import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DateTimeToDate from "../../utilities/DateTimeToDate";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import Spinner from "../../utilities/spinner";
import axios from "axios";
import { toast } from "react-toastify";

const TraineeApplications = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState("");
  const [payNow, setPayNow] = useState("");
  const [myCourses, setMyCourses] = useState([]);
  const [generatedErr, setGeneratedErr] = useState(false);
  const [clickedCoursePayment, setClickedCoursePayment] = useState("");

  function makePayment(uuid) {
    postPay(uuid);
    try {
      let paymentEngine = window.RmPaymentEngine.init({
        key: process.env.REACT_APP_REMITA_PUBLIC_KEY,
        processRrr: true,
        transactionId: Math.floor(Math.random() * 1101233), // Replace with a reference you generated or remove the entire field for us to auto-generate a reference for you. Note that you will be able to check the status of this transaction using this transaction Id
        extendedData: {
          customFields: [
            {
              name: "rrr",
              value: payNow,
            },
          ],
        },
        onSuccess: function (response) {
          confirmPaymentForAuto();
          // navigate("/trainee/my_courses");
        },
        onError: function (response) {},
        onClose: function () {},
      });
      paymentEngine.showPaymentWidget();
    } catch (error) {
      toast.error("Unable to make payment");
    }
  }
  window.onload = function () {
    window.setDemoData();
  };
  const generateErr = async (uuid, courseId) => {
    const formData = new FormData();
    setClickedCoursePayment(courseId);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(urls.api + "payment/generateRRR/" + uuid, postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setGeneratedErr(true);
          setApplication(json.data[0].rrr);
          localStorage.setItem("rrr", application);
          toast.success("Successfully Generated RRR");
        } else if (json.message !== null) {
        } else {
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {});
  };

  const postPay = async (uuid) => {
    const formData = new FormData();
    formData.append("applicationUuid", uuid);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    fetch(urls.api + "payment/generateRRR/" + uuid, postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setPayNow(json.data[0].rrr);
          localStorage.setItem("rrr", application);
        } else {
          toast.error("Unable to make payment");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {});
  };

  const coursesApplied = () => {
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    // setLoading(true);
    fetch(urls.api + "application/all/", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          const myCourses1 = json.data.applications;
          return setMyCourses(myCourses1);
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };
  const confirmPaymentForAuto = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(urls.api + "payment/confirm/" + payNow, config)
      .then((result) => {
        if (result.data.message !== "confirmed") {
          toast.warning(result.data.data.message);
        } else {
          toast.success(result.data.data.message);
        }
      })
      .then((error) => {});
  };
  const confirmPayment = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(urls.api + "payment/confirm/" + application, config)
      .then((result) => {
        toast.warning(result.data.data.message);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    coursesApplied();
  }, []);

  return (
    <div class="content container-fluid">
      <PageTitle title="Applications" />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginTop: "-60px" }}>
          {" "}
          {application ? <p>RRR: {application}</p> : ""}
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div style={{ display: loading ? `block` : `none` }}>
            <Spinner />
          </div>
          <div
            style={{ display: !editProfile ? `block` : `none` }}
            className="row"
          >
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Course Title</th>
                    <th>Status </th>
                    <th>Payment Status</th>
                    <th>Make Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {myCourses?.map((x) => (
                    <tr>
                      <td>{DateTimeToDate.dateTimeToString(x.createdAt)}</td>
                      <td>{x.courseTitle ? x.courseTitle : x.programName}</td>
                      <td>
                        {x.status == "prospective" ? (
                          <span className="text-warning">Prospective</span>
                        ) : (
                          ""
                        )}
                        {x.status == "confirmed" ? (
                          <span className="text-success">Confirmed</span>
                        ) : (
                          ""
                        )}
                        {x.status == "shortlisted" ? (
                          <span className="text-warning">Shortlisted</span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {x.status == "shortlisted" ? (
                          <span className="text-warning">Pending Payment</span>
                        ) : (
                          ""
                        )}
                        {x.status == "confirmed" ? (
                          <span className="text-success">Paid</span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {x.status == "shortlisted" ? (
                          <>
                            {x.courseUuid === clickedCoursePayment ? (
                              <>
                                <button
                                  style={{
                                    display: generatedErr
                                      ? `inline-flex`
                                      : `none`,
                                  }}
                                  className=" rounded py-1 px-2"
                                  value={x.uuid}
                                  onClick={() => {
                                    confirmPayment();
                                  }}
                                >
                                  Confirm Payment
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  style={{ display: "inline-block" }}
                                  className=" rounded py-1 px-2"
                                  value={x.uuid}
                                  onClick={() => {
                                    generateErr(
                                      x.uuid,
                                      x.courseUuid
                                        ? x.courseUuid
                                        : x.programUuid
                                    );
                                  }}
                                >
                                  Generate RRR
                                </button>
                              </>
                            )}
                            <button
                              style={{ display: "inline-block" }}
                              className=" rounded py-1 px-2"
                              value={x.uuid}
                              onClick={() => {
                                makePayment(x.uuid);
                              }}
                            >
                              Pay Now
                            </button>

                            {/* */}
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeApplications;
