import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import urls from "../../utilities/urls";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddQuiz = () => {
  const navigate = useNavigate();
  const [answerType, setAnswerType] = useState(null);
  const [enterOptions, setEnterOptions] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [myCourses, setMyCourses] = useState([]);
  const [uuid, setUuid] = useState("");
  const [isActive, setIsActive] = useState("1");
  const [course_code, setCourse_code] = useState("");
  const [title, setTitle] = useState("");
  const [inputFields, setInputFields] = useState([
    {
      question: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      correctAnswer: "",
    },
  ]);
  const [time_limit, setTime_limit] = useState(null);
  // const [correct_answer, setCorrect_answer] = useState("");

  const selectedAnswerType = (x) => {
    if (x == "options") {
      setEnterOptions(true);
    } else {
      setEnterOptions(false);
    }
  };

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { name: "", age: "" };

    setInputFields([...inputFields, newfield]);
  };

  const getMyCourses = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      urls.api + "courses/all/my-courses",
      config
    );
    const data = response.data.data.courses;
    setMyCourses(data);
  };

  // console.log(time_limit);

  const postExam = async () => {
    const formData = new FormData();
    inputFields.map((item) => {
      console.log(
        item.option1,
        item.option2,
        item.option3,
        item.option4,
        item.correctAnswer
      );
      formData.append("questions[*][question]", item.question);
      formData.append("questions[*][option1]", item.option1);
      formData.append("questions[*][option2]", item.option2);
      formData.append("questions[*][option3]", item.option3);
      formData.append("questions[*][option4]", item.option4);
      formData.append("questions[*][correctAnswer]", item.correctAnswer);
    });
    formData.append("courseUuid", uuid);
    formData.append("isActive", isActive);
    // formData.append(
    //   "questions[*][option1]",
    //   JSON.stringify(inputFields.option1)
    // );
    formData.append("title", title);
    formData.append("timeLimit", time_limit);
    console.log(formData);

    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: formData,
    };
    fetch(urls.api + "quizzes/submit", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          toast.success(json.message);
          console.log(json);
        } else if (json.message !== null) {
          toast.error(json.message);
          console.log(json);
        } else {
          console.log("else statement");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      })
      .finally(() => {
        console.log("done");
        // setQuestion("");
        // setTitle("");
        // setUuid("");
        // setAnswer_option_1("");
        // setAnswer_option_2("");
        // setAnswer_option_3("");
        // setAnswer_option_4("");
        // setCorrect_answer("");
      });
  };

  useEffect(() => {
    // alert("Hello world")
    getMyCourses();
  }, []);

  console.log(uuid);

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  return (
    <div class="content container-fluid">
      <PageTitle title="Create Quiz" />
      <ToastContainer />

      <div className="row">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div class="col-xl-4 col-sm-6 col-12 mb-3">
                <label>Course</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setCourse_code(e.target.value);
                    setUuid(
                      myCourses.find(
                        (course) => course.courseCode === e.target.value
                      )?.courseUuid
                    );
                  }}
                >
                  <option value={""}>Select</option>
                  {myCourses.map((id) => {
                    return <option value={id.courseCode}>{id.title}</option>;
                  })}
                </select>
              </div>
              <div class="col-xl-4 col-md-6 col-12 mb-3">
                <label>Starts</label>
                <input type={"date"} className="form-control" />
              </div>
              <div class="col-xl-4 col-md-6 col-12 mb-3">
                <label>Ends</label>
                <input
                  type={"date"}
                  value={time_limit}
                  className="form-control"
                  onChange={(e) => setTime_limit(e.target.value)}
                />
              </div>
              <div class="col-xl-4 col-md-6 col-12 mb-3">
                <label>Title</label>
                <input
                  type={"text"}
                  value={title}
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              {inputFields.map((input, index) => {
                return (
                  <div style={{ width: "100%" }}>
                    <div className="row">
                      <div class="col-xl-10 col-md-6 col-12 mb-3">
                        <label>Question</label>
                        <textarea
                          className="form-control"
                          name="question"
                          value={input.question}
                          onChange={(event) => handleFormChange(index, event)}
                          style={{ minWidth: "100%" }}
                        ></textarea>
                      </div>
                      {/* <div class="col-xl-6 col-md-6 col-12 mb-3">
                <label>Attach Image (Optional)</label>
                <input type={"file"} className="form-control" />
              </div> */}
                      <div class="col-xl-6 col-md-6 col-12 mb-3">
                        <label>Answer Type</label>
                        <select
                          onChange={(e) => {
                            setAnswerType(e.target.value);
                            selectedAnswerType(e.target.value);
                          }}
                          className="form-control"
                        >
                          <option value={""}>Select</option>
                          {/* <option value={"true_or_false"}>True of False</option> */}
                          <option value={"options"}>Options</option>
                        </select>
                      </div>
                    </div>

                    <div style={{ display: enterOptions ? `block` : `none` }}>
                      <div className="row">
                        <div class="col-xl-6 col-md-12 col-12 mb-3">
                          <label>option 1</label>
                          <input
                            className="form-control"
                            value={input.option1}
                            name="option1"
                            onChange={(event) => handleFormChange(index, event)}
                          />
                        </div>
                        <div class="col-xl-6 col-md-12 col-12 mb-3">
                          <label>option 2</label>
                          <input
                            className="form-control"
                            value={input.option2}
                            name="option2"
                            onChange={(event) => handleFormChange(index, event)}
                          />
                        </div>
                        <div class="col-xl-6 col-md-12 col-12 mb-3">
                          <label>option 3</label>
                          <input
                            className="form-control"
                            value={input.option3}
                            name="option3"
                            onChange={(event) => handleFormChange(index, event)}
                          />
                        </div>
                        <div class="col-xl-6 col-md-12 col-12 mb-3">
                          <label>option 4</label>
                          <input
                            className="form-control"
                            value={input.option4}
                            name="option4"
                            onChange={(event) => handleFormChange(index, event)}
                          />
                        </div>
                        <div
                          class="col-xl-6 col-md-12 col-12 mb-3"
                          style={{ marginBottom: "10px" }}
                        >
                          <label>correct answer</label>
                          <input
                            className="form-control"
                            value={input.correctAnswer}
                            name="correctAnswer"
                            onChange={(event) => handleFormChange(index, event)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div style={{ display: answerType !== null ? `block` : `none` }}>
              <div className="row">
                <div class="col-xl-6 col-md-12 col-12">
                  {/* <button className="btn btn-secondary">
                    Add More <i className="fa fa-plus-square"></i>
                  </button> */}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div class="">
                    {/* <button className="btn btn-success" onClick={addFields}>
                      Add More <i className="fa fa-check"></i>{" "}
                    </button> */}
                  </div>
                  {inputFields.length > 1 ? (
                    <>
                      <div class="">
                        {/* <button
                          className="btn btn-success"
                          onClick={removeFields}
                        >
                          Delete <i className="fa fa-check"></i>{" "}
                        </button> */}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div class="">
                    <button className="btn btn-success" onClick={postExam}>
                      Submit <i className="fa fa-check"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuiz;
