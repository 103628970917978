import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";
import relationships from "../../utilities/Relationships";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";


const TrainerNOK = () =>{
	const [token, setToken] = useState(localStorage.getItem("token"));
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [foundError, setFoundError] = useState(false)

	
    const [editProfile, setEditProfile] = useState(false);
		const EditBtn = () => {
		setEditProfile(true)
			}


			const [firstName, setFirstName] = useState(null)
			const [surname, setSurname] = useState(null)
			const [relationship, setRelationship] = useState(0)
			const [address, setAddress] = useState(null)
			const [phoneNumber, setPhoneNumber] = useState(null)

			const [firstNameStr, setFirstNameStr] = useState(null)
			const [surnameStr, setSurnameStr] = useState(null)
			const [relationshipStr, setRelationshipStr] = useState(0)
			const [addressStr, setAddressStr] = useState(null)
			const [phoneNumberStr, setPhoneNumberStr] = useState(null)
				
	const addNOKBtn = () =>{
        setShowAlert(false)
        setLoading(true)
		setFoundError(true)
    
        const postOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': true,
				'Authorization': 'Bearer '+token                  
            },
            body: JSON.stringify({
				firstName: firstName,
				surname: surname,
				relationship: parseInt(relationship),
				address: address,
				phoneNumber: phoneNumber
			})
        };

        fetch(urls.api + 'profile/nok-update', postOptions)
            .then((response) => response.json())
            .then((json) => {
				if(json.success === true) {
                    setAlertMessage(json.message)
					setFoundError(false)
                }
                else if(json.message !== null) {
                    setAlertMessage(json.message)
					setFoundError(true)
                }
                else{
                    setAlertMessage(ErrorMessages.UnkownRetry)
					setFoundError(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => setLoading(false));       
    }

	const successMsg = () =>{
		return (<SweetAlert 
			success
			title={alertMessage}
			onConfirm={() => {setFoundError(false); setAlertMessage(null); setEditProfile(false);}}
			/> )
		}
		
		const errorMsg = () =>{
			return (<SweetAlert 
				error
				title={alertMessage}
				onConfirm={() => {setFoundError(false); setAlertMessage(null);}}
				/>)
			}

			const getUserProfile = () =>{
				setFoundError(false)
				setAlertMessage(null)
				setLoading(true)
			
				const postOptions = {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
						"Accept": "application/json",
						"Content-Type": "application/json",
						'Access-Control-Allow-Credentials': true
					}
				};
			
				fetch(urls.api + 'profile/my-profile', postOptions)
					.then((response) => response.json())
					.then((json) => {
						if(json.success === true) {
							//setProfile(json.data)
							let xnok = json.data.user.nextOfKin
							//setBio(json.data.user.bio)
						  
							// setStateOfOrigin(xbio.stateOfOrigin)
							// setLgaOfOrigin(xbio.lgaOfOrigin)
							// setStateOfResidence(xbio.stateOfResidence)
							// setLgaOfResidence(xbio.lgaOfResidence)
							setFirstNameStr(xnok.firstName)
							setSurnameStr(xnok.surname)
							setPhoneNumberStr(xnok.phoneNumber)
							setAddressStr(xnok.address)

							setFirstName(xnok.firstName)
							setSurname(xnok.surname)
							setPhoneNumber(xnok.phoneNumber)
							setAddress(xnok.address)
		setRelationship(JSON.parse(JSON.stringify(relationships.filter( x => x.id == xnok.relationship)[0].name)))
		
							//setKit(json.data.user.kit)
							//setNextOfKin(json.data.user.nextOfKin)
							//setEducation(json.data.user.education)
							//setProfessionalCerts(json.data.user.professionalCerts)
						}                                    
						else {
							console.log(json.message)
						}
					})
					.catch((error) => {
						console.log(error)
					})
					.finally(() => setLoading(false));      
			   
			}
		
				useEffect(()=>{
					getUserProfile()
       // alert("Hello world")
    }, [] )

    
    return ( <div class="content container-fluid">
        <PageTitle title="Next of Kin"/>        
		
		<div class="card">
		<div class="card-body">

        <div style={{display: !editProfile ? `block` : `none`}}>
                <div className="row">

                <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>First Name</h6>
					<label>{firstNameStr} </label>					
					</div>

                    <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Surname</h6>
					<label>{surnameStr} </label>					
					</div>

                    <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Phone Number</h6>
					<label>{phoneNumberStr} </label>					
					</div>

                    <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Home Address</h6>
					<label>{addressStr} </label>					
					</div>


                    <div className='col-md-12'>
                        <button onClick={() => EditBtn()} className="btn btn-block btn-success">Edit <i className="fa fa-pen"></i> </button>
                        </div>

                </div>
            </div>

		{/* Profile edit start */}
		<div style={{display: editProfile ? `block` : `none`}}>
			<h4>Edit</h4>
			<div className="row">
                    
			<div class="col-xl-6 col-sm-6 col-12 mb-3">
					<label>Surname</label>
					<input className="form-control" value={surname} onChange={(e) => setSurname(e.target.value) } />
					</div>
					
					<div class="col-xl-6 col-sm-6 col-12 mb-3">
					<label>First Name</label>
					<input className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value) } />
					</div>

					<div class="col-xl-6 col-sm-6 col-12 mb-3">
					<label>Relationship</label>
					<select className="form-control" onChange={(e) => setRelationship(e.target.value)}>
					<option value={0}>Select</option>
					{relationships.map(x =>
					<option value={x.id}>{x.name}</option>
						)}
						</select>
						</div>

					<div class="col-xl-6 col-sm-6 col-12 mb-3">
					<label>Phone Number</label>
					<input className="form-control" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value) } />
					</div>
					
					<div class="col-xl-12 col-sm-12 col-12 mb-3">
					<label>Home Address</label>
					<textarea className="form-control" onChange={(e) => setAddress(e.target.value) }>
						{address}						
						</textarea>
					</div>	

                        <div className='col-md-12'>
                        <button onClick={() => addNOKBtn() } style={{display: loading? `none` : `block` }} className="btn btn-block btn-success">Update <i className="fa fa-check"></i> </button>
                        <button style={{display: loading? `block` : `none` }} className="btn btn-block btn-success"> <Spinner/> </button>
                        </div>

                     
					</div>
					</div>
					{/* Profile edit end */}


					

				</div>
				</div>

				<div className="col">

{!foundError && alertMessage !== null ? successMsg() : '' }

{foundError && alertMessage !== null? errorMsg() : '' }

</div>


        </div>  

    )
}

export default TrainerNOK;