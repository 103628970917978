import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../utilities/Alert";
import ErrorMessages from "../../utilities/ErrorMessages";
import states from "../../utilities/NigeriaStates";
import relationships from "../../utilities/Relationships";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";

const TraineeRegistration = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const id_ = new URLSearchParams(search).get("punch");

  const [statesList, setStatesList] = useState([]);
  const [lgaList, setLgaList] = useState([]);

  const [token, setToken] = useState(id_);
  const [nextBtn, setNextBtn] = useState(0);
  const [foundError, setFoundError] = useState(true);

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const [stateOfResidence, setStateOfResidence] = useState(null);
  const [lgaOfResidence, setLgaOfResidence] = useState(null);
  const [stateOfOrigin, setStateOfOrigin] = useState(null);
  const [lgaOfOrigin, setLgaOfOrigin] = useState(null);
  const [homeAddress, setHomeAddress] = useState(null);

  const addAddressBtn = () => {
    setShowAlert(false);
    setLoading(true);
    setFoundError(true);

    const postOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        stateOfResidence: stateOfResidence,
        lgaOfResidence: lgaOfResidence,
        stateOfOrigin: stateOfOrigin,
        lgaOfOrigin: lgaOfOrigin,
        homeAddress: homeAddress,
      }),
    };

    fetch(urls.api + "profile/address-update", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        } else if (json.success === true) {
          setShowAlert(false);
          setLoading(false);
          setFoundError(false);
          if (nextBtn === 0) {
            NextBtn();
          }
        } else {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        }
      })
      .catch((error) => {
        setAlertMessage(ErrorMessages.UnkownRetry);
        setAlertTitle("Error!");
        setAlertType("danger");
        setShowAlert(true);
        setFoundError(true);
      })
      .finally(() => setLoading(false));
  };

  const [firstName, setFirstName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [relationship, setRelationship] = useState(0);
  const [address, setAddress] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const addNOKBtn = () => {
    setShowAlert(false);
    setLoading(true);
    setFoundError(true);

    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        firstName: firstName,
        surname: surname,
        relationship: parseInt(relationship),
        address: address,
        phoneNumber: phoneNumber,
      }),
    };

    fetch(urls.api + "profile/nok-update", postOptions)
      .then((response) => response.json())
      .then((json) => {
        //alert(relationship)
        if (json.success === false) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        } else if (json.success === true) {
          setShowAlert(false);
          setLoading(false);
          setFoundError(false);
          if (nextBtn === 1) {
            NextBtn();
          }
        } else {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        }
      })
      .catch((error) => {
        setAlertMessage(ErrorMessages.UnkownRetry);
        setAlertTitle("Error!");
        setAlertType("danger");
        setShowAlert(true);
        setFoundError(true);
      })
      .finally(() => setLoading(false));
  };

  const [shoeSize, setShoeSize] = useState(null);
  const [overallSize, setOverallSize] = useState(null);
  const [tShirtSize, setTShirtSize] = useState(null);
  const addKitBtn = () => {
    setShowAlert(false);
    setLoading(true);
    setFoundError(true);

    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shoeSize: shoeSize,
        overallSize: overallSize,
        tShirtSize: tShirtSize,
      }),
    };

    fetch(urls.api + "profile/kit-update", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        } else if (json.success === true) {
          setShowAlert(false);
          setLoading(false);
          setFoundError(false);
          if (nextBtn === 2) {
            window.location.replace(urls.traineeUrl + "dashboard");
            //NextBtn();
          }
        } else {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        }
      })
      .catch((error) => {
        setAlertMessage(ErrorMessages.UnkownRetry);
        setAlertTitle("Error!");
        setAlertType("danger");
        setShowAlert(true);
        setFoundError(true);
      })
      .finally(() => setLoading(false));
  };

  const NextBtn = (x) => {
    if (x == 0 || x < 0) {
      addAddressBtn();
      if (!foundError) setNextBtn(nextBtn + 1);
    }
    if (x == 1) {
      addNOKBtn();
      if (!foundError) setNextBtn(nextBtn + 1);
    }
    if (x == 2) {
      addKitBtn();
      if (!foundError) setNextBtn(nextBtn + 1);
    }
  };

  const PrevBtn = () => {
    setNextBtn(nextBtn - 1);
    //if(foundError)	setNextBtn(nextBtn-1)
  };

  //Get LGA
  const getLga = (x) => {
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "state/" + x + "/local-areas", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setFoundError(true);
        } else if (json.success === true) {
          setLgaList(json.data);
        } else if (json.message !== null) {
          //alert(json.message)
        } else {
          //alert(ErrorMessages.UnkownRetry)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("LGAs"));
  };

  //Get LGA 2
  const [lgaList2, setLgaList2] = useState([]);
  const getLga2 = (x) => {
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "state/" + x + "/local-areas", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          //alert(json.message)
        } else if (json.success === true) {
          setLgaList2(json.data);
        } else if (json.message !== null) {
          //alert(json.message)
        } else {
          //alert(ErrorMessages.UnkownRetry)
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("LGAs"));
  };

  const profileCheck = () => {
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "profile/my-profile", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if ((json.data.company.verified = true)) {
          let user = json.data.company.name;
          console.log(user);
          window.location.href = urls.home;
          // alert(user);
        } else if (json.message == "Unauthenticated.") {
          console.log(json);
          window.location.href = urls.home + "logout";
        }
        // alert(JSON.stringify(json.message))
      })
      .catch((error) => {
        // console.log(error)
        //logOut()
        window.location.href = urls.home + "login";
      })
      .finally(() => console.log("***"));
  };

  useEffect(() => {
    if (id_ != null) {
      localStorage.setItem("token", id_);
      setToken(id_);
    } else {
      if (localStorage.getItem("token") != null) {
        setToken(localStorage.getItem("token"));
      }
    }
    profileCheck();
    setStatesList(states);
  }, []);

  return (
    <div class="content container-fluid">
      <PageTitle title="Registration" />

      <div class="card">
        <div class="card-body">
          {/* Location start */}
          <div style={{ display: nextBtn == 0 ? `block` : `none` }}>
            <h4>Location</h4>
            <div className="row">
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <span>*</span> <label>State of Residence</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setStateOfResidence(e.target.value);
                    getLga(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  {statesList.map((x) => (
                    <option value={x.id}>{x.name}</option>
                  ))}
                </select>
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <span>*</span> <label>LGA of Residence</label>
                <select
                  className="form-control"
                  onChange={(e) => setLgaOfResidence(e.target.value)}
                >
                  <option value={""}>Select</option>
                  {lgaList.map((x) => (
                    <option value={x.id}>{x.name}</option>
                  ))}
                </select>
              </div>

              <div class="col-xl-12 col-sm-12 col-12 mb-3">
                <span>*</span> <label>Home Address</label>
                <textarea
                  className="form-control"
                  onChange={(e) => setHomeAddress(e.target.value)}
                ></textarea>
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <span>*</span> <label>State of Origin</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setStateOfOrigin(e.target.value);
                    getLga2(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  {statesList.map((x) => (
                    <option value={x.id}>{x.name}</option>
                  ))}
                </select>
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <span>*</span> <label>LGA of Origin</label>
                <select
                  className="form-control"
                  onChange={(e) => setLgaOfOrigin(e.target.value)}
                >
                  <option value={""}>Select</option>
                  {lgaList2.map((x) => (
                    <option value={x.id}>{x.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* Location end */}

          {/*Alert Section start*/}
          {showAlert ? (
            <Alert type={alertType} title={alertTitle} message={alertMessage} />
          ) : (
            ""
          )}
          {/*Alert Section end*/}

          <div class="row">
            {nextBtn > 0 ? (
              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <button
                  onClick={() => PrevBtn()}
                  className="btn btn-block btn-secondary"
                >
                  <i className="fa fa-arrow-left"></i> Back{" "}
                </button>
              </div>
            ) : (
              <div class="col-xl-6 col-sm-6 col-12 mb-3"></div>
            )}
            <div class="col-xl-6 col-sm-6 col-12 mb-3">
              <button
                onClick={() => NextBtn(nextBtn)}
                className="btn btn-block btn-success"
              >
                {" "}
                {nextBtn > 1 ? (
                  <span>
                    Submit <i className="fa fa-check"></i>
                  </span>
                ) : (
                  <span>
                    Next <i className="fa fa-arrow-right"></i>
                  </span>
                )}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeRegistration;
