import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import TraineeCheckAccountStatus from "../registration-components/CheckAccountStatus";
import Spinner from "../../utilities/spinner";
import axios from "axios";
import csv from "../../../images/csv-svgrepo-com.svg";
import template from "../../../images/template.csv.csv";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { set } from "date-fns";

const TraineeApplications = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [applicableType, setApplicableType] = useState("");
  const [run, setRun] = useState(false);
  const [applicableUuid, setApplicableUuid] = useState("");
  const [myCourses, setMyCourses] = useState([]);
  const [courseUuid, setCourseUuid] = useState("");
  const [calenderUuid, setCalenderUuid] = useState("");
  const [application, setApplication] = useState([]);
  const [format, setFormat] = useState("");
  const [getCalendar, setGetCalendar] = useState([]);
  const [enable, setEnable] = useState(true);
  const [status, setStatus] = useState(false);
  const [returnData, setReturnData] = useState({
    alreadyAppliedForCourse: 0,
    alreadyHaveAccount: 0,
    applicationCreatedForCourse: 0,
    registrationSuccessful: 0,
  });
  const [failed, setFailed] = useState([]);
  let failedRegistration = [];
  const [show, setShow] = useState(false);
  const [rtc, setRtc] = useState([]);
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const coursesApplied = async () => {
    setLoading(true);
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    let courses, programs, allApplications;
    await axios
      .get(urls.api + "courses")
      .then((result) => {
        // setApplications(result.data.data.courses);
        courses = result.data.data.courses;
      })
      .catch((error) => {
        console.log(error);
      });
    await fetch(urls.api + "program/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        // setPrograms(json.data);
        programs = json.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));

    allApplications = [...courses, ...programs];
    setApplication(allApplications);
    setLoading(false);
  };

  const onHandleChanges = (e) => {
    const inputString = e.target.value;
    const parts = inputString.split("+");
    setApplicableType(parts[1]);
    setApplicableUuid(parts[0]);
    const url =
      urls.api + (parts[1] === "course" ? "courses/" : "program/") + parts[0];
    axios
      .get(url)
      .then((result) => {
        console.log(result.data.data);
        setGetCalendar(result.data.data.calendars);
        setRtc(
          result.data.data.regionalTrainingCentres ||
            result.data.data.regionalTrainingCenters
        );
        console.log(getCalendar);
        console.log(rtc);
        setStatus(true);
      })
      .catch((error) => {
        console.log(error);
      });
    //  console.log(applicableType, applicableUuid);
    // setCourseUuid(e.target.value);
    setEnable(false);
  };
  const onHandleFiles = (e) => {
    let name = e.target.files[0];
    setFormat(name);
  };
  const createBatchApplication = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData();
    formdata.append("applicableUuid", applicableUuid);
    formdata.append("csvFile", format);
    formdata.append("applicableType", applicableType);
    formdata.append("calendarUuid", calenderUuid);
    await axios
      .post(urls.api + "application/create/batch", formdata, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        console.log(result.data);
        if (result.data.message === "Applications Uploaded Successfully") {
          toast.success("Applications Uploaded Successfully");
          const filingData = { ...returnData };
          failedRegistration.push(
            result.data.data.batchApplicationSummary.registrationFailed
          );
          setFailed(
            result.data.data.batchApplicationSummary.registrationFailed
          );
          console.log(failed);
          filingData.alreadyAppliedForCourse =
            result.data.data.batchApplicationSummary.alreadyAppliedForCourse;
          filingData.alreadyHaveAccount =
            result.data.data.batchApplicationSummary.alreadyHaveAccount;
          filingData.applicationCreatedForCourse =
            result.data.data.batchApplicationSummary.applicationCreatedForCourse;
          filingData.registrationSuccessful =
            result.data.data.batchApplicationSummary.registrationSuccessful;
          setReturnData(filingData);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        toast.error("an error occured");
        console.log("an error has occured", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    coursesApplied();
    const date = new Date();
    const converter = date.toString();
    setDate(converter);
  }, [applicableType, applicableUuid, getCalendar, rtc]);

  return (
    <div class="content container-fluid">
      <TraineeCheckAccountStatus _token={token} />
      <div className="alert alert-warning">
        {" "}
        <p>
          Note: Select a course, download the csv file, view RTC to copy the ID
          required to complete the file respectively.
        </p>
      </div>{" "}
      <div class="card">
        <div class="card-body">
          <div style={{ display: loading ? `block` : `none` }}>
            <Spinner />
          </div>
          <div
            style={{ display: !editProfile ? `block` : `none` }}
            className="row"
          >
            <div className="table-responsive">
              <form
                onSubmit={(e) => {
                  createBatchApplication(e);
                }}
              >
                <table className="table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Course Title</th>
                      <th> Download Template</th>
                      <th>Submit Template</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: "10%" }}>{date.slice(0, 10)}</td>
                      <td style={{ width: "20%" }}>
                        <select
                          required
                          style={{ width: "100%" }}
                          id="courseUuid"
                          name="courseUuid"
                          onChange={(e) => {
                            onHandleChanges(e);
                          }}
                        >
                          <option>Select</option>
                          {application &&
                            application.map((course) => (
                              <option
                                value={
                                  course.courseUuid
                                    ? course.courseUuid + "+course"
                                    : course.uuid + "+program"
                                }
                              >
                                {course.title ? course.title : course.name}
                              </option>
                            ))}
                        </select>
                      </td>

                      <td style={{ width: "32%" }}>
                        <a
                          style={{ float: "left" }}
                          href={template}
                          download="template.csv"
                        >
                          <img src={csv} style={{ height: "40px" }} />
                        </a>
                        <button
                          type="button"
                          disabled={status ? false : true}
                          id="selectRtc"
                          onClick={() => {
                            //viewRtc();
                            setShow(true);
                          }}
                          style={{ float: "left", marginTop: "-1px" }}
                          className="btn btn-secondary"
                        >
                          RTC
                        </button>
                        <select
                          disabled={enable ? true : false}
                          className=""
                          required
                          style={{ width: "50%", float: "right" }}
                          id="calendarUuid"
                          name="calendarUuid"
                          onChange={(e) => {
                            setCalenderUuid(e.target.value);
                          }}
                        >
                          <option>Select Session</option>
                          {getCalendar &&
                            getCalendar.map((each) => {
                              return (
                                <>
                                  {" "}
                                  <option value={each.uuid}>
                                    {new Date(
                                      each.startDate
                                    ).toLocaleDateString(
                                      "en-US",
                                      DATE_OPTIONS
                                    ) +
                                      " - " +
                                      new Date(each.endDate).toLocaleDateString(
                                        "en-US",
                                        DATE_OPTIONS
                                      )}
                                  </option>
                                  ;
                                </>
                              );
                            })}
                        </select>
                      </td>
                      <td>
                        <input
                          onChange={(e) => {
                            onHandleFiles(e);
                          }}
                          type="file"
                          name="file"
                          className="form-control"
                        />
                      </td>
                      <td>
                        <input
                          id="submit"
                          style={{ marginTop: "-3px" }}
                          type="submit"
                          className="btn btn-secondary"
                          value="Proceed"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
          <section>
            <Modal show={show} style={{ width: "100%" }}>
              <Modal.Header closeButton>
                <Modal.Title>Regional Training Centers</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        {" "}
                        <tr>
                          <th>Regional ID</th>
                          <th>RTC Name</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rtc &&
                          rtc.map((key) => {
                            return (
                              <>
                                {" "}
                                <tr>
                                  <td>{key.uuid}</td> <td>{key.name}</td>{" "}
                                  <td>{key.address}</td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Close RTC
                </Button>
              </Modal.Footer>
            </Modal>
          </section>
          <div className="row">
            <div className="col-lg-12 my-4">
              <p>
                <strong>Trainee Details</strong>
              </p>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3">
              <center>Applied Trainee</center>
              <center>{returnData.alreadyAppliedForCourse}</center>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3">
              <center>Existing Trainee</center>
              <center>{returnData.alreadyHaveAccount}</center>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3">
              <center>Existing Trainee Application</center>
              <center> {returnData.applicationCreatedForCourse}</center>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3">
              <center>Successful Registeration</center>
              <center>{returnData.registrationSuccessful}</center>
            </div>
          </div>
          <div className="row table-responsive m-4">
            <p className="m-4">
              <strong>Failed Registration</strong>
            </p>
            <table className="table">
              <thead>
                <th>First Name</th>
                <th>Surname</th>
                <th>Email</th>
                <th>Gender</th>
                <th>Phone Number</th>
              </thead>
              <tbody>
                {failed.length ? (
                  failed.map((failed) => {
                    return (
                      <>
                        <tr>
                          <td>{failed.firstName}</td>
                          <td>{failed.surname}</td>
                          <td>{failed.email}</td>
                          <td>{failed.gender === "f" ? "Female" : "male"}</td>
                          <td>{failed.phoneNumber}</td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeApplications;
