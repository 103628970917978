import { useEffect } from "react";
import { Link } from "react-router-dom";
import NavMobile from "../Layouts/NavMobile";
import PageTitle from "../Layouts/PageTitle";

const TrainerProfile = () => {
  let profileMenu = [
    {
      id: 1,
      title: "Personal Information",
      url: "/trainer/personal_profile",
      icon: "fas fa-user-tie fa-2x",
    },
    {
      id: 3,
      title: "Location",
      url: "/trainer/location",
      icon: "fas fa-map-marker fa-2x",
    },
    {
      id: 2,
      title: "Next of Kin",
      url: "/trainer/nok",
      icon: "fas fa-user fa-2x",
    },
    // {
    //   id: 4,
    //   title: "Kit Information",
    //   url: "/trainer/kit_information",
    //   icon: "fas fa-tshirt fa-2x",
    // },
    {
      id: 5,
      title: "Educational Qualifications",
      url: "/trainer/educational",
      icon: "fas fa-graduation-cap fa-2x",
    },
    {
      id: 6,
      title: "Certificates",
      url: "/trainer/certificates",
      icon: "fas fa-list fa-2x",
    },
    {
      id: 7,
      title: "Update Password",
      url: "/trainer/update_password",
      icon: "fas fa-lock fa-2x",
    },
  ];

  useEffect(() => {
    // alert("Hello world")
  }, []);

  return (
    <div class="">
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div style={{ marginLeft: "20px" }}>
          <PageTitle title="Profile" />
          <div class="row">
            {profileMenu.map((x) => (
              <div class="col-xl-3 col-sm-6 col-12">
                <Link to={x.url}>
                  <div class="card">
                    <div class="card-body">
                      <div class="dash-widget-header">
                        <span class="text-primary">
                          <i class={x.icon}></i>
                        </span>
                        <div class="dash-widget-info">
                          <h6 class="text-muted">{x.title}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerProfile;
