import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorMessages from "../../utilities/ErrorMessages";
import Images from "../../utilities/Images";
import Spinner from "../../utilities/spinner";
import { toast } from "react-toastify";
import urls from "../../utilities/urls";
import PageTitle from "../Layouts/PageTitle";
import axios from "axios";
import TraineeCheckAccountStatus from "../registration-components/CheckAccountStatus";

const TraineePersonalInformation = () => {
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [signature, setSignature] = useState(null);
  const [logo, setLogo] = useState(null);

  const token = localStorage.getItem("token");
  const [foundError, setFoundError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const [updateDP, setUpdateDP] = useState(false);
  const [updateSignature, setUpdateSignature] = useState(false);

  const updateDPInitBtn = () => {
    setUpdateDP(true);
  };
  const updateSignatureInitBtn = () => {
    setUpdateSignature(true);
  };

  const [editProfile, setEditProfile] = useState(false);
  const EditBtn = () => {
    setEditProfile(true);
  };

  const uploadDP = () => {
    setFoundError(false);
    setAlertMessage(null);
    setLoading(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("profilePicture", profilePicture);

    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        // "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: formData,
      /*body: JSON.stringify({
                formData
            })*/
    };

    fetch(urls.api + "profile/upload-picture", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setAlertMessage(json.message);
          setFoundError(false);
        } else if (json.message !== null) {
          setAlertMessage(json.message);
          setFoundError(true);
        } else {
          setAlertMessage(ErrorMessages.UnkownRetry);
          setFoundError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const uploadSignature = () => {
    setFoundError(false);
    setAlertMessage(null);
    setLoading(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("scannedSignature", signature);

    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        // "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: formData,
      /*body: JSON.stringify({
                        formData
                    })*/
    };

    fetch(urls.api + "profile/upload-signature", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setAlertMessage(json.message);
          setFoundError(false);
        } else if (json.message !== null) {
          setAlertMessage(json.message);
          setFoundError(true);
        } else {
          setAlertMessage(ErrorMessages.UnkownRetry);
          setFoundError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const [bio, setBio] = useState([]);

  const getUserProfile = () => {
    setFoundError(false);
    setAlertMessage(null);
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "profile/my-profile", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          setBio(json.data.company.bio);
          setName(json.data.company.name);
          setEmail(json.data.company.email);
          setPhone(json.data.company.phoneNumber);
          setAddress(json.data.company.address);
          setLogo(json.data.company.logo);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const updateProfile = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        // "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };
    const data = {
      address: address,
      phoneNumber: phone,
    };
    axios
      .post(urls.api + "update/company-address", data, postOptions)
      .then((updated) => {
        toast.success("Profile Updated successfully");
      })
      .catch((error) => {
        toast.error("Unable to Update Profile");
      });
  };

  const successMsg = () => {
    return (
      <SweetAlert
        success
        title={alertMessage}
        onConfirm={() => {
          setFoundError(false);
          setAlertMessage(null);
        }}
      />
    );
  };

  const errorMsg = () => {
    return (
      <SweetAlert
        error
        title={alertMessage}
        onConfirm={() => {
          setFoundError(false);
          setAlertMessage(null);
        }}
      />
    );
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div class="content container-fluid">
      <PageTitle title="Personal Information" />

      <TraineeCheckAccountStatus _token={token} />

      <div class="card">
        <div class="card-body">
          <div style={{ display: !editProfile ? `block` : `none` }}>
            <div className="row">
              <div class="col-xl-12 col-sm-6  text-center mb-3">
                <div class="avatar avatar-xxl">
                  <img
                    className="avatar-img rounded-circle"
                    src={logo == null ? Images.avatar : logo}
                  />
                </div>{" "}
              </div>

              {/* <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>First Name</h6>
                <label>{bio.firstName} </label>
              </div> */}

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Name</h6>
                <label>{name}</label>
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Email</h6>
                <label>{email} </label>
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Phone Number</h6>
                <label>{phone} </label>
              </div>

              <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Company/Sponsorship Address</h6>
                <label>{address} </label>
              </div>

              {/* <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Signature</h6>

                {bio.signature == null ? (
                  <label>No Signature</label>
                ) : (
                  <div class="avatar avatar-xxl">
                    <img
                      className="avatar-img rounded-circle"
                      src={bio.signature}
                    />{" "}
                  </div>
                )}
              </div> */}

              {/* <div class="col-xl-6 col-sm-6 col-12 mb-3">
                <h6>Account Type</h6>
					<label> </label>					
					</div> */}

              <div className="col-md-12">
                <button
                  onClick={() => EditBtn()}
                  className="btn btn-block btn-success"
                >
                  Edit <i className="fa fa-pen"></i>{" "}
                </button>
              </div>
            </div>
          </div>

          {/* Profile edit start */}
          <div style={{ display: editProfile ? `block` : `none` }}>
            <h4>Edit Profile</h4>
            <div className="row">
              <div class="col-xl-6 col-sm-6  text-center mb-3">
                <div class="avatar avatar-xxl">
                  <img
                    className="avatar-img rounded-circle"
                    src={logo == null ? Images.avatar : logo}
                  />
                </div>{" "}
              </div>

              {/* <div class="col-xl-6 col-sm-6  text-center mb-3">
                <div class="avatar avatar-xxl">
                  <img
                    className="avatar-img rounded-circle"
                    src={
                      bio.signature == null ? Images.signature : bio.signature
                    }
                  />
                </div>{" "}
              </div> */}

              <div class="col-xl-6 col-sm-6  text-center mb-3">
                <div style={{ display: !updateDP ? `block` : `none` }}>
                  <button
                    onClick={() => updateDPInitBtn()}
                    className="btn btn-sm btn-primary"
                  >
                    Update Logo <i className="fas fa-camera"></i>{" "}
                  </button>
                </div>
                <div style={{ display: updateDP ? `block` : `none` }}>
                  <div class="input-group">
                    <input
                      className="form-control"
                      type={"file"}
                      accept="image/*"
                      onChange={(e) => setProfilePicture(e.target.files[0])}
                    />
                    <div class="input-group-append">
                      <button
                        style={{ display: loading ? `none` : `block` }}
                        onClick={() => uploadDP()}
                        className="btn btn-sm btn-primary"
                      >
                        Upload Profile Picture <i className="fas fa-camera"></i>{" "}
                      </button>
                      <button
                        style={{ display: loading ? `block` : `none` }}
                        className="btn btn-sm btn-primary"
                      >
                        {" "}
                        <Spinner />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="col-xl-6 col-sm-6  text-center mb-3">
                <div style={{ display: !updateSignature ? `block` : `none` }}>
                  <button
                    onClick={() => updateSignatureInitBtn()}
                    className="btn btn-sm btn-primary"
                  >
                    Update Signature <i className="fas fa-camera"></i>{" "}
                  </button>
                </div>
                <div style={{ display: updateSignature ? `block` : `none` }}>
                  <div class="input-group">
                    <input
                      className="form-control"
                      type={"file"}
                      accept="image/*"
                      onChange={(e) => setSignature(e.target.files[0])}
                    />
                    <div class="input-group-append">
                      <button
                        style={{ display: loading ? `none` : `block` }}
                        onClick={() => uploadSignature()}
                        className="btn btn-sm btn-primary"
                      >
                        Upload Signature <i className="fas fa-camera"></i>{" "}
                      </button>
                      <button
                        style={{ display: loading ? `block` : `none` }}
                        className="btn btn-sm btn-primary"
                      >
                        {" "}
                        <Spinner />{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-md-6">
                <div class="form-group">
                  <label for="text">Name:</label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div class="form-group">
                  <label for="email">Email:</label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    class="form-control"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div class="form-group">
                  <label for="email">Phone:</label>
                  <input
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    type="tel"
                    class="form-control"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div class="form-group">
                  <label for="address">Company/Sponsorship Address:</label>
                  <input
                    value={address}
                    required
                    onChange={(e) => setAddress(e.target.value)}
                    type="address"
                    class="form-control"
                  />
                </div>
              </div>

              {/* <div className='col-md-6'>
                        <div class="form-group">
                            <label for="password">Account Type:</label>

                            
                            <select class="form-control" onChange={(e) => setAccountType(e.target.value)}>
                                <option value={null}>Select</option>
{accountTypeList.map(x => 
  <option value={x.type}>{x.label}</option>  
    )}
                            </select>
                            
                        </div>
                        </div> */}
              <div className="col-md-12"></div>

              <div className="col-md-12">
                <button
                  onClick={() => updateProfile()}
                  style={{ display: loading ? `none` : `block` }}
                  className="btn btn-block btn-success"
                >
                  Update <i className="fa fa-check"></i>{" "}
                </button>
                <button
                  style={{ display: loading ? `block` : `none` }}
                  className="btn btn-block btn-success"
                >
                  {" "}
                  <Spinner />{" "}
                </button>
              </div>
            </div>
          </div>
          {/* Profile edit end */}
        </div>
      </div>

      <div className="col">
        {!foundError && alertMessage !== null ? successMsg() : ""}

        {foundError && alertMessage !== null ? errorMsg() : ""}
      </div>
    </div>
  );
};

export default TraineePersonalInformation;
