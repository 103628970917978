import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../Layouts/PageTitle";
import urls from "../../utilities/urls";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const token = localStorage.getItem("token");
const TraineeTickets = () => {
  const [ticketCategoryList, setTicketCategoryList] = useState([
    { id: 1, cat: "Low" },
    { id: 2, cat: "Medium" },
    { id: 3, cat: "High" },
  ]);
  const [ticket, setTicket] = useState([]);
  const [createNewTicket, setCreateNewTicket] = useState(false);
  const createTicket = () => {
    setCreateNewTicket(true);
  };
  // console.log(createNewTicket);

  const [initialState, setInitialState] = useState({
    title: "",
    description: "",
    priority: "",
    email: "",
  });

  const handleChange = (e) => {
    const newData = { ...initialState };
    newData[e.target.id] = e.target.value;
    setInitialState(newData);
  };
  const submitTicket = async (e) => {
    e.preventDefault();
    console.log(initialState);
    //const id = toast.loading("Loading...")
    try {
      await axios
        .post(
          urls.api + "support-ticket/create-new-support-ticket",
          initialState,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        )
        .then((result) => {
          console.log(result);
          console.log(result.data.success);
          if (result.data.success === true) {
            toast.success("Ticket Created Successfully");
          }
        })
        .catch((internalError) => {
          console.log("an error has occured", internalError);
        });
    } catch (error) {
      console.log(error);
      //  toast.update(id, {render: error.response.data.message, type: "error", isLoading: false, toastId: 2, autoClose: 5000});
    }
  };
  const readyTicket = async () => {
    await axios
      .get(urls.api + "support-ticket", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        console.log(result.data.data.supportTicket);
        setTicket(result.data.data.supportTicket);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    readyTicket();
  }, []);

  return (
    <div class="content container-fluid">
      <PageTitle title="Tickets" />
      <ToastContainer pauseOnFocusLoss={false} />
      <button onClick={() => createTicket()} className="btn btn-success mb-3">
        New Ticket <i className="fa fa-plus-square"></i>{" "}
      </button>

      <div class="card">
        <div class="card-body">
          <form
            style={{ display: createNewTicket ? `block` : `none` }}
            onSubmit={(e) => {
              submitTicket(e);
            }}
          >
            <label>Email</label>
            <input
              className="form-control mb-3"
              id="email"
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder="Email"
              required
            />

            <label>Ticket Title</label>
            <input
              className="form-control mb-3"
              id="title"
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder="Subject"
              required
            />

            <label>Ticket Priority</label>
            <select
              className="form-control mb-3"
              id="priority"
              onChange={(e) => {
                handleChange(e);
              }}
              required
            >
              {ticketCategoryList.map((y) => (
                <option>{y.cat}</option>
              ))}
            </select>

            <label>Ticket Description</label>
            <textarea
              placeholder="Enter your feedback"
              id="description"
              onChange={(e) => {
                handleChange(e);
              }}
              className="form-control mb-3"
              required
            ></textarea>
            <input className="btn btn-success" type="submit" value="Create" />
          </form>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div
            className="table-responsive"
            style={{
              height: "400px",
              margin: "4px 4px",
              padding: "4px",
              overflowX: "hidden",
              overflowY: "auto",
              textAlign: "justify",
              display: createNewTicket ? `none` : `block`,
            }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Title</th>
                  <th>Priority</th>
                  <th>Status</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {ticket.length ? (
                  ticket.map((key) => {
                    return (
                      <>
                        <tr>
                          <td>{key.email}</td>
                          <td>{key.title}</td>
                          <td>{key.priority}</td>
                          <td>{key.status}</td>
                          <td>{key.description}</td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeTickets;
