import { useEffect, useState } from "react";
import Alert from "../../utilities/Alert";
import ErrorMessages from "../../utilities/ErrorMessages";
import Spinner from "../../utilities/spinner";
import urls from "../../utilities/urls";
import PageTitle from "../../trainer-dashboard/Layouts/PageTitle";

const TraineeCheckAccountStatus = (props) => {
  const [foundError, setFoundError] = useState(false);

  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  const profileCheck = (x) => {
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "profile/my-profile", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          let user = json.data.user;
        } else if (json.message == "Unauthenticated.") {
          //alert(props._token)
          logOut();
          window.location.href = urls.home + "logout";
        }
        // alert(JSON.stringify(json.message))
      })
      .catch((error) => {
        logOut();
        window.location.href = urls.home + "logout";
      })
      .finally(() => console.log("***"));
  };

  useEffect(() => {
    profileCheck(5);
  }, []);

  return <></>;
};

export default TraineeCheckAccountStatus;
