import { FileText } from "phosphor-react";
import React from "react";
import PageBuilder from "../../components/layout/PageBuilder";
import { triggerDownloadPipe } from "../../helpers";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";

const TrainerDataReports = () => {
  const Links = {
    CONFIRMED_TRAINER_LIST: {
      link: "/api/v1/trainer-reports/list-of-trainers",
      filename: "CONFIRMED_TRAINER_LIST_REPORT",
    },
    CONFIRMED_TRAINER_REGISTRATIONS_LIST: {
      link: "/api/v1/trainer-reports/confirmed-trainer-registration",
      filename: "CONFIRMED_REGISTERED_TRAINER_LIST_REPORT",
    },
    NO_PROFILE_UPDATE: {
      link: "/api/v1/trainer-reports/no-profile-update",
      filename: "TRAINER_NO_PROFILE_UPDATE_REPORT",
    },
    KIT_SIZE_SUMMARY: {
      link: "/api/v1/trainer-reports/kit-size-summary",
      filename: "TRAINER_KIT_SIZE_SUMMARY_REPORT",
    },
    STATE_OF_RESIDENCE_SUMMARY: {
      link: "/api/v1/trainer-reports/state-of-residence",
      filename: "TRAINER_STATE_OF_RESIDENCE_REPORT",
    },
    REGION_SUMMARY: {
      link: "/api/v1/trainer-reports/region-summary",
      filename: "TRAINER_REGION_SUMMARY_REPORT",
    },
    FULL_PROFILE_LIST: {
      link: "/api/v1/trainer-reports/full-profile-list",
      filename: "TRAINER_FULL_PROFILE_REPORT",
    },
    ALL_TRAINERS_LIST: {
      link: "/api/v1/trainer-reports/all",
      filename: "ALL_TRAINERS_REPORT",
    },
  };

  const client = useClient();

  const download = ({ link, filename }) => {
    flashMessage({ message: "Generating report", type: "info" });
    client(link, {
      method: "GET",
      res: "file",
    })
      .then(triggerDownloadPipe(filename))
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response;
          flashMessage({ message: data.message, type: "error" });
        } else {
          flashMessage({
            message:
              "Something went wrong generating your report, please try again later.",
            type: "error",
          });
        }
      });
  };
  return (
    <PageBuilder name="Trainer Data Reports">
      <div className="flex flex-col w-full p-4">
        <div className="flex flex-col mt-10">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-4">
            Confirmed Trainers
          </span>
          <div className="flex">
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() => download(Links.CONFIRMED_TRAINER_LIST)}
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">List of Confirmed Trainers</span>
            </button>
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() =>
                download(Links.CONFIRMED_TRAINER_REGISTRATIONS_LIST)
              }
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">
                List of Confirmed Trainer Registrations
              </span>
            </button>
          </div>
        </div>
        <div className="flex flex-col mt-14">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-4">
            Generic Reports
          </span>
          <div className="flex">
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() => download(Links.NO_PROFILE_UPDATE)}
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">No Profile Update</span>
            </button>
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() => download(Links.KIT_SIZE_SUMMARY)}
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Kit Size Summary</span>
            </button>
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() => download(Links.STATE_OF_RESIDENCE_SUMMARY)}
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">State of Residence Summary</span>
            </button>
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() => download(Links.REGION_SUMMARY)}
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Region Summary</span>
            </button>
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() => download(Links.FULL_PROFILE_LIST)}
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">Full Profile List</span>
            </button>
            <button
              className="flex flex-col mr-10 my-10 max-w-[150px] min-w-[150px]"
              onClick={() => download(Links.ALL_TRAINERS_LIST)}
            >
              <div className="rounded-full bg-gray-200 p-3 max-w-max mb-[0.75rem]">
                <FileText weight="regular" className="w-7 h-7" />
              </div>
              <span className="!font-thin">List of Records</span>
            </button>
          </div>
        </div>
      </div>
    </PageBuilder>
  );
};

export default TrainerDataReports;
