import { CalendarBlank } from "phosphor-react";
import React from "react";
import Datetime from "react-datetime";
import Select from "react-select";
import PaginatedDataTable from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";

const TrainingSuggestions = () => {
  return (
    <PageBuilder name={"Training Suggestions"}>
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full px-4 py-4">
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Basic Search
            </span>
            <div className="flex w-full">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Fiscal Year</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black">Training Category</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
            </div>
            <div className="flex w-full mt-4">
              <div className="flex flex-col mr-2">
                <span className="text-black">Status</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <button type="submit" className="mx-2 my-4 btn btn-primary">
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2">
            <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
              Advanced Search
            </span>
            <div className="flex w-full">
              <div className="flex flex-col mr-5">
                <span className="text-black">Start Date</span>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "01-02-2020",
                    }}
                  />
                  <CalendarBlank
                    weight="bold"
                    className="z-10 w-5 h-5 -ml-10"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-black">End Date</span>
                <div className="flex items-center">
                  <Datetime
                    inputProps={{
                      className:
                        "bg-gray-50 min-w-[15rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                      placeholder: "01-02-2020",
                    }}
                  />
                  <CalendarBlank
                    weight="bold"
                    className="z-10 w-5 h-5 -ml-10"
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full mt-4">
              <div className="flex flex-col mr-[1.9rem]">
                <span className="text-black">Training Category</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <span className="text-black">Status</span>
                <Select
                  options={[]}
                  isSearchable={false}
                  className="flex items-center justify-center min-w-[15rem]"
                  classNamePrefix="select"
                  placeholder="All"
                />
              </div>
              <div className="flex flex-col">
                <button type="submit" className="mx-2 my-4 btn btn-primary">
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col px-4">
          <PaginatedDataTable
            title="List of Training Suggestions"
            exports={{ excel: true, messagingList: true }}
            refresh
            columns={[
              { Header: "Course Title", accessor: "applicationUuid" },
              { Header: "Training Category", accessor: "firstName" },
              { Header: "Status", accessor: "surname" },
              { Header: "Submitted On", accessor: "courseTitle" },
              { Header: "Submitted By", accessor: "updatedAt" },
            ]}
            data={[]}
          />
        </div>
      </div>
    </PageBuilder>
  );
};

export default TrainingSuggestions;
