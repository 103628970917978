import { WarningCircle } from "phosphor-react";
import { Tabs, Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageBuilder from "../../components/layout/PageBuilder";
import { flashMessage } from "../../helpers/flash";
import { useClient } from "../../providers/AuthProvider";
import { Spinner } from "flowbite-react";
import Select from "react-select";

const FAQDetails = () => {
  const { id } = useParams();
  const [faq, setFAQ] = useState(null);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const client = useClient();

  useEffect(() => {
    client(`/api/v1/faqs/all/${id}`, {
      method: "GET",
      credentials: true,
    })
      .then((res) => {
        if (res.success === true) {
          setFAQ(res.data);
          setLoading(false);
        } else {
          setFAQ(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
        setLoading(false);
      });
  }, [id, client]);

  const [modalShow, setModalShow] = useState(false);

  const deleteFAQ = () => {
    client(`/api/v1/faqs/${id}`, {
      method: "DELETE",
      credentials: true,
    })
      .then(() => {
        navigate(-1);
        flashMessage({
          message: "FAQ Deleted Successfully",
          type: "success",
          delay: 1000,
        });
      })
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  const editFAQ = (e) => {
    setModalShow(false);
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;

    const data = {
      faq_name: form.name.value,
      faq_content: form.content.value,
      published_status: form.status.value,
    };

    client(`/api/v1/faqs/${id}`, {
      method: "PUT",
      data: data,
      credentials: true,
    }).then((res) => {
      if (res.success === true) {
        flashMessage({ message: res.message, type: "success" });
      } else {
        flashMessage({ message: res.message, type: "error" });
      }
    });
  };

  return (
    <PageBuilder
      name={`FAQ Details - ${faq?.faq_name ?? "loading..."}`}
      extras={
        faq ? (
          <div className="flex flex-row w-full h-full py-4">
            <button
              type="button"
              className="mx-2 my-2 text-red-600 bg-red-100 btn hover:bg-red-300"
              onClick={() => deleteFAQ()}
            >
              Delete
            </button>
            <button
              type="button"
              className="mx-2 my-2 btn btn-secondary"
              onClick={() => setModalShow(true)}
            >
              Edit
            </button>
            <React.Fragment>
              <Modal
                show={modalShow}
                onClose={() => {
                  setModalShow(false);
                }}
                size="5xl"
              >
                <Modal.Body>
                  <form
                    className="flex flex-col bg-white max-h-[75vh] overflow-y-scroll"
                    onSubmit={editFAQ}
                    id="editFAQForm"
                  >
                    <div className="flex items-center justify-between px-5">
                      <h1 className="my-6 mr-4 text-3xl text-left text-gray-700 text-bold">
                        Edit FAQ Details
                      </h1>
                      <div className="flex">
                        <button
                          type="button"
                          className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                          onClick={() => setModalShow(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="flex items-center mx-0 btn btn-secondary"
                        >
                          <span>Edit FAQ</span>
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center w-full px-5">
                      <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                      <span>
                        All fields in asterisk (
                        {<span className="text-red-600">*</span>}) are required.
                      </span>
                    </div>
                    <div className="flex flex-col w-auto px-5 my-4 bg-white">
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col min-w-[24rem] mr-16">
                          <label htmlFor="name" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} FAQ Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="FAQ Name"
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            defaultValue={faq?.faq_name}
                            required
                          />
                        </div>
                      </div>
                      <div className="flex w-full mb-4">
                        <div className="flex flex-col w-full mr-16">
                          <label htmlFor="content" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>}
                            FAQ Content
                          </label>
                          <textarea
                            id="content"
                            name="content"
                            placeholder="FAQ Content"
                            className="placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            required
                            defaultValue={faq?.faq_content}
                          ></textarea>
                        </div>
                        <div className="flex flex-col min-w-[24rem]">
                          <label htmlFor="name" className="mb-1 text-left">
                            {<span className="text-red-600">*</span>} FAQ
                            Published Status
                          </label>
                          <Select
                            className="p-0 placeholder-gray-300 border border-gray-100 rounded-lg focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                            placeholder="Published Status"
                            classNamePrefix="select"
                            options={["published", "pending"].map((option) => ({
                              value: option,
                              label: option,
                            }))}
                            isSearchable={false}
                            name="status"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </React.Fragment>
          </div>
        ) : (
          <></>
        )
      }
    >
      <div className="flex flex-col w-full">
        {loading ? (
          <div className="flex items-center justify-center w-full py-10">
            <Spinner size="xl" />
          </div>
        ) : faq !== null ? (
          // eslint-disable-next-line
          <Tabs.Group style="underline">
            <Tabs.Item active={true} title="FAQ Information">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-12">
                  <div className="flex items-center mb-6">
                    <h6 className="font-[Arial] uppercase text-xs text-gray-400 font-semibold mr-2">
                      FAQ Information
                    </h6>
                    <span
                      className={`py-1 px-3 rounded-3xl text-xs uppercase max-h-[1.75rem] ${
                        faq?.published_status === "published"
                          ? "text-secondary-500 bg-secondary-200 border-secondary-400"
                          : "text-yellow-500 bg-yellow-200 border-yellow-400"
                      }`}
                    >
                      {" "}
                      {faq?.published_status}
                    </span>
                  </div>
                  <div className="flex w-full mb-10">
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        FAQ Name
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {faq?.faq_name}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        FAQ Content
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {faq?.faq_content}
                      </span>
                    </div>
                    <div className="flex flex-col mr-12 min-w-[250px] max-w-[250px]">
                      <span className="mb-1 text-black text-md font-regular">
                        FAQ Publish Date
                      </span>
                      <span className="text-base text-gray-400 !font-[200]">
                        {faq?.published_date}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Tabs.Item>
          </Tabs.Group>
        ) : (
          <div className="flex items-center justify-center w-full py-10">
            <h3 className="text-lg">Unable to fetch records for faq '{id}'</h3>
          </div>
        )}
      </div>
    </PageBuilder>
  );
};

export default FAQDetails;
