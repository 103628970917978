import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PageTitle from "./Layouts/PageTitle";
import TraineeCheckAccountStatus from "./registration-components/CheckAccountStatus";
import axios from "axios";
import urls from "../utilities/urls";
import NavMobile from "./Layouts/NavMobile";

const TraineeDashbaord = () => {
  // let assignments = [
  //   {
  //     course: "Animal Breeding",
  //     date: "Jan 2, 2022",
  //     date_due: "Jan 4, 2022",
  //     score: 0,
  //     status: 0,
  //   },
  //   {
  //     course: "Animal Breeding",
  //     date: "Dec 2, 2021",
  //     date_due: "Dec 4, 2021",
  //     score: 62,
  //     status: 1,
  //   },
  //   {
  //     course: "Animal Breeding",
  //     date: "Dec 2, 2021",
  //     date_due: "Dec 4, 2021",
  //     score: 43,
  //     status: 1,
  //   },
  //   {
  //     course: "Animal Breeding",
  //     date: "Mar 2, 2021",
  //     date_due: "Mar 4, 2021",
  //     score: 0,
  //     status: 2,
  //   },
  // ];

  const search = useLocation().search;
  const id_ = new URLSearchParams(search).get("punch");

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [announcements, setAnnouncements] = useState([]);
  
  const [myCourses, setMyCourses] = useState([]);
  const [countOfCourses, setCountOfCourses] = useState(0)
  const [countApplication, setCountApplication] = useState(0)
  const [countQuiz, setCountQuiz] = useState(0)
  const [countAssignment, setCountAssignment] = useState(0)

  const postOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  };

  const coursesApplied = async () => {
   await fetch(urls.api + "application/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          //console.log('Logging my courses')
         // console.log(json.data.applications);
          setMyCourses(json.data.applications);
        } else {
       //   console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const countOccurence = (myCourses,key)=>{
      let arr2=[];
      myCourses.forEach((course)=>{
         // Checking if there is any object in arr2
            // which contains the key value
             if(arr2.some((val)=>{ return val[key] === course.status })){
              

               // If yes! then increase the occurrence by 1
               arr2.forEach((x)=>{
                 if(x[key] === course.status){
                  x["occurrence"]++
                  if(course.status === "confirmed"){
                    setCountOfCourses(x["occurrence"])
                    setCountQuiz(x["occurrence"])
                    setCountAssignment(x["occurrence"])
                  }
                  
                 }
               })
             }else{
              // If not! Then create a new object initialize 
       // it with the present iteration key's value and 
       // set the occurrence to 1
              let a ={};
              a[key] = course.status
              a["occurrence"] = 1
              arr2.push(a);
             }
      })
      return arr2
    }
//     const interval = setInterval(function() {
//    console.log(countOccurence(myCourses,"status"));
//  }, 5000);

// clearInterval(interval)


  useEffect(() => {
  
    coursesApplied();

    countOccurence(myCourses,"status");
    setCountApplication(myCourses.length);

    (async () => {
      const announcements = urls.api + "announcements/";
      await axios.get(announcements).then((result) => {
        const announcements = result.data.data.announcements;
        setAnnouncements(announcements);
      });
    })();


    if (id_ !== null && id_.length > 2) {
      localStorage.setItem("token", id_);
      setToken(id_);
    } else {
      if (localStorage.getItem("token") !== null) {
        setToken(localStorage.getItem("token"));
      }
    }
  }, [myCourses]);

  const appCount = localStorage.getItem("appCount");

  return (
    <div>
      <div className="navMenu">
        <div className="t">
          <NavMobile />
        </div>
        <div className="w">
          <PageTitle title="Dashboard" />

          <TraineeCheckAccountStatus _token={token} />

          <div class="row">
            <div class="col-xl-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-body">
                  <Link to={"/trainee/my_courses"} class="dash-widget-header">
                    <span class="text-primary">
                      <i class="fas fa-layer-group fa-2x"></i>
                    </span>
                    <div class="dash-widget-info">
                      <h3 className="text-primary">{countOfCourses}</h3>
                      <h6 class="text-muted">My Courses</h6>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-body">
                  <Link to={"/trainee/application"} class="dash-widget-header">
                    <span class="text-primary">
                      <i class="fas fa-user-shield fa-2x"></i>
                    </span>
                    <div class="dash-widget-info">
                      <h3 className="text-primary">
                        {countApplication}
                      </h3>
                      <h6 class="text-muted">Applications</h6>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-body">
                  <Link to={"/trainee/quizzes"} class="dash-widget-header">
                    <span class="text-primary">
                      <i class="fas fa-pen fa-2x"></i>
                    </span>
                    <div class="dash-widget-info">
                      <h3 className="text-primary">
                        {countQuiz}
                      </h3>
                      <h6 class="text-muted">Quizzes</h6>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-body">
                  <Link to={"/trainee/assignments"} class="dash-widget-header">
                    <span class="text-primary">
                      <i class="far fa-list-alt fa-2x"></i>
                    </span>
                    <div class="dash-widget-info">
                      <h3 className="text-primary">
                        {countAssignment}
                      </h3>
                      <h6 class="text-muted">Assignments</h6>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div class="col-xl-12 col-sm-6 col-12">
              <h4 className="my-3">Announcements</h4>
              <div class="card">
                {announcements.length != 0 ? (
                  <div class="card-body">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Title</th>
                            <th>
                              <Link
                                to={"/trainee/announcement"}
                                className="btn btn-sm btn-primary m-0"
                              >
                                View All
                              </Link>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {announcements.map((x) => (
                            <tr className="text-secondary">
                              <td>{x.createdAt}</td>
                              <td>{x.title}</td>
                              <td>
                                <Link
                                  to={"/trainee/" + x.id + "/view_announcement"}
                                  className="text-info"
                                >
                                  {" "}
                                  <button className="btn btn-sm btn-secondary">
                                    Read{" "}
                                    <i className="fa fa-chevron-right text-white"></i>
                                  </button>{" "}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <h5 className="p-4 font-bold text-lg">"No Announcements"</h5>
                )}
              </div>
            </div>
          </div>


          {/* <div className="row">
            <div class="col-xl-12 col-sm-6 col-12">
              <h4 className="my-3">Assignments</h4>
              <div class="card">
                <div class="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Course Title</th>
                          <th>
                            <p>Date</p>
                          </th>
                          <th>Due Date </th>
                          <th>Score </th>
                          <th>Status </th>
                          <th>
                            <Link
                              to={"/trainee/assignments"}
                              className="btn btn-sm btn-primary"
                            >
                              View All
                            </Link>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {assignments.map((x) => (
                          <tr class="text-secondary">
                            <td>{x.course}</td>
                            <td>{x.date}</td>
                            <td>{x.date_due}</td>
                            <td>{x.score}%</td>
                            <td colSpan={2}>
                              {x.status == 0 ? (
                                <span className="text-info">
                                  Take Assignment
                                </span>
                              ) : (
                                ""
                              )}
                              {x.status == 1 ? (
                                <span className="text-success">View</span>
                              ) : (
                                ""
                              )}
                              {x.status == 2 ? (
                                <span className="text-secondary">
                                  Not Taken
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TraineeDashbaord;
