import {
  ArrowsCounterClockwise,
  EnvelopeSimple,
  Table,
  ArrowDown,
  ArrowUp,
  WarningCircle,
  CalendarBlank,
} from "phosphor-react";
import React, { useState, useRef, useEffect, useMemo, forwardRef } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { matchSorter } from "match-sorter";
import { Modal, Pagination, ToggleSwitch } from "flowbite-react";
import Select from "react-select";
import Datetime from "react-datetime";
import { useData } from "../providers/DataProvider";
import { useNavigate } from "react-router-dom";
import { flashMessage } from "../helpers/flash";

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex w-full px-2 py-1">
      <span className="text-md">Search:</span>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="ml-2 w-full h-full"
      />
    </div>
  );
}

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="py-2 px-1 mt-1 rounded-md"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      className="py-2 pl-3 mt-1 rounded-md min-w-[10rem]"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

export function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const PaginatedDataTable = ({
  title = "",
  exports = { excel: false, messagingList: false },
  refresh = false,
  columns = [],
  data = [],
  pageSize = 100,
  count = 1,
  currentPage = 1,
  setCurrentPage = () => {},
  onRowClick = () => {},
  multiSelectActions = () => {},
  exportToExcel = (startDate, endDate, selectedColumns) => {},
}) => {
  const [multiSelectMode, setMultiSelectMode] = useState(false);
  const [excelExportColumns, setExcelExportColumns] = useState([]);
  const [excelExportRange, setExcelExportRange] = useState({});
  const [messageExportColumn, setMessageExportColumn] = useState(null);
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [showMessagingListModal, setShowMessagingListModal] = useState(false);
  const {
    temp: { storeMessagingList },
  } = useData();
  const navigate = useNavigate();

  const doMSLExport = (console) => {
    if (messageExportColumn === null) {
      flashMessage({
        message: "You need to select the column to be exported",
        type: "error",
      });
    } else {
      // console.log(
      //   selectedFlatRows.map((row) => ({
      //     label: row.original[messageExportColumn],
      //     value: row.original[messageExportColumn],
      //   }))
      // );
      storeMessagingList(
        selectedFlatRows.map((row) => ({
          label: row.original[messageExportColumn],
          value: row.original[messageExportColumn],
        }))
      );
      navigate(console);
    }
  };

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  const start = (currentPage - 1) * pageSize + 1;
  const end = pageSize > data.length ? count : currentPage * pageSize;
  return (
    <div className="flex flex-col w-full my-4">
      <div className="flex flex-col sm:flex-row h-16 w-full mb-5 items-center justify-between">
        <div className="flex-col sm:w-4/12 xl:w-6/12">
          <span className="!font-light text-xl text-center lg:!text-start w-full text-slate-600 mb-3">
            {title}
          </span>
          <div className="flex items-center justify-center md:justify-start">
            <ToggleSwitch
              checked={multiSelectMode}
              label="Multi Select Mode"
              onChange={() => setMultiSelectMode(!multiSelectMode)}
            />
          </div>
        </div>
        <div className="flex sm:flex-col sm:w-8/12 xl:w-6/12">
          {selectedFlatRows.length > 0 ? (
            multiSelectActions(selectedFlatRows)
          ) : (
            <></>
          )}
          <div className="flex w-full justify-end">
            {refresh ? (
              <button
                className="btn btn-white !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between mr-2"
                onClick={() => window.location.reload()}
              >
                <ArrowsCounterClockwise weight="bold" className="w-5 h-5" />
                <span className="ml-1">Refresh</span>
              </button>
            ) : (
              <></>
            )}
            <div className="btn-group">
              {exports.excel ? (
                <>
                  <button
                    className="btn btn-white !border !border-slate-300 shadow-sm rounded-l-md rounded-r-none !border-r-[0.5px] outline-0 flex items-center justify-between"
                    onClick={() => setShowExcelModal(true)}
                  >
                    <Table weight="bold" className="w-4 h-4" />
                    <span className="ml-1 hidden sm:flex">
                      {selectedFlatRows.length > 0
                        ? "Export selected to Excel"
                        : "Export to Excel"}
                    </span>
                  </button>
                  <React.Fragment>
                    <Modal
                      show={showExcelModal}
                      onClose={() => {
                        setShowExcelModal(false);
                      }}
                      size="5xl"
                    >
                      <Modal.Body>
                        <div className="flex flex-col bg-white">
                          <div className="flex justify-between items-center px-5">
                            <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
                              Export parameters
                            </h1>
                            <div className="flex">
                              <button
                                type="button"
                                className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                                onClick={() => setShowExcelModal(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary mx-0 flex items-center"
                                onClick={() => {
                                  if (
                                    excelExportRange.startDate === undefined ||
                                    excelExportRange.endDate === undefined
                                  ) {
                                    flashMessage({
                                      message: "Please select date range",
                                      type: "error",
                                    });
                                  } else if (excelExportColumns.length < 1) {
                                    flashMessage({
                                      message:
                                        "Please select columns to export",
                                      type: "error",
                                    });
                                  } else {
                                    exportToExcel(
                                      excelExportRange?.startDate,
                                      excelExportRange?.endDate,
                                      excelExportColumns
                                    );
                                  }
                                }}
                              >
                                <span>Export</span>
                              </button>
                            </div>
                          </div>
                          <div className="flex items-center w-full px-5">
                            <WarningCircle
                              weight="bold"
                              className="w-5 h-5 mr-2"
                            />
                            <span>
                              All fields in asterisk (
                              {<span className="text-red-600">*</span>}) are
                              required.
                            </span>
                          </div>
                          <div className="flex flex-col px-5 bg-white my-4 w-auto">
                            {selectedFlatRows.length > 0 ? (
                              <></>
                            ) : (
                              <div className="flex w-full mb-4">
                                <div className="flex flex-col min-w-[24rem] mr-16">
                                  <label
                                    htmlFor="start_date"
                                    className="text-left mb-1"
                                  >
                                    {<span className="text-red-600">*</span>}{" "}
                                    Start Date
                                  </label>
                                  <div className="flex items-center">
                                    <Datetime
                                      inputProps={{
                                        className:
                                          "bg-gray-50 min-w-[24rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                        placeholder: "01-02-2020",
                                        id: "start_date",
                                        name: "start_date",
                                        required: true,
                                      }}
                                      onChange={(value) => {
                                        setExcelExportRange({
                                          ...excelExportRange,
                                          startDate: value,
                                        });
                                      }}
                                    />
                                    <CalendarBlank
                                      weight="bold"
                                      className="w-5 h-5 -ml-10 z-10"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-col min-w-[24rem]">
                                  <label
                                    htmlFor="end_date"
                                    className="text-left mb-1"
                                  >
                                    {<span className="text-red-600">*</span>}{" "}
                                    End Date
                                  </label>
                                  <div className="flex items-center">
                                    <Datetime
                                      inputProps={{
                                        className:
                                          "bg-gray-50 min-w-[24rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                        placeholder: "01-04-2020",
                                        id: "end_date",
                                        name: "end_date",
                                        required: true,
                                      }}
                                      onChange={(value) => {
                                        setExcelExportRange({
                                          ...excelExportRange,
                                          endDate: value,
                                        });
                                      }}
                                    />
                                    <CalendarBlank
                                      weight="bold"
                                      className="w-5 h-5 -ml-10 z-10"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="flex flex-col w-full mb-4">
                              <label
                                htmlFor="start_date"
                                className="text-left mb-1"
                              >
                                {<span className="text-red-600">*</span>}{" "}
                                Columns to Export
                              </label>
                              <Select
                                className="w-full border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                                placeholder="Columns"
                                classNamePrefix="select"
                                options={columns.map((column) => ({
                                  value: column.accessor,
                                  label: column.Header,
                                }))}
                                isSearchable
                                isMulti
                                onChange={(newVal) => {
                                  setExcelExportColumns(
                                    newVal.map((x) => x.value)
                                  );
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </React.Fragment>
                </>
              ) : (
                <></>
              )}
              {exports.messagingList ? (
                <>
                  <button
                    className="btn btn-white !border !border-slate-300 shadow-sm rounded-l-none rounded-r-md !border-l-[0.5px] outline-0 flex items-center justify-between"
                    onClick={() => setShowMessagingListModal(true)}
                  >
                    <EnvelopeSimple weight="bold" className="w-4 h-4" />
                    <span className="ml-1 hidden sm:flex">
                      {selectedFlatRows.length > 0
                        ? "Export selected as Messaging List"
                        : "Export as Messaging List"}
                    </span>
                  </button>
                  <React.Fragment>
                    <Modal
                      show={showMessagingListModal}
                      onClose={() => {
                        storeMessagingList([]);
                        setShowMessagingListModal(false);
                      }}
                      size="5xl"
                    >
                      <Modal.Body>
                        <div className="flex flex-col bg-white">
                          <div className="flex justify-between items-center px-5">
                            <h1 className="my-6 text-3xl text-left text-bold text-gray-700 mr-4">
                              Export parameters
                            </h1>
                            <div className="flex">
                              <button
                                type="button"
                                className="btn btn-white !bg-gray-200 !border-slate-300 shadow-sm rounded-md outline-0 flex items-center justify-between"
                                onClick={() => setShowMessagingListModal(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary mx-1 flex items-center"
                                onClick={() =>
                                  doMSLExport("/admin/manage/messaging/emails")
                                }
                              >
                                <span>Export to Email Console</span>
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary mx-1 flex items-center"
                                onClick={() =>
                                  doMSLExport("/admin/manage/messaging/smses")
                                }
                              >
                                <span>Export to SMS Console</span>
                              </button>
                            </div>
                          </div>
                          <div className="flex items-center w-full px-5">
                            <WarningCircle
                              weight="bold"
                              className="w-5 h-5 mr-2"
                            />
                            <span>
                              All fields in asterisk (
                              {<span className="text-red-600">*</span>}) are
                              required.
                            </span>
                          </div>
                          <div className="flex flex-col px-5 bg-white my-4 w-auto">
                            {selectedFlatRows.length > 0 ? (
                              <></>
                            ) : (
                              <div className="flex w-full mb-4">
                                <div className="flex flex-col min-w-[24rem] mr-16">
                                  <label
                                    htmlFor="start_date"
                                    className="text-left mb-1"
                                  >
                                    {<span className="text-red-600">*</span>}{" "}
                                    Start Date
                                  </label>
                                  <div className="flex items-center">
                                    <Datetime
                                      inputProps={{
                                        className:
                                          "bg-gray-50 min-w-[24rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                        placeholder: "01-02-2020",
                                        id: "start_date",
                                        name: "start_date",
                                        required: true,
                                      }}
                                    />
                                    <CalendarBlank
                                      weight="bold"
                                      className="w-5 h-5 -ml-10 z-10"
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-col min-w-[24rem]">
                                  <label
                                    htmlFor="end_date"
                                    className="text-left mb-1"
                                  >
                                    {<span className="text-red-600">*</span>}{" "}
                                    End Date Range
                                  </label>
                                  <div className="flex items-center">
                                    <Datetime
                                      inputProps={{
                                        className:
                                          "bg-gray-50 min-w-[24rem] !border-0 !outline-0 focus:!outline-0 focus-within:!outline-0 focus:!border-0 focus-within:!border-0 rounded-md shadow-sm cursor-pointer",
                                        placeholder: "01-04-2020",
                                        id: "end_date",
                                        name: "end_date",
                                        required: true,
                                      }}
                                    />
                                    <CalendarBlank
                                      weight="bold"
                                      className="w-5 h-5 -ml-10 z-10"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="flex flex-col w-full mb-4">
                              <label
                                htmlFor="start_date"
                                className="text-left mb-1"
                              >
                                {<span className="text-red-600">*</span>} Column
                                to Export
                              </label>
                              <Select
                                className="w-full border border-gray-100 rounded-lg p-0 placeholder-gray-300 focus:outline-none focus:ring focus:ring-green-600 focus:shadow-md"
                                placeholder="Columns"
                                classNamePrefix="select"
                                options={columns.map((column) => ({
                                  value: column.accessor,
                                  label: column.Header,
                                }))}
                                isSearchable
                                onChange={(newVal) => {
                                  setMessageExportColumn(newVal.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </React.Fragment>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-12 md:mt-2 w-auto border border-slate-300 shadow-sm rounded-lg max-h-[40rem] overflow-y-scroll">
        <table
          className="table-auto w-full max-h-[40rem] overflow-y-scroll"
          {...getTableProps()}
        >
          <thead className="bg-white shadow-sm shadow-primary-200 sticky">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    className="table-col-head hover:cursor-pointer hover:bg-stone-200 transition-colors duration-200"
                  >
                    <div className="flex items-center">
                      <span>{column.render("Header")}</span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDown className="w-5 h-5" />
                          ) : (
                            <ArrowUp className="w-5 h-5" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
            <tr>
              <th
                colSpan={visibleColumns.length}
                scope="col"
                className="table-col-head"
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`border-y ${
                    onRowClick
                      ? "transition-colors hover:bg-slate-200 cursor-pointer"
                      : ""
                  }`}
                  onClick={() => {
                    multiSelectMode
                      ? row.toggleRowSelected()
                      : onRowClick(row.original);
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="table-col-body">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {data.length === 0 ? (
        <span className="text-2xl text-center block w-full my-5">
          No data found for this table
        </span>
      ) : (
        <div className="flex flex-col sm:flex-row w-full justify-center sm:justify-between items-center px-4 py-2 mt-2">
          <div className="flex my-2 sm:flex-col justify-center sm:justify-start w-full md:w-4/12">
            <div className="dataTables_info" role="status" aria-live="polite">
              Showing {start} to {end} of {count} entries
            </div>
          </div>
          <div className="flex my-2 sm:flex-col justify-center sm:justify-start w-full md:w-8/12 items-end">
            <div className="flex !p-0 max-w-fit min-h-fit">
              <Pagination
                currentPage={currentPage}
                layout="table"
                onPageChange={(page) => setCurrentPage(page)}
                showIcons={true}
                totalPages={count < pageSize ? 1 : Math.ceil(count / pageSize)}
                hidden={count < pageSize}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaginatedDataTable;
