import { useEffect, useState } from "react";
import ReactStars from "react-stars";
import urls from "../utilities/urls";
import NavMobile from "./Layouts/NavMobile";
import PageTitle from "./Layouts/PageTitle";
import { toast } from "react-toastify";
import TraineeCheckAccountStatus from "./registration-components/CheckAccountStatus";
import axios from "axios";

const TraineeFeedback = () => {
  const [availableCourses, setAvailableCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [courseUuid, setCourseUuid] = useState("");
  const [courseRating, setCourseRating] = useState(0);
  const [feedback, setFeedback] = useState({
    courseUuid: "",
    comment: "",
  });
  const token = localStorage.getItem("token");

  //getting the item of all the form
  const onHandle = (e) => {
    const confirming_array = { ...feedback };
    confirming_array[e.target.id] = e.target.value;
    setFeedback(confirming_array);
    console.log(feedback);
  };

  //on submitting feedback form data to api
  const onSubmitHandle = async (e) => {
    // alert('testing')
    e.preventDefault();
    feedback.rating = courseRating;
    await axios
      .post(urls.api + "course-feed-back/new/", feedback, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((result) => {
        if (!result.success === "true") toast.error(result.message);
        toast.success(result.message);
        toast.success("feedback sent");
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(availableCourses);

  // const postFeedback = async () => {
  //   const formData = new FormData();

  //   formData.append("courseUuid", courseUuid);
  //   formData.append("courseRating", courseRating);
  //   formData.append("comment", comment);
  //   console.log(formData);

  //   const postOptions = {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       Accept: "application/json",
  //       "Access-Control-Allow-Credentials": true,
  //     },
  //     body: formData,
  //   };
  //   fetch(urls.api + "course-feed-back/new/", postOptions)
  //     .then((response) => response.json())
  //     .then((json) => {
  //       if (json.success === true) {
  //         toast.success(json.message);
  //         toast.success("feedback sent");
  //         console.log(json);
  //       } else if (json.message !== null) {
  //         toast.error(json.message);
  //         console.log(json);
  //       } else {
  //         console.log("else statement");
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error.message);
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       console.log("done");
  //     });
  // };

  const coursesApplied = () => {
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(urls.api + "application/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === true) {
          console.log(json.data.applications);
          setAvailableCourses(json.data.applications);
        } else {
          console.log(json.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const [editProfile, setEditProfile] = useState(false);

  useEffect(() => {
    coursesApplied();
  }, []);

  return (
    <div class="navMenu">
      <div className="t">
        <NavMobile />
      </div>
      <div className="w">
        <PageTitle title="Feedback" />

        <TraineeCheckAccountStatus _token={token} />

        <div style={{ display: !editProfile ? `block` : `none` }}>
          <div class="row">
            <div class="col-lg-12 col-md-8">
              <div class="card">
                <div class="card-body">
                  We love to hear from you about our courses. To send feedback
                  on any of our courses, please select the course below and also
                  type your feedback in the box below.
                </div>
              </div>
            </div>
          </div>

          <div class="row ">
            <div class="col-lg-12 col-md-8">
              <div class="card">
                <div class="card-body">
                  <form
                    onSubmit={(e) => {
                      onSubmitHandle(e);
                    }}
                  >
                    <select
                      id="courseUuid"
                      required
                      className="form-control mb-3"
                      onChange={(e) => {
                        onHandle(e);
                      }}
                    >
                      <option value={""}>Select a course</option>
                      {availableCourses.map((x) =>
                        x.status === "confirmed" ? (
                          <>
                            <option value={x.courseUuid}>
                              {x.courseTitle}
                            </option>
                          </>
                        ) : (
                          <></>
                        )
                      )}
                    </select>

                    <textarea
                      required
                      placeholder="Enter your feedback"
                      className="form-control mb-3"
                      //value={comment}
                      id="comment"
                      onChange={(e) => {
                        onHandle(e);
                      }}
                    ></textarea>
                    <div>
                      <ReactStars
                        count={5}
                        onChange={(e) => setCourseRating(e)}
                        size={24}
                        color2={"#ffd700"}
                      />
                      {courseRating}
                    </div>
                    <input
                      type="submit"
                      value="Send Feedback"
                      className="btn btn-success"
                    />
                    {/* <button
                    className="btn btn-success"
                    onClick={() => postFeedback()}
                  >
                    Send Feedback <i className="fa fa-check"></i>{" "}
                  </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeFeedback;
