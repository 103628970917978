import { CheckCircle, WarningCircle, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import PaginatedDataTable, {
  SelectColumnFilter,
} from "../../components/PaginatedDataTable";
import PageBuilder from "../../components/layout/PageBuilder";
import { useData } from "../../providers/DataProvider";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../providers/AuthProvider";
import { flashMessage } from "../../helpers/flash";
import { triggerDownloadPipe } from "../../helpers";

const ConfirmedTrainees = () => {
  const {
    data: { trainees },
    loaders: { getTrainees },
    pagination: {
      trainees: { updatePage },
    },
  } = useData();

  const [loaded, setLoaded] = useState(false);
  const [alert, setAlert] = useState(true);
  const navigate = useNavigate();
  const client = useClient();

  const exportTrainees = (startDate, endDate, columns) => {
    flashMessage({ message: "Generating export", type: "info" });
    client("/api/v1/trainee-excel-reports/confirmed-trainee", {
      method: "POST",
      res: "file",
      data: {
        startDate: startDate,
        endDate: endDate,
        columns: columns,
      },
      credentials: true,
    })
      .then(triggerDownloadPipe("CONFIRMED_TRAINEES_EXPORT", "xlsx"))
      .catch((err) => {
        console.log(err);
        flashMessage({ message: err.message, type: "error" });
      });
  };

  useEffect(() => {
    if (!loaded) {
      getTrainees({
        getConfirmed: true,
      });
      setLoaded(true);
    }
  }, [loaded, getTrainees]);

  return (
    <PageBuilder name="Confirmed Trainees">
      <div className="flex flex-col w-full">
        {trainees?.confirmed?.count === 0
          ? alert ?? (
              <div className="flex items-center justify-between w-full px-3 py-2 mb-6 text-yellow-400 bg-yellow-100 shadow-md">
                <div className="flex items-center">
                  <WarningCircle weight="bold" className="w-5 h-5 mr-2" />
                  <span>No confirmed trainee record was found</span>
                </div>
                <button
                  className="btn bg-yellow-100 hover:bg-yellow-100 focus:bg-yellow-100 focus-within:bg-yellow-100 !h-6 !px-1 !py-0 my-0 !border-0 outline-0 flex items-center justify-between"
                  onClick={() => setAlert(false)}
                >
                  <X weight="bold" className="w-4 h-4 text-yellow-400" />
                </button>
              </div>
            )
          : alert ?? (
              <div className="flex items-center justify-between w-full px-4 py-2 mb-6 text-green-400 bg-green-100 shadow-md">
                <div className="flex items-center">
                  <CheckCircle weight="bold" className="w-5 h-5 mr-2" />
                  <span>
                    Request Processed Successfully. Total Retrieved Confirmed
                    Trainee Records:
                    {trainees?.confirmed?.count}
                  </span>
                </div>
                <button className="btn bg-green-100 hover:bg-green-100 focus:bg-green-100 focus-within:bg-green-100 !h-6 !px-1 !py-0 my-0 !border-0 outline-0 flex items-center justify-between">
                  <X weight="bold" className="w-4 h-4 text-green-400" />
                </button>
              </div>
            )}
      </div>
      <PaginatedDataTable
        title="List of Confirmed Trainees"
        exports={{ excel: true, messagingList: true }}
        refresh
        columns={[
          { Header: "Trainee First Name", accessor: "firstName" },
          { Header: "Trainee Last Name", accessor: "surname" },
          {
            Header: "Title",
            accessor: (row) => {
              return row.courseUuid ? row.courseTitle : row.programName;
            },
          },
          { Header: "RTC", accessor: "rtc" },
          {
            Header: "Education Level",
            accessor: "educationLevel[0].qualificationObtained",
          },
          {
            Header: "Session Start Date",
            accessor: "calendar.startDate",
          },
          {
            Header: "Session End Date",
            accessor: "calendar.endDate",
          },
          {
            Header: "Session Payment Deadline",
            accessor: "calendar.payCloseDate",
          },
          {
            Header: "Application Status",
            accessor: "applicationStatus",
            Filter: SelectColumnFilter,
          },
          { Header: "Email Address", accessor: "email" },
          { Header: "Gender", accessor: "gender", Filter: SelectColumnFilter },
          { Header: "Mobile Number", accessor: "phoneNumber" },
          { Header: "Created At", accessor: "createdAt" },
          { Header: "Updated At", accessor: "updatedAt" },
        ]}
        data={trainees?.confirmed.data}
        count={trainees?.confirmed.count}
        currentPage={trainees?.confirmed.page}
        setCurrentPage={(page) => {
          updatePage({ newPage: page, category: "confirmed" });
          setLoaded(false);
        }}
        onRowClick={(row) => {
          navigate(
            `/admin/manage/trainee/applicants/details/${row.applicationUuid}`
          );
        }}
        exportToExcel={exportTrainees}
      />
    </PageBuilder>
  );
};

export default ConfirmedTrainees;
